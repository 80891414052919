<template>
  <ModalPopupComp :contextWidth="400">
    <div class="title">{{ $t("forgot_password") }}</div>
    <div class="header">
      <div class="item" :class="isPhoneState ? 'item-active' : ''" @click="isPhoneState = true">
        {{ $t("phone") }}
      </div>
      <div class="item" :class="isPhoneState ? '' : 'item-active'" @click="isPhoneState = false">
        {{ $t("email") }}
      </div>
    </div>
    <div class="phone" v-if="isPhoneState">
      <div>+{{ areaCode }}</div>
      <input type="text" v-model="phone" :placeholder="$t('enter_phone_number')" />
    </div>
    <div class="email" v-else>
      <img src="@/assets/login/email_icon.webp" alt="" />
      <input type="text" v-model="email" :placeholder="$t('enter_email_address')" />
    </div>
    <div class="code">
      <img src="@/assets/login/code_icon.webp" alt="" />
      <input type="text" v-model="code" :placeholder="$t('enter_verification_code')" />
      <div class="get-code" @click="getCodeHandle">{{ codeTitle }}</div>
    </div>
    <div class="pw">
      <img src="@/assets/login/pw_icon.webp" alt="" />
      <input type="text" v-model="password" :placeholder="$t('enter_password')" />
      <img src="@/assets/login/invisible_icon.png" alt="" />
    </div>
    <div class="btn" @click="submitHandle">{{ $t("submit") }}</div>
    <div class="desc">
      {{ $t("website_protection") }}
    </div>
  </ModalPopupComp>
</template>

<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  props: {
    isPhone: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isPhoneState: this.isPhone,
      phone: "",
      phone2: "",
      email: "",
      email2: "",
      code: "",
      password: "",
      codeTitle: this.$t("verification_code"),
      areaCode: "55",
    };
  },
  components: { ModalPopupComp },
  methods: {
    getCodeHandle() {
      if (this.codeTitle === this.$t("sent")) {
        this.$toast.error(this.$t("verification_code_sent"));
        return;
      }

      let login_type = 5;
      let login_name = this.email;
      if (this.isPhoneState) {
        const value = this.$tools.isNumeric(this.phone);
        if (value === false) {
          this.$toast.error(this.$t("enter_phone_number"));
          return;
        }

        login_type = 4;
        login_name = this.areaCode + this.phone;
      } else {
        const value = this.$tools.isValidEmail(this.email);
        if (value === false) {
          this.$toast.error(this.$t("enter_email_address"));
          return;
        }
      }

      const data = {
        action: "find_password_captcha",
        data: {
          login_type,
          login_name,
        },
      };

      this.$http
        .post("login", data)
        .then(() => {
          this.codeTitle = this.$t("sent");
          if (this.isPhoneState) {
            this.phone2 = this.phone;
          } else {
            this.email2 = this.email;
          }
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    submitHandle() {
      if (this.code === "") {
        this.$toast.error(this.$t("enter_verification_code"));
        return;
      }

      let login_type = 5;
      let login_name = this.email;
      if (this.isPhoneState) {
        if (this.phone !== this.phone2) {
          this.$toast.error(this.$t("phone_verification_failed"));
          return;
        }
        login_type = 4;
        login_name = this.areaCode + this.phone;
      } else {
        if (this.email !== this.email2) {
          this.$toast.error(this.$t("email_verification_failed"));
          return;
        }
      }

      const data = {
        action: "find_password",
        data: {
          login_type,
          login_name,
          captcha: this.code,
          login_pwd: this.password,
        },
      };

      this.$http
        .post("login", data)
        .then(() => {
          this.$emit("endChangedPasswordEvent");
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 42px auto 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--color-popup-title);
}
.header {
  margin: 20px auto 0;
  display: flex;
  width: 216px;
  height: 40px;
  justify-content: space-around;

  .item {
    width: 44px;
    height: 40px;
    color: rgba($color: #ffffff, $alpha: 0.5);
  }

  .item-active {
    color: #edd184;
    border-bottom: 2px #e9c97e solid;
  }
}

.phone {
  margin: 30px auto 0;
  display: flex;
  background: #202020;
  width: 320px;
  height: 40px;
  border-radius: 8px;
  div {
    margin: 10px;
    width: 36px;
    height: 20px;
    border-radius: 4px;
    background: #383838;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #ebcb7f;
  }
  input {
    flex: 1;
    background: transparent;
    border: 0;
    outline-style: none;
    color: white;
  }
}

.email {
  margin: 30px auto 0;
  display: flex;
  background: #202020;
  width: 320px;
  height: 40px;
  border-radius: 8px;
  img {
    margin: auto 20px;
    width: 20px;
    height: 20px;
  }
  input {
    flex: 1;
    background: transparent;
    border: 0;
    outline-style: none;
    color: white;
  }
}

.code {
  margin: 20px auto 0;
  display: flex;
  background: #202020;
  width: 320px;
  height: 40px;
  border-radius: 8px;
  img {
    margin: auto 20px;
    width: 20px;
    height: 20px;
  }
  input {
    flex: 1;
    background: transparent;
    border: 0;
    outline-style: none;
    color: white;
  }

  .get-code {
    margin: 5px;
    border: 1px solid #e9c97e;
    box-sizing: border-box;
    width: 86px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    color: #e9c97e;
    text-align: center;
  }
}
.pw {
  margin: 20px auto 0;
  display: flex;
  background: #202020;
  width: 320px;
  height: 40px;

  img {
    margin: auto 20px;
    width: 20px;
    height: 20px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    outline-style: none;
    color: white;
  }
}

.btn {
  margin: 40px auto 0;
  width: 320px;
  height: 40px;
  border-radius: 8px;
  background: linear-gradient(90deg, #e9bf72 0%, #eccf83 100%);
  font-size: 14px;
  font-weight: 500;
  color: #070704;
  text-align: center;
  line-height: 40px;
}

.desc {
  margin: 60px auto 20px;
  width: 326px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  color: rgba($color: #ffffff, $alpha: 0.4);
}
</style>
