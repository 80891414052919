<template>
  <ModalPopupComp>
    <div class="title">Falha ao efetuar a transação</div>
    <div class="des1">Saldo insuficiente da conta</div>
    <div class="des2">Recompensa aleatória de recarga</div>
    <div class="value">2%~200%</div>
    <div class="btn" @click="handleOfDeposit">{{ $t("deposit") }}</div>
  </ModalPopupComp>
</template>
<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: {
    ModalPopupComp,
  },
  methods: {
    handleOfDeposit() {
      this.$emit("depositEvent");
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin-top: 25px;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-popup-title);
}

.des1 {
  margin-top: 22.5px;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
}

.des2 {
  margin-top: 29px;
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
}

.value {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #e0c908;
}

.btn {
  margin: 13px 25px 17px;
  height: 40px;
  background: var(--cate-selected-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--cate-text-selected-color);
}
</style>
