<template>
  <div class="account">
    <div class="wrapper">
      <CategoryComp class="cate" :list="cateList" v-model:index="index"></CategoryComp>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CategoryComp from "@/components/CategoryComp.vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:title"],
  data() {
    return {
      index: 0,
      cateList: [
        { title: this.$t("general"), path: "m-general" },
        { title: this.$t("security"), path: "m-secure" },
        { title: this.$t("freeze"), path: "m-freeze" },
      ],
    };
  },
  components: { CategoryComp },
  mounted() {
    this.$emit("update:title", this.$t("my_account"));
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.account {
  display: flex;
  flex-direction: column;
}
.wrapper {
  margin: 0 15px;
  display: flex;
  flex-direction: column;

  .cate {
    margin-top: 10px;
    margin-bottom: 8px;
  }
}
</style>
