<template>
  <div class="container">
    <div class="wrapper">
      <mine-empty v-if="tableData.length === 0"></mine-empty>
      <div v-else-if="isMobile" class="m-table">
        <div class="m-cell" v-for="(cell, index) in tableData" :key="index">
          <div class="m-item">
            <div class="m-title">{{ $t("date") }}</div>
            <div class="m-value">{{ cell.create_time }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("sn") }}</div>
            <div class="m-value">{{ cell.id }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("type") }}</div>
            <div class="m-value">{{ cell.pix }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("amount") }}</div>
            <div :class="cell.disbursement_amount >= 0 ? 'm-value-positive' : 'm-value-negative'">
              R${{ parseFloat(cell.disbursement_amount).toFixed(2) }}
            </div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("status") }}</div>
            <div class="m-value">{{ withdrawalStatus(index) }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("remarks") }}</div>
            <div class="m-value">{{ cell.notes }}</div>
          </div>
        </div>
        <LoadMoreComp
          :curCount="tableData.length"
          :totalCount="total"
          @loadMoreEvent="fetchWithdrawalList(page + 1)"
        ></LoadMoreComp>
      </div>
      <div v-else class="table">
        <div class="header">
          <div>{{ $t("date") }}</div>
          <div>{{ $t("sn") }}</div>
          <div>{{ $t("type") }}</div>
          <div>{{ $t("amount") }}</div>
          <div>{{ $t("status") }}</div>
        </div>
        <div class="cell" v-for="(item, index) in tableData" :key="index">
          <div>{{ item.create_time }}</div>
          <div>{{ item.id }}</div>
          <div>{{ item.pix }}</div>
          <div :class="item.disbursement_amount >= 0 ? 'value-positive' : 'mvalue-negative'">
            R${{ parseFloat(item.disbursement_amount).toFixed(2) }}
          </div>
          <div>{{ withdrawalStatus(index) }}</div>
        </div>
      </div>
      <el-pagination
        v-if="isMobile === false && tableData.length !== 0"
        style="justify-content: right"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  components: { MineEmpty, LoadMoreComp },
  data() {
    return {
      tableData: [],
      isMobile: false,
      pageSize: 10,
      page: 0,
      total: 0,
    };
  },
  mounted() {
    this.isMobile = this.$tools.isMobile();
    this.fetchWithdrawalList(0);
  },
  methods: {
    changePage(index) {
      this.fetchWithdrawalList(index - 1);
    },
    fetchWithdrawalList(page) {
      const data = {
        action: "user_withdrawal_list",
        data: {
          amount_type: 1,
          page,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("userWallet", data)
        .then((response) => {
          if (this.isMobile) {
            this.tableData.push(...response.data.data_list);
          } else {
            this.tableData = response.data.data_list;
          }

          this.page = response.data.page;
          this.total = response.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    withdrawalStatus(index) {
      const st = this.tableData[index].status;
      let str = "";
      switch (st) {
        case 1:
          // 待处理
          str = "Pendente";
          break;
        case 2:
          // 代付失败
          str = "Falhar";
          break;
        case 3:
          // 锁定中
          str = "Trancar";
          break;
        case 4:
          // 代付中
          str = "Pagamento esperando";
          break;
        case 5:
          // Completado
          str = "Completado";
          break;
        case 6:
          // 已拒绝
          str = "Rejeitado";
          break;
        case 7:
          // 已退单
          str = "Pedido de devolução";
          break;
        case 8:
          // 已冻结
          str = "Congelar";
          break;
        case 9:
          // 已关闭
          str = "Fecho";
          break;
        case 10:
          // 拒绝并挂起
          str = "Recusar e desligar";
          break;
        case 11:
          // 退单并挂起
          str = "Voltar e desligar";
          break;
        case 12:
          // 待审核
          str = "A ser revisado";
          break;
        default:
          // 未知
          str = "Desconhecido";
          break;
      }

      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-pagination.is-background .btn-prev) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-prev:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled).is-active) {
  background-color: #e9c97e;
  color: #070704;
}

:deep(.el-pagination.is-background .btn-next) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-next:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}
.container {
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .m-table {
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      .m-cell {
        margin-bottom: 8px;
        box-sizing: border-box;
        background: var(--mine-card-bg-color);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 10px 16px 7px;
        .m-item {
          display: flex;
          margin-bottom: 8px;
          .m-title {
            width: 30%;
            font-size: 14px;
            font-weight: 250;
            color: #3e7bb0;
            text-align: left;
          }
          .m-value {
            flex: 1;
            font-size: 14px;
            font-weight: 250;
            color: #ffffff;
            opacity: 0.8;
            text-align: left;
          }

          .m-value-positive {
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            color: #42f388;
            opacity: 0.8;
            text-align: left;
          }

          .m-value-negative {
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            color: #d04835;
            opacity: 0.8;
            text-align: left;
          }
        }
      }
    }

    .table {
      background: #1a1919;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .header {
        margin: 0 30px;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        height: 56px;
        justify-content: center;
        align-items: center;
        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }

      .cell {
        margin: 0 10px 8px;
        padding: 0 20px;
        display: flex;
        font-size: 12px;
        color: rgba($color: #ffffff, $alpha: 0.8);
        height: 40px;
        border-radius: 8px;
        background: #1f1e1e;
        box-sizing: border-box;
        border: 1px solid #2b2b2b;
        justify-content: space-between;
        align-items: center;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .value-positive {
          flex: 1;
          font-size: 12px;
          color: #42f388;
          opacity: 0.8;
          text-align: left;
        }

        .value-negative {
          flex: 1;
          font-size: 12px;
          color: #d04835;
          opacity: 0.8;
          text-align: left;
        }
      }
    }
  }
}
</style>
