import { createApp, reactive } from "vue";

import hongbaoPage from "../components/HongBao.vue";

const info = reactive({
  show: false,
  amount: 0,
  clickCallback: () => {}, // 点击红包的回调
});

const $hb = createApp(hongbaoPage, { info }).mount(document.createElement("div"));

const hbObj = {
  show(amount, clickCallback) {
    info.show = true;
    info.amount = amount;
    info.clickCallback = clickCallback;
    document.body.appendChild($hb.$el);
  },

  hide() {
    info.show = false;
  },
};
export { hbObj };
