<template>
  <div class="container">
    <div class="wrapper">
      <div class="exp">expiracao {{ order?.expiration_time }}</div>
      <div class="info-box">
        <div class="order-id">{{ order?.orderId }}</div>
        <div class="line"></div>
        <div class="amount-box">
          <div>Valor</div>
          <div>{{ formatCurrency(order?.amount / 100) }}</div>
        </div>
      </div>
      <div class="qr-box">
        <div class="des">
          Abra o app com sua chave PlX cadastradaescolha Pagar com Pix e escaneie o QR Code ou copie e cole o código.<br /><br />
          Código de pagamento único, por favor, nǎo pague repetidamente, isso causará depósito anormal.<br />
        </div>
        <QrcodeVue class="qrcode" :value="order?.qrcodeRaw" :size="200"></QrcodeVue>
        <div class="btn" @click="handleOfCopy">
          <div>PIX CÓPIA É COLA</div>
          <img src="@/assets/pay/copy.webp" alt="" />
        </div>
      </div>
      <div class="ext-box">
        <div>POWERED BY</div>
        <img src="@/assets/pay/pix.webp" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import clipboard from "clipboard";
export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    this.$emit("update:title", "Depósito");
    this.order = JSON.parse(sessionStorage.getItem("order"));
  },
  methods: {
    handleOfCopy() {
      clipboard.copy(this.order.qrcodeRaw);
      this.$toast.success("ID de transação copiado");
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  width: 100vw;
  height: 100vh;

  .wrapper {
    background: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 18px 30px;

    .exp {
      margin-right: 15px;
      text-align: right;
      color: #9e9e9e;
      font-size: 12px;
      font-weight: normal;
    }

    .info-box {
      margin-top: 5px;
      border: 1px solid #dde7f0;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 10px 15px 0;

      .order-id {
        text-align: right;
        color: #9e9e9e;
        font-size: 12px;
        font-weight: normal;
      }

      .line {
        margin-top: 10px;
        width: 100%;
        height: 1px;
        background: #d8d8d8;
      }

      .amount-box {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div:nth-child(1) {
          color: #000000;
          font-size: 12px;
          font-weight: bold;
        }

        & > div:nth-child(2) {
          color: #000000;
          font-size: 32px;
          font-weight: bold;
        }
      }
    }

    .qr-box {
      margin-top: 10px;
      border: 1px solid #d6d6d6;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 15px;
      display: flex;
      flex-direction: column;

      .des {
        color: #000000;
        font-size: 10px;
        font-weight: bold;
      }

      .qrcode {
        align-self: center;
        margin-top: 20px;
      }

      & > .btn {
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 47px;
        padding: 0 12px;
        border-radius: 10px;

        & > div {
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
        }

        & > img {
          width: 20px;
          height: 21px;
        }
      }
    }

    .ext-box {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > div {
        color: #9e9e9e;
        font-size: 12px;
        font-weight: normal;
      }

      & > img {
        margin-top: 10px;
        width: 71px;
        height: auto;
      }
    }
  }
}
</style>
