<template>
  <div class="games">
    <div class="card" v-for="item in showList" :key="item.game_id">
      <GameCard :game="item"></GameCard>
    </div>
  </div>
</template>
<script>
import GameCard from "./GameCard.vue";
export default {
  components: { GameCard },
  props: {
    showMax: {
      type: Number,
      default: 9,
    },
    gameList: Array,
  },
  computed: {
    showList() {
      if (!this.gameList) return [];
      return this.gameList.slice(0, this.showMax);
    },
  },
};
</script>
<style lang="scss" scoped>
.games {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 10px;
  margin-bottom: 6px;

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 90px;
    height: auto;
  }
}
</style>
