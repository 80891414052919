<template>
  <div class="login-input">
    <div class="wrapper">
      <div class="box" :class="inputStatusStyle">
        <div class="left">
          <img v-if="icon !== ''" :src="icon" alt="" />
          <div v-else>{{ areaCode }}</div>
        </div>
        <input
          :disabled="inputDisabled"
          :type="showInputType"
          :placeholder="placeholder"
          v-model="inputValueStr"
          @input="handleOfInput"
          @focus="handleFocus"
          @blur="handleBlur"
        />
        <img
          class="ext-img"
          v-if="type === 'password'"
          :src="isPwVisible ? require('@/assets/login/visible_icon.png') : require('@/assets/login/invisible_icon.png')"
          alt=""
          @click="isPwVisible = !isPwVisible"
        />
        <slot></slot>
      </div>
      <div class="err" v-show="errMsg !== ''">
        <img src="@/assets/login/wraning.png" alt="" />
        <div>{{ errMsg }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    areaCode: {
      type: String,
      default: "+55",
    },
    icon: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errMsg: {
      type: String,
      default: "",
    },
    isMessageCode: {
      type: Boolean,
      default: true,
    },
    inputValue: {
      type: String,
      default: "",
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPwVisible: false,
      inputValueStr: this.inputValue,
      inputStatus: 0, // 0: 初始状态 1: 输入中 2: 正确输入 3：错误数据
    };
  },
  watch: {
    inputValue(val) {
      this.inputValueStr = val;
      this.handleBlur();
    },
    inputValueStr(val) {
      this.$emit("update:inputValue", val);
    },
    errMsg(val) {
      if (val !== "") {
        this.inputStatus = 3;
      } else {
        this.inputStatus = 0;
      }
    },
  },
  mounted() {},
  computed: {
    inputStatusStyle() {
      switch (this.inputStatus) {
        case 0:
          return "normal";
        case 1:
          return "focused";
        case 2:
          return "correct";
        case 3:
          return "error";
        default:
          return "";
      }
    },
    showInputType() {
      if (this.type === "password") {
        return this.isPwVisible ? "text" : "password";
      } else {
        return this.type;
      }
    },
  },
  methods: {
    handleFocus() {
      this.inputStatus = 1;
    },
    handleBlur() {
      if (this.inputValueStr === "") {
        this.inputStatus = 0;
      } else {
        if (this.type === "password") {
          if (this.inputValueStr.length < 6 || this.inputValueStr.length > 30) {
            this.inputStatus = 3;
          } else {
            this.inputStatus = 2;
          }
        } else if (this.type === "tel") {
          if (this.inputValueStr.length === 11) {
            this.inputStatus = 2;
          } else {
            this.inputStatus = 3;
          }
        } else if (this.type === "email") {
          if (this.$tools.isValidEmail(this.inputValueStr)) {
            this.inputStatus = 2;
          } else {
            this.inputStatus = 3;
          }
        } else {
          this.inputStatus = 0;
        }
      }
    },
    handleOfInput() {
      this.$emit("update:errMsg", "");
    },
  },
};
</script>
<style lang="scss" scoped>
.login-input {
  width: 100%;
  display: flex;
  // flex-direction: column;

  & > .wrapper {
    width: 100%;
    margin-bottom: 5px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .normal {
      border: 1px solid #0a1f32;
    }

    .focused {
      border: 1px solid #526382;
    }

    .correct {
      border: 1px solid #10b349;
    }

    .error {
      border: 1px solid #ff5000;
    }

    .box {
      width: 100%;
      background: #0a1f32;
      border-radius: 8px;
      height: 50px;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #277ce6;
        font-size: 12px;
        font-weight: 900;
        padding: 15px;

        img {
          width: 17px;
          height: 17px;
        }
      }

      input {
        all: unset;
        flex: 1;
        // margin: 0;
        background: transparent;
        border: 0;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        outline-style: none;
        text-align: left;
      }

      input::placeholder {
        color: #96a7c6;
        font-size: 16px;
        font-weight: 500;
      }

      .ext-img {
        margin-right: 15px;
        width: 15px;
        height: 12px;
      }
    }

    .err {
      margin-top: 3px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ff7373;
      font-size: 9px;
      font-weight: 500;

      img {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }
}
</style>
