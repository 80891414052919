<template>
  <div class="container">
    <div class="wrapper">
      <div class="wrap1">
        <div class="title">{{ $t("my_wallet") }}</div>
        <div class="collection">
          <div class="box">
            <img class="icon1" src="@/assets/wallet_icon1.png" alt="" />
            <div class="subTitle">{{ $t("account_balance") }}</div>
            <div class="data">
              <div>R$</div>
              <div>{{ coins }}</div>
            </div>
            <div class="btn" @click="handlerOfDeposit">{{ $t("deposit") }}</div>
          </div>
          <div class="box">
            <img class="info" src="../../assets/info.png" alt="" @click="showWithdrawalRules" />
            <img class="icon2" src="@/assets/wallet_icon2.png" alt="" />
            <div class="subTitle">{{ $t("withdrawal_amount") }}</div>
            <div class="data">
              <div>R$</div>
              <div>{{ withdrawable }}</div>
            </div>
            <div class="btn" @click="withdrawalHandle">
              {{ $t("withdrawal") }}
            </div>
          </div>
          <div class="box">
            <img class="info" src="../../assets/info.png" alt="" @click="showBountyRules" />
            <div class="subTitle">{{ $t("bonuses") }}</div>
            <div class="data">
              <div>R$</div>
              <div>{{ bonus }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap2" v-if="taskDetail !== null">
        <div class="title">{{ $t("bonus_to_real_money") }}</div>
        <div class="wrap2-context">
          <div class="real-gold-info">
            <div class="subTitle">{{ $t("conversion_bounty") }}</div>
            <div class="data">
              <div>R$</div>
              <div>{{ parseFloat(taskDetail.bounty_amount).toFixed(2) }}</div>
            </div>
            <div class="progress">
              <div class="progress-value">{{ taskProgress }}%</div>
              <div class="total">
                <div class="current" :style="{ width: taskProgress + '%' }"></div>
              </div>
              <div class="range">
                <div class="min">R${{ taskDetail.dama_done }}</div>
                <div class="max">R${{ taskDetail.dama_target }}</div>
              </div>
            </div>
          </div>
          <div class="describe">
            <span>Você precisa para concluir</span>
            <span style="color: #15ad51">&nbsp;R${{ taskDetail.dama_target }}&nbsp;</span>
            <span>count apostas válidas, então até</span>
            <span style="color: #15ad51">&nbsp;R${{ taskDetail.bounty_amount }}&nbsp;</span>
            <span>dinheiro real podem ser convertidos. Conversão deduzirá Correspondente dinheiro de bónus.</span>
          </div>
          <div :class="taskProgress === 100 ? 'btn' : 'btn-disable'" @click="bounty2Coins">{{ $t("convert") }}</div>
        </div>
      </div>
    </div>

    <WithdrawalRules
      :style="{ 'margin-bottom': modalPopupMarginBottom }"
      v-show="isShowRulesOfWithdrawal"
      @close="isShowRulesOfWithdrawal = false"
    ></WithdrawalRules>
    <BountyRules
      :style="{ 'margin-bottom': modalPopupMarginBottom }"
      v-show="isShowRulesOfBounty"
      @close="isShowRulesOfBounty = false"
    ></BountyRules>
    <DrawMoney
      v-if="isShowDrawMoney"
      :userInfo="userInfo"
      @close="isShowDrawMoney = false"
      @endSaveEvent="userInfoEndSaveEvent"
    ></DrawMoney>
    <DrawMoney2
      v-if="isShowDrawMoney2"
      :userInfo="userInfo"
      :withdrawable="withdrawable"
      @close="isShowDrawMoney2 = false"
      @endDrawMoney2Event="endDrawMoney2Event"
    ></DrawMoney2>
  </div>
</template>

<script>
import DrawMoney from "./wallet/DrawMoney.vue";
import DrawMoney2 from "./wallet/DrawMoney2.vue";
import WithdrawalRules from "./wallet/WithdrawalRules.vue";
import BountyRules from "./wallet/BountyRules.vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:title"],
  components: {
    DrawMoney,
    DrawMoney2,
    WithdrawalRules,
    BountyRules,
  },
  data() {
    let modalPopupMarginBottom = "0px";
    if (location.pathname.includes("m-") === false) {
      const tabBarHeight = getComputedStyle(document.documentElement).getPropertyValue("--tab-bar-height");
      modalPopupMarginBottom = tabBarHeight;
    }
    return {
      wallet: {},
      isShowRulesOfWithdrawal: false,
      isShowDescOfWithdrawal: false,
      isShowRulesOfBounty: false,
      isShowDrawMoney: false,
      isShowDrawMoney2: false,
      taskDetail: null,
      userInfo: null,
      modalPopupMarginBottom,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("my_wallet"));
    this.getUserWallet();
    this.getUserTask();
  },
  methods: {
    endDrawMoney2Event() {
      this.isShowDrawMoney2 = false;
      this.getUserWallet();
      this.getUserTask();
      this.$eventBus.emit("refreshHeaderCoins");
    },
    withdrawalHandle() {
      const data = {
        action: "my_account",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          const data = response.data.data;
          this.userInfo = data;
          if (
            data.firstname === null ||
            data.lastname === null ||
            data.birthday === null ||
            data.customer_cert === null ||
            data.email === null ||
            data.phone_verify === 0
          ) {
            if (this.$tools.isMobile()) {
              this.$router.push({
                path: "/m-verification-info1",
                query: { withdrawable: this.withdrawable, amountType: 1 },
              });
            } else {
              this.isShowDrawMoney = true;
            }
          } else {
            if (this.$tools.isMobile()) {
              this.$router.push({
                path: "/m-verification-info2",
                query: { withdrawable: this.withdrawable, amountType: 1 },
              });
            } else {
              this.isShowDrawMoney2 = true;
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    userInfoEndSaveEvent() {
      this.isShowDrawMoney = false;
      this.withdrawalHandle();
    },
    bounty2Coins() {
      if (this.taskProgress !== 100) {
        return;
      }

      const data = {
        action: "user_bounty_to_coins",
        data: {},
      };
      this.$http
        .post("userWallet", data)
        .then(() => {
          this.getUserWallet();
          this.getUserTask();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getUserTask() {
      const data = {
        action: "query_user_bounty_task",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.taskDetail = response.data.user_bounty_task;
          if (this.taskDetail !== null && this.taskDetail.dama_done === null) {
            this.taskDetail.dama_done = 0;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getUserWallet() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.wallet = response.data.user_wallet;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handlerOfDeposit() {
      this.$eventBus.emit("showDeposit", "deposit");
    },
    showWithdrawalRules() {
      this.isShowRulesOfWithdrawal = true;
    },
    showBountyRules() {
      this.isShowRulesOfBounty = true;
    },
    closeRules() {
      this.isShowRulesOfWithdrawal = false;
      this.isShowRulesOfBounty = false;
      this.isShowDescOfWithdrawal = false;
    },
  },
  computed: {
    taskProgress() {
      return ((this.taskDetail.dama_done / this.taskDetail.dama_target) * 100).toFixed(0);
    },
    bonus() {
      if (this.wallet && this.wallet.bonus) {
        return parseFloat(this.wallet.bonus).toFixed(2);
      } else {
        return "0.00";
      }
    },
    coins() {
      if (this.wallet && this.wallet.coins) {
        return parseFloat(this.wallet.coins).toFixed(2);
      } else {
        return "0.00";
      }
    },
    withdrawable() {
      if (this.wallet && this.wallet.withdrawable) {
        return parseFloat(this.wallet.withdrawable).toFixed(2);
      } else {
        return "0.00";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .wrapper {
    margin: 0 140px;
    display: flex;
    flex-direction: column;
  }

  .wrap1 {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 40px;
      margin-bottom: 18px;
      text-align: left;
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
    }

    .collection {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .box {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #070704;
        background: #1f1e1e;
        border-radius: 16px;
        margin-right: 16px;
        position: relative;

        .info {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 16px;
          right: 16px;
        }

        .icon1 {
          position: absolute;
          width: 75.5px;
          height: 78.5px;
          left: 25px;
          bottom: 30px;
          z-index: 0;
        }

        .icon2 {
          position: absolute;
          width: 96px;
          height: 96px;
          right: 25px;
          bottom: 30px;
          z-index: 0;
        }

        .subTitle {
          margin: 40px auto 18px;
          font-size: 20px;
          text-align: center;
          color: #eac275;
        }

        .data {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #eac275;

          div:first-child {
            font-size: 24px;
            font-weight: 600;
            align-self: self-end;
            margin-right: 6px;
            margin-bottom: 10px;
          }

          div:last-child {
            font-size: 48px;
            font-weight: 600;
          }
        }

        .btn {
          z-index: 1;
          margin-left: 25px;
          margin-right: 25px;
          margin-bottom: 30px;
          height: 40px;
          background: #eac275;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: #070704;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      .box:last-child {
        margin-right: 0;
      }
    }
  }
  .wrap2 {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 40px;
      margin-bottom: 18px;
      text-align: left;
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
    }
    .wrap2-context {
      margin-bottom: 60px;
      background: #0c411f;
      border-radius: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .real-gold-info {
        background: #1f1e1e;
        display: flex;
        flex-direction: column;
        align-items: center;

        .subTitle {
          margin: 40px auto 0;
          font-size: 20px;
          line-height: 30px;
          color: #1b803b;
        }

        .data {
          margin: 6px auto 0;
          display: flex;
        }

        .data > div:first-child {
          font-size: 24px;
          font-weight: 600;
          line-height: 30px;
          height: 30px;
          color: var(--primary-text-color);
          align-self: self-end;
          margin-right: 6px;
          margin-bottom: 10px;
        }

        .data > div:last-child {
          font-size: 48px;
          font-weight: 600;
          line-height: 56px;
          height: 56px;
          color: var(--primary-text-color);
        }

        .progress {
          margin-top: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .progress-value {
            margin-bottom: 20px;
            color: #e9c97e;
            font-size: 24px;
            font-weight: 500;
          }

          .total {
            margin: auto;
            max-width: 480px;
            min-width: 480px;
            height: 20px;
            border-radius: 10px;
            background: #121110;
            display: flex;
            align-items: center;

            .current {
              margin: 0;
              height: 14px;
              border-radius: 10px;
              background: #15ad51;
            }
          }

          .range {
            margin-top: 4px;
            max-width: 480px;
            min-width: 480px;
            color: #bcbcbc;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
          }
        }
      }
      .describe {
        margin: 10px auto 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #bcbcbc;
      }

      .btn {
        margin-top: 20px;
        margin-bottom: 40px;
        background: var(--color-btn-bg);
        max-width: 480px;
        min-width: 480px;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;
        color: var(--color-btn-text);
      }

      .btn-disable {
        margin-top: 20px;
        margin-bottom: 40px;
        background: #444444;
        max-width: 480px;
        min-width: 480px;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;
        color: #070704;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      margin: 28px 15px 0;
      overflow-y: auto;
    }

    .wrapper .wrap1 .title {
      display: none;
    }

    .wrapper .wrap1 .collection .box {
      flex: 0 1 100%;
      margin-right: 0;
      margin-bottom: 15px;
      border-radius: 8px;
      background: #1b1b1b;
    }

    .wrapper .wrap1 .collection .box .info {
      top: 12px;
      right: 12px;
    }

    .wrapper .wrap1 .collection .box .subTitle {
      margin: 20px auto 13px;
      font-size: 16px;
      font-weight: 500;
      color: #1c813b;
    }

    .wrapper .wrap1 .collection .data {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .wrapper .wrap1 .collection .data div:first-of-type {
      font-size: 24px;
      font-weight: 600;
    }

    .wrapper .wrap1 .collection .data div:last-of-type {
      font-size: 40px;
      font-weight: 600;
    }

    .wrapper .wrap1 .collection .box .btn {
      background: var(--color-btn-bg);
      color: var(--color-btn-text);
      margin: 25px;
      height: 40px;
      font-size: 18px;
      font-weight: 800;
    }

    .wrapper .wrap2 .title {
      margin: 5px 0 10px;
      font-size: 14px;
      font-weight: 500;
    }

    .wrapper .wrap2 .wrap2-context {
      margin-bottom: 60px;
      border-radius: 8px;
    }

    .wrapper .wrap2 .wrap2-context .real-gold-info {
      flex: 0 1 100%;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
    }

    .wrapper .wrap2 .wrap2-context .real-gold-info .subTitle {
      margin-top: 25px;
      margin-bottom: 10px;
      font-weight: 300;
    }

    .wrapper .wrap2 .wrap2-context .real-gold-info .progress {
      width: 100%;
      // margin-left: 25px;
      // margin-right: 25px;
    }

    .wrapper .wrap2 .wrap2-context .real-gold-info .progress .total {
      margin-left: 25px;
      margin-right: 25px;
      min-width: 300px;
    }

    .wrapper .wrap2 .wrap2-context .real-gold-info .progress .range {
      margin-left: 25px;
      margin-right: 25px;
      min-width: 300px;
    }

    .wrapper .wrap2 .wrap2-context .describe {
      margin-left: 20px;
      margin-right: 20px;
      font-size: 12px;
      font-weight: normal;
    }

    .wrapper .wrap2 .wrap2-context .btn,
    .wrapper .wrap2 .wrap2-context .btn-disable {
      margin: 25px;
      min-width: 300px;
      height: 40px;
      font-size: 16px;
      font-weight: 800;
    }
  }
}
</style>
