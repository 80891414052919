<template>
  <div class="container">
    <div class="search-box">
      <input type="text" :placeholder="$t('search')" v-model="searchInputValue" @keyup.enter="handleOfSearch" />
      <img src="../../assets/header/search.png" alt="" @click="handleOfSearch" />
    </div>
    <div class="main">
      <MineEmpty v-if="gameList.length === 0"></MineEmpty>
      <div v-else class="collection-box">
        <div class="collection">
          <div class="item" v-for="item in gameList" :key="item.game_id">
            <GameCard :game="item"></GameCard>
          </div>
        </div>
        <LoadMoreComp :cur-count="curCount" :total-count="totalCount" @loadMoreEvent="fetchGameListData"></LoadMoreComp>
      </div>
    </div>
    <div class="recommend" v-if="recommendList.length > 0">
      <div class="recommend-title">Recomendação de Jogos</div>
      <GamesSet :gameList="recommendList"></GamesSet>
    </div>
  </div>
</template>
<script>
import MineEmpty from "@/components/MineEmpty.vue";
import GamesSet from "@/components/GamesSet.vue";
import GameCard from "@/components/GameCard.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  components: {
    MineEmpty,
    GamesSet,
    GameCard,
    LoadMoreComp,
  },
  data() {
    return {
      recommendList: [],
      searchInputValue: "",
      gameList: [],
      page: 0,
      curCount: 0,
      totalCount: 0,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("search"));
    this.fetchRecommendGameListData();
  },
  methods: {
    handleOfSearch() {
      this.$tools.userTrackReport({ track_id: "26", input: this.searchInputValue });
      if (this.searchInputValue === "") {
        this.gameList = [];
        return;
      }
      this.page = 0;
      this.fetchGameListData();
    },
    fetchGameListData() {
      if (this.page === 0) {
        this.gameList = [];
      }

      const data = {
        action: "game_list",
        data: {
          page: this.page++,
          game_name_en: this.searchInputValue,
        },
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          this.gameList.push(...response.data.game_list);
          this.curCount = this.gameList.length;
          this.totalCount = response.data.count;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
          this.page--;
          this.isLoading = false;
        });
    },
    fetchRecommendGameListData() {
      const data = {
        action: "game_recommend",
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          this.recommendList = response.data.game_list;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .search-box {
    margin: 10px 15px 15px;
    display: flex;
    align-items: center;
    background: #112e47;
    border-radius: 40px;
    height: 45px;
    line-height: 45px;

    input {
      flex: 1;
      margin: 0 15px;
      background-color: transparent;
      border: 0;
      outline-style: none;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }

    input::placeholder {
      color: #ffffff;
      opacity: 0.5;
    }

    & > img {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }
  }

  .main {
    margin: 0 15px;
    display: flex;
    flex-direction: column;

    .collection-box {
      display: flex;
      flex-direction: column;

      .collection {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 10px;
        column-gap: 10px;
        margin-top: 3px;
        margin-bottom: 6px;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 90px;
          height: auto;
        }
      }
    }
  }

  .recommend {
    margin: 15px;
    display: flex;
    flex-direction: column;
    .recommend-title {
      text-align: left;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
</style>
