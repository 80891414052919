<template>
  <div class="chc-container" v-if="list.length > 0">
    <div class="list">
      <div
        class="item"
        :class="i === index ? 'higlight' : 'normal'"
        v-for="(item, i) in list"
        :key="i"
        @click="handleOfItem(i)"
      >
        <img :src="$global.data.cdn_url + (i === index ? item.icon_select : item.category_icon)" alt="" />
        <div>{{ item.catagory_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:index"],
  methods: {
    handleOfItem(i) {
      this.$emit("update:index", i);
      this.$emit("changeEvent", i);
    },
  },
};
</script>

<style lang="scss" scoped>
.chc-container {
  background: var(--color-upper-bg);
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;

  .list {
    display: flex;
    overflow-x: scroll;

    .item {
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 70px;
      height: 15px;
      border-radius: 5px;
      background: #33439e;
      font-size: 12px;
      font-weight: normal;
      padding: 10px;

      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        // filter: hue-rotate(90deg) brightness(150%) saturate(200%);
      }

      & > div {
        text-wrap: nowrap;
        color: #ffffff;
      }
    }

    .higlight {
      background: linear-gradient(297deg, #d6ae60 0%, #ebdd9c 100%);
      & > div {
        color: #0c080e;
      }
    }

    .normal {
      background: #0c2133;
      & > div {
        color: #ffffff;
      }
    }
  }
}
</style>
