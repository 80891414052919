<template>
  <div class="container">
    <LoginComp
      :isRegisterStatus="isRegister"
      :isShowTop="false"
      @close-login="closeHandle"
      @registerSuccess="handleOfRegisterSuccess"
      @changeEvent="handleOfChangeEvent"
    ></LoginComp>
  </div>
</template>

<script>
import LoginComp from "@/components/LoginComp.vue";
export default {
  components: {
    LoginComp,
  },
  emits: ["forgetPassword"],
  data() {
    return {
      isRegister: true,
      isPhone: true,
      username: "",
      password: "",
      areaCode: "55",
      isPwVisible: false,
    };
  },
  mounted() {
    this.handleOfChangeEvent(this.$route.query.isRegister === "1");
  },
  methods: {
    handleOfRegisterSuccess() {
      sessionStorage.setItem("registerSuccess", 1);
      this.closeHandle();
    },
    handleOfChangeEvent(isRegisterSt) {
      this.isRegister = isRegisterSt;
      if (isRegisterSt) {
        this.$emit("update:title", "Registrar-se");
      } else {
        this.$emit("update:title", "Entrar");
      }
    },
    closeHandle() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
}
</style>
