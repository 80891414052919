<template>
  <div class="loading" v-show="msg.show">
    <div class="content">
      <!-- <img src="../assets/loading.gif" alt="" /> -->
      <img src="../assets/loading.png" alt="" />
      <!-- <div>{{ msg.title }}</div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    msg: Object,
  },
};
</script>
<style lang="scss" scoped>
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      height: auto;
      animation: rotate360 0.68s linear infinite;
    }

    div {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
