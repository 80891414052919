<template>
  <div class="wrapper" @click="isShowMineMenu = false">
    <div class="main" ref="scrollContainer">
      <router-view
        class="box"
        :isLoggedIn="isLoggedIn"
        :isShowExtend="isShowExtend"
        :registerGiveCoins="registerGiveCoins"
      ></router-view>
    </div>
    <TabbarView class="m-tabbar"></TabbarView>

    <!-- 隐私政策 -->
    <PrivacyPolicy v-if="isShowPrivacyPolicy" @close="isShowPrivacyPolicy = false"></PrivacyPolicy>
    <!-- 服务条款 -->
    <ServiceTerms v-if="isShowService" @close="isShowService = false"></ServiceTerms>

    <!-- 忘记密码弹窗 -->
    <ForgetPassword
      v-if="isShowForgetPW"
      :isPhone="isForgetOfPhone"
      @close="isShowForgetPW = false"
      @endChangedPasswordEvent="endChangedPasswordEvent"
    ></ForgetPassword>

    <!-- 登录弹窗 -->
    <ModalPopupComp v-if="isShowLoginModal" :isShowDefaultCloseBtn="false">
      <LoginComp
        :isRegisterStatus="isRegisterForLoginPage"
        @close-login="closeLogin"
        @registerSuccess="handleOfRegisterSuccess"
      ></LoginComp>
    </ModalPopupComp>

    <!-- 首页扩展页面 -->
    <div class="extend-bg" v-show="isShowExtendBg" @click="isShowExtend = false">
      <div class="extentd-box" :class="{ 'slide-in-from-bottom': isShowExtend, 'slide-out-to-bottom': !isShowExtend }">
        <div class="extend-content">
          <div class="operator" @click="isShowExtend = false"></div>
          <div class="row">
            <!-- 官方频道 -->
            <div class="item" @click.stop="handleOfTelegram">
              <div class="item-content">
                <img src="@/assets/extend/icon1.png" alt="" />
                <div>Eaglegroup | oficial</div>
              </div>
            </div>
            <!-- 官方视频 -->
            <div class="item" @click.stop="handleOfInstagram">
              <div class="item-content">
                <img src="@/assets/extend/icon2.png" alt="" />
                <div>Eaglegroup | oficial</div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- 成为合作伙伴 -->
            <div class="item" @click.stop="handleOfPartner">
              <div class="item-content">
                <img src="@/assets/extend/icon3.png" alt="" />
                <div>Tornar-se um parceiro</div>
              </div>
            </div>
            <!-- 邀请好友 -->
            <div class="item" @click="handleOfInvite">
              <div class="item-content">
                <img src="@/assets/extend/icon4.png" alt="" />
                <div>Convide amigos</div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- 下载APP -->
            <div class="item" @click="handleOfDownload">
              <div class="item-content">
                <img src="@/assets/extend/icon5.png" alt="" />
                <div>Baixe o aplicativo</div>
              </div>
            </div>
            <!-- 官方客服 -->
            <div class="item" @click="handleOfService">
              <div class="item-content">
                <img src="@/assets/extend/icon6.png" alt="" />
                <div>Atendimento ao Cliente</div>
              </div>
            </div>
          </div>
          <!-- 会员俱乐部 -->
          <div class="club" @click="handleOfClub">
            <div class="club-des">
              <div>Clube de Associados</div>
              <div>Sorteio Diário | Reembolso de Apostas | Bônus Semanal</div>
            </div>
            <img class="club-img" src="@/assets/extend/club.png" alt="" />
          </div>
          <!-- 幸运抽奖 -->
          <div class="draw" @click="handleOfDraw">
            <img src="@/assets/extend/draw1.png" alt="" />
            <div>Sorteio da sorte</div>
            <img src="@/assets/extend/draw2.png" alt="" />
          </div>
          <div class="row">
            <!-- 奖品 -->
            <div class="item" @click="handleOfAward">
              <div class="item-content">
                <img src="@/assets/extend/icon7.png" alt="" />
                <div>Prêmios</div>
              </div>
            </div>
            <!-- 消息 -->
            <div class="item" @click="handleOfMessage">
              <div class="item-content">
                <img src="@/assets/extend/icon8.png" alt="" />
                <div>Mensagens</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- apple install -->
    <AppleInstall v-if="isShowAppleInstall" @close="isShowAppleInstall = false"></AppleInstall>

    <!-- 红包雨游戏 -->
    <div class="hongbaoRainGame" v-if="hongbaoRainGameUrl !== ''">
      <iframe
        ref="hongbaoRainGameIframe"
        :src="hongbaoRainGameUrl"
        frameborder="0"
        width="100%"
        height="100%"
        @load="iframeLoaded"
      ></iframe>
    </div>
    <!-- 未登录欢迎弹窗 -->
    <dialog ref="welcomeDialog">
      <div class="welcome-dialog-wrapper">
        <div class="welcome-dialog-box">
          <div class="top-bg">
            <img src="@/assets/welcome/icon1.webp" alt="" />
          </div>
          <div class="title">Vantagens da plataforma</div>
          <div class="list">
            <div class="row">
              <img src="@/assets/welcome/icon2.webp" alt="" />
              <div>Dinheiro e bônus, duas formas de jogar.</div>
            </div>
            <div class="row">
              <img src="@/assets/welcome/icon3.webp" alt="" />
              <div>100% de reembolso de apostas, disponível em tempo real.</div>
            </div>
            <!-- <div class="row">
              <img src="@/assets/welcome/icon4.webp" alt="" />
              <div>Bônus convertido em dinheiro, disponível todos os dias.</div>
            </div> -->
            <div class="row">
              <img src="@/assets/welcome/icon9.webp" alt="" />
              <div>Depósito mínimo de 10, saque mínimo de 20, sem taxa de retirada.</div>
            </div>
            <div class="row">
              <img src="@/assets/welcome/icon5.webp" alt="" />
              <div>Siga nosso canal para receber mais dinheiro e bônus.</div>
            </div>
            <div class="btn" @click="handleOfFollow">Seguir</div>
          </div>
        </div>
        <img src="@/assets/login/close_icon.png" alt="" @click="handleOfWelcomeClose" />
      </div>
    </dialog>
    <!-- 注册奖励弹窗 -->
    <dialog ref="registerRewardTipsDialog">
      <div class="register-reward-wrapper">
        <div class="register-reward-box">
          <div class="top-bg">
            <img src="@/assets/welcome/icon6.webp" alt="" />
          </div>
          <div class="title">Pacote de Boas-Vindas</div>
          <div class="list">
            <div class="row" v-if="registerGiveCoins > 0">
              <img src="@/assets/welcome/icon2.webp" alt="" />
              <div>Registro garante bônus de</div>
              <div class="money">R${{ registerGiveCoins }}</div>
            </div>
            <div class="row">
              <img src="@/assets/welcome/icon7.webp" alt="" />
              <div>Faça login diariamente para ganhar prêmios todos os dias</div>
            </div>
            <div class="row">
              <img src="@/assets/welcome/icon4.webp" alt="" />
              <div>Login contínuo garante um pacote de luxo</div>
            </div>
            <div class="row">
              <img src="@/assets/welcome/icon8.webp" alt="" />
              <div>Mais recompensas, esperamos que você venha resgatar</div>
            </div>
            <div class="btn" @click="handleOfRegisterReward">Registro</div>
          </div>
        </div>
        <img src="@/assets/login/close_icon.png" alt="" @click="handleOfCloseRegisterReward" />
      </div>
    </dialog>
    <!-- 注册成功奖励 -->
    <RegistrationRewardsComp
      v-if="isShowRegistrationRewards"
      @closeEvent="handleOfCloseRegistrationRewards"
    ></RegistrationRewardsComp>
    <!-- 新手任务弹窗 -->
    <dialog ref="newerDialog">
      <div class="newer-dialog-wrapper">
        <img class="close" src="@/assets/newer-task/close.webp" alt="" @click="$refs.newerDialog.close()" />
        <div class="title">Missões para novatos</div>
        <div class="sub-title">Benefícios Exclusivos para Novos Usuários</div>
        <img class="bg" src="@/assets/newer-task/bg.webp" alt="" />
        <div class="money">R$ 11,00</div>
        <div class="des">Completar tarefas e ganhar bônus</div>
        <div class="icons">
          <div>
            <img src="@/assets/newer-task/icon1_s.webp" alt="" />
          </div>
          <div>
            <img src="@/assets/newer-task/icon2_s.webp" alt="" />
          </div>
          <div>
            <img src="@/assets/newer-task/icon3_s.webp" alt="" />
          </div>
          <div>
            <img src="@/assets/newer-task/icon4_s.webp" alt="" />
          </div>
          <div>
            <img src="@/assets/newer-task/icon5_s.webp" alt="" />
          </div>
          <div>
            <img src="@/assets/newer-task/icon6_s.webp" alt="" />
          </div>
          <div>
            <img src="@/assets/newer-task/icon7_s.webp" alt="" />
          </div>
        </div>
        <div class="button" @click="handleOfNewerDialogBtn">Receber tarefas</div>
      </div>
    </dialog>
    <!-- 登录后，升级提示弹窗 -->
    <dialog ref="upgradeTipsDialog">
      <div class="upgrade-tips-wrapper">
        <div class="box">
          <div class="header">
            <img src="@/assets/welcome/coin.webp" alt="" />
          </div>
          <div class="info">
            <div class="title">Grande Atualização</div>
            <div class="value">
              1. Retirada de bônus: Agora você pode retirar seu bônus diretamente!<br />
              2. Conversão em dinheiro: Cancelamento da exigência de múltiplos de apostas para converter bônus em
              dinheiro, agora é calculado apenas com base nas apostas válidas.<br />
            </div>
          </div>
        </div>
        <img src="@/assets/login/close_icon.png" alt="" @click="handleOfCloseUpgradeTips" />
      </div>
    </dialog>
  </div>
</template>

<script>
import PrivacyPolicy from "@/pages/containers/PrivacyPolicy.vue";
import ServiceTerms from "@/pages/containers/ServiceTerms.vue";
import ForgetPassword from "./containers/ForgetPassword.vue";
import TabbarView from "@/pages/TabbarView.vue";
import AppleInstall from "@/pages/containers/AppleInstall.vue";
import clipboard from "clipboard";
import LoginComp from "@/components/LoginComp.vue";
import ModalPopupComp from "@/components/ModalPopupComp.vue";
import RegistrationRewardsComp from "@/components/RegistrationRewardsComp.vue";

export default {
  components: {
    ForgetPassword,
    TabbarView,
    PrivacyPolicy,
    ServiceTerms,
    AppleInstall,
    LoginComp,
    ModalPopupComp,
    RegistrationRewardsComp,
  },
  data() {
    return {
      isShowLoginModal: false,
      isShowPrivacyPolicy: false,
      isShowService: false,
      // 是否登录
      isLoggedIn: false,
      // 是否展示登录页面
      isShowLoginPage: false,
      // 是否展示忘记密码页面
      isShowForgetPW: false,
      // 忘记密码页面状态是否是手机
      isForgetOfPhone: true,
      // 登录页面是否展示注册状态
      isRegisterForLoginPage: false,
      // 显示个人中心menu
      isShowMineMenu: false,
      list: [
        {
          title: this.$t("my_wallet"),
          icon: require("@/assets/user/wallet_icon.png"),
          path: "wallet",
        },
        {
          title: this.$t("my_account"),
          icon: require("@/assets/user/account_icon.png"),
          path: "general",
        },
        // {
        //   title: this.$t("my_prizes"),
        //   icon: require("@/assets/user/award_icon.png"),
        //   path: "award",
        // },
        {
          title: this.$t("my_invitations"),
          icon: require("@/assets/user/invite_icon.png"),
          path: "commission",
        },
        {
          title: this.$t("transaction_records"),
          icon: require("@/assets/user/transcation_icon.png"),
          path: "flow",
        },
        {
          title: this.$t("game_history"),
          icon: require("@/assets/user/history_icon.png"),
          path: "history",
        },
        // {
        //   title: this.$t("settings"),
        //   icon: require("@/assets/user/setting_icon.png"),
        //   path: "setting",
        // },
        // {
        //   title: this.$t("messages"),
        //   icon: require("@/assets/user/message_icon.png"),
        //   path: "email",
        // },
        {
          title: this.$t("cs"),
          icon: require("@/assets/user/service_icon.png"),
          path: "service",
        },
        {
          title: this.$t("log_out"),
          icon: require("@/assets/user/exit_icon.png"),
          path: "",
        },
      ],
      scrollPosition: 0,
      isShowAppleInstall: false,
      userCode: "",
      isShowCopiado: false,
      hongbaoRainGameUrl: "",
      // hongbaoRainGameUrl: "/web-mobile/index.html?amount=10",
      isShowExtend: false,
      isShowExtendBg: false,
      isShowRegistrationRewards: false,
    };
  },
  props: {
    registerGiveCoins: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    isShowExtend(newVal) {
      if (newVal) {
        this.isShowExtendBg = true;
      } else {
        setTimeout(() => {
          this.isShowExtendBg = false;
        }, 500);
      }
    },
  },
  mounted() {
    this.userCode = this.$store.getters.ucode;
    // this.$eventBus.on("ShowAppleInstall", () => {
    //   this.isShowAppleInstall = true;
    // });
    this.$eventBus.on("emitOfShowExtend", (isShow) => {
      this.isShowExtend = isShow;
    });
    this.$eventBus.on("eventOfDownload", this.handleOfDownload);

    this.$eventBus.on("hongbaoRainStart", (amount) => {
      this.hongbaoRainGameUrl = `/web-mobile/index.html?amount=${amount}`;
    });

    this.$eventBus.on("showLogin", this.showLogin);
    this.$eventBus.on("showRegister", this.showRegister);
    this.$eventBus.on("isNeedLogin", () => {
      const registerInfo = this.$store.getters.registerInfo;
      if (registerInfo !== null) {
        this.showLogin();
      } else {
        this.showRegister();
      }
    });

    // 在组件挂载后，监听滚动事件
    if (this.$tools.isMobile()) {
      this.$refs.scrollContainer.addEventListener("scroll", this.scrollHandle);
    } else {
      window.addEventListener("scroll", this.scrollHandle);
    }

    this.$eventBus.on("logoutSuccessHandle", this.logoutSuccessHandle);
    this.isLoggedIn = this.$store.getters.isLoggedIn;

    if (this.isLoggedIn && sessionStorage.getItem("registerSuccess") === "1") {
      this.handleOfRegisterSuccess();
      sessionStorage.setItem("registerSuccess", 0);
    }

    if (this.isLoggedIn) {
      const hasShow = sessionStorage.getItem("redemption_code_has_show");
      if ("rc" in this.$route.query && !hasShow) {
        setTimeout(() => {
          this.$router.push({ name: "redemption-code", query: { rc: this.$route.query.rc } });
          sessionStorage.setItem("redemption_code_has_show", true);
        }, 1000);
      }
      this.showUpgradeTipsIsNeeded();
    } else {
      if ("code" in this.$route.query) {
        // google login
        // this.googleLogin(this.$route.query.code);
      } else {
        this.$refs.welcomeDialog.showModal();

        if ("s" in this.$route.query) {
          // session storage 推广值，给注册的时候使用
          localStorage.setItem("source_channel", this.$route.query.s);
        }

        if ("pa" in this.$route.query) {
          localStorage.setItem("pa", this.$route.query.pa);
          this.promoteAd(this.$route.query.pa);
        }
      }
    }
  },
  methods: {
    showUpgradeTipsIsNeeded(isForce = false) {
      if (isForce) {
        this.$refs.upgradeTipsDialog.showModal();
        return;
      }

      const today = this.$tools.formattedDate(new Date());
      const recordDate = localStorage.getItem("upgradeTipsCloseDate") || "";
      if (recordDate !== today) {
        this.$refs.upgradeTipsDialog.showModal();
      }
    },
    handleOfCloseUpgradeTips() {
      this.$refs.upgradeTipsDialog.close();
      const today = this.$tools.formattedDate(new Date());
      localStorage.setItem("upgradeTipsCloseDate", today);
    },
    handleOfNewerDialogBtn() {
      this.$refs.newerDialog.close();
      this.$router.push({ name: "newer-task" });
    },
    handleOfCloseRegistrationRewards() {
      this.isShowRegistrationRewards = false;
      this.$refs.newerDialog.showModal();
    },
    handleOfRegisterReward() {
      this.$refs.registerRewardTipsDialog.close();
      this.showRegister();
    },
    handleOfCloseRegisterReward() {
      this.$refs.registerRewardTipsDialog.close();
    },
    handleOfFollow() {
      this.handleOfTelegram();
      this.$refs.welcomeDialog.close();
      this.$refs.registerRewardTipsDialog.showModal();
    },
    handleOfWelcomeClose() {
      this.$refs.welcomeDialog.close();
      this.$refs.registerRewardTipsDialog.showModal();
    },
    handleOfClub() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "m-vip" });
      } else {
        this.showRegister();
      }
    },
    handleOfDraw() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "m-raffle" });
      } else {
        this.showRegister();
      }
    },
    handleOfAward() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "m-award" });
      } else {
        this.showRegister();
      }
    },
    handleOfMessage() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "m-message" });
      } else {
        this.showRegister();
      }
    },
    handleOfService() {
      if (this.isLoggedIn) {
        this.$router.push("m-service");
      } else {
        this.showRegister();
      }
    },
    handleOfDownload() {
      // 首页下载桌面点击
      this.$tools.userTrackReport({ track_id: "35" });
      this.$router.push({ name: "app-download-page" });
    },
    handleOfInvite() {
      this.$eventBus.emit("emitOfChangeTab", "commission");
    },
    handleOfPartner() {
      window.open(this.$global.data.cooperate_link, "_self");
    },
    handleOfInstagram() {
      window.open(this.$global.data.ins_link, "_self");
    },
    handleOfTelegram() {
      this.$tools.openTelegram();
    },
    handleOfRegisterSuccess() {
      if (this.registerGiveCoins > 0) {
        this.isShowRegistrationRewards = true;
      } else {
        this.$refs.newerDialog.showModal();
        this.showUpgradeTipsIsNeeded(true);
      }
    },
    iframeLoaded() {
      console.log("Iframe loaded!");
      const iframe = this.$refs.hongbaoRainGameIframe;
      // 向iframe中注入回调方法
      iframe.contentWindow.globalHongbaoRainEndEvent = this.callbackInIframe;
    },
    callbackInIframe(result) {
      console.log("Callback in iframe:", result);
      this.hongbaoRainGameUrl = "";
      this.$eventBus.emit("honebaoRainGameEndEvent", result);
    },
    copyHandle() {
      clipboard.copy(this.userCode);
      this.isShowCopiado = true;
      setTimeout(() => {
        this.isShowCopiado = false;
      }, 2000);
    },
    scrollHandle() {
      if (this.$tools.isMobile()) {
        this.scrollPosition = this.$refs.scrollContainer.scrollTop || this.$refs.scrollContainer.pageYOffset;
      } else {
        this.scrollPosition = window.scrollY || window.pageYOffset;
      }
    },
    scrollToTop(y) {
      this.$nextTick(() => {
        if (this.$tools.isMobile()) {
          this.$refs.scrollContainer.scrollTop = y;
        } else {
          setTimeout(() => {
            window.scrollTo(0, y);
          }, 100);
        }
      });
    },
    endChangedPasswordEvent() {
      this.isShowForgetPW = false;
      this.isShowLoginPage = true;
    },
    /**
     * 展示登录页面
     */
    showLogin() {
      if (this.$global.isDialogModelShowLogin) {
        this.isRegisterForLoginPage = false;
        this.isShowLoginModal = true;
      } else {
        this.$router.push({ name: "login-page", query: { isRegister: 0 } });
      }
    },
    /**
     * 展示注册页面
     * trackId: 埋点id； "1"点击注册按钮 "2"其他注册（默认）
     */
    showRegister(trackId = "2") {
      this.$tools.userTrackReport({ track_id: trackId });
      if (this.$global.isDialogModelShowLogin) {
        this.isRegisterForLoginPage = true;
        this.isShowLoginModal = true;
      } else {
        this.$router.push({ name: "login-page", query: { isRegister: 1 } });
      }
    },
    /**
     * 关闭登录页面
     * @param {Boolean} isLoggedIn 是否登录成功后的关闭事件。true：登录后的关闭 false：直接关闭按钮
     */
    closeLogin(isLoggedIn) {
      this.isShowLoginPage = false;
      this.isLoggedIn = isLoggedIn;
      this.isShowLoginModal = false;
    },
    /**
     * 个人中心menu列表中的点击事件
     * @param {Int} index 列表中点击的选项下标
     */
    menuItemHandle(index) {
      const item = this.list[index];
      if (item.title === this.$t("log_out")) {
        this.logout();
      } else {
        if (item.path != "") {
          this.$router.push({ name: item.path });
        }
      }
      this.isShowMineMenu = false;
    },
    logout() {
      const data = {
        action: "logout",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then()
        .catch()
        .finally(() => {
          this.localLogout();
        });
    },
    localLogout() {
      this.$store.commit("clearInfo");
      this.$eventBus.emit("logoutSuccessHandle");
      this.$router.push({ name: "casino" });
    },
    logoutSuccessHandle() {
      this.isLoggedIn = false;
    },
    async promoteAd(id) {
      const data = {
        action: "promote_ad_open",
        data: {
          promote_ad_id: id,
        },
      };
      try {
        await this.$http.post("promote", data);
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  updated() {
    this.isLoggedIn = this.$store.getters.isLoggedIn;
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .main {
    flex: 1;
    display: flex;
    background: transparent;
    flex-direction: column;
    margin-bottom: var(--tab-bar-height);

    .box {
      width: 100%;
      height: 100%;
    }
  }

  .m-tabbar {
    // display: none;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .slide-in-from-bottom {
    animation: slideInFromBottom 0.5s ease forwards;
  }

  @keyframes slideOutToBottom {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }

  .slide-out-to-bottom {
    animation: slideOutToBottom 0.5s ease forwards;
  }

  .extend-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .extentd-box {
      max-width: var(--max-width);
      width: 100%;
      background: #194164;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      transform: translateY(100%);

      .extend-content {
        width: 100%;
        padding: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .operator {
          margin-top: 3px;
          background: #e5ecf2;
          width: 40px;
          border-radius: 5px;
          height: 5px;
        }

        .row {
          width: 100%;
          display: flex;
          margin-top: 18px;

          .item {
            flex: 1;
            display: flex;
            justify-content: space-around;

            .item-content {
              width: 100%;
              height: 45px;
              background: #0063a7;
              border-radius: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: #ffffff;
              font-size: 12px;
              font-weight: 500;
            }

            img {
              margin: auto 10px;
              width: 30px;
              height: 30px;
            }
          }

          .item:last-child {
            margin-left: 10px;
          }
        }

        .club {
          margin-top: 18px;
          background: #0a1f32;
          border-radius: 10px;
          width: 100%;
          height: 90px;
          display: flex;
          align-items: center;

          .club-des {
            margin: auto 12px;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div:nth-child(1) {
              color: #ffd400;
              font-size: 18px;
              font-weight: 500;
            }

            div:nth-child(2) {
              color: #299c3a;
              font-size: 8px;
              font-weight: 500;
            }
          }
          .club-img {
            margin-right: 7px;
            width: 92px;
            height: 82px;
          }
        }

        .draw {
          margin-top: 18px;
          width: 100%;
          height: 60px;
          background: #0063a7;
          border-radius: 10px;
          color: #ffffff;
          font-size: 18px;
          font-weight: 500;
          display: flex;
          justify-content: space-around;
          align-items: center;

          img:first-of-type {
            margin-top: auto;
            width: 69.5px;
            height: 58px;
          }

          img:last-of-type {
            margin-top: auto;
            width: 55px;
            height: 43.5px;
          }
        }
      }
    }
  }

  .hongbaoRainGame {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .welcome-dialog-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .welcome-dialog-box {
      width: 100%;
      background: #33439e;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .top-bg {
        width: 100%;
        height: 130px;
        background: url("../assets/welcome/bg.webp") no-repeat;
        background-size: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > img {
          width: 72px;
          height: auto;
        }
      }

      & > .title {
        margin-top: 10px;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }

      & > .list {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        & > .row {
          height: 40px;
          display: flex;
          align-items: center;
          margin: 10px 15px 0;
          border: 0.5px solid #8a84ff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 3px 5px;

          & > img {
            width: 25px;
            height: 25px;
          }

          & > div {
            margin-left: 10px;
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
            text-align: left;
          }
        }

        & > .btn {
          margin: 14px 15px 11px;
          height: 40px;
          line-height: 40px;
          border-radius: 8px;
          background: #ffd400;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    & > img {
      margin-top: 22px;
      width: 32px;
      height: 32px;
    }
  }

  .register-reward-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .register-reward-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #33439e;
      border-radius: 10px;
      width: 345px;

      & > .top-bg {
        width: 100%;
        height: 130px;
        background: url("../assets/welcome/bg.webp") no-repeat;
        background-size: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > img {
          width: 119px;
          height: 100px;
        }
      }

      & > .title {
        margin-top: 10px;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }

      & > .list {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        & > .row {
          height: 40px;
          display: flex;
          align-items: center;
          margin: 10px 15px 0;
          border: 0.5px solid #8a84ff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 3px 5px;

          & > img {
            width: 25px;
            height: 25px;
          }

          & > div {
            margin-left: 10px;
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
            text-align: left;
          }

          .money {
            color: #06eb56;
            font-size: 18px;
            font-weight: bold;
          }
        }

        & > .btn {
          margin: 14px 15px 11px;
          height: 40px;
          line-height: 40px;
          border-radius: 8px;
          background: #ffd400;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    & > img {
      margin-top: 22px;
      width: 32px;
      height: 32px;
    }
  }

  .newer-dialog-wrapper {
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #33439e 0%, #283478 100%);
    border-radius: 10px;
    position: relative;

    & > .close {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 20px;
      right: 20px;
    }

    & > .title {
      margin: 13px 15px 0;
      color: #ffffff;
      font-size: 22.5px;
      font-weight: 900;
      text-align: left;
    }

    & > .sub-title {
      margin: 3px 15px 0;
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      text-align: left;
    }

    & > .bg {
      margin-top: 15px;
      width: 100%;
      height: auto;
    }

    & > .money {
      margin-top: -25px;
      color: #21b300;
      font-size: 40px;
      font-weight: bold;
    }

    & > .des {
      margin-top: 21px;
      color: #ffffff;
      font-size: 10px;
      font-weight: normal;
    }

    & > .icons {
      margin-top: 10px;
      display: flex;
      justify-content: center;

      & > div {
        display: flex;
        align-items: center;
        margin-right: 10px;
        & > img {
          width: 25px;
          height: auto;
        }
      }

      & > div:last-child {
        margin-right: 0;
      }
    }

    & > .button {
      margin: 20px 10px 15px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      background: #ffd400;
      color: #010406;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .upgrade-tips-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .box {
      display: flex;
      flex-direction: column;
      background: #33439e;
      border-radius: 10px;

      & > .header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 130px;
        background: url("../assets/welcome/bg.webp") no-repeat;
        background-size: 100%;

        & > img {
          width: 102px;
          height: auto;
        }
      }

      & > .info {
        display: flex;
        flex-direction: column;
        padding: 17px 25px 15px;

        & > .title {
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
        }

        & > .value {
          margin-top: 15px;
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
          text-align: left;
        }
      }
    }

    & > img {
      margin-top: 30px;
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    position: relative;
    height: 100vh;
    width: 100vw;
  }

  .wrapper .main {
    margin-bottom: 0;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: var(--tab-bar-height);
    left: 0;
    margin-top: 0;
  }
}
</style>
