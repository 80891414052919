<template>
  <div class="container">
    <div class="box">
      <div class="wrapper">
        <div class="main">
          <div class="item">
            <div class="sub-title">Benefícios da associação</div>
            <div class="sub-content">
              <div>1.O VIP Bronze pode ativar os benefícios do sorteio. Quanto maior o nível, maior a recompensa.</div>
              <div>
                2.Silver VIP pode ativar o benefício de desconto. Aposte no primeiro dia e ganhe rakeback no segundo
                dia.
              </div>
              <div>3.Gold VIP ativa os benefícios do clube. Pelo menos 10.000 bônus por semana.</div>
              <div>4.Quanto maior o nível, menor a taxa de retirada.</div>
            </div>
          </div>
          <div class="item">
            <div class="sub-title">reembolso de apostas</div>
            <div class="sub-content">
              <div>
                O VIP Silver pode ativar benefícios de reembolso. Os reembolsos de apostas das últimas 24 horas são
                liquidados às 17:00 diariamente. Níveis VIP mais altos oferecem porcentagens de reembolso mais altas.
              </div>
              <div class="box1">
                <div class="box1-header">
                  <div>VIP</div>
                  <div>porcentagem de reembolso</div>
                  <div>VIP</div>
                  <div>porcentagem de reembolso</div>
                </div>
                <div class="box1-content">
                  <div class="col">
                    <div v-for="index in 4" :key="index">{{ benefits[index - 1]?.vip_level_name }}</div>
                  </div>
                  <div class="col">
                    <div v-for="index in 4" :key="index">{{ benefits[index - 1]?.bet_return_rate_per_thousand }}‰</div>
                  </div>
                  <div class="col">
                    <div v-for="index in 3" :key="index">{{ benefits[index - 1 + 4]?.vip_level_name }}</div>
                  </div>
                  <div class="col">
                    <div v-for="index in 3" :key="index">
                      {{ benefits[index - 1 + 4]?.bet_return_rate_per_thousand }}‰
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item" v-if="goldCludInfo !== ''">
            <div class="sub-title">Vantagem do clube</div>
            <div class="sub-content">
              <div v-html="goldCludInfo"></div>
            </div>
          </div>
          <div class="item">
            <div class="sub-title">taxa de saque</div>
            <div class="sub-content">
              <div class="box1">
                <div class="box1-header">
                  <div>VIP</div>
                  <div>porcentagem de reembolso</div>
                  <div>VIP</div>
                  <div>porcentagem de reembolso</div>
                </div>
                <div class="box1-content">
                  <div class="col">
                    <div v-for="index in 4" :key="index">{{ benefits[index - 1]?.vip_level_name }}</div>
                  </div>
                  <div class="col">
                    <div v-for="index in 4" :key="index">{{ benefits[index - 1]?.pay_out_rate }}%</div>
                  </div>
                  <div class="col">
                    <div v-for="index in 3" :key="index">{{ benefits[index - 1 + 4]?.vip_level_name }}</div>
                  </div>
                  <div class="col">
                    <div v-for="index in 3" :key="index">{{ benefits[index - 1 + 4]?.pay_out_rate }}‰</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: {
    // ModalPopupComp,
  },
  props: {
    benefits: {
      type: Array,
      default: null,
    },
    goldCludInfo: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
}
.box {
  display: flex;
  flex-direction: column;

  & > .wrapper {
    display: flex;
    flex-direction: column;
    padding: 15px 10px 0;
  }

  .main {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .sub-title {
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        margin: 5px 0 10px;
      }

      .sub-content {
        width: 100%;
        color: #96a7c6;
        font-size: 10px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > div {
          text-align: left;
        }

        .box1 {
          width: 100%;
          background: #14324b;
          border-radius: 4px;
          display: flex;
          flex-direction: column;

          .box1-header {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 5px;
            color: #ffffff;
            font-size: 9px;
            font-weight: 300;

            & > div {
              flex: 1;
              text-align: center;
            }
          }

          .box1-content {
            background: #14324b;
            border-radius: 4px;
            margin: 0 5px 7px;
            padding: 5px 0;
            display: flex;
            justify-content: space-around;

            .col {
              color: #ffffff;
              font-size: 9px;
              font-weight: 300;
              display: flex;
              flex-direction: column;
              align-items: space-around;

              & > div {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
