<template>
  <div class="container">
    <div
      class="top-tips"
      v-if="isShowTopTips && (isLoggedIn || (!isLoggedIn && registerGiveCoins > 0))"
      @click="handleOfAppDownload"
    >
      <img
        class="tips-icon"
        :src="isLoggedIn ? require('@/assets/header/tip2.webp') : require('@/assets/header/tip1.webp')"
        alt=""
      />
      <div v-if="isLoggedIn" class="tips-text">Baixe o aplicativo e ganhe um bônus de R$5</div>
      <div v-else-if="registerGiveCoins > 0" class="tips-text">
        Registro concede R$ {{ parseFloat(registerGiveCoins).toFixed(2) }}
      </div>
      <img class="tips-close-icon" src="@/assets/float/tip_close.webp" alt="" @click.stop="handleOfCloseTopTips" />
    </div>
    <HeaderView
      class="m-header"
      :isLoggedIn="isLoggedIn"
      :isShowExtend="isShowExtend"
      @popupLogin="handleOfLogin"
      @popupRegister="handleOfRegister"
    ></HeaderView>

    <div class="box" ref="boxRef" @scroll.passive="handleScroll">
      <!-- 轮播图 -->
      <SwiperComp :bannerList="bannerList"></SwiperComp>

      <PiggyBannerComp></PiggyBannerComp>

      <CategoryHorizontalComp
        class="m-cate"
        v-model:index="category"
        :list="cateListWithLoddy"
        @changeEvent="setupCategory"
      ></CategoryHorizontalComp>

      <div class="main-wrapper">
        <div class="right">
          <!-- 大厅数据 -->
          <div v-if="category === 0" class="lobby">
            <div class="lobby-wrapper">
              <!-- 最近游戏 -->
              <div class="tags-list" v-if="resentList.length > 0">
                <CardHeader
                  :title="$t('recent_games')"
                  isShowArrows
                  :icon="$global.data.recent_icon"
                  @tap-left="resentListPrevEvent"
                  @tap-right="resentListNextEvent"
                ></CardHeader>
                <GamesSet class="m-game-list" :gameList="resentList"></GamesSet>
              </div>

              <!-- 厂商 -->
              <div class="provider-list">
                <CardHeader title="Provedores de Jogos" :icon="provider_icon"></CardHeader>
                <div class="provider-content">
                  <div
                    class="provider"
                    v-for="(provider, j) in provider_list"
                    :key="j"
                    @click="handleOfProvider(provider)"
                  >
                    <img :src="$global.data.cdn_url + provider.provider_logo" alt="" />
                  </div>
                </div>
              </div>

              <!-- tags list -->
              <div class="tags-list" v-for="(item, index) in tagsList" :key="index">
                <CardHeader
                  :title="item.tag_name"
                  :icon="item.tag_icon"
                  :totalCount="item.totalCount"
                  isShowMoreBtn
                  isShowArrows
                  @tap-left="movePrev(index)"
                  @tap-right="moveNext(index)"
                  @show-all="showAllCallBack(item)"
                ></CardHeader>
                <GamesSet class="m-game-list" :gameList="item.list"></GamesSet>
              </div>
            </div>
          </div>

          <!-- 非大厅数据 -->
          <div v-else class="cate-game-list">
            <div class="collection">
              <div class="item" v-for="game in cateGameList(category - 1)" :key="game.game_id">
                <GameCard :game="game"></GameCard>
              </div>
            </div>
            <div class="footer">
              <LoadMoreComp
                :cur-count="cateGameList(category - 1).length"
                :total-count="cateGameTotalCount(category - 1)"
                @loadMoreEvent="fetchGameListTypeData('game_category', cateList[category - 1])"
              ></LoadMoreComp>
            </div>
          </div>
          <MobileFooter class="mobile-page-footer"></MobileFooter>
        </div>
      </div>
    </div>

    <div class="float-box" :style="{ top: `${floatBoxTop}px` }">
      <div class="float-content">
        <!-- 礼包 -->
        <div class="gift-box">
          <Transition name="fade">
            <img
              v-if="!isOpenFloatGift"
              class="gift-icon"
              src="@/assets/float/gift.webp"
              alt=""
              @click="isOpenFloatGift = true"
            />
          </Transition>
          <div
            class="gift-wrapper"
            :style="{
              maxHeight: this.isOpenFloatGift ? '500px' : '0', // 假设内容高度不会超过500px
              opacity: this.isOpenFloatGift ? 1 : 0,
              overflow: 'hidden',
              transition: 'all 0.5s ease-in-out',
            }"
          >
            <img class="close" src="@/assets/float/gift_close.webp" alt="" @click="isOpenFloatGift = false" />
            <!-- buff -->
            <div class="float-buff" v-if="buffInfo !== null">
              <div class="buff-info" v-if="isShowBuffDetail">
                <div>{{ buffInfo?.prize_name }}</div>
                <div>{{ buffInfo?.describe }}</div>
              </div>
              <div class="buff-icon" @click="handleOfBuffIcon">
                <img class="buff-img" :src="$global.data.cdn_url + buffInfo?.prize_image_url" alt="" />
                <div class="buff-timer">{{ buffCountDownTimeStr }}</div>
              </div>
            </div>
            <!-- 次日登录 -->
            <div
              class="second-day-login-box"
              v-if="isLoggedIn && next_day_login_active_info"
              @click="handleOfShowSecondDayLoginActivity"
            >
              <div class="second-day-login">
                <div>{{ secondDayLoginCountDownTimeStr }}</div>
              </div>
            </div>
            <!-- 新手任务 -->
            <div class="new-user-box" v-if="new_user_active_info" @click="handleOfNewerTask">
              <img src="@/assets/newer-task/entrance.webp" alt="" />
              <div class="progress">{{ newUserFinishCount }}/7</div>
              <div v-if="newUserTimer" class="countDown">{{ newUserCountDownTimerStr }}</div>
            </div>
            <!-- 每日登录 -->
            <div class="float-item" v-if="isShowDailyLoginRewards">
              <img src="@/assets/daily-login/icon.webp" alt="" @click="fetchDailyLoginRewardsData" />
              <div class="close-box">
                <img src="@/assets/float/close.webp" alt="" @click="handleOfCloseDailyLoginRewards" />
              </div>
            </div>
            <!-- 转盘 -->
            <div class="raffle-box">
              <img
                v-if="isLoggedIn"
                src="@/assets/level/float_icon.webp"
                alt=""
                @click="$router.push({ name: 'm-raffle', query: { index: 0 } })"
              />
              <div v-if="total_draw_numbers > 0">{{ total_draw_numbers }}</div>
            </div>
            <!-- 今日挑战 -->
            <div
              v-if="isLoggedIn && dailyCheckInTotalGiveCoins > 0"
              class="daily"
              @click="$router.push('/daily-check-in')"
            >
              <img src="@/assets/daily-check-in/float_icon.webp" alt="" />
              <div>R$ {{ dailyCheckInTotalGiveCoins }}</div>
            </div>
            <!-- 下载app -->
            <img src="@/assets/casino_download.webp" alt="" @click="handleOfAppDownload" />
          </div>
        </div>
      </div>
    </div>

    <div class="float2-box">
      <!-- 飞机 -->
      <img class="icon" src="@/assets/float/telegram.webp" alt="" @click="handleOfTelegram" />
      <!-- 客服 -->
      <img src="@/assets/casino_cs.webp" alt="" @click="handleOfCS" />
    </div>

    <!-- 每日登录活动弹窗 -->
    <!--    <dialog ref="dailyLoginRewardsDialog" v-if="isLoggedIn">-->
    <!--      <DailyLoginRewards-->
    <!--        :info="dailyLoginRewardsData"-->
    <!--        @cancelEvent="$refs.dailyLoginRewardsDialog.close()"-->
    <!--        @checkAwardEvent="handleOfShowAwardList"-->
    <!--        @receiveEvent="dailyLoginReceiveReward"-->
    <!--        @retainEvent="handleOfShowDailyLoginRewardsCancelDialog"-->
    <!--        @rechargeEvent="dailyLoginRecharge"-->
    <!--      ></DailyLoginRewards>-->
    <!--    </dialog>-->
    <!-- 每日活动领取成功弹窗 -->
    <dialog ref="dailyLoginRewardsReceiveDialog">
      <div class="daily-login-rewards-receive-wrapper">
        <div class="box">
          <div class="content">
            <div>Dica</div>
            <div>Resgate concluído com sucesso</div>
            <div>Por favor, verifique e utilize na página 'Meus Prêmios</div>
            <div class="btns">
              <div @click="$refs.dailyLoginRewardsReceiveDialog.close()">Está bem</div>
              <div @click="handleOfShowAwardList">Verificar</div>
            </div>
          </div>
          <img
            class="close"
            src="@/assets/daily-login/close.png"
            alt=""
            @click="$refs.dailyLoginRewardsReceiveDialog.close()"
          />
        </div>
      </div>
    </dialog>
    <!-- 每日活动取消挽留弹窗 -->
    <dialog ref="dailyLoginRewardsCancelDialog">
      <div class="daily-login-rewards-cancel-wrapper">
        <div class="box">
          <div class="content">
            <div>Dica</div>
            <div>Bônus generoso por login consecutivo por 7 dias e sem requisitos de apostas.</div>
            <div>Você tem certeza de que deseja sair?</div>
            <div class="btns">
              <div @click="$refs.dailyLoginRewardsCancelDialog.close()">Sair</div>
              <div @click="handleOfShowDailyLoginActivity">Receber</div>
            </div>
          </div>
          <img
            class="close"
            src="@/assets/daily-login/close.png"
            alt=""
            @click="$refs.dailyLoginRewardsCancelDialog.close()"
          />
        </div>
      </div>
    </dialog>

    <!-- 红包雨dialog -->
    <dialog ref="hongbaoRainDialog">
      <CommonDialogTipContent
        title="Dica"
        des="Parabéns por qualificar-se para uma loteria gratuita"
        value="R$:1,00~10,00"
        buttonTitle="Começa agora"
        @confirmEvent="handleOfShowHongbaoRainStart"
        @closeEvent="handleOfCloseHongbaoRainDialog"
      ></CommonDialogTipContent>
    </dialog>
    <!-- 红包雨开始dialog -->
    <dialog ref="hongbaoRainStartDialog">
      <div class="start_dialog_bg">
        <img class="start_icon" src="@/assets/hongbaoRainStartTopIcon.png" alt="" />
        <div class="start_dialog_content">
          <div class="start_title">Chuva de envelope vermelho Contar para trás</div>
          <div class="start_count">{{ hongbaoRainStartCountDown }}</div>
          <div class="start_des">
            Clique rapidamente e reivindique o seu bónus Quanto mais colecionar, maior será a recompensa
          </div>
        </div>
      </div>
    </dialog>
    <!-- 红包雨结束dialog -->
    <dialog ref="hongbaoRainEndDialog">
      <div class="end_dialog_bg">
        <img class="end_icon" src="@/assets/hongbaoRainStartTopIcon.png" alt="" />
        <div class="end_dialog_content">
          <div class="end_title">Parabéns por conseguir</div>
          <div class="end_money">R$:{{ hongbaoRainResult }}</div>
          <div class="end_des">
            <span>Recarga</span>
            <span>&nbsp;R${{ activityFactorValue }}&nbsp;</span>
            <span>para receber recompensas de envelope vermelho</span>
          </div>
          <div class="end_btn" @click="handleOfDeposit">Vai recargar</div>
        </div>
        <img class="end_close" src="@/assets/login/close_icon.png" alt="" @click="handleOfHongbaoRainEndClose" />
      </div>
    </dialog>

    <!-- 次日登录弹窗 -->
    <dialog ref="dialogSecondDayLogin" v-if="next_day_login_active_info">
      <div class="dialog-second-day-login-wrapper">
        <div class="bg-box">
          <div></div>
          <div></div>
        </div>
        <div class="title-box">
          <div>Acesso no dia seguinte</div>
          <img src="@/assets/second-day-login/close.webp" alt="" @click="$refs.dialogSecondDayLogin.close()" />
        </div>
        <div class="des">Acesso no segundo dia</div>
        <div class="context-box">
          <div class="context">
            <div>100%</div>
            <div>Reivindicar</div>
            <div>Contagem regressiva</div>
            <div>{{ secondDayLoginCountDownTimeStr }}</div>
          </div>
          <img src="@/assets/second-day-login/img.webp" alt="" />
        </div>
        <div
          class="btn"
          :class="
            next_day_login_active_info?.receive_state === 0
              ? 'state0'
              : next_day_login_active_info?.receive_state === 1
              ? 'state1'
              : 'state2'
          "
          @click="receiveSecondDayRewards"
        >
          {{
            next_day_login_active_info?.receive_state === 0
              ? "Amanhã para recolher"
              : next_day_login_active_info?.receive_state === 1
              ? "Reivindicar"
              : "Já recebido"
          }}
        </div>
      </div>
    </dialog>
    <!-- 次日登录领取成功弹窗 -->
    <dialog ref="dialogSecondDayLoginRewardTips">
      <div class="second-day-login-reward-tips-box">
        <div>Parabéns</div>
        <div>{{ user_prize_info?.prize_info?.prize_name }}</div>
        <div>Já armazenado na lista de prémios</div>
        <div class="buttons">
          <div @click="$refs.dialogSecondDayLoginRewardTips.close()">Bom</div>
          <div @click="handleOfUsingPrizes">Usar</div>
        </div>
      </div>
    </dialog>
    <!-- 次日登录领取奖品兑换真金弹窗 -->
    <dialog ref="dialogSecondDayLoginPrizeAmountTips">
      <div class="second-day-login-reward-tips-box">
        <div>Prompt</div>
        <div>R$:{{ parseFloat(prizeAmountTipsValue).toFixed(2) }}</div>
        <div>A recompensa foi depositada na carteira</div>
        <div class="button" @click="handleOfPrizeAmountTipsOk">OK</div>
      </div>
    </dialog>
  </div>
</template>

<script>
import MobileFooter from "@/components/MobileFooter.vue";
import CardHeader from "@/components/CardHeader.vue";
import GameCard from "@/components/GameCard.vue";
import SwiperComp from "@/components/SwiperComp.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
import GamesSet from "@/components/GamesSet.vue";
import CategoryHorizontalComp from "@/components/CategoryHorizontalComp.vue";
import HeaderView from "@/pages/HeaderView.vue";
import CommonDialogTipContent from "@/components/CommonDialogTipContent.vue";
// import DailyLoginRewards from "@/pages/containers/DailyLoginRewards.vue";
import PiggyBannerComp from "@/components/PiggyBannerComp.vue";

export default {
  components: {
    CardHeader,
    GameCard,
    SwiperComp,
    LoadMoreComp,
    MobileFooter,
    GamesSet,
    CategoryHorizontalComp,
    HeaderView,
    CommonDialogTipContent,
    PiggyBannerComp,
  },
  props: {
    isShowExtend: {
      type: Boolean,
      default: false,
    },
    registerGiveCoins: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const activityId = this.$store.getters.activityId;
    const isJoinActivity = this.$store.getters.isJoinActivity;
    const isLoggedIn = this.$store.getters.isLoggedIn;
    const isShowTopTips = sessionStorage.getItem("isShowTopTips") !== "false";
    const isShowTelegram = sessionStorage.getItem("isShowTelegram") !== "false";
    const isShowDailyLoginRewards = sessionStorage.getItem("isShowDailyLoginRewards") !== "false";
    return {
      category: -1,
      cateListWithLoddy: [],
      cateList: [],
      searchInputValue: "",
      tagsList: [],
      resentList: [],
      resentIndex: 0,
      bannerList: [],
      hongbaoRainStartCountDown: 3,
      hongbaoRainResult: 0,
      isJoinActivity,
      activityId,
      countDownTimeStr: "",
      activityFactorValue: 0,
      isLoggedIn,
      buffInfo: null,
      buffTimer: null,
      buffCountDownTimeStr: "",
      isShowBuffDetail: false,
      showBuffDetailTimer: null,
      isShowTelegram,
      isShowDownload: true,
      isShowTopTips,
      floatBoxTop: 0,
      provider_list: [],
      provider_icon: "",
      dailyLoginRewardsData: null,
      isShowDailyLoginRewards,
      next_day_login_active_info: null,
      secondDayLoginTimer: null,
      secondDayLoginCountDownTimeStr: "00:00:00",
      user_prize_info: null,
      usePrizeInfo: null,
      prizeAmountTipsValue: 0,
      new_user_active_info: null,
      newUserFinishCount: 0,
      newUserTimer: null,
      newUserCountDownTimerStr: "",
      dailyCheckInTotalGiveCoins: 0,
      isOpenFloatGift: false,
      total_draw_numbers: 0,
    };
  },
  watch: {
    isLoggedIn() {
      this.$nextTick(() => {
        this.setupFloatBoxTop();
      });
    },
  },
  unmounted() {
    clearInterval(this.buffTimer);
    clearInterval(this.secondDayLoginTimer);
    clearInterval(this.newUserTimer);
  },
  mounted() {
    this.$nextTick(() => {
      this.setupFloatBoxTop();
      const uc = sessionStorage.getItem("uc");
      if (uc !== null && uc !== undefined && uc !== "") {
        this.$eventBus.emit("showRegister", 2);
      }
    });
    this.$tools.userTrackReport({ track_id: "10" });
    if (this.$route.query.ext === "preHongbaoRain") {
      this.preHongbaoRain();
    }
    this.activityId = this.$store.getters.activityId;
    this.isJoinActivity = this.$store.getters.isJoinActivity;
    if (this.isLoggedIn && this.isJoinActivity && this.activityId !== "") {
      this.$eventBus.emit("fireGlobalTimer");
    }
    // this.$eventBus.on("loginSuccessHandle", this.loginSuccessHandle);
    this.$eventBus.on("logoutSuccessHandle", this.logoutSuccessHandle);
    this.$eventBus.on("globalCountDown", (totalSeconds) => {
      if (totalSeconds <= 0) {
        this.countDownTimeStr = "";
        return;
      }

      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      this.countDownTimeStr = `${formattedMinutes}:${formattedSeconds}`;
    });

    this.$eventBus.on("honebaoRainGameEndEvent", (result) => {
      const value = parseFloat(result).toFixed(2);
      const data = {
        action: "receive_red_prize",
        data: {
          activity_id: this.activityId,
          red_prize: Number(value),
        },
      };
      this.$http
        .post("activity", data)
        .then(() => {
          this.activityId = "";
          this.$store.commit("clearActivityInfo");
          this.$eventBus.emit("clearGlobalTimer");
          this.hongbaoRainResult = value;
          this.$refs.hongbaoRainEndDialog?.showModal();
        })
        .catch((error) => {
          console.log("error", error);
        });
    });

    this.setupCategory(0);
    if (this.$tools.isMobile() && this.$store.getters.isLoggedIn) {
      this.fetchRecentGames();
      this.fetchBuff();
      this.fetchSecondDayLoginData();
      this.fetchNewerTaskInfo();
      this.fetchDailyCheckInInfo();
      this.fetchDrawsCount();
    }

    this.handleOfCloseDailyLoginRewards();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("setCassinoData", this.$data);
    next();
  },
  computed: {
    isShowActivity() {
      return this.activityId !== "" && this.isJoinActivity === true && this.countDownTimeStr !== "";
    },
  },
  methods: {
    async fetchDrawsCount() {
      const data = {
        action: "draws_numbers",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.total_draw_numbers = response.data.draws_numbers;
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfAppDownload() {
      if (this.isLoggedIn) {
        this.$eventBus.emit("eventOfDownload");
      } else {
        this.handleOfRegister();
      }
    },
    handleScroll(event) {
      if (event.target.scrollTop < 10) {
        this.isOpenFloatGift = true;
      } else {
        this.isOpenFloatGift = false;
      }
    },
    async fetchDailyCheckInInfo() {
      const data = {
        action: "active_daily_check_in_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.dailyCheckInTotalGiveCoins = 0;
        const list = response.data.active_daily_check_in_info?.activity_prize_list;
        list.forEach((item) => {
          this.dailyCheckInTotalGiveCoins += item.coins_give;
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchNewerTaskInfo() {
      const data = {
        action: "new_user_active_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.new_user_active_info = response.data.new_user_active_info;
        if (this.new_user_active_info === null) {
          return;
        }
        this.newUserFinishCount = 0;
        for (const key of Object.keys(this.new_user_active_info)) {
          if (key.endsWith("finish")) {
            this.newUserFinishCount += this.new_user_active_info[key];
          }
        }
        const targetTime = new Date(this.new_user_active_info.effect_end);
        const targetTimestamp = targetTime.getTime();
        const currentTimestamp = Date.now();
        let diff = targetTimestamp - currentTimestamp;
        if (diff <= 86400) {
          this.newUserTimer = setInterval(() => {
            if (diff <= 0) {
              clearInterval(this.newUserTimer);
              this.newUserTimer = null;
              this.newUserCountDownTimerStr = "";
              return;
            }
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
              .toString()
              .padStart(2, "0");
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
              .toString()
              .padStart(2, "0");
            const seconds = Math.floor((diff % (1000 * 60)) / 1000)
              .toString()
              .padStart(2, "0");
            this.newUserCountDownTimerStr = `${hours}:${minutes}:${seconds}`;
            diff -= 1000;
          }, 1000);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfNewerTask() {
      this.$router.push({ name: "newer-task" });
    },
    handleOfInvite() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "m-commission" });
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
    handleOfAwardTask() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "award-task-page" });
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
    handleOfPiggyBank() {
      if (this.isLoggedIn) {
        this.$router.push({ name: "piggy-bank-page" });
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
    handleOfShowSecondDayLoginActivity() {
      this.$refs.dialogSecondDayLogin.showModal();
      // 次日登录活动页面展示
      this.$tools.userTrackReport({ track_id: "24" });
    },
    handleOfPrizeAmountTipsOk() {
      this.$refs.dialogSecondDayLoginPrizeAmountTips.close();
      this.nextOfUsedPrize();
    },
    nextOfUsedPrize() {
      const followPage = this.usePrizeInfo.prize_info.follow_page;
      switch (followPage) {
        case 0: // 不跳转
          break;
        case 1: // 首页
          this.$eventBus.emit("emitOfChangeTab", "casino");
          break;
        case 2: // 充值页
          this.$router.push({ name: "m-deposit-page" });
          break;
        case 3: // 提款页面
          this.$router.push({ name: "m-withdrawal-page" });
          break;
        case 4: // 抽奖页面
          break;

        default:
          break;
      }
    },
    async usePrize() {
      const data = {
        action: "use_raffle_prize",
        data: {
          raffle_prize_id: this.user_prize_info.id,
        },
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.usePrizeInfo = response.data.user_raffle_prize_info;
        if ("prize_amount" in response.data && response.data.prize_amount > 0) {
          this.prizeAmountTipsValue = response.data.prize_amount;
          this.$refs.dialogSecondDayLoginPrizeAmountTips.showModal();
        } else {
          this.nextOfUsedPrize();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfUsingPrizes() {
      this.$refs.dialogSecondDayLoginRewardTips.close();
      this.usePrize();
    },
    /**
     * 获取次日登录数据
     */
    async fetchSecondDayLoginData() {
      const data = {
        action: "next_day_login_active_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.next_day_login_active_info = response.data.next_day_login_active_info;
        if (this.next_day_login_active_info) {
          this.startSecondDayLoginCountDown();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    startSecondDayLoginCountDown() {
      if (this.next_day_login_active_info?.receive_state !== 0) {
        return;
      }

      const targetTime = new Date(this.next_day_login_active_info?.end_time);
      const targetTimestamp = targetTime.getTime();
      const currentTimestamp = Date.now();
      let diff = targetTimestamp - currentTimestamp;
      this.secondDayLoginTimer = setInterval(() => {
        if (diff <= 0) {
          clearInterval(this.secondDayLoginTimer);
          this.secondDayLoginCountDownTimeStr = "00:00:00";
          return;
        }
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((diff % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");
        this.secondDayLoginCountDownTimeStr = `${hours}:${minutes}:${seconds}`;
        diff -= 1000;
      }, 1000);
    },
    /**
     * 领取次日登录奖励
     */
    async receiveSecondDayRewards() {
      if (this.next_day_login_active_info?.receive_state !== 1) {
        return;
      }

      this.$refs.dialogSecondDayLogin.close();

      const data = {
        action: "receive_next_day_login",
        data: {},
      };
      try {
        // 次日登录活动领取成功
        this.$tools.userTrackReport({ track_id: "25" });
        const response = await this.$http.post("activity", data);
        this.user_prize_info = response.data.user_prize_info;
        this.$refs.dialogSecondDayLoginRewardTips.showModal();
        this.fetchSecondDayLoginData();
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfCS() {
      if (this.isLoggedIn) {
        this.$router.push("m-service");
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
    handleOfCloseTopTips() {
      this.isShowTopTips = false;
      sessionStorage.setItem("isShowTopTips", this.isShowTopTips);
    },
    handleOfCloseDailyLoginRewards() {
      this.isShowDailyLoginRewards = false;
      sessionStorage.setItem("isShowDailyLoginRewards", this.isShowDailyLoginRewards);
    },
    async fetchDailyLoginRewardsData() {
      if (!this.isLoggedIn) {
        this.handleOfRegister();
        return;
      }
      const data = {
        action: "seven_day_activity",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.dailyLoginRewardsData = response.data;
        this.$refs?.dailyLoginRewardsDialog?.showModal();
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfShowDailyLoginActivity() {
      this.$refs.dailyLoginRewardsCancelDialog.close();
      this.fetchDailyLoginRewardsData();
    },
    async dailyLoginRecharge(id, amount) {
      this.$refs.dailyLoginRewardsDialog.close();
      const data = {
        action: "collection",
        data: {
          scene: "login",
          amount: parseInt(amount) * 100,
          pay_method: "hkpPay",
          activity_type: 5, // 每日登录活动(7日活动) - 固定值
          activity_id: id,
        },
      };
      try {
        const response = await this.$http.post("pay", data);
        const payUrl = response.data.order.payUrl;
        this.$router.push({ name: "m-pay-page", query: { payUrl: payUrl } });
      } catch (error) {
        console.log("error", error);
      }
    },
    async dailyLoginReceiveReward() {
      this.$refs.dailyLoginRewardsDialog.close();
      const data = {
        action: "receive_seven_day",
        data: {},
      };
      try {
        await this.$http.post("activity", data);
        this.$refs.dailyLoginRewardsReceiveDialog.showModal();
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfShowAwardList() {
      this.$refs.dailyLoginRewardsDialog.close();
      this.$router.push({ name: "m-award" });
    },
    handleOfShowDailyLoginRewardsCancelDialog() {
      this.$refs.dailyLoginRewardsDialog.close();
      this.$refs.dailyLoginRewardsCancelDialog.showModal();
    },
    handleOfProvider(providerInfo) {
      console.log("providerInfo", providerInfo);
      if (providerInfo.id === 1001) {
        // PG
        this.$tools.userTrackReport({ track_id: "27" });
      } else if (providerInfo.id === 1003) {
        // JDB
        this.$tools.userTrackReport({ track_id: "29" });
      } else if (providerInfo.id === 1004) {
        // PP
        this.$tools.userTrackReport({ track_id: "28" });
      }
      this.$router.push({
        path: "/m-game-list/" + providerInfo.provider_name,
        query: { id: providerInfo.id, type: "provider" },
      });
    },
    setupFloatBoxTop() {
      const boxRef = this.$refs.boxRef;
      if (boxRef) {
        const rect = boxRef.getBoundingClientRect();
        const topDistance = rect.top + window.pageYOffset;
        this.floatBoxTop = topDistance;
      } else {
        console.error('Element with ref "boxRef" not found');
      }
      this.isOpenFloatGift = true;
    },
    handleOfBuffIcon() {
      this.isShowBuffDetail = !this.isShowBuffDetail;
      if (this.isShowBuffDetail) {
        clearTimeout(this.showBuffDetailTimer);
        this.showBuffDetailTimer = setTimeout(() => {
          this.isShowBuffDetail = false;
        }, 10000);
      }
    },
    handleOfBuffDetail() {
      this.isShowBuffDetail = false;
    },
    handleOfCloseTelegram() {
      this.isShowTelegram = false;
      sessionStorage.setItem("isShowTelegram", this.isShowTelegram);
    },
    handleOfTelegram() {
      this.$tools.newerActivityFinish({ join_channel_finish: 1 }, () => {
        this.fetchNewerTaskInfo();
      });
      this.$tools.openTelegram();
    },
    async fetchBuff() {
      const data = {
        action: "user_raffle_prize_buff",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.buffInfo = response.data.buff_info;
        if (this.buffInfo) {
          this.startBuffCountDown();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    startBuffCountDown() {
      const targetTime = new Date(this.buffInfo.state_duration);
      const targetTimestamp = targetTime.getTime();
      const currentTimestamp = Date.now();
      let diff = targetTimestamp - currentTimestamp;
      this.buffTimer = setInterval(() => {
        if (diff <= 0) {
          clearInterval(this.buffTimer);
          this.buffInfo = null;
          this.buffCountDownTimeStr = "";
          return;
        }
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((diff % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");
        this.buffCountDownTimeStr = `${hours}:${minutes}:${seconds}`;
        diff -= 1000;
      }, 1000);
    },
    handleOfLogin() {
      this.$eventBus.emit("showLogin");
    },
    handleOfRegister() {
      const trackId = "1"; // 注册按钮点击埋点id
      this.$eventBus.emit("showRegister", trackId);
    },
    handleOfDeposit() {
      this.handleOfHongbaoRainEndClose();
      this.$eventBus.emit("showDeposit", "deposit");
    },
    handleOfHongbaoRainEndClose() {
      this.$refs.hongbaoRainEndDialog.close();
    },
    handleOfShowHongbaoRainDialog() {
      this.$refs.hongbaoRainDialog.showModal();
    },
    handleOfCloseHongbaoRainDialog() {
      this.$refs.hongbaoRainDialog.close();
    },
    handleOfShowHongbaoRainStart() {
      this.fetchHongbaoRainData();
    },
    fetchHongbaoRainData() {
      const data = {
        action: "claim_parize",
        data: {
          activity_id: this.activityId,
        },
      };
      this.$http
        .post("activity", data)
        .then((response) => {
          this.activityFactorValue = response.data.factor_value;
          this.handleOfCloseHongbaoRainDialog();
          const amount = response.data.data;
          this.$refs.hongbaoRainStartDialog.showModal();
          this.startTime(amount);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    startTime(amount) {
      this.hongbaoRainStartCountDown = 3;
      const timer = setInterval(() => {
        this.hongbaoRainStartCountDown--;
        if (this.hongbaoRainStartCountDown === 0) {
          clearInterval(timer);
          this.$refs.hongbaoRainStartDialog.close();
          this.$eventBus.emit("hongbaoRainStart", amount);
        }
      }, 1000);
    },
    handleOfService() {
      if (this.isLoggedIn) {
        this.$router.push("m-service");
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
    setupCategory(cate) {
      this.category = cate;
      if (cate === 0) {
        this.fetchLobbyData();
      } else {
        let index = cate - 1;
        const list = this.cateGameList(index);
        if (list.length === 0) {
          if (index < 0) {
            return;
          }
          if (this.cateList.length - 1 < index) {
            return;
          }
          let obj = this.cateList[index];
          if (obj.id === 4) {
            // novo 新的
            this.$tools.userTrackReport({ track_id: "30" });
          } else if (obj.id === 2) {
            // Slots 老虎机
            this.$tools.userTrackReport({ track_id: "31" });
          } else if (obj.id === 3) {
            // Pesca 捕鱼
            this.$tools.userTrackReport({ track_id: "32" });
          } else if (obj.id === 1) {
            // Arcade 街机
            this.$tools.userTrackReport({ track_id: "33" });
          } else if (obj.id === 5) {
            // Card 棋牌
            this.$tools.userTrackReport({ track_id: "34" });
          }
          this.fetchGameListTypeData("game_category", obj);
        }
      }
    },
    fetchLobbyData() {
      const data = {
        action: "game_lobby",
      };
      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // tags
          this.tagsList = response.data.game_tags_list.list;
          this.tagsList.forEach((element) => {
            this.fetchGameListTypeData("game_tag", element);
          });
          // banner
          this.bannerList = response.data.banner_list;
          this.bannerList.forEach((item) => {
            item.picture = this.$global.data.cdn_url + item.picture;
            item.phone_picture = this.$global.data.cdn_url + item.phone_picture;
          });
          // categories
          const categoryList = response.data.category_list;
          this.cateList = categoryList;

          // 添加lobby分类
          const lobby = {
            catagory_name: "Salão",
            category_icon: this.$global.data.home_icon,
            icon_select: this.$global.data.home_icon_select,
          };
          this.cateListWithLoddy = [lobby, ...categoryList];

          // 厂商
          this.provider_list = response.data.provider_list;
          this.provider_icon = response.data.provider_icon;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // loginSuccessHandle(info) {
    //   // 注册or登录成功后 增加首页展示埋点
    //   this.$tools.userTrackReport({ track_id: "10" });

    //   this.isLoggedIn = true;
    //   this.fetchRecentGames();
    //   this.fetchBuff();
    //   // this.fetchDailyLoginRewardsData();
    //   this.fetchSecondDayLoginData();
    //   this.fetchNewerTaskInfo();
    //   this.fetchDailyCheckInInfo();

    //   if (info.code !== null && info.amount !== null && info.amount >= 0) {
    //     this.$hongbao.show(info.amount, () => {
    //       const data = {
    //         action: "activity_code_convert",
    //       };

    //       this.$http
    //         .post("userWallet", data)
    //         .then(() => {
    //           this.$hongbao.hide();
    //           this.$eventBus.emit("refreshHeaderCoins");
    //         })
    //         .catch((error) => {
    //           console.log("error", error);
    //         });
    //     });
    //   }
    //   this.preHongbaoRain();
    // },
    preHongbaoRain() {
      this.activityId = this.$store.getters.activityId;
      this.isJoinActivity = this.$store.getters.isJoinActivity;
      const isMobile = this.$tools.isMobile();
      if (isMobile && this.isJoinActivity === true && this.activityId !== "") {
        this.$eventBus.emit("fireGlobalTimer");
        this.$nextTick(() => {
          this.$refs.hongbaoRainDialog?.showModal();
        });
      }
    },
    logoutSuccessHandle() {
      this.resentList = [];
      this.new_user_active_info = null;
      this.dailyCheckInTotalGiveCoins = 0;
    },
    fetchRecentGames() {
      const data = {
        action: "game_list_recent",
      };
      this.$http
        .post("gameRecent", data)
        .then((response) => {
          this.resentList = response.data.game_list_recent;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    resentListPrevEvent() {
      if (this.resentIndex > 0) {
        this.resentIndex--;
      } else {
        this.resentIndex = 0;
      }
    },
    resentListNextEvent() {
      if (this.resentIndex < this.resentList.length - 1) {
        this.resentIndex++;
      }
    },
    /**
     * 分类游戏列表
     * @param {*} type 接口参数字段 (目前支持的字段：game_category, game_tag)
     * @param {*} item
     */
    fetchGameListTypeData(type, item) {
      let page = 0;
      if ("page" in item) {
        page = item.page + 1;
      }

      const data = {
        action: "game_list",
        data: {
          page,
        },
      };

      data.data[type] = item.id;
      if (type === "game_tag") {
        data.data.page_size = 10;
      }

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          if ("list" in item) {
            item.list.push(...response.data.game_list);
          } else {
            item.list = response.data.game_list;
          }
          item.page = page;
          item.moveIndex = 0;
          item.totalCount = response.data.count;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    /**
     * 根据下标获取本地分类游戏列表
     * @param {*} index
     */
    cateGameList(index) {
      if (index < 0) {
        return [];
      }
      if (this.cateList.length - 1 < index) {
        return [];
      }
      let obj = this.cateList[index];
      if ("list" in obj) {
        return obj.list;
      } else {
        return [];
      }
    },
    /**
     * 根据下标获取本地分类游戏的总个数
     * @param {*} index
     */
    cateGameTotalCount(index) {
      if (index < 0) {
        return 0;
      }
      if (this.cateList.length - 1 < index) {
        return 0;
      }
      let obj = this.cateList[index];
      return obj.totalCount;
    },
    /**
     * 搜索事件
     */
    serachInputHandle() {
      this.$tools.userTrackReport({ track_id: "19" });
      if (!this.isLoggedIn) {
        this.$eventBus.emit("isNeedLogin");
        return;
      }

      const isMobile = this.$tools.isMobile();
      if (!isMobile && this.searchInputValue === "") {
        return;
      }

      if (isMobile) {
        this.$router.push("m-search-detail");
      } else {
        this.$router.push({
          path: "/m-game-list/" + this.searchInputValue,
          query: { id: this.searchInputValue, type: "search" },
        });
      }
    },
    /**
     * see all按钮点击回调
     * @param {*} item
     */
    showAllCallBack(item) {
      const isMobile = this.$tools.isMobile();
      if (isMobile) {
        this.$router.push({ path: "/m-tag-list/" + item.id });
      } else {
        this.$router.push({
          path: "/game-list/" + item.tag_name,
          query: { id: item.id, type: "tag" },
        });
      }
    },
    /**
     * 右箭头事件
     * @param {*} index
     */
    moveNext(index) {
      let gameIndex = this.tagsList[index].moveIndex;
      let gameCount = this.tagsList[index].list.length;

      if (gameIndex < gameCount - 1) {
        gameIndex++;
      } else {
        gameIndex = gameCount - 1;
      }
      this.tagsList[index].moveIndex = gameIndex;
    },
    /**
     * 左箭头事件
     * @param {*} index
     */
    movePrev(index) {
      let gameIndex = this.tagsList[index].moveIndex;

      if (gameIndex > 0) {
        gameIndex--;
      } else {
        gameIndex = 0;
      }

      this.tagsList[index].moveIndex = gameIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes shake-animation {
  0% {
    transform: scale(1) rotate(-10deg);
  }
  20% {
    transform: scale(1) rotate(-10deg);
  }
  30% {
    transform: scale(1.2) rotate(-15deg);
  }
  40% {
    transform: scale(1.2) rotate(-5deg);
  }
  50% {
    transform: scale(1.2) rotate(-10deg);
  }
  60% {
    transform: scale(1.2) rotate(-15deg);
  }
  70% {
    transform: scale(1.2) rotate(-5deg);
  }
  80% {
    transform: scale(1.1) rotate(-10deg);
  }
  100% {
    transform: scale(1) rotate(-10deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.container {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .top-tips {
    display: flex;
    align-items: center;
    background: #ffd278;
    height: 40px;

    .tips-icon {
      margin-left: 6px;
      width: 30px;
      height: 30px;
    }

    .tips-text {
      flex: 1;
      margin-left: 10px;
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      line-height: 40px;
    }

    .tips-close-icon {
      margin-right: 16px;
      width: 15px;
      height: 15px;
    }
  }

  .float-box {
    position: fixed;
    display: flex;
    justify-content: space-between;
    right: 6px;
    z-index: 1;

    .float-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > img {
        width: 49px;
        height: 49px;
        margin-bottom: 10px;
      }

      & > .gift-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > .gift-icon {
          position: absolute;
          z-index: 1;
          width: 49px;
          height: 49px;
          margin-bottom: 10px;
          animation: shake-animation 1.5s infinite;
          // transition: opacity 0.5s ease-in-out;
        }

        & > .gift-wrapper {
          position: absolute;
          transition: max-width 0.5s ease-in-out;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          overflow: hidden;

          & > .close {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

          .second-day-login-box {
            margin-bottom: 36px;
            display: flex;
            position: relative;
            width: 62px;
            height: 62px;

            .second-day-login {
              width: 100%;
              height: 100%;
              background: url("../../assets/second-day-login/float_bg.webp") no-repeat;
              background-size: 100%;
              margin-top: 7px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              & > div {
                color: #ffffff;
                font-size: 10px;
                font-weight: bold;
              }
            }

            & > img {
              position: absolute;
              bottom: 1px;
              right: 5px;
              width: 55px;
              height: 48.5px;
            }
          }

          .float-buff {
            max-width: 300px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 15px;

            .buff-info {
              background: linear-gradient(0deg, #0c2133, #0c2133), linear-gradient(180deg, #164100 0%, #095000 100%);
              box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 1px rgba(6, 19, 28, 0.2);
              border-radius: 13px 0 0 13px;
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: flex-start;
              padding-left: 10px;
              padding-right: 10px;
              margin-right: -15px;
              margin-top: -6px;
              height: 60px;

              & > div:nth-child(1) {
                color: #ffd400;
                font-size: 12px;
                font-weight: 500;
              }

              & > div:nth-child(2) {
                color: #ffffff;
                font-size: 9px;
                font-weight: 500;
                text-align: left;
              }
            }

            .buff-icon {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              background-image: url("../../assets/float/buff_bg.png");
              background-repeat: no-repeat;
              background-size: cover;
              width: 75px;
              height: 75px;

              .buff-img {
                width: 30px;
                height: 20px;
              }

              .buff-timer {
                color: #ffffff;
                font-size: 12px;
                font-weight: 900;
              }
            }
          }

          .float-item {
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;
            & > img {
              width: 49px;
              height: 49px;
            }

            .close-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0 0 3px;
              & > img {
                width: 15px;
                height: 15px;
              }
            }
          }

          .new-user-box {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            position: relative;

            & > img {
              width: 62px;
              height: 62px;
            }

            & > .progress {
              position: absolute;
              top: 42px;
              width: 100%;
              font-size: 12px;
              color: #ffffff;
            }

            & > .countDown {
              margin-top: 2px;
              background: #dde8e7;
              height: 19px;
              line-height: 19px;
              border-radius: 5px;
              font-size: 12px;
              font-weight: bold;
              color: #d80000;
            }
          }

          .daily {
            margin-bottom: 10px;
            background: url("../../assets/daily-check-in/float_bg.webp") no-repeat;
            background-size: cover;
            width: 49px;
            height: 49px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & > img {
              width: 29px;
              height: 26px;
            }

            & > div {
              color: #ffffff;
              font-size: 8px;
              font-weight: 1000;
            }
          }

          & > .raffle-box {
            display: flex;
            flex-direction: column;
            position: relative;

            & > img {
              width: 49px;
              height: 49px;
              margin-bottom: 10px;
            }

            & > div {
              position: absolute;
              top: 0;
              right: 0;
              width: 15px;
              height: 15px;
              box-sizing: border-box;
              border: 0.5px solid #ff0000;
              border-radius: 50%;
              background: #ffffff;
              color: #ff0000;
              font-size: 10px;
              font-weight: 900;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          & > img {
            width: 49px;
            height: 49px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .float2-box {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 5px;
    bottom: 68px;
    z-index: 1;

    & > img {
      width: 49px;
      height: 49px;
      margin-bottom: 10px;
    }
  }

  .m-header {
    display: flex;
    margin: 10px 0;
  }

  & > .box {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .m-cate {
    padding-top: 10px;
    padding-bottom: 5px;
    display: block;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: -1px;
    z-index: 1;
  }

  .main-wrapper {
    margin-top: 10px;
    display: flex;

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100vw;

      .lobby {
        padding: 0 15px;
        display: flex;
        flex-direction: column;

        .lobby-wrapper {
          display: flex;
          flex-direction: column;

          .tags-list {
            margin-top: 6px;
            .m-game-list {
              display: grid;
              margin: 10px 0px;
            }
          }

          .provider-list {
            display: flex;
            flex-direction: column;
            .provider-content {
              margin-top: 10px;
              margin-bottom: 10px;
              display: flex;
              overflow-y: scroll;
            }
            .provider {
              display: flex;
              margin-right: 14px;
              & > img {
                width: 125px;
                height: auto;
              }
            }

            .provider:last-of-type {
              margin-right: 0;
            }
          }
        }
      }

      .cate-game-list {
        display: flex;
        flex-direction: column;

        .collection {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          row-gap: 10px;
          column-gap: 10px;
          margin: 3px 15px 6px;

          .item {
            height: auto;
            min-width: 90px;
          }
        }
      }
      .mobile-page-footer {
        display: flex;
      }
    }
  }

  .daily-login-rewards-receive-wrapper {
    display: flex;

    & > .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .content {
        display: flex;
        flex-direction: column;
        background: #0a1f32;
        border-radius: 10px;

        & > div:nth-child(1) {
          width: 345px;
          margin-top: 20px;
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
        }

        & > div:nth-child(2) {
          margin-top: 35px;
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
        }

        & > div:nth-child(3) {
          margin-top: 15px;
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
        }

        & > .btns {
          margin-top: 63px;
          margin-bottom: 20px;
          display: flex;

          & > div:first-of-type {
            flex: 1;
            margin-left: 25px;
            background: #33439e;
            border-radius: 8px;
            height: 40px;
            line-height: 40px;
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
          }

          & > div:last-of-type {
            flex: 1;
            margin-left: 15px;
            margin-right: 25px;
            background: #ffd400;
            border-radius: 8px;
            height: 40px;
            line-height: 40px;
            color: #000000;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }

      & > .close {
        margin-top: 22px;
        width: 32px;
        height: 32px;
      }
    }
  }

  .daily-login-rewards-cancel-wrapper {
    display: flex;

    & > .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > .content {
        display: flex;
        flex-direction: column;
        background: #0a1f32;
        border-radius: 10px;

        & > div:nth-child(1) {
          width: 345px;
          margin-top: 20px;
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
        }

        & > div:nth-child(2) {
          margin-top: 35px;
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
        }

        & > div:nth-child(3) {
          margin-top: 15px;
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
        }

        & > .btns {
          margin-top: 63px;
          margin-bottom: 20px;
          display: flex;

          & > div:first-of-type {
            flex: 1;
            margin-left: 25px;
            background: #33439e;
            border-radius: 8px;
            height: 40px;
            line-height: 40px;
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
          }

          & > div:last-of-type {
            flex: 1;
            margin-left: 15px;
            margin-right: 25px;
            background: #ffd400;
            border-radius: 8px;
            height: 40px;
            line-height: 40px;
            color: #000000;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }

      & > .close {
        margin-top: 22px;
        width: 32px;
        height: 32px;
      }
    }
  }

  .start_dialog_bg {
    position: relative;
    height: 314px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .start_icon {
      position: absolute;
      width: 90px;
      height: 90px;
      top: 0px;
    }

    .start_dialog_content {
      margin-top: 48px;
      background: var(--color-popup-bg);
      border-radius: 10px;
      height: 266px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 15px;

      .start_title {
        margin-top: 58px;
        color: var(--primary-text-color);
        font-size: 18px;
        font-weight: bold;
      }

      .start_count {
        color: #ffffff;
        font-size: 72px;
        font-weight: 500;
      }

      .start_des {
        color: #ffffff;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .end_dialog_bg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .end_icon {
      position: absolute;
      width: 90px;
      height: 90px;
      top: 0px;
    }

    .end_dialog_content {
      margin-top: 48px;
      background: var(--color-popup-bg);
      border-radius: 10px;
      height: 365px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 15px;

      .end_title {
        margin-top: 58px;
        color: var(--primary-text-color);
        font-size: 18px;
        font-weight: bold;
      }

      .end_money {
        margin-top: 37px;
        color: var(--primary-text-color);
        font-size: 36px;
        font-weight: 500;
      }

      .end_des {
        margin-top: 45px;
        color: #ffffff;
        font-size: 18px;
        font-weight: normal;

        span:nth-child(2) {
          color: #ecc603;
        }
      }

      .end_btn {
        margin-top: 24px;
        background: var(--color-btn-bg);
        border-radius: 8px;
        height: 40px;
        color: var(--color-btn-text);
        font-size: 18px;
        font-weight: 500;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .end_close {
      margin-top: 28px;
      width: 32px;
      height: 32px;
    }
  }

  .dialog-second-day-login-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 345px;
    height: 357px;
    position: relative;

    & > .bg-box {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      z-index: -1;

      & > div:nth-child(1) {
        flex: 1;
        background: #283478;
        border-radius: 10px 10px 0px 0px;
      }

      & > div:nth-child(2) {
        flex: 1;
        background: #33439e;
        border-radius: 0px 0px 10px 10px;
      }
    }

    & > .title-box {
      margin: 15px 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        color: #94a4ff;
        font-size: 16px;
        font-weight: 500;
      }

      & > img {
        width: 20px;
        height: 20px;
      }
    }

    & > .des {
      margin: 16px 20px 0;
      color: #ffffff;
      font-size: 26px;
      font-weight: 900;
      text-align: left;
    }

    & > .context-box {
      margin: 10px 20px 0;
      display: flex;
      justify-content: space-between;

      & > .context {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;

        & > div:nth-child(1) {
          color: #ffd400;
          font-size: 40px;
          font-weight: 900;
        }

        & > div:nth-child(2) {
          color: #ffffff;
          font-size: 17px;
          font-weight: 500;
        }

        & > div:nth-child(3) {
          margin-top: 60px;
          color: #94a4ff;
          font-size: 12px;
          font-weight: 500;
        }

        & > div:nth-child(4) {
          color: #34ce4b;
          font-size: 25px;
          font-weight: bold;
        }
      }

      & > img {
        margin-top: 29px;
        width: 183.5px;
        height: 163px;
      }
    }

    & > .btn {
      margin: 18px 10px auto;

      height: 40px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 18px;
      font-weight: 500;
    }

    & > .state0 {
      background: #6e76a5;
      color: #ffffff;
    }

    & > .state1 {
      background: #ffd400;
      color: #000000;
    }

    & > .state2 {
      background: #299c3a;
      color: #000000;
    }
  }

  .second-day-login-reward-tips-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 345px;
    height: 220px;
    background: var(--color-popup-bg);
    border-radius: 10px;

    div:nth-child(1) {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }

    div:nth-child(2) {
      color: #ffd400;
      font-size: 32px;
      font-weight: 500;
    }

    div:nth-child(3) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    .buttons {
      width: 90%;
      display: flex;
      justify-content: space-between;

      div {
        flex: 0 1 48%;
        background: var(--color-btn-bg);
        height: 40px;
        border-radius: 8px;
        color: var(--color-btn-text);
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .button {
      background: var(--color-btn-bg);
      color: var(--color-btn-text);
      border-radius: 8px;
      width: 80%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
