<template>
  <div class="container">
    <div class="top-banner">
      <div class="item">
        <div class="title-box">
          <img class="flag" src="@/assets/wallet/bra_flag.webp" alt="" />
          <div>Saldo R$</div>
          <img class="icon" src="@/assets/wallet/wallet_icon.webp" alt="" />
        </div>
        <div class="money">{{ $tools.formatCurrency(walletData?.user_wallet?.coins || 0) }}</div>
        <div class="mind">
          Você precisa apostar
          <span @click="$router.push({ name: 'bet-task' })">{{
            $tools.formatCurrency(
              walletData?.pay_out_task?.task_amount - walletData?.pay_out_task?.task_amount_done || 0
            )
          }}</span>
          para poder sacar
        </div>
      </div>
    </div>
    <div class="pix-empty" v-if="walletData?.user_pix_list.length === 0" @click="handleOfAddPix">
      <img src="@/assets/wallet/empty_icon.webp" alt="" />
      <div>Adicionar conta de saque</div>
      <img src="@/assets/wallet/arrow_right.webp" alt="" />
    </div>
    <div class="pix-box" v-else>
      <img class="pix-icon" src="@/assets/wallet/pix-icon.webp" alt="" />
      <div>PIX({{ walletData?.user_pix_list[0]?.pix_id }}) {{ walletData?.user_pix_list[0].pix }}</div>
      <img class="pix-lock" src="@/assets/wallet/pix-lock.webp" alt="" />
    </div>

    <div class="tips">
      O valor mínimo para retirada é <span>R$ {{ $global.data.payout_mini_amount }}</span
      >, sem taxa de retirada
    </div>

    <div class="input-box">
      <div class="unit">R$</div>
      <input
        type="number"
        :placeholder="'Mínimo ' + $global.data.payout_mini_amount + ', máximo ' + $global.data.payout_max_amount"
        v-model="inputMoney"
      />
      <div class="btn" @click="handleOfAll">Tudo</div>
    </div>

    <div class="pw-box">
      <div class="header">
        <div>Nova senha de saque</div>
        <img
          :src="
            isVisiblePw
              ? require('@/assets/password/visible_icon.webp')
              : require('@/assets/password/invisible_icon.webp')
          "
          alt=""
          @click="isVisiblePw = !isVisiblePw"
        />
      </div>
      <Password6Input v-if="walletData?.user_status === 1" v-model:inputValue="password" :isVisible="isVisiblePw" />
      <div class="lock" v-else>
        <img src="@/assets/wallet/lock.webp" alt="" />
      </div>
    </div>

    <div
      class="btn"
      :class="walletData?.user_wallet?.coins > 0 ? 'btn-enable' : 'btn-disable'"
      v-if="walletData?.user_status === 1"
      @click="withdrawalHandle"
    >
      Retirada
    </div>
    <div class="btn" v-else @click="$router.push({ name: 'm-service' })">Entre em contato com o suporte</div>
    <div class="box">
      <div class="title">Instruções de Retirada</div>
      <div class="rules">
        <div>
          1.Cada depósito requer a conclusão de uma tarefa de apostas antes que seja possível realizar um saque.<br />
          2.Caso a tarefa de apostas não tenha sido concluída, depósitos adicionais ou bônus recebidos aumentarão
          automaticamente a tarefa de apostas.<br />
          3.Somente valores inteiros podem ser sacados, sem incluir casas decimais.<br />
          4.O valor mínimo de saque diário é de R$ {{ $global.data.payout_mini_amount }}, e o máximo é de R$
          {{ $global.data.payout_max_amount }}, sem taxa de saque.
        </div>
      </div>
    </div>
  </div>
  <dialog ref="taskDialog">
    <div class="task-dialog-wrapper">
      <div class="header">
        <div>Dica</div>
        <img src="@/assets/wallet/coin_icon.webp" alt="" />
        <img src="@/assets/award-task/close.webp" alt="" @click="$refs.taskDialog.close()" />
      </div>
      <div class="des1">
        De acordo com as regras, você ainda precisa apostar
        <span>{{
          $tools.formatCurrency(walletData?.pay_out_task?.task_amount - walletData?.pay_out_task?.task_amount_done)
        }}</span>
        para poder sacar!
      </div>
      <div class="btns">
        <div class="btn" @click="handleOfGoTask">Verificar Tarefas</div>
        <div class="btn" @click="handleOfGoCasino">Ir Apostar</div>
      </div>
    </div>
  </dialog>
</template>
<script>
import Password6Input from "@/components/Password6Input.vue";
export default {
  components: {
    Password6Input,
  },
  data() {
    return {
      walletData: null,
      inputMoney: "",
      password: "",
      isVisiblePw: false,
    };
  },
  mounted() {
    this.$emit("update:title", "Carteira");
    this.getUserWallet();
  },
  methods: {
    handleOfGoTask() {
      this.$refs.taskDialog.close();
      this.$router.push({ name: "bet-task" });
    },
    handleOfGoCasino() {
      this.$refs.taskDialog.close();
      this.$router.push({ name: "casino" });
    },
    handleOfAll() {
      if (this.walletData.pay_out_status) {
        this.inputMoney = Math.min(this.walletData?.user_wallet?.coins, this.$global.data.payout_max_amount);
      } else {
        this.$refs.taskDialog.showModal();
      }
    },
    handleOfAddPix() {
      this.$router.push({ name: "add-pix" });
    },
    async withdrawalHandle() {
      if (this.walletData.user_wallet.coins <= 0) {
        this.$toast.error("Sem saldo disponível para saque");
        return;
      }

      if (this.walletData.pay_out_status === false) {
        this.$refs.taskDialog.showModal();
        return;
      }

      const data = {
        action: "user_withdrawal",
        data: {
          amount: this.inputMoney, // 提款金额
          amount_type: 1, // 提款类型 1现金 2佣金 3奖金提现任务
          user_pix_id: this.walletData.user_pix_list[0].id, // 提款账户id
          pay_out_pwd: this.password, // 提款密码
          activity_id: 0, // 提款券奖品ID:user_pay_coupon_list接口id字段 无则传递0
        },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        console.log("response", response);
        this.getUserWallet();
      } catch (error) {
        console.log("error", error);
      }
    },
    getUserWallet() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.walletData = response.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    checkUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          const data = response.data.data;
          this.userInfo = data;
          if (
            data.real_name === null ||
            data.customer_cert === null ||
            data.email === null ||
            data.phone_verify === 0
          ) {
            this.$router.push({
              path: "/m-verification-info1",
              query: { withdrawable: this.wallet.withdrawable, amountType: 1 },
            });
          } else {
            this.$router.push({
              path: "/m-verification-info2",
              query: { withdrawable: this.wallet.withdrawable, amountType: 1 },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .top-banner {
    margin: 0 15px;
    background: #33439e;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;

    & > .item {
      display: flex;
      flex-direction: column;

      .title-box {
        display: flex;
        align-items: center;

        & > .flag {
          width: 15px;
          height: 15px;
        }

        & > div {
          margin-left: 10px;
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }

        & > .icon {
          margin-left: auto;
          width: 15px;
          height: 15px;
        }
      }

      .money {
        text-align: left;
        margin-top: 5px;
        color: #ffd400;
        font-size: 36px;
        font-weight: bold;
      }

      .mind {
        text-align: left;
        margin-top: 10px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;

        & > span {
          color: #ffd400;
        }
      }
    }
  }

  .pix-empty {
    margin: 10px 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #0a1f32;
    border-radius: 10px;
    padding: 0 15px;

    & > img {
      width: 24.5px;
      height: 19px;
    }

    & > div {
      margin-left: 10px;
      margin-right: auto;
      color: #96a7c6;
      font-size: 14px;
      font-weight: 500;
      height: 45px;
      line-height: 45px;
    }
  }

  .pix-box {
    margin: 10px 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1c8277;
    border-radius: 10px;
    padding: 11px 10px 11px 15px;

    .pix-icon {
      width: 22px;
      height: 22px;
    }

    & > div {
      margin-left: 12px;
      margin-right: auto;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    .pix-lock {
      width: 22px;
      height: 22px;
    }
  }

  .tips {
    margin: 20px 30px 0;
    text-align: left;
    color: #96a7c6;
    font-size: 10px;
    font-weight: 500;

    & > span {
      color: #ffd400;
    }
  }

  .input-box {
    margin: 10px 15px 0;
    background: #0a1f32;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 15px;

    .unit {
      color: #96a7c6;
      font-size: 14px;
      font-weight: 500;
    }

    input {
      flex: 1;
      margin: 0 10px;
      font-size: 12px;
      font-weight: 500;
      height: 45px;
    }

    & > .btn {
      background: transparent;
      color: #ffd400;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .pw-box {
    margin: 40px 15px 0;
    display: flex;
    flex-direction: column;

    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
      }

      & > img {
        width: 15px;
        height: 12px;
      }
    }

    .lock {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #0a1f32;
      height: 45px;
      border-radius: 10px;

      & > img {
        width: 20px;
        height: 20px;
      }
    }
  }

  & > .btn {
    margin: 20px 15px;

    height: 40px;
    line-height: 40px;
    border-radius: 8px;

    font-size: 18px;
    font-weight: 500;
  }

  & > .btn-enable {
    color: #000000;
    background: linear-gradient(277deg, #d6ae60 0%, #ebdd9c 100%);
  }

  & > .btn-disable {
    background: #0a1f32;
    color: #264f74;
  }

  & > .box {
    display: flex;
    flex-direction: column;

    .title {
      margin: 10px 15px 0;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .rules {
      margin: 10px 15px 0;
      font-size: 12px;
      font-weight: 300;
      line-height: 22px;
      color: #96a7c6;
      display: flex;
      flex-direction: column;

      div {
        text-align: left;
      }
    }
  }
}

.task-dialog-wrapper {
  display: flex;
  flex-direction: column;
  // min-width: 345px;
  height: 247px;
  background: #0a1f32;
  border-radius: 10px;

  & > .header {
    border-radius: 10px 10px 0px 0px;
    background: #132f48;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px 0 23px;

    & > div:first-child {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
    }

    & > img:first-of-type {
      width: 70px;
      height: 48px;
    }

    & > img:last-of-type {
      width: 20px;
      height: 20px;
    }
  }

  & > .des1 {
    margin-top: 50px;
    color: #96a7c6;
    font-size: 12px;
    font-weight: 500;

    & > span {
      color: #ffd400;
    }
  }

  & > .btns {
    margin-top: 65px;
    display: flex;
    justify-content: space-around;

    & > .btn {
      background: #33439e;
      width: 135px;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
