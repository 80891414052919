<template>
  <div class="common-dialog-tip-content">
    <div class="content-box">
      <div>{{ title }}</div>
      <div>{{ des }}</div>
      <div>{{ value }}</div>
      <div @click="handleOfConfirm">{{ buttonTitle }}</div>
    </div>
    <img src="@/assets/login/close_icon.png" alt="" @click="handleOfClose" />
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Dicas",
    },
    des: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    buttonTitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleOfConfirm() {
      this.$emit("confirmEvent");
    },
    handleOfClose() {
      this.$emit("closeEvent");
    },
  },
};
</script>
<style lang="scss" scoped>
.common-dialog-tip-content {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  .content-box {
    width: 90%;
    padding: 20px;
    margin: auto 10px;
    background: var(--color-popup-bg);
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    div:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
    }

    div:nth-child(2) {
      margin-top: 20px;
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    div:nth-child(3) {
      margin-top: 20px;
      color: #ffd400;
      font-size: 25px;
      font-weight: bold;
    }

    div:nth-child(4) {
      margin-top: 40px;
      height: 40px;
      background: #3e7bb0;
      border-radius: 8px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  img {
    margin-top: 22px;
    width: 32px;
    height: 32px;
  }
}
</style>
