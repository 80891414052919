<template>
  <div class="card-container">
    <div class="card-wrapper">
      <div
        class="card"
        v-for="item in gameList"
        :key="item.game_id"
        :style="{ transform: 'translateX(' + currentIndex * moveWidth + 'px)' }"
      >
        <GameCard :style="{ width: cardWidth + 'px', 'margin-right': cardMarginRight + 'px' }" :game="item"></GameCard>
      </div>
    </div>
  </div>
</template>
<script>
import GameCard from "./GameCard.vue";

export default {
  props: {
    currentIndex: Number,
    gameList: Array,
  },
  data() {
    let cardWidth = 0;
    let cardMarginRight = 0;
    if (this.$tools.isMobile()) {
      cardWidth = 105;
      cardMarginRight = 8;
    } else {
      cardWidth = 180;
      cardMarginRight = 16;
    }
    return {
      cardWidth,
      cardMarginRight,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandle);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandle);
  },
  methods: {
    resizeHandle() {
      if (this.$tools.isMobile()) {
        this.cardWidth = 105;
      } else {
        this.cardWidth = 180;
      }
    },
  },
  computed: {
    moveWidth() {
      return -(this.cardWidth + this.cardMarginRight);
    },
    cardWrapperWidth() {
      if (!Array.isArray(this.gameList)) {
        return;
      }

      // 根据屏幕宽度和卡片数量动态计算卡片容器的宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const cardCount = this.gameList.length;
      const cardWidth = this.cardWidth; // 设置卡片的固定宽度，根据实际需要进行调整

      const maxVisibleCards = Math.floor(screenWidth / cardWidth); // 可见的最大卡片数
      const wrapperWidth = Math.min(cardCount, maxVisibleCards - 1) * cardWidth; // 根据可见的卡片数计算容器的宽度

      return wrapperWidth;
    },
  },
  components: { GameCard },
};
</script>
<style lang="scss" scoped>
.card-container {
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  overflow: hidden;

  .card-wrapper {
    display: flex;
    margin-bottom: 40px;

    .card {
      transition: transform 0.3s ease-in-out;
      flex-shrink: 0;
    }
  }
}

@media (max-width: 768px) {
  .card-container .card-wrapper {
    overflow-x: scroll;
    margin-bottom: 20px;
  }
}
</style>
