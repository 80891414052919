<template>
  <div class="container" ref="scrollContent" @click="showProviderList = false">
    <div class="wrapper">
      <div class="options">
        <div class="search">
          <img src="../../assets/header/search.png" alt="" />
          <input type="text" :placeholder="$t('search')" v-model="searchInputValue" @keyup.enter="serachInputHandle" />
        </div>
        <div class="sort">
          <div class="provider" @click.stop="showProviderListHandle">
            <div>{{ providerName }}</div>
            <img src="../../assets/header/down_arrow.png" alt="" />
            <div v-if="showProviderList === true" class="provider-list">
              <div class="section" @click.stop="onTapProvicerItem({})">
                {{ $t("all_suppliers") }}
              </div>
              <div
                class="cell"
                v-for="(item, index) in providerList"
                :key="index"
                @click.stop="onTapProvicerItem(item)"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
          <div class="hot" @click.stop="showOrderListHandle">
            <div>{{ sortOrderText }}</div>
            <img src="../../assets/header/down_arrow.png" alt="" />
          </div>
        </div>
      </div>
      <div class="collection">
        <div class="item" v-for="item in gameList" :key="item.game_id">
          <GameCard :game="item"></GameCard>
        </div>
      </div>
      <div class="footer">
        <LoadMoreComp :cur-count="curCount" :total-count="totalCount" @loadMoreEvent="fetchGameListData"></LoadMoreComp>
      </div>
    </div>
    <MobileFooter class="mobile-page-footer"></MobileFooter>
  </div>
  <van-popup v-model:show="showProviderPicker" round position="bottom">
    <van-picker :columns="providerList" @cancel="showProviderPicker = false" @confirm="providerPickerConfirmHandle" />
  </van-popup>
  <van-popup v-model:show="showOrderPicker" round position="bottom">
    <van-picker :columns="orderList" @cancel="showOrderPicker = false" @confirm="orderPickerConfirmHandle" />
  </van-popup>
</template>

<script>
import MobileFooter from "@/components/MobileFooter.vue";
import GameCard from "@/components/GameCard.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
import { Picker, Popup } from "vant";
import "vant/lib/index.css";
export default {
  emits: ["update:title"],
  data() {
    return {
      title: "",
      gameList: [],
      page: 0,
      curCount: 0,
      totalCount: 0,
      isLoading: false,
      searchInputValue: "",
      type: "",
      providerId: "",
      providerName: this.$t("all_suppliers"),
      providerList: [],
      categoryId: "",
      showProviderList: false,
      tagId: "",
      showProviderPicker: false,
      orderList: [
        { text: this.$t("popular_sort"), value: "" },
        { text: "A-Z", value: "asc" },
        { text: "Z-A", value: "desc" },
      ],
      showOrderPicker: false,
      sortOrderText: this.$t("popular_sort"),
      sortOrderValue: "",
      scrollOffset: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "casino") {
      this.$store.commit("clearGameListCache");
    } else {
      this.$store.commit("setGameListData", this.$data);
    }
    next();
  },
  mounted() {
    this.$refs.scrollContent.addEventListener("scroll", () => {
      if (this.$tools.isMobile()) {
        this.scrollOffset = this.$refs.scrollContent.scrollTop || this.$refs.scrollContent.pageYOffset;
      }
    });

    const gameListData = this.$store.getters.gameListData;
    if (gameListData) {
      this.title = gameListData.title;
      this.gameList = gameListData.gameList;
      this.page = gameListData.page;
      this.curCount = gameListData.curCount;
      this.totalCount = gameListData.totalCount;
      this.searchInputValue = gameListData.searchInputValue;
      this.type = gameListData.type;
      this.providerId = gameListData.providerId;
      this.providerName = gameListData.providerName;
      this.providerList = gameListData.providerList;
      this.categoryId = gameListData.categoryId;
      this.tagId = gameListData.tagId;
      this.sortOrderText = gameListData.sortOrderText;
      this.sortOrderValue = gameListData.sortOrderValue;
      this.scrollOffset = gameListData.scrollOffset;
      this.$nextTick(() => {
        this.$refs.scrollContent.scrollTop = this.scrollOffset;
      });
    } else {
      this.fetchGameListSupplierData();
      this.type = this.$route.query.type;
      const id = this.$route.query.id;
      const info = this.$route.params.info;
      if (this.type === "provider") {
        this.title = info + " Gaming Supplier";
        this.providerId = id;
      } else if (this.type === "category") {
        this.title = info;
        this.categoryId = id;
      } else if (this.type === "search") {
        this.title = "Search: " + '"' + id + '"';
        this.searchInputValue = id;
      } else if (this.type === "tag") {
        this.title = info;
        this.tagId = id;
      }
      this.fetchGameListData();
    }
    this.$emit("update:title", this.title);
  },
  methods: {
    showProviderListHandle() {
      if (this.$tools.isMobile()) {
        this.showProviderPicker = true;
      } else {
        this.showProviderList = true;
      }
    },
    providerPickerConfirmHandle(opt) {
      if ("value" in opt.selectedOptions[0]) {
        this.providerId = opt.selectedOptions[0].value;
        this.providerName = opt.selectedOptions[0].text;
      } else {
        this.providerId = "";
        this.providerName = this.$t("all_suppliers");
      }
      this.showProviderPicker = false;
      this.page = 0;
      this.fetchGameListData();
    },
    showOrderListHandle() {
      if (this.$tools.isMobile()) {
        this.showOrderPicker = true;
      }
    },
    orderPickerConfirmHandle(opt) {
      this.sortOrderText = opt.selectedOptions[0].text;
      this.sortOrderValue = opt.selectedOptions[0].value;
      this.showOrderPicker = false;
      this.page = 0;
      this.fetchGameListData();
    },
    onTapProvicerItem(item) {
      if ("value" in item) {
        this.providerId = item.value;
        this.providerName = item.text;
      } else {
        this.providerId = "";
        this.providerName = this.$t("all_suppliers");
      }
      this.showProviderList = false;
      this.page = 0;
      this.fetchGameListData();
    },
    /**
     * 搜索事件
     */
    serachInputHandle() {
      this.title = "Search: " + '"' + this.searchInputValue + '"';
      this.page = 0;
      this.fetchGameListData();
    },
    /**
     * 获取游戏列表
     */
    fetchGameListData() {
      this.isLoading = true;

      if (this.page === 0) {
        this.gameList = [];
      }

      const data = {
        action: "game_list",
        data: {
          page: this.page++,
          sortOrder: this.sortOrderValue,
        },
      };

      if (this.providerId !== "") {
        data.data.game_provider = parseInt(this.providerId);
      }
      if (this.categoryId !== "") {
        data.data.game_category = parseInt(this.categoryId);
      }
      if (this.searchInputValue !== "") {
        data.data.game_name_en = this.searchInputValue;
      }
      if (this.tagId !== "") {
        data.data.game_tag = parseInt(this.tagId);
      }

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          this.gameList.push(...response.data.game_list);
          this.curCount = this.gameList.length;
          this.totalCount = response.data.count;
          this.isLoading = false;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
          this.page--;
          this.isLoading = false;
        });
    },
    /**
     * 供应商列表
     */
    fetchGameListSupplierData() {
      const data = {
        action: "provider_list",
        data: {},
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          const obj = { text: this.$t("all_suppliers") };
          const list = response.data.provider_list.map((item) => {
            return { text: item.provider_name, value: item.id };
          });
          this.providerList = [obj, ...list];
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  components: {
    GameCard,
    LoadMoreComp,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    MobileFooter,
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .wrapper {
    margin: 20px 15px 0;
    display: flex;
    flex-direction: column;

    .options {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search {
        background: #112e47;
        display: flex;
        height: 40px;
        border-radius: 8px;

        & > img {
          margin: 10px 6px 10px 8px;
          width: 18px;
          height: 18px;
        }

        & > input {
          width: 100%;
          background: transparent;
          line-height: 40px;
          border: 0;
          outline-style: none;
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
        }

        & > input::placeholder {
          color: #ffffff;
          opacity: 0.5;
        }
      }

      .sort {
        margin-left: 10px;
        justify-content: space-between;
        height: 40px;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;

        .provider {
          background: #112e47;
          flex: 1;
          height: 40px;
          padding: 0 6px 0 12px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          margin-right: 10px;
          position: relative;

          & > div {
            margin-right: 0;
            line-height: 17px;
            font-size: 12px;
            font-weight: 600;
            color: #ffffff;
            flex: 1;
            text-align: left;
          }

          & > img {
            width: 16px;
            height: 16px;
          }

          .provider-list {
            position: absolute;
            display: flex;
            flex-direction: column;
            background: #141414;
            border-radius: 8px;
            top: 40px;
            left: -20px;
            width: 220px;
            padding-top: 10px;
            padding-bottom: 10px;
            z-index: 1;

            .section {
              margin: 10px 20px;
              font-size: 14px;
              color: #e9c97e;
              width: 100%;
              height: 40px;
              text-align: left;
            }

            .cell {
              margin: 10px 20px;
              font-size: 14px;
              color: #ffffff;
              width: 100%;
              height: 40px;
              text-align: left;
            }
          }
        }

        .hot {
          background: #112e47;
          flex: 1;
          height: 40px;
          padding: 0 6px 0 12px;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div {
            margin-left: 6px;
            line-height: 40px;
          }

          & > img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .collection {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 35px -7.5px 0;

      .item {
        margin: 0 7.5px;
        height: auto;
        min-width: 105px;
        margin-bottom: 15px;
      }
    }

    .footer {
      margin: 0px auto;
      display: flex;
      justify-content: center;
    }
  }

  .mobile-page-footer {
    display: flex;
  }
}
</style>
