<template>
  <div class="secure">
    <div class="wrapper">
      <div class="yes" v-if="userInfo?.pay_out_pwd_set">
        <div class="tips-box">
          <img src="@/assets/account/secure_icon.webp" alt="" />
          <div>Segurança de Retirada Garantida</div>
        </div>
        <div class="button" @click="handleOfModifyPW">Alterar Senha de Retirada</div>
        <div class="mind">
          Atenção: A senha de saque protege seus fundos, é muito importante. Mantenha-a em segredo para evitar qualquer
          perda financeira.
        </div>
      </div>
      <div class="no" v-else>
        <img src="@/assets/account/secure_icon.webp" alt="" />
        <div>
          Para definir a senha de retirada<br />
          é necessário verificar o celular
        </div>
        <div class="btn" @click="handleOfSetPW">Verificar celular</div>
      </div>
    </div>
    <dialog ref="verifyDialog">
      <VerifyDialogForPhoneOrEmail
        :title="dialogTitle"
        :verifyType="2"
        :phone="userInfo?.phone"
        @closeEvent="handleOfVerifyDialogClose"
        @endVerifyEvent="
          $router.push({ name: 'set-password', query: { type: this.userInfo.pay_out_pwd_set ? '1' : '0' } })
        "
      ></VerifyDialogForPhoneOrEmail>
    </dialog>
  </div>
</template>

<script>
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmail.vue";
export default {
  components: {
    VerifyDialogForPhoneOrEmail,
  },
  data() {
    return {
      userInfo: null,
      dialogTitle: "",
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    handleOfVerifyDialogClose() {
      this.$refs.verifyDialog.close();
      this.userInfo = null;
      this.getUserInfo();
    },
    handleOfSetPW() {
      this.dialogTitle = "Para definir a senha de saque\n é necessário vincular o celular";
      this.$refs.verifyDialog.showModal();
    },
    handleOfModifyPW() {
      this.dialogTitle = "Para alterar a senha de saque\n é necessário verificar a identidade";
      this.$refs.verifyDialog.showModal();
    },
    getUserInfo() {
      const data = {
        action: "my_account",
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.userInfo = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.secure {
  display: flex;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .no {
      width: 100%;
      display: flex;
      flex-direction: column;

      & > img {
        margin-top: 88px;
        align-self: center;
        width: 41px;
        height: auto;
      }

      & > div {
        margin-top: 23px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > .btn {
        margin-top: 106px;
        background: linear-gradient(277deg, #d6ae60 0%, #ebdd9c 100%);
        height: 40px;
        border-radius: 5px;
        line-height: 40px;
        color: #06131c;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .yes {
      display: flex;
      flex-direction: column;
      text-align: left;

      & > .tips-box {
        margin-top: 10px;
        background: #01b740;
        height: 45px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 0 13px;

        & > img {
          width: 20px;
          height: 20px;
        }

        & > div {
          margin-right: 20px;
          text-align: center;
          flex: 1;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .button {
        margin-top: 26px;
        margin-left: auto;
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
        text-decoration: underline;
      }

      .mind {
        margin-top: 80px;
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
</style>
