<template>
  <div class="container">
    <MineEmpty v-if="listData?.data_list.length === 0"></MineEmpty>
    <div class="list" v-else>
      <div class="item" v-for="(item, index) in listData?.data_list" :key="index">
        <div class="box">
          <div class="title">{{ item.task_type === 1 ? "Depósito" : "Bônus" }}</div>
          <div class="value" :class="item.task_type === 1 ? 'yellow' : 'green'">
            +{{ parseFloat(item.amount).toFixed(2) }}
          </div>
          <div class="multiple">{{ item.task_rate }} Vezes</div>
          <div class="info">{{ item.task_title }}</div>
        </div>
        <div class="box">
          <div class="title">Aposta válida necessária</div>
          <div class="value" :class="item.task_type === 1 ? 'yellow' : 'green'">
            {{ parseFloat(item.task_amount).toFixed(2) }}
          </div>
          <div class="des" :class="item.status === 1 ? 'white' : item.status === 2 ? 'blue' : 'green'">
            {{ item.status === 1 ? "aguardando a aposta" : item.status === 2 ? "em andamento" : "concluído" }}
          </div>
          <div class="info">{{ item.done_time }}</div>
        </div>
      </div>
      <LoadMoreComp
        :curCount="listData?.data_list.length"
        :totalCount="listData?.count"
        @loadMoreEvent="queryData()"
      ></LoadMoreComp>
    </div>
  </div>
</template>
<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  components: { MineEmpty, LoadMoreComp },
  data() {
    return {
      listData: null,
      page: -1,
    };
  },
  mounted() {
    this.$emit("update:title", "tarefa de aposta");
    this.queryData();
  },
  methods: {
    async queryData() {
      const page = this.page + 1;
      const data = {
        action: "user_pay_out_task_list",
        data: {
          page,
        },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        console.log("response", response);
        this.listData = response.data;
        this.page = this.listData.page;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  .item {
    display: flex;
    justify-content: space-between;
    background: #0a1f32;
    border-radius: 4px;
    height: 80px;
    padding: 15px;
    margin-bottom: 5px;

    & > .box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > .title {
        color: #96a7c6;
        font-size: 11px;
        font-weight: 500;
      }

      & > .value {
        font-size: 18px;
        font-weight: bold;
      }

      & > .multiple {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > .info {
        color: #96a7c6;
        font-size: 11px;
        font-weight: 500;
      }

      .des {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      .yellow {
        color: #ffd400;
      }

      .green {
        color: #01b740;
      }

      .white {
        color: #ffffff;
      }

      .blue {
        color: #277ce6;
      }
    }

    & > .box:first-of-type {
      align-items: flex-start;
    }

    & > .box:last-of-type {
      align-items: flex-end;
    }
  }
}
</style>
