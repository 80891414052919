<template>
  <div class="load-more-wrapper" v-if="pageSize <= totalCount">
    <div class="footer-title">
      <div v-show="!isNoMore">{{ $t("loaded_records") }}</div>
      <div class="title">&nbsp;{{ footerTitle }}&nbsp;</div>
    </div>

    <div class="load-more-btn" v-if="!isNoMore" @click="$emit('loadMoreEvent')">
      {{ $t("load_more") }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    curCount: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {};
  },
  computed: {
    /**
     * 加载更多的title
     */
    footerTitle() {
      const title = this.curCount + "/" + this.totalCount;
      return this.isNoMore ? this.$t("all_loaded") : title;
    },
    isNoMore() {
      return this.curCount === this.totalCount;
    },
  },
};
</script>
<style lang="scss" scoped>
.load-more-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .footer-title {
    display: flex;

    div {
      color: #3e7bb0;
      font-size: 12px;
    }
    .title {
      font-weight: 500;
      color: #ffffff;
    }
  }

  .load-more-btn {
    margin-top: 12px;
    margin-bottom: 10px;
    width: 120px;
    height: 40px;
    border-radius: 8px;
    background: #194164;
    font-size: 14px;
    font-weight: 550;
    text-align: center;
    line-height: 40px;
    color: #3e7bb0;
  }
}
</style>
