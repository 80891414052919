<template>
  <div class="conatiner">
    <div class="tips">Para garantir a segurança dos seus fundos, você precisa definir uma senha de saque primeiro!</div>
    <div class="title">Definir senha de saque</div>

    <div class="pw-box">
      <div class="header">
        <div>Nova senha de saque</div>
        <img
          :src="
            isVisiblePw
              ? require('@/assets/password/visible_icon.webp')
              : require('@/assets/password/invisible_icon.webp')
          "
          alt=""
          @click="isVisiblePw = !isVisiblePw"
        />
      </div>
      <Password6Input v-model:inputValue="password" :isVisible="isVisiblePw" />
    </div>

    <div class="pw-box">
      <div class="header">
        <div>Confirmar nova senha de saque</div>
        <img
          :src="
            isVisiblePw2
              ? require('@/assets/password/visible_icon.webp')
              : require('@/assets/password/invisible_icon.webp')
          "
          alt=""
          @click="isVisiblePw2 = !isVisiblePw2"
        />
      </div>
      <Password6Input v-model:inputValue="password2" :isVisible="isVisiblePw2" />
    </div>

    <div class="mind">
      Atenção: A senha de saque protege seus fundos, é muito importante. Mantenha-a em segredo para evitar qualquer
      perda financeira.
    </div>
    <div class="btn" @click="handleOfOk">Confirmar</div>
  </div>
</template>
<script>
import Password6Input from "@/components/Password6Input.vue";
export default {
  components: {
    Password6Input,
  },
  data() {
    return {
      password: "",
      isVisiblePw: false,
      password2: "",
      isVisiblePw2: false,
      type: "0",
      isFromMine: false,
    };
  },
  watch: {
    // password(val) {
    //   console.log("password", val);
    // },
    // password2(val) {
    //   console.log("password2", val);
    // },
  },
  mounted() {
    this.$emit("update:title", "Senha de saque");
    this.type = this.$route.query.type;
    this.isFromMine = this.$route.query.from === "mine";
  },
  methods: {
    handleOfInputBlur() {
      console.log("this.inputValue", this.inputValue);
    },
    async handleOfOk() {
      const data = {
        action: "set_pay_out_pwd",
        data: {
          type: this.type, // 1修改提款密码;其他值为设置提款密码;
          pay_out_pwd: this.password,
          repeat_pay_out_pwd: this.password2,
        },
      };
      try {
        const response = await this.$http.post("userCenter", data);
        console.log("response", response);
        if (this.isFromMine) {
          sessionStorage.setItem("endOfSetPwFromMine", true);
        }
        this.$router.back();
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.conatiner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px 15px;

  .tips {
    color: #01b740;
    font-size: 12px;
    font-weight: 500;
  }

  .title {
    margin-top: 20px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
  }

  .pw-box {
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
      }

      & > img {
        width: 15px;
        height: 12px;
      }
    }
  }

  .mind {
    margin-top: 40px;
    color: #96a7c6;
    font-size: 12px;
    font-weight: 500;
  }

  .btn {
    margin-top: 20px;
    background: linear-gradient(277deg, #d6ae60 0%, #ebdd9c 100%);
    border-radius: 5px;
    height: 45px;
    line-height: 45px;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}
</style>
