<template>
  <div class="container">
    <MineEmpty v-if="messageList.length === 0"></MineEmpty>
    <div class="wrapper" v-for="item in messageList" :key="item.id" @click="handleOfGoDetail(item.id)">
      <img class="icon" src="@/assets/message/message_icon2.png" alt="" />
      <div class="main">
        <div class="title">{{ item.title }}</div>
        <div class="date">{{ item.send_time }}</div>
      </div>
      <div class="circle" :class="item.status === 0 ? 'unread' : 'read'"></div>
      <div class="state">{{ item.status === 0 ? "Não lido" : "lidos" }}</div>
      <img class="arrow" src="@/assets/message/message_arrow.png" alt="" />
    </div>
    <LoadMoreComp :cur-count="curCount" :total-count="totalCount" @loadMoreEvent="fetchMessageListData"></LoadMoreComp>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  components: { MineEmpty, LoadMoreComp },
  data() {
    return {
      messageList: [],
      page: 0,
      curCount: 0,
      totalCount: 0,
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("messages"));
    this.fetchMessageListData();
  },
  methods: {
    handleOfGoDetail(id) {
      this.$router.push("/m-message-detail/" + id);
    },
    fetchMessageListData() {
      const data = {
        action: "platform_messages",
        data: {
          page: this.page++,
        },
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.messageList.push(...response.data.platform_messages);
          this.curCount = this.messageList.length;
          this.totalCount = response.data.count;
        })
        .catch((error) => {
          console.error(error);
          this.page--;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 0;
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    background: var(--mine-card-bg-color);
    margin: 5px 15px;
    border-radius: 10px;
    height: 60px;
    align-items: center;

    .icon {
      margin-left: 13px;
      margin-right: 13px;
      width: 21px;
      height: 20px;
    }

    .main {
      flex: 1;
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;

      .title {
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
      }

      .date {
        color: #3e7bb0;
        font-size: 10px;
        font-weight: 500;
      }
    }

    .circle {
      margin-right: 10px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    .unread {
      background: #ff0000;
    }

    .read {
      background: #15ad51;
    }

    .state {
      margin-right: 20px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    .arrow {
      margin-right: 15px;
      width: 4px;
      height: 8px;
    }
  }
}
</style>
