<template>
  <div class="container">
    <div class="context">{{ messageInfo }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      messageInfo: "",
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("messages"));
    this.id = this.$route.params.id;
    this.fetchMessageDetail();
  },
  methods: {
    fetchMessageDetail() {
      const data = {
        action: "view_platform_message",
        data: {
          id: this.id,
        },
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.messageInfo = response.data.message_info;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .context {
    flex: 1;
    margin: 25px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
}
</style>
