<template>
  <ModalPopupComp :contextWidth="560">
    <div class="box">
      <div class="title">Safety Certification</div>
      <div class="name-box">
        <div class="name">
          <input type="text" v-model="lastname" placeholder="Name" />
        </div>
        <div class="name">
          <input type="text" v-model="firstname" placeholder="Surname" />
        </div>
      </div>
      <div class="birthdays">
        <input type="date" v-model="birthday" placeholder="Birthdays" />
      </div>
      <div class="cpf">
        <input type="text" v-model="cpf" placeholder="CPF" />
      </div>
      <div class="email">
        <input type="text" v-model="email" placeholder="E-mail" />
      </div>
      <div class="phone">
        <input type="text" v-model="phone" placeholder="Phone number" />
      </div>
      <div class="validate">
        <input type="text" v-model="code" placeholder="Validate" />
        <button @click="sendCodeHandle">{{ codeTitle }}</button>
      </div>
      <div class="footer">
        <div>To ensure the security of your account, please fill in true and accurate information</div>
        <div class="button" @click="saveHandle">{{ $t("save") }}</div>
      </div>
    </div>
  </ModalPopupComp>
</template>

<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: { ModalPopupComp },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    let phone = "";
    if (this.userInfo.phone !== null && this.userInfo.phone !== "") {
      phone = this.userInfo.phone.substr(2, this.userInfo.phone.length - 2);
    }
    return {
      firstname: "",
      lastname: "",
      birthday: "",
      cpf: "",
      email: this.userInfo.email,
      phone,
      phone2: "",
      code: "",
      codeTitle: this.$t("verification_code"),
    };
  },
  methods: {
    saveHandle() {
      const data = {
        action: "update_user_customer",
        data: {
          firstname: this.firstname,
          lastname: this.lastname,
          birthday: this.birthday,
          customer_cert: this.cpf,
          email: this.email,
          phone: "55" + this.phone2,
          captcha: this.code,
        },
      };

      const hasEmpty = Object.keys(data.data).some((item) => {
        return data.data[item] === "";
      });

      if (hasEmpty) {
        return;
      }

      this.$http
        .post("userCenter", data)
        .then(() => {
          // 请求成功后的处理
          this.$emit("endSaveEvent");
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    sendCodeHandle() {
      if (this.codeTitle === this.$t("sent")) {
        this.$toast.error(this.$t("verification_code_sent"));
        return;
      }

      const value = this.$tools.isNumeric(this.phone);
      if (value === false) {
        this.$toast.error(this.$t("enter_phone_number"));
        return;
      }

      const data = {
        action: "sendCaptcha",
        data: {
          type: 2,
          phone: "55" + this.phone,
        },
      };

      this.$http
        .post("userCenter", data)
        .then((response) => {
          // 请求成功后的处理
          console.log(response.data);
          this.codeTitle = this.$t("sent");
          this.phone2 = this.phone;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--color-popup-title);
  margin: 30px auto 0;
}

input {
  background: transparent;
  margin: auto 20px;
  flex: 1;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  opacity: 0.8;
}

.name-box {
  display: flex;
  justify-content: space-between;
  margin: 30px 40px 0;
  .name {
    flex: 1;
    background: #202020;
    border-radius: 8px;
    width: 230px;
    height: 40px;
    display: flex;
  }
}

.birthdays {
  background: #202020;
  border-radius: 8px;
  margin: 20px 40px 0;
  height: 40px;
  display: flex;
}

.cpf {
  background: #202020;
  border-radius: 8px;
  margin: 20px 40px 0;
  height: 40px;
  display: flex;
}

.email {
  background: #202020;
  border-radius: 8px;
  margin: 20px 40px 0;
  height: 40px;
  display: flex;
}

.phone {
  background: #202020;
  border-radius: 8px;
  margin: 20px 40px 0;
  height: 40px;
  display: flex;
}

.validate {
  background: #202020;
  border-radius: 8px;
  margin: 20px 40px 0;
  height: 40px;
  display: flex;

  button {
    width: 86px;
    background: transparent;
    box-sizing: border-box;
    border: 1px solid #e9c97e;
    border-radius: 6px;
    margin: 5px;
    color: #e9c97e;
    font-size: 12px;
    font-weight: 500;
  }
}

.footer {
  margin: 40px;
  display: flex;
  flex-direction: column;

  div:first-of-type {
    // height: 16px;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    opacity: 0.4;
  }

  .button {
    margin-top: 12px;
    background: linear-gradient(90deg, #e9bf72 0%, #eccf83 100%);
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 800;
  }
}
</style>
