<template>
  <div class="container">
    <div class="item-box">
      <img class="icon" src="@/assets/pix/name_icon.webp" alt="" />
      <input type="text" placeholder="Digite o nome verdadeiro" v-model="name" />
    </div>
    <div class="item-box" @click="isShowPixList = true">
      <img class="icon" src="@/assets/pix/type_icon.webp" alt="" />
      <div>{{ list[index] }}</div>
      <img class="ext" src="@/assets/pix/arrow_down.webp" alt="" />
    </div>
    <div class="pix-list" v-if="isShowPixList">
      <div class="item" v-for="(item, i) in list" :key="i" @click="handleOfSelectedType(i)">{{ item }}</div>
    </div>
    <div class="item-box" v-if="index !== 0">
      <img class="icon" src="@/assets/pix/pix_icon.webp" alt="" />
      <input type="text" placeholder="Digite seu PIX-ID" v-model="id" />
    </div>
    <div class="item-box">
      <img class="icon" src="@/assets/pix/cpf_icon.webp" alt="" />
      <input type="text" placeholder="Digite o CPF da depósito (11 dígitos)" v-model="cpf" />
    </div>

    <div class="mind">
      Contas do tipo PIX-CPF podem ser adicionadas apenas uma vez. Para garantir a segurança dos seus fundos,
      certifique-se de preencher informações verdadeiras e verifique cuidadosamente
    </div>

    <div class="btn" @click="handleOfAdd">Enviar</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: ["CPF", "PHONE", "EMAIL"],
      index: 0,
      isShowPixList: false,
      name: "",
      id: "",
      cpf: "",
    };
  },
  mounted() {
    this.$emit("update:title", "Adicionar conta");
  },
  methods: {
    async handleOfAdd() {
      const data = {
        action: "add_pix_info",
        data: {
          real_name: this.name, // 真实姓名
          account_type: this.list[this.index], // PIX类型
          account_num: this.id, // PIX-ID
          customer_cert: this.cpf, // CPF
        },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        console.log("response", response);
        this.$router.back();
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfSelectedType(i) {
      this.isShowPixList = false;
      this.index = i;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;

  .item-box {
    margin-top: 10px;
    display: flex;
    align-items: center;
    background: #0a1f32;
    height: 45px;
    border-radius: 10px;
    padding: 0px 15px;

    .icon {
      width: 15px;
      height: 15px;
    }

    & > input {
      flex: 1;
      margin-left: 10px;
    }

    & > div {
      flex: 1;
      margin-left: 10px;
      text-align: left;
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
    }

    .ext {
      width: 22px;
      height: 22px;
    }
  }

  .pix-list {
    display: flex;
    flex-direction: column;

    & > .item {
      background: #0a1f32;
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
      line-height: 45px;
      margin-top: 10px;
    }
  }

  .mind {
    margin-top: 15px;
    color: #ff5000;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
  }

  & > .btn {
    margin-top: 50px;
    background: linear-gradient(277deg, #d6ae60 0%, #ebdd9c 100%);
    border-radius: 5px;
    height: 45px;
    line-height: 45px;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
