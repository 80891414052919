<template>
  <div class="wrapper">
    <iframe v-if="gameHtml !== ''" :srcdoc="gameHtml" width="100%" :height="height" frameborder="0"></iframe>
    <iframe v-else :src="gameUrl" width="100%" :height="height" frameborder="0"></iframe>
    <img class="back" src="@/assets/back2.png" alt="" @click="backHandle" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      gameUrl: "",
      gameHtml: "",
      id: "",
      type: 0,
      height: 0,
      providerId: "",
    };
  },
  mounted() {
    this.height = window.innerHeight;
    this.id = this.$route.params.id;
    this.providerId = this.$route.query.pId;
    this.type = parseInt(this.$route.query.type);
    this.setupData();
  },
  methods: {
    backHandle() {
      this.$router.back();
    },
    setupData() {
      const params = { game_id: this.id, game_type: this.type, game_provider: this.providerId };
      this.$http
        .post("updateSession", { data: params })
        .then((response) => {
          // 请求成功后的处理
          const data = response.data;

          if ("game_html" in data && data.game_html !== "" && data.game_html !== null) {
            this.gameHtml = data.game_html;
            return;
          }

          // 如果有game_url，直接使用
          if ("game_url" in data && data.game_url !== "" && data.game_url !== null) {
            this.gameUrl = data.game_url;
            return;
          }

          // 没有需要自己拼接
          const userInfo = this.$store.state.userInfo;
          let pgparamStr = "";
          const pgparam = userInfo.pgparam;
          for (let key in pgparam) {
            if (key === "game_url") {
              continue;
            }
            if (pgparam[key] != null && Object.prototype.hasOwnProperty.call(pgparam, key)) {
              pgparamStr += "&" + key + "=" + pgparam[key];
            }
          }
          let url =
            "https://m.pgsoft-games.com/" +
            this.id +
            "/index.html?ops=" +
            data.ops +
            "&op=" +
            data.ocp +
            "&btt=1" +
            pgparamStr +
            "&__refer=" +
            pgparam.game_url +
            "&or=static.pgsoft-games.com&__hv=1f8e1d3b";
          this.gameUrl = url;
        })
        .catch((error) => {
          console.error("error: ", error);
          this.$router.go(-1);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .back {
    position: absolute;
    z-index: 10;
    top: 14px;
    left: 15px;
    width: 30px;
    height: 30px;
  }
}
</style>
