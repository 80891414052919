<template>
  <div class="container">
    <!-- <div class="banner">
      <img src="@/assets/activity/pix_icon_green.png" alt="" />
      <div class="des">
        <div>Nós garantimos a segurança dos fundos</div>
        <div>Se o seu depósito não for creditado, entre em contato com o suporte</div>
      </div>
    </div> -->
    <div class="subTitle">Introduz quantidade</div>
    <div class="bg">
      <div>R$</div>
      <input
        type="number"
        :placeholder="'Depósito mínimo ' + parseFloat($global.data.mini_pay_amount).toFixed(2)"
        v-model="customInputValue"
      />
      <div class="percent">{{ giveText }}</div>
    </div>
    <div class="subTitle">{{ $t("select_deposit_amount") }}</div>
    <div class="collection">
      <div
        class="item"
        :class="index === selRechargeIndex ? 'sel-item' : ''"
        v-for="(item, index) in depositAmountList"
        :key="index"
        @click="onTapRechargeItem(index)"
      >
        <div class="deposit">
          <div class="unit">{{ depositUnit }}&nbsp;</div>
          <div class="name">{{ item.deposit_amount }}</div>
        </div>
        <div class="give" v-if="item.is_give === 1 && item.give_amount !== null">
          {{ item.give_amount }}
        </div>
        <img class="gift" v-if="item.is_hot === 1" src="@/assets/gift.png" alt="" />
      </div>
    </div>
    <div class="give-des">
      Cada usuário tem apenas 3 oportunidades por dia para receber o bônus de depósito único! <br />
      O bônus de depósito não tem requisitos de apostas
    </div>
    <div class="subTitle" v-if="couponList?.length > 0">Um cupom de retirada（{{ couponList?.length }}）</div>
    <div class="coupons" v-if="couponList?.length > 0">
      <div class="coupon" v-for="(item, i) in couponList" :key="i" @click="handleOfCoupon(i)">
        <img
          :src="
            couponIndex === i ? require('@/assets/coupon_bg_highlight.png') : require('@/assets/coupon_bg_normal.png')
          "
          alt=""
        />
        <div>{{ item.prize_info.prize_name }}</div>
      </div>
    </div>
    <div class="btn-box">
      <div class="btn" @click="saveHandle">{{ $t("deposit") }}</div>
    </div>
  </div>
  <dialog ref="tipsDialog">
    <div class="tips-box">
      <div class="tips-title">Dica</div>
      <div class="tips-content">
        <span>A sua recompensa</span>
        <span>&nbsp;R$:{{ prizeAmount }}&nbsp;</span>
        <span>está a ser distribuída.</span>
      </div>
      <div class="tips-des">Por favor, verifique sua carteira mais tarde</div>
      <div class="tips-btn" @click="handleOfTipsOK">OK</div>
    </div>
  </dialog>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      depositUnit: "R$",
      depositAmountList: [],
      selRechargeIndex: -1,
      customInputValue: "",
      giveText: "",
      activityPayAmount: 0,
      prizeAmount: 0,
      couponIndex: -1,
      couponList: [],
    };
  },
  mounted() {
    this.$tools.userTrackReport({ track_id: "20" });
    this.$emit("update:title", "Depósito");
    this.$emit("update:isShowHeaderRightBtn", true);
    this.fetchDepositList();
    this.fetchCouponList();
  },
  unmounted() {
    this.$emit("update:isShowHeaderRightBtn", false);
  },
  watch: {
    customInputValue(newValue) {
      if (newValue > 0) {
        this.selRechargeIndex = -1;
        this.couponIndex = -1;
        const depositItem = this.depositAmountList.toReversed().find((item) => {
          return newValue >= item.deposit_amount;
        });
        if (depositItem && depositItem.give_amount !== 0) {
          this.giveText = depositItem.give_amount;
        } else {
          this.giveText = "";
        }
      }
    },
  },
  computed: {
    validRewards() {
      if (this.selRechargeIndex === -1) {
        return false;
      }

      if (this.selRechargeIndex >= this.depositAmountList.length) {
        return false;
      }

      return this.depositAmountList[this.selRechargeIndex].deposit_amount === this.activityPayAmount;
    },
  },
  methods: {
    handleOfCoupon(i) {
      this.customInputValue = "";
      this.couponIndex = i;
      this.selRechargeIndex = -1;
    },
    async fetchCouponList() {
      const data = {
        action: "user_pay_coupon_list",
        data: { type: 1 },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        this.couponList = response.data.coupon_list;
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfTipsOK() {
      this.$refs.tipsDialog.close();
    },
    fetchDepositList() {
      const data = {
        action: "query_deposit_list",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.activityPayAmount = response.data.activity_pay_amount;
          this.prizeAmount = parseFloat(response.data.prize_amount || 0).toFixed(2);
          this.depositAmountList = response.data.deposit_list;

          this.selRechargeIndex = this.depositAmountList.findIndex((item) => {
            return item.is_hot === 1;
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    onTapRechargeItem(index) {
      this.giveText = "";
      this.customInputValue = "";
      this.selRechargeIndex = index;
      this.couponIndex = -1;
    },
    saveHandle() {
      this.$tools.userTrackReport({ track_id: "21" });
      this.recharge();
    },
    recharge() {
      if (this.selRechargeIndex === -1 && this.customInputValue === "" && this.couponIndex === -1) {
        this.$toast.error(this.$t("sel_recharge_opt"));
        return;
      }

      let amount;
      let product_id = "";
      let activity_type = 0;
      let activity_id = 0;
      if (this.customInputValue !== "") {
        if (this.$tools.isNumeric(this.customInputValue) && parseInt(this.customInputValue) > 0) {
          amount = this.customInputValue;
          activity_type = 1;
          activity_id = 1;
        } else {
          this.$toast.error(this.$t("enter_valid_number"));
          return;
        }
      } else if (this.couponIndex !== -1) {
        let item = this.couponList[this.couponIndex];
        amount = item.prize_info.prize_require_amount;
        activity_type = 2;
        activity_id = item.id;
      } else {
        let item = this.depositAmountList[this.selRechargeIndex];
        amount = item.deposit_amount;
        product_id = item.id;
        activity_type = 1;
        activity_id = 1;
      }

      const data = {
        action: "collection",
        data: {
          scene: "login",
          amount: parseInt(amount) * 100,
          product_id,
          pay_method: "hkpPay",
          activity_type,
          activity_id,
        },
      };

      if (this.$global.kwaiPixelId !== "") {
        data.data.ks = this.$global.kwaiPixelId;
      }

      if (this.$global.fbPixelId !== "") {
        data.data.fb = this.$global.fbPixelId;
      }

      this.$http
        .post("pay", data)
        .then((response) => {
          const order = response.data.order;
          sessionStorage.setItem("order", JSON.stringify(order));
          this.$router.push({ name: "m-pay-page" });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  margin: 0 15px 65px;

  .banner {
    margin-top: 4px;
    display: flex;
    align-items: center;
    background: #20732b;
    height: 45px;
    border-radius: 10px;
    padding: 0 10px;

    & > img {
      width: 49px;
      height: 20px;
    }

    & > .des {
      flex: 1;
      display: flex;
      flex-direction: column;
      color: #ffffff;
      text-align: right;

      & > div:first-of-type {
        font-size: 10px;
        font-weight: 500;
      }

      & > div:last-of-type {
        font-size: 7px;
        font-weight: 500;
      }
    }
  }

  .subTitle {
    margin-top: 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;
  }

  .collection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    gap: 10px; /* 设置适当的间距 */

    margin-top: 10px;
    margin-bottom: 20px;

    .item {
      position: relative;
      border-radius: 8px;
      height: 50px;
      background: #0a1f32;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .gift {
        position: absolute;
        width: 20px;
        height: 12px;
        top: 0px;
        right: 0px;
      }

      .deposit {
        display: flex;
        align-items: center;

        .name {
          line-height: 16px;
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
        }

        .unit {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
        }
      }

      .give {
        margin-top: 3px;
        line-height: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #ffd400;
      }
    }

    .sel-item {
      background: #33439e;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .deposit {
        display: flex;
        align-items: center;

        .name {
          line-height: 16px;
          font-size: 24px;
          font-weight: bold;
          color: #c8e0f4;
        }

        .unit {
          font-size: 14px;
          font-weight: 500;
          color: #c8e0f4;
        }
      }
      .give {
        line-height: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #ffd400;
      }
    }
  }

  .give-des {
    color: #7686a2;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }

  .coupons {
    margin-top: 10px;
    display: flex;
    overflow-x: scroll;

    .coupon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img {
        width: 160px;
        height: 60px;
      }

      div {
        position: absolute;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .bg {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    height: 50px;
    background: var(--input-bg-color);
    border-radius: 8px;

    div {
      margin: 12px 16px;
      font-size: 18px;
      font-weight: 800;
      color: #ffd400;
      text-align: left;
    }

    input {
      flex: 1;
      background: transparent;
      border: 0;
      outline: 0;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }

    input::placeholder {
      color: #7686a2;
    }

    .percent {
      color: #ffd400;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .btn-box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;

    .btn {
      width: var(--max-width);
      line-height: 45px;
      background: #ffd400;
      color: #000000;
      font-size: 18px;
      font-weight: 800;
      border-radius: 8px;
    }
  }
}

.tips-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: var(--color-popup-bg);
  border-radius: 10px;
  height: 242px;

  .tips-title {
    color: var(--primary-text-color);
    font-size: 18px;
    font-weight: bold;
  }

  .tips-content {
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;

    span:nth-child(2) {
      color: #ecc603;
    }
  }

  .tips-des {
    color: #ffffff;
    font-size: 12px;
    font-weight: normal;
  }

  .tips-btn {
    width: 80%;
    background: var(--color-btn-bg);
    height: 40px;
    border-radius: 8px;
    color: var(--color-btn-text);
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
