<template>
  <div class="login-wapper">
    <div class="context">
      <div class="top" v-if="isShowTop">
        <img
          class="bg"
          :src="
            isForget
              ? require('@/assets/login/top_bg3.webp')
              : isRegister
              ? require('@/assets/login/top_bg1.webp')
              : require('@/assets/login/top_bg2.webp')
          "
          alt=""
        />
        <div class="title">{{ showTitle }}</div>
        <img class="close" src="@/assets/login/close_icon.png" alt="" @click="closeHandle" />
      </div>
      <div class="tabs" v-if="isShowTop">
        <div class="item" :class="isPhone ? 'highlight' : 'normal'" @click="handleOfPhone">Telefone</div>
        <div class="item" :class="isPhone ? 'normal' : 'highlight'" @click="handleOfEmail">Email</div>
      </div>
      <div class="tabs2" v-else>
        <div :class="isPhone ? 'highlight' : ''" @click="handleOfPhone">Telefone</div>
        <div :class="isPhone ? '' : 'highlight'" @click="handleOfEmail">Email</div>
      </div>
      <div class="form">
        <div class="row">
          <!-- 手机号 -->
          <LoginInputComp
            v-if="isPhone"
            placeholder="Numero de telefone"
            type="tel"
            v-model:inputValue="username"
            v-model:errMsg="usernameErr"
          ></LoginInputComp>

          <!-- email -->
          <LoginInputComp
            v-else
            placeholder="Email"
            type="email"
            :icon="require('../assets/login/email_icon.webp')"
            v-model:inputValue="email"
            v-model:errMsg="emailErr"
          ></LoginInputComp>

          <!-- 验证码 -->
          <LoginInputComp
            v-if="isForget"
            :icon="require('../assets/login/code_icon.webp')"
            placeholder="Código de Verificação"
            v-model:inputValue="code"
            v-model:errMsg="codeErr"
          >
            <div class="get-code" @click="getCodeHandle">{{ codeTitle }}</div>
          </LoginInputComp>

          <!-- 密码 -->
          <LoginInputComp
            :icon="require('../assets/login/pw_icon.webp')"
            :placeholder="$t('enter_password')"
            type="password"
            v-model:inputValue="password"
            v-model:errMsg="passwordErr"
          ></LoginInputComp>

          <!-- 忘记密码 -->
          <div class="forget" v-if="!isForget && !isRegister" @click="handleOfForget">
            {{ $t("forgot_password") }}
          </div>
          <!-- 邀请码 -->
          <div class="invite-box" v-show="isRegister">
            <div class="invite-title" @click="isShowInvite = !isShowInvite">
              <div>Adicionar código&nbsp;</div>
              <img src="@/assets/login/arrow.png" alt="" :style="{ transform: isShowInvite ? '' : 'rotate(180deg)' }" />
            </div>
            <LoginInputComp
              v-show="isRegister && isShowInvite"
              :inputDisabled="isDisabledInvite"
              :icon="require('@/assets/login/invite_icon.webp')"
              :placeholder="$t('enter_invitation_code')"
              v-model:inputValue="invitationCode"
              v-model:errMsg="invitationCodeErr"
            ></LoginInputComp>
          </div>
        </div>
        <!-- 记住密码 -->
        <div class="remember-box" v-if="isRegister" @click="isRememberPw = !isRememberPw">
          <img
            class="mark"
            :src="isRememberPw ? require('@/assets/login/mark.webp') : require('@/assets/login/mark_empty.webp')"
            alt=""
          />
          <div>lembrar senha</div>
        </div>
        <!-- 协议 -->
        <div class="protection" v-if="isRegister">
          <img class="mark" :src="require('@/assets/login/mark.webp')" alt="" />
          <div class="normal">Tenho 18 anos,li e concordo com</div>
          <div class="highlight" @click="handleOfAgreement(1)">《Política de Privacidade》</div>
        </div>
        <!-- 按钮 -->
        <button class="button" @click="handleOfBtn">
          {{ showBtnTitle }}
        </button>

        <div v-if="flase">
          <!-- 分割线 -->
          <div class="division-line-box" v-if="!isForget">
            <div class="line"></div>
            <div class="txt">&nbsp;OU&nbsp;</div>
            <div class="line"></div>
          </div>

          <!-- 谷歌登录 -->
          <div class="google-box" v-if="!isForget" @click="handleOfGoogle">
            <img src="@/assets/login/google.webp" alt="" />
            <div>Entrar com Google+</div>
          </div>
        </div>
      </div>
      <div class="footer" v-if="!isForget">
        <div v-if="isRegister" class="ext-box">
          <div>Já tem conta?&nbsp;</div>
          <div class="ext-highlight" @click="handleOfChange">Entrar</div>
        </div>
        <div v-else class="ext-box">
          <div>Näo tem uma conta?&nbsp;</div>
          <div class="ext-highlight" @click="handleOfChange">Registro</div>
        </div>
      </div>
    </div>
  </div>

  <dialog ref="agreement">
    <AgreementComp :aId="agreementId" @closeEvent="agreementCloseEvent"></AgreementComp>
  </dialog>
</template>

<script>
import AgreementComp from "@/components/AgreementComp.vue";
import LoginInputComp from "@/components/LoginInputComp.vue";
export default {
  components: {
    AgreementComp,
    LoginInputComp,
  },
  props: {
    isRegisterStatus: Boolean,
    isShowTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let invitationCode = "";
    let isShowInvite = false;
    let isDisabledInvite = false;
    const uc = sessionStorage.getItem("uc");
    if (uc !== null && uc !== undefined && uc !== "") {
      invitationCode = uc;
      isShowInvite = true;
      isDisabledInvite = true;
      sessionStorage.removeItem("uc");
    }

    let email = "";
    let username = "";
    let password = "";
    let isPhone = true;
    return {
      invitationCode,
      username,
      password,
      areaCode: "55",
      isPwVisible: false,
      codeTitle: this.$t("verification_code"),
      isForget: false,
      code: "",
      agreementId: "",
      phone: "",
      isRegister: this.isRegisterStatus,
      time: 120,
      timer: null,
      usernameErr: "",
      passwordErr: "",
      codeErr: "",
      invitationCodeErr: "",
      isShowInvite,
      isRememberPw: true,
      isPhone,
      email,
      emailErr: "",
      isDisabledInvite,
    };
  },
  unmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isRegister = this.isRegisterStatus;
    });

    if (this.isRegisterStatus) {
      // 注册页面展示
      this.$tools.userTrackReport({ track_id: "3" });
    } else {
      // 登录页面展示
      this.$tools.userTrackReport({ track_id: "7" });
    }
  },
  methods: {
    handleOfChange() {
      this.isRegister = !this.isRegister;
      this.$emit("changeEvent", this.isRegister);
    },
    handleOfPhone() {
      this.username = "";
      this.email = "";
      this.password = "";
      this.isPhone = true;
      this.$nextTick(() => {
        if (!this.isRegister) {
          const registerInfo = this.$store.getters.registerInfo;
          if (registerInfo !== null) {
            this.username = registerInfo.phone;
            this.password = registerInfo.phonePw;
          }
        }
      });
    },
    handleOfEmail() {
      this.username = "";
      this.email = "";
      this.password = "";
      this.isPhone = false;
      this.$nextTick(() => {
        if (!this.isRegister) {
          const registerInfo = this.$store.getters.registerInfo;
          if (registerInfo !== null) {
            this.email = registerInfo.email;
            this.password = registerInfo.emailPw;
          }
        }
      });
    },
    async handleOfGoogle() {
      localStorage.setItem("uc", this.invitationCode);
      const data = {
        action: "get_google_oauth",
        data: {},
      };
      try {
        const response = await this.$http.post("login", data);
        console.log("response", response);
        window.open(response.data.login_url, "_self");
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfForget() {
      this.username = "";
      this.password = "";
      this.email = "";
      this.isForget = true;
    },
    handleOfBtn() {
      if (this.isForget) {
        this.handleOfForgetPW();
      } else {
        this.handleOfLogin();
      }
    },
    handleOfForgetPW() {
      const data = {
        action: "find_password",
        data: {
          login_type: this.isPhone ? 4 : 5,
          login_name: this.isPhone ? this.areaCode + this.phone : this.email,
          captcha: this.code,
          login_pwd: this.password,
        },
      };
      this.$http
        .post("login", data)
        .then(() => {
          const registerInfo = this.$store.getters.registerInfo;
          if (registerInfo) {
            if (this.isPhone) {
              registerInfo.phonePw = this.new1;
            } else {
              registerInfo.emailPw = this.new1;
            }
            this.$store.commit("setRegisterInfo", registerInfo);
          }
          this.handleOfLogin();
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
          this.showInputErr(error);
        });
    },
    agreementCloseEvent() {
      this.$refs.agreement.close();
    },
    handleOfAgreement(id) {
      this.agreementId = id + "";
      this.$refs.agreement.showModal();
    },
    getCodeHandle() {
      if (this.codeTitle !== this.$t("verification_code")) {
        this.$toast.error(this.$t("verification_code_sent"));
        return;
      }

      if (this.isPhone) {
        const value = this.$tools.isNumeric(this.username);
        if (value === false) {
          this.$toast.error(this.$t("enter_phone_number"));
          this.usernameErr = this.$t("phone_verification_failed");
          return;
        }
      } else {
        const value = this.$tools.isValidEmail(this.email);
        if (value === false) {
          this.$toast.error(this.$t("email_verification_failed"));
          this.emailErr = this.$t("email_verification_failed");
          return;
        }
      }

      const data = {
        action: "find_password_captcha",
        data: {
          login_type: this.isPhone ? 4 : 5,
          login_name: this.isPhone ? this.areaCode + this.username : this.email,
        },
      };

      this.$http
        .post("login", data)
        .then(() => {
          this.startTime();
          this.phone = this.username;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
          this.showInputErr(error);
        });
    },
    startTime() {
      this.time = 120;
      this.timer = setInterval(() => {
        this.time--;
        this.codeTitle = this.time;
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.codeTitle = this.$t("verification_code");
        }
      }, 1000);
    },
    /**
     * 关闭窗口
     */
    closeHandle(isLoggedIn = false) {
      this.username = "";
      this.email = "";
      this.password = "";
      this.isPwVisible = false;
      this.codeTitle = this.$t("verification_code");
      this.isForget = false;
      this.code = "";
      if (isLoggedIn === true && this.isRegister) {
        this.$emit("registerSuccess");
      } else {
        this.$emit("closeLogin", isLoggedIn);
      }
    },
    /**
     * 登录事件
     */
    handleOfLogin() {
      let action = "login";
      if (this.isRegister) {
        action = "register";
        this.$tools.userTrackReport({ track_id: "22" });
      }

      if (this.isPhone) {
        const value = this.$tools.isNumeric(this.username);
        if (value === false) {
          this.usernameErr = this.$t("phone_verification_failed");
          return;
        }
      } else {
        const value = this.$tools.isValidEmail(this.email);
        if (value === false) {
          this.emailErr = this.$t("email_verification_failed");
          return;
        }
      }

      if (this.password === "") {
        this.passwordErr = this.$t("enter_password");
        return;
      }

      const source_channel = localStorage.getItem("source_channel") || "";
      localStorage.removeItem("source_channel");
      const promote_ad_id = localStorage.getItem("pa") || "";
      localStorage.removeItem("pa");

      const data = {
        action,
        data: {
          login_type: this.isPhone ? 4 : 5,
          login_name: this.isPhone ? this.areaCode + this.username : this.email,
          login_pwd: this.password,
          source_user: this.invitationCode,
          source_channel,
          promote_ad_id,
        },
      };

      this.$http
        .post("login", data)
        .then((response) => {
          const loginInfo = {
            sessionid: response.sessionid,
            ucode: response.ucode,
          };
          this.$store.commit("setLoginInfo", loginInfo);
          this.$store.commit("setChannel", response.data.channel);
          this.$store.commit("setActivityInfo", {
            activityId: response.data.activity_id,
            isJoinActivity: response.data.is_join_activity,
          });
          this.initiateData(response.data.temp_event_code, response.data.temp_event_amount);
          if (action === "register") {
            this.$tools.userTrackReport({ track_id: "4" });
            this.$fbq("track", "CompleteRegistration");
            this.$kwaiq.instance(this.$global.kwaiPixelId).track("completeRegistration");
            if (this.isRememberPw) {
              let registerInfo = this.$store.getters.registerInfo;
              if (registerInfo === null) {
                registerInfo = {
                  phone: "",
                  email: "",
                  phonePw: "",
                  emailPw: "",
                };
              }

              if (this.isPhone) {
                registerInfo.phone = this.username;
                registerInfo.phonePw = this.password;
              } else {
                registerInfo.email = this.email;
                registerInfo.emailPw = this.password;
              }
              this.$store.commit("setRegisterInfo", registerInfo);
            }
          } else {
            this.$tools.userTrackReport({ track_id: "8" });
          }
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error("login:", error);
          if (error.code === 10009) {
            this.isRegister = false;
          } else {
            this.showInputErr(error);
          }
          if (action === "register") {
            this.$tools.userTrackReport({ track_id: "5" });
          } else {
            this.$tools.userTrackReport({ track_id: "9" });
          }
        });
    },
    /**
     * 登录成功后初始化平台的一些用户数据
     */
    initiateData(code, amount) {
      this.$http
        .post("initiate", {})
        .then((response) => {
          // 请求成功后的处理
          let pgparam = response.data.pgparam;

          const user = {
            username: this.username,
            pgparam,
          };

          this.$store.commit("setLastLoginType", this.isPhone ? 4 : 5);
          this.$store.commit("setUserInfo", user);
          this.$eventBus.emit("loginSuccessHandle", {
            code,
            amount,
          });
          this.closeHandle(true);
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error("error: ", error);
        });
    },
    showInputErr(error) {
      if ("code" in error) {
        const code = error.code;
        switch (code) {
          case 10001: //loginName null
          case 10011: //loginName ERROR
          case 10003: //Email ERROR
          case 10004: //PHONE ERROR
          case 10009: //USER ALREADY EXIST
          case 10010: //USER NOT EXIST
            if (this.isPhone) {
              this.usernameErr = "Por favor insira o número de telefone correto";
            } else {
              this.emailErr = "Por favor, introduz o endereço correto de correio";
            }
            break;

          case 10002: //loginPwd NULL
          case 10012: //loginPwd ERROR
          case 10005: //PWD LENGTH ERROR
            this.passwordErr = "8-16 caracteres";
            break;

          case 20010: //CAPTCHA ERROR
            this.codeErr = "Erro do código de verificação";
            break;

          case 11001: //USERCODE ERROR
            this.invitationCodeErr = "Código de convite inválido";
            break;

          default:
            break;
        }
      }
    },
  },
  computed: {
    showBtnTitle() {
      return this.isRegister ? this.$t("register_and_login") : this.$t("login");
    },
    showTitle() {
      if (this.isForget) {
        return this.$t("forgot_password");
      } else {
        return this.isRegister ? this.$t("register") : this.$t("login");
      }
    },
  },
  watch: {
    isRegister(newVal) {
      if (newVal) {
        // 注册页面展示
        this.$tools.userTrackReport({ track_id: "3" });
        this.username = "";
        this.email = "";
        this.password = "";
      } else {
        // 登录页面展示
        this.$tools.userTrackReport({ track_id: "7" });
        const registerInfo = this.$store.getters.registerInfo;
        if (registerInfo !== null) {
          const lastLoginType = this.$store.getters.lastLoginType;
          if (lastLoginType === 5) {
            this.isPhone = false;
            this.$nextTick(() => {
              this.email = registerInfo.email;
              this.password = registerInfo.emailPw;
            });
          } else if (lastLoginType === 4) {
            this.isPhone = true;
            this.$nextTick(() => {
              this.username = registerInfo.phone;
              this.password = registerInfo.phonePw;
            });
          }
        }
      }
    },
    isRegisterStatus(newVal) {
      this.isRegister = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // padding: 0 15px;

  .context {
    width: 100%;
    margin: 0 15px;
    background: transparent;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    .top {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
      }

      .title {
        margin-top: 8px;
        margin-left: 34px;
        font-size: 25px;
        font-weight: 500;
        color: var(--login-title-color);
        z-index: 0;
      }

      .close {
        margin-top: 16px;
        margin-right: 14px;
        width: 20px;
        height: 20px;
        z-index: 0;
      }
    }

    .tabs2 {
      margin-top: 20px;
      display: flex;
      padding: 0 34px;

      & > div {
        text-align: left;
        width: 90px;
        color: #526382;
        font-size: 14px;
        font-weight: 500;
      }

      & > .highlight {
        color: #ffd500;
      }
    }

    .tabs {
      display: flex;

      & > .item {
        flex: 1;
        height: 45px;
        line-height: 45px;
        border-radius: 5px 5px 0px 0px;
        font-size: 15px;
        font-weight: bold;
      }

      & > .normal {
        background: #243675;
        color: #96a7c6;
      }

      & > .highlight {
        background: #33439e;
        color: #ffffff;
      }
    }

    .form {
      margin: 20px 34px;
      display: flex;
      flex-direction: column;

      .row {
        width: 100%;
        display: flex;
        flex-direction: column;

        .get-code {
          background: #33439e;
          box-sizing: border-box;
          width: 100px;
          height: 30px;
          line-height: 30px;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          text-wrap: nowrap;
        }

        .forget {
          margin-top: 7px;
          margin-left: auto;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          height: 16px;
          text-align: right;
        }

        .invite-box {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          .invite-title {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            & > div {
              color: #96a7c6;
              font-size: 14px;
              font-weight: 500;
            }

            & > img {
              margin-left: 10px;
              width: 10px;
              height: 5px;
            }
          }
        }
      }

      .remember-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .mark {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }

        div {
          white-space: nowrap;
          font-size: 12px;
          font-weight: 500;
          color: #96a7c6;
        }
      }

      .protection {
        margin-top: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .mark {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }

        div {
          white-space: nowrap;
          font-size: 12px;
        }

        .normal {
          color: #96a7c6;
          font-weight: 500;
        }

        .highlight {
          color: #96a7c6;
          font-size: 10px;
          font-weight: 500;
        }
      }

      .button {
        margin-top: 20px;
        height: 40px;
        background: #ffd400;
        border-radius: 8px;
        color: #0a1f32;
        line-height: 40px;
        border: 0;
        font-weight: bold;
        font-size: 18px;
      }

      .division-line-box {
        margin-top: 10px;
        display: flex;
        align-items: center;
        width: 100%;

        .line {
          flex: 1;
          height: 0.5px;
          background-color: #96a7c6;
        }

        .txt {
          font-size: 12px;
          font-weight: 500;
          color: #96a7c6;
        }
      }
    }

    .google-box {
      margin-top: 10px;
      background: #299c3a;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 25px;
        height: 25px;
      }

      & > div {
        margin-left: 5px;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .footer {
      margin: 0 34px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .ext-box {
        margin-top: 20px;
        display: flex;

        & > div:nth-child(1) {
          color: #96a7c6;
          font-size: 14px;
          font-weight: 500;
        }

        .ext-highlight {
          color: #ffd400;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
