<template>
  <div class="apple-install">
    <div class="box">
      <div class="title-box">
        <img src="@/assets/ios/icon.png" alt="" />
        <div class="title">Como instalar?</div>
        <img src="@/assets/ios/close.png" alt="" @click="handlerOfClose" />
      </div>
      <div>
        <img src="@/assets/ios/1.webp" alt="" />
      </div>

      <div>
        <img src="@/assets/ios/2.webp" alt="" />
      </div>
      <div>
        <img src="@/assets/ios/3.webp" alt="" />
      </div>
      <div>
        <img src="@/assets/ios/4.webp" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handlerOfClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.apple-install {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 15px;

  & > .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #06131c;
    border-radius: 10px;

    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > img:first-of-type {
        margin-left: 15px;
        width: 50.5px;
        height: 47.5px;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: var(--color-popup-title);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & > img:last-of-type {
        margin-right: 15px;
        width: 20px;
        height: 20px;
      }
    }

    & > div {
      display: flex;
      margin: 0 15px 10px;

      & > img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
