<template>
  <div class="container">
    <img src="../assets/back.png" alt="" @click="backHandel" />
    <div>{{ title }}</div>
    <img v-show="isShowRightBtn" class="cs-icon" src="@/assets/header-right-cs.png" alt="" @click="handleOfCs" />
  </div>
</template>
<script>
export default {
  props: { title: { type: String, default: "" }, isShowRightBtn: { type: Boolean, default: false } },
  methods: {
    backHandel() {
      this.$router.back();
    },
    handleOfCs() {
      this.$router.push({
        name: "m-service",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: var(--max-width);
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 550;
  color: #ffffff;
  background: var(--primary-color);

  & > img:first-of-type {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 14px;
    left: 30px;
  }

  .cs-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 15px;
    top: 14px;
  }
}
</style>
