<template>
  <div class="registration-rewards-comp">
    <div class="wrapper">
      <div class="main">
        <div class="title">Registro bem-sucedido</div>
        <div class="sub-title">O bônus de registro foi distribuído</div>
        <div class="money">R$ {{ parseFloat($global.data.register_give_coins).toFixed(2) }}</div>
        <div class="des">O bônus deve ser usado após o depósito bem-sucedido.</div>
      </div>
      <div class="btn" @click="handleOfClose">Entendido</div>
      <img class="close" src="@/assets/float/close.png" alt="" @click="handleOfClose" />
      <img class="logo" src="@/assets/float/logo.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleOfClose() {
      this.$emit("closeEvent");
    },
  },
};
</script>
<style lang="scss" scoped>
.registration-rewards-comp {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  & > .wrapper {
    max-width: var(--max-width);
    width: 100%;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    background: #33439e;
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0px 0px;

    & > .main {
      margin-top: 15px;
      margin-left: 10px;
      margin-right: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;

      & > .title {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }

      & > .sub-title {
        margin-top: 10px;
        color: #ffffff;
        font-size: 12px;
        font-weight: normal;
      }

      & > .money {
        margin-top: 35px;
        color: #ffd400;
        font-size: 36px;
        font-weight: bold;
      }

      & > .des {
        margin-right: 110px;
        color: #ffd400;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
      }
    }

    & > .btn {
      margin: 7px 15px 12px;
      height: 45px;
      border-radius: 8px;
      background: #ffd400;
      color: #010406;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > .logo {
      position: absolute;
      width: 104px;
      height: 100px;
      top: 65px;
      right: 0;
    }

    & > .close {
      position: absolute;
      right: 10px;
      top: 17px;
      width: 20px;
      height: 20px;
    }
  }
}
</style>
