<template>
  <div class="launcher">
    <img src="@/assets/launch/icon.webp" alt="" />
  </div>
</template>
<script>
export default {
  mounted() {
    if ("uc" in this.$route.query) {
      sessionStorage.setItem("uc", this.$route.query.uc);
    }
    setTimeout(() => {
      this.$router.push({ name: "casino", query: this.$route.query });
    }, 3000);
  },
};
</script>
<style lang="scss" scoped>
.launcher {
  width: 100vw;
  height: 100vh;
  background: #06131c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img:nth-child(1) {
    width: 120px;
    height: auto;
  }
}
</style>
