<template>
  <div class="container">
    <img :src="$global.data.cdn_url + imageUrl" alt="" />
    <div v-show="id === 6" class="collection">
      <div class="cell" v-for="item in thirdPlatform" :key="item.id" @click="handleOfThirdPlatForm(item)">
        <img :src="$global.data.cdn_url + item.icon" alt="" />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <div class="des" v-html="des"></div>
    <div v-show="id === 2 || id === 3" class="btn" @click="handleOfTelegram">Junte-se ao canal</div>
    <div v-show="id === 3" class="btn" @click="handleOfCs">Adicionar atendimento ao cliente exclusivo</div>
    <div v-show="id === 5 || id === 6" class="btn" @click="handleOfCs">Entre em contato conosco</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageUrl: "",
      id: "",
      des: "",
      thirdPlatform: [],
    };
  },
  mounted() {
    this.$emit("update:title", "Atividade promocional");
    this.imageUrl = this.$route.query.image_url;
    this.id = parseInt(this.$route.params.id);
    switch (this.id) {
      case 2:
        this.des = `Condições de participação:</br>
1. Cada usuário só pode participar desta atividade uma vez por dia.</br>
2. Os usuários que violarem as regras com várias contas de IP serão desqualificados. Uma vez identificados, todos os prêmios serão cancelados e o acesso à conta será permanentemente proibido.</br>
</br>
Atenção:</br>
1. Os novos usuários devem clicar no botão abaixo para se juntar ao canal oficial.</br>
2. Siga e curta as mensagens do canal.</br>
3. O canal oficial publicará pacotes de presentes no número do canal.</br>`;
        break;
      case 3:
        this.des = `Condições de participação:</br>
1. Cada usuário só pode participar da atividade uma vez.</br>
2. Usuários que violarem as regras com várias contas de IP terão todas as recompensas canceladas e serão permanentemente proibidos de fazer login.</br>
</br>
Regras da atividade:</br>
1. Novos usuários devem clicar no botão abaixo para se juntar ao canal oficial.</br>
2. Siga e curta as mensagens do canal oficial.</br>
3. O canal oficial irá lançar pacotes de presente no canal.</br>`;
        break;
      case 4:
        break;
      case 5:
        this.des = `Condições de parceria:</br>
</br>
1. A indústria do blogueiro não é restrita.</br>
2. O número de seguidores do blogueiro deve ser superior a 100.000.</br>
3. Pelo menos duas experiências anteriores de promoção na indústria.</br>
</br>
Declaração de parceria:</br>
</br>
1. Estamos recrutando parceiros sem pagamento adiantado.</br>
2. Mostre-nos o seu potencial e garantimos o lucro dos parceiros.</br>`;
        break;
      case 6:
        this.des = `Todos os dias, divulgaremos notícias sobre eventos</br>
nos principais meios de comunicação.</br>
Siga-nos e ganhe presentes todos os dias!!!`;
        break;

      default:
        break;
    }

    if (this.id === 6) {
      this.fetchPlatform();
    }
  },
  methods: {
    handleOfCs() {
      this.$router.push("/m-service");
    },
    handleOfTelegram() {
      this.$tools.openTelegram();
    },
    fetchPlatform() {
      const data = {
        action: "platform_link",
        data: {},
      };

      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.thirdPlatform = response.data.platform_link;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleOfThirdPlatForm(item) {
      window.open(item.link, "_self");
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  img {
    width: 100%;
    height: auto;
  }

  .des {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
  }

  .collection {
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    column-gap: 10px;
    .cell {
      display: flex;
      background: var(--mine-card-bg-color);
      height: 64px;
      align-items: center;
      border-radius: 10px;

      img {
        margin-left: 14px;
        margin-right: 10px;
        width: 25px;
        height: auto;
      }

      div {
        flex: 1;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  .btn {
    margin-top: 10px;
    background: #33439e;
    height: 45px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
