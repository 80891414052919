<template>
  <div class="container">
    <!-- <div class="money-wrapper">
      <div class="item">
        <div class="title">Dinheiro a receber</div>
        <div class="money-box">
          <img src="@/assets/common/coins.webp" alt="" />
          <div class="coins">{{ parseFloat(activityInfo?.todo_coins).toFixed(2) }}</div>
        </div>
      </div>
      <div class="item">
        <div class="title">Bônus a receber</div>
        <div class="money-box">
          <img src="@/assets/common/bonus.webp" alt="" />
          <div class="bonus">{{ parseFloat(activityInfo?.todo_bonus).toFixed(2) }}</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="button" :class="activityInfo?.receive ? 'enable' : 'disable'" @click="handleOfOneClickClaim">
      Receber tudo de uma vez
    </div> -->
    <div class="list">
      <div
        v-for="(item, index) in activityInfo?.other_activity"
        :key="index"
        class="item"
        @click="$router.push(item.jump_link)"
      >
        <div class="img-box">
          <img :src="$global.data.cdn_url + item.image_url" alt="" />
        </div>
        <div class="info-wrapper">
          <div class="title">{{ item.title }}</div>
          <div class="info-item" v-for="(info, j) in item.info" :key="j">
            <div
              class="item-box-column"
              :class="j === item.info.length - 1 && item.jump_link !== '/m-invite/m-commission' ? 'item-box-row' : ''"
            >
              <div class="key">{{ info.key }}</div>
              <div class="value-box">
                <div
                  class="value"
                  :style="{
                    color:
                      v.style === 0 ? '#FFFFFF' : v.style === 1 ? '#FFD400' : v.style === 2 ? '#0AC325' : '#96A7C6',
                  }"
                  v-for="(v, k) in info.value"
                  :key="k"
                >
                  {{ v.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img class="arrow" src="@/assets/activity/arrow.webp" alt="" />
      </div>
    </div>
    <div class="subtitle">Colecção acumulada</div>
    <div class="list">
      <div
        v-for="(item, index) in activityInfo?.vip_activity"
        :key="index"
        class="item"
        @click="$router.push(item.jump_link)"
      >
        <div class="img-box">
          <img :src="$global.data.cdn_url + item.image_url" alt="" />
        </div>
        <div class="info-wrapper">
          <div class="title">{{ item.title }}</div>
          <div class="info-item" v-for="(info, j) in item.info" :key="j">
            <div class="item-box-row">
              <div class="key">{{ info.key }}</div>
              <div class="value-box">
                <div
                  class="value"
                  :style="{
                    color:
                      v.style === 0 ? '#FFFFFF' : v.style === 1 ? '#FFD400' : v.style === 2 ? '#0AC325' : '#96A7C6',
                  }"
                  v-for="(v, k) in info.value"
                  :key="k"
                >
                  {{ v.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img class="arrow" src="@/assets/activity/arrow.webp" alt="" />
      </div>
    </div>
    <div class="rule">
      <div class="title">Explicação sobre dinheiro</div>
      <div class="content">
        1，O dinheiro é o valor depositado pelo usuário<br />
        2，Dinheiro pode ser usado para apostar, lucrar e sacar<br />
        3，As apostas em dinheiro podem aumentar o progresso de tarefas como experiência de membro, cofrinho, e
        conversão de bônus em dinheiro real<br />
        4，As apostas e retiradas em dinheiro não têm requisitos de multiplicação<br />
      </div>
      <div class="title">Explicação sobre bônus</div>
      <div class="content">
        1，Os bônus podem ser usados para apostas, mas não podem ser retirados<br />
        2，Participar de atividades como bônus de check-in pode render grandes recompensas<br />
        3，Converter bônus de missões em dinheiro, que pode ser sacado após apostas<br />
        4，Mais opções de uso para os bônus estão por vir, fiquem atentos<br />
      </div>
    </div>
    <div class="feedback" @click="$tools.openTelegram($global.data.cooperate_link)">
      Feedback e sugestões
      <img src="@/assets/activity/feedback_icon.webp" alt="" />
    </div>
    <div class="ext">Ao adotar sugestões de feedback você pode receber um bônus especial!</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activityInfo: [],
    };
  },
  mounted() {
    this.fetchListData();
    this.$tools.newerActivityFinish({ view_active_finish: 1 });
  },
  methods: {
    async handleOfOneClickClaim() {
      if (!this.activityInfo.receive) {
        return;
      }
      const data = {
        action: "collect_all_activity_reward",
        data: {},
      };
      try {
        await this.$http.post("activity", data);
        this.fetchListData();
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchListData() {
      const data = {
        action: "promotion_activity_list",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.activityInfo = response.data;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  & > .money-wrapper {
    margin: 10px 15px 0;
    display: flex;
    background: #0a1f32;
    border-radius: 10px;
    min-height: 70px;

    & > .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      & > .title {
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
      }

      & > .money-box {
        display: flex;
        align-items: center;

        & > img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }

        & > .coins {
          color: #ffd400;
          font-size: 15px;
          font-weight: 500;
        }

        & > .bonus {
          color: #10b349;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  & > .button {
    margin: 10px 15px 0;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
  }

  & > .enable {
    color: #06131c;
    background: #ffd400;
  }

  & > .disable {
    background: #0a1f32;
    color: #526382;
  }

  .list {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .item {
      margin-bottom: 10px;
      background: linear-gradient(90deg, #0c2133 0%, #293577 100%);
      border-radius: 10px;
      min-height: 79px;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 7px 14px 9px;

      .arrow {
        position: absolute;
        width: 15px;
        height: 15px;
        top: 10px;
        right: 15px;
      }

      .img-box {
        position: absolute;
        right: 18px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 75px;
          height: auto;
        }
      }

      & > .info-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 75px;

        & > .title {
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
          text-align: left;
        }

        & > .info-item {
          margin-top: 2px;
          display: flex;
          flex-direction: column;

          & > .item-box-column {
            display: flex;
            flex-direction: column;

            & > .key {
              color: #96a7c6;
              font-size: 10px;
              font-weight: normal;
              text-align: left;
            }

            & > .value-box {
              margin-top: 5px;
              display: flex;

              & > .value {
                font-size: 15px;
                font-weight: bold;
              }
            }
          }

          & > .item-box-row {
            margin-top: 14px;
            display: flex;
            flex-direction: row;

            & > .key {
              color: #96a7c6;
              font-size: 10px;
              font-weight: normal;
              text-align: left;
            }

            & > .value-box {
              margin-top: auto;
              display: flex;

              & > .value {
                font-size: 10px;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }

  .subtitle {
    margin: 10px 15px 0;
    text-align: left;
    color: #dadada;
    font-size: 12px;
    font-weight: 500;
  }

  .rule {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > .title {
      margin-top: 10px;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
    }

    & > .content {
      margin-top: 10px;
      text-align: left;
      color: #96a7c6;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .feedback {
    margin: 30px 15px 15px;
    background: #33439e;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    position: relative;

    & > img {
      position: absolute;
      width: 28.5px;
      height: 24px;
      top: 8px;
      right: 12px;
    }
  }

  .ext {
    margin: auto;
    margin-bottom: 30px;
    color: #96a7c6;
    font-size: 10px;
    font-weight: 500;
    width: 185px;
  }
}
</style>
