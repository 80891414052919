<template>
  <div class="container">
    <div class="wrapper">
      <!-- 用户信息 -->
      <div class="info" @click="handleOfAccount">
        <div class="header-box">
          <img
            v-if="userInfo"
            class="user-header"
            :src="$global.data.cdn_url + userInfo?.icon"
            alt=""
            @click.stop="$router.push('/avatar-page')"
          />
        </div>
        <div class="user-info">
          <div>CamilaSouzaSilva</div>
          <div class="id-box">
            <div>ID:{{ userCode }}</div>
            <img src="@/assets/user/copy.png" alt="" @click.stop="copyHandle" />
            <img class="copiado" v-show="isShowCopiado" src="../../assets/user/copiado.png" alt="" />
          </div>
        </div>
        <img class="next" src="@/assets/user/arrow.png" alt="" />
      </div>
      <!-- 钱包 -->
      <!-- <div class="wallet">
        <div class="wallet-item" @click="$router.push('m-withdrawal-page')">
          <div>Saldo em dinheiro</div>
          <div class="wallet-money">
            <img src="@/assets/user/coin_y.webp" alt="" />
            <div class="yellow">{{ parseFloat(userWallet?.coins || 0).toFixed(2) }}</div>
          </div>
        </div>
        <div class="splitter"></div>
        <div class="wallet-item" @click="handleOfShowAwardTask">
          <div>Saldo de bônus</div>
          <div class="wallet-money">
            <img src="@/assets/user/coin_g.webp" alt="" />
            <div class="green">{{ parseFloat(userWallet?.coins_give || 0).toFixed(2) }}</div>
          </div>
        </div>
      </div> -->
      <div class="banner-box">
        <div class="banner-item" @click="handleOfBalance">
          <div class="top">
            <img class="icon" src="@/assets/user/flag_icon.webp" alt="" />
            <div>Saldo R$</div>
            <img class="arrow" src="@/assets/user/arrow.png" alt="" />
          </div>
          <div class="bottom">
            <div class="money-yellow">{{ $tools.formatCurrency(userWallet?.coins || 0) }}</div>
            <img class="coin" src="@/assets/user/coin_y.webp" alt="" />
          </div>
        </div>
        <div class="banner-item" @click="$router.push({ name: 'piggy-bank-page' })">
          <div class="top">
            <img class="icon" src="@/assets/user/piggy_icon.webp" alt="" />
            <div>Cofrinho</div>
            <img class="arrow" src="@/assets/user/arrow.png" alt="" />
          </div>
          <div class="bottom">
            <div class="money-green">{{ parseFloat(piggyAmount).toFixed(4) }}</div>
            <img class="coin" src="@/assets/user/coin_g.webp" alt="" />
          </div>
        </div>
      </div>

      <!-- 高亮模块 -->
      <div class="highlight-box">
        <div class="highlight-item" v-for="(item, index) in highlightList" :key="index" @click="onTapCell(item)">
          <img :src="item.icon" alt="" />
          <div>{{ item.title }}</div>
        </div>
      </div>

      <!-- 列表 -->
      <div class="collection">
        <div class="cell" v-for="(item, index) in list" :key="index" @click="onTapCell(item)">
          <img :src="item.icon" alt="" />
          <div>{{ item.title }}</div>
        </div>
        <div class="cell" v-for="item in thirdPlatform" :key="item.id" @click="handleOfThirdPlatForm(item)">
          <img :src="$global.data.cdn_url + item.icon" alt="" />
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div class="exit" @click="exitHandle">{{ $t("log_out") }}</div>
    </div>
    <dialog ref="levelUpTips">
      <div class="up-tips-box">
        <div>Parabéns</div>
        <div>Atualize para {{ userInfo?.vip_level_name }} VIP</div>
        <img :src="vipIcon" alt="" />
        <div>{{ userInfo?.vip_up_info }}</div>
        <div @click="handleOfCloseLevelUpTips">bom</div>
      </div>
    </dialog>
    <dialog ref="verifyDialog">
      <VerifyDialogForPhoneOrEmail
        title="Para definir a senha de saque é necessário vincular o celular"
        :verifyType="2"
        :phone="userInfo?.phone"
        @closeEvent="handleOfVerifyDialogClose"
        @endVerifyEvent="$router.push({ name: 'set-password', query: { type: '0', from: 'mine' } })"
      ></VerifyDialogForPhoneOrEmail>
    </dialog>
  </div>
</template>
<script>
import clipboard from "clipboard";
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmail.vue";
export default {
  components: {
    VerifyDialogForPhoneOrEmail,
  },
  data() {
    return {
      highlightList: [
        {
          title: this.$t("deposit"),
          icon: require("@/assets/user/drawing_icon.png"),
          path: "m-deposit-page",
        },
        {
          title: this.$t("withdrawal"),
          icon: require("@/assets/user/recharge_icon.png"),
          path: "m-withdrawal-page",
        },
        {
          title: this.$t("transaction_records"),
          icon: require("@/assets/user/transcation_icon.png"),
          path: "m-flow",
        },
        {
          title: this.$t("game_history"),
          icon: require("@/assets/user/history_icon.png"),
          path: "m-history",
        },
      ],
      list: [
        {
          title: "Convidar",
          icon: require("@/assets/user/invite_icon.webp"),
          path: "commission",
        },
        {
          title: "tarefa de aposta",
          icon: require("@/assets/user/bet_task_icon.webp"),
          path: "bet-task",
        },
        {
          title: this.$t("my_prizes"),
          icon: require("@/assets/user/award_icon.png"),
          path: "m-award",
        },
        {
          title: "código de resgate",
          icon: require("@/assets/user/redemption_icon.png"),
          path: "redemption-code",
        },
        {
          title: "Atendimento ao Cliente",
          icon: require("@/assets/user/service_icon.png"),
          path: "m-service",
        },
        {
          title: this.$t("messages"),
          icon: require("@/assets/user/message_icon.png"),
          path: "m-message",
        },
      ],
      userCode: "",
      isShowCopiado: false,
      userWallet: null,
      thirdPlatform: [],
      userInfo: null,
      vipIcon: "",
      piggyAmount: 0,
    };
  },
  mounted() {
    this.userCode = this.$store.getters.ucode;
    this.getUserWallet();
    this.fetchPlatform();
    this.fetchUserInfo();
    this.fetchzPiggyInfo();
    const endOfSetPwFromMine = sessionStorage.getItem("endOfSetPwFromMine");
    if (endOfSetPwFromMine) {
      sessionStorage.removeItem("endOfSetPwFromMine");
      this.$nextTick(() => {
        this.$router.push({ name: "m-withdrawal-page" });
      });
    }
  },
  computed: {
    vipSchedule() {
      if (this.userInfo === null || this.userInfo?.next_vip_level_small_need_exp === 0) {
        return 0;
      }
      const ret =
        ((this.userInfo?.next_vip_level_small_need_exp -
          (this.userInfo?.next_vip_level_small_need_exp_sum - this.userInfo?.experience)) /
          this.userInfo?.next_vip_level_small_need_exp) *
        100;
      return parseInt(ret);
    },
  },
  methods: {
    handleOfVerifyDialogClose() {
      this.$refs.verifyDialog.close();
      this.userInfo = null;
      this.fetchUserInfo();
    },
    async fetchzPiggyInfo() {
      const data = {
        action: "cofrinho_active_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        const cofrinho_active_info = response.data.cofrinho_active_info;
        this.piggyAmount = parseFloat(cofrinho_active_info.amount - cofrinho_active_info.extract_amount).toFixed(4);
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfBalance() {
      if (this.userInfo.pay_out_pwd_set === false) {
        this.$refs.verifyDialog.showModal();
        return;
      }

      this.$router.push({
        name: "m-withdrawal-page",
      });
    },
    handleOfShowAwardTask() {
      this.$router.push({
        name: "award-task-page",
      });
    },
    async handleOfCloseLevelUpTips() {
      this.$refs.levelUpTips.close();
      const data = {
        action: "vip_up_status",
        data: {},
      };
      try {
        await this.$http.post("userVip", data);
        this.userInfo.vip_up_status = 1;
      } catch (error) {
        console.log("error", error);
      }
    },
    fetchUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };

      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.userInfo = response.data.data;
          this.vipIcon = this.$global.data.cdn_url + this.userInfo.vip_icon;
          if (this.userInfo.vip_up_status === 0) {
            this.$refs.levelUpTips.showModal();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleOfThirdPlatForm(item) {
      window.open(item.link, "_self");
    },
    fetchPlatform() {
      const data = {
        action: "platform_link",
        data: {},
      };

      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.thirdPlatform = response.data.platform_link;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleOfAccount() {
      this.onTapCell({ path: "m-general" });
    },
    getUserWallet() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.userWallet = response.data.user_wallet;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    copyHandle() {
      clipboard.copy(this.userCode);
      this.isShowCopiado = true;
      setTimeout(() => {
        this.isShowCopiado = false;
      }, 2000);
    },
    onTapCell(item) {
      if (item.path === "m-withdrawal-page" && this.userInfo.pay_out_pwd_set === false) {
        this.$refs.verifyDialog.showModal();
        return;
      }

      this.$router.push({
        name: item.path,
      });
    },
    exitHandle() {
      this.logout();
    },
    localLogout() {
      this.$store.commit("clearInfo");
      this.$eventBus.emit("logoutSuccessHandle");
      this.$router.push({ name: "casino" });
    },
    logout() {
      const data = {
        action: "logout",
        data: {},
      };
      this.$http.post("userCenter", data).finally(() => {
        this.localLogout();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  height: 100vh;

  .wrapper {
    overflow-y: scroll;
    flex: 1;
    margin: 15px;
    margin-top: 21px;
    display: flex;
    flex-direction: column;

    .info {
      background: #0a1f32;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;

      .header-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;

        .user-header {
          width: 100%;
          height: auto;
        }
      }

      .user-info {
        padding: 15px 0;
        height: 40px;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 18px;
        justify-content: space-between;
        text-align: left;

        div:first-of-type {
          font-size: 12px;
          font-weight: bold;
          color: #96a7c6;
        }

        .id-box {
          display: flex;
          align-items: center;
          position: relative;

          div {
            font-size: 12px;
            font-weight: 500;
            color: #d6d6d6;
          }

          img {
            margin-left: 16px;
            width: 16px;
            height: 16px;
          }

          .copiado {
            position: absolute;
            top: -30px;
            left: 60px;
            width: 60px;
            height: auto;
          }
        }
      }

      .next {
        margin-right: 7px;
        width: 15px;
        height: 15px;
      }
    }

    .wallet {
      margin-top: 10px;
      display: flex;
      align-items: center;
      background: var(--mine-card-bg-color);
      border-radius: 10px;

      .wallet-item {
        height: 46px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 12px 9px;

        & > div:first-child {
          color: #96a7c6;
          font-size: 12px;
          font-weight: 500;
        }

        .wallet-money {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: 500;

          & > img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }

          & > .yellow {
            color: #ffd400;
          }

          & > .blue {
            color: #4e68fd;
          }

          & > .green {
            color: #10b349;
          }
        }
      }

      .splitter {
        background: #96a7c6;
        border-radius: 10px;
        width: 2px;
        height: 10px;
      }
    }

    .banner-box {
      margin-top: 15px;
      display: flex;

      .banner-item {
        flex: 1;
        background: url("../../assets/user/banner_bg.webp") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 12px 10px 0;
        height: 85px;

        & > .top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > .icon {
            width: 15px;
            height: 15px;
          }

          & > div {
            margin-left: 4px;
            margin-right: auto;
            color: #ffffff;
            font-size: 12px;
            font-weight: bold;
          }

          & > .arrow {
            width: 15px;
            height: 15px;
          }
        }

        & > .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;

          & > .money-yellow {
            color: #ffd400;
            font-size: 20px;
            font-weight: bold;
          }

          & > .money-green {
            color: #00d94c;
            font-size: 20px;
            font-weight: bold;
          }

          & > .coin {
            width: 15px;
            height: 15px;
          }
        }
      }

      .banner-item:last-child {
        margin-left: 7px;
      }
    }

    .highlight-box {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--mine-card-bg-color);
      border-radius: 10px;
      height: 50px;
      padding: 11px 0 10px;

      .highlight-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;

        & > img {
          width: 25px;
          height: 25px;
        }

        & > div {
          margin-top: 8px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 800;
          line-height: 16px;
        }
      }
    }

    .collection {
      margin-top: 10px;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 10px;
      column-gap: 10px;
      .cell {
        display: flex;
        background: var(--mine-card-bg-color);
        height: 64px;
        align-items: center;
        border-radius: 10px;

        img {
          margin-left: 14px;
          margin-right: 10px;
          width: 25px;
          height: auto;
        }

        div {
          flex: 1;
          text-align: left;
          font-size: 12px;
          font-weight: 500;
          color: #96a7c6;
        }
      }
    }

    .exit {
      border-radius: 10px;
      background: var(--mine-card-bg-color);
      box-sizing: border-box;
      height: 45px;
      line-height: 45px;
      color: #3e7bb0;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .up-tips-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 345px;
    background: var(--color-popup-bg);
    border-radius: 10px;
    padding: 20px 0;

    div:nth-child(1) {
      color: #ffd400;
      font-size: 18px;
      font-weight: bold;
    }

    div:nth-child(2) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
      margin-top: 10px;
    }

    img {
      margin: 25px 0;
      width: 65px;
      height: 65px;
    }

    div:nth-child(4) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    div:nth-child(5) {
      margin-top: 10px;
      background: #ffd400;
      width: 270px;
      height: 40px;
      border-radius: 8px;
      color: #070704;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
