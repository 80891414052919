<template>
  <div class="header">
    <img class="icon" :src="iconUrl" alt="" />
    <div class="title">{{ title }}</div>
    <div class="buttons">
      <button v-if="isShowMoreBtn" class="all" @click="onTapAllHandler">
        {{ $t("view_all") + " " + totalCount }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    isShowMoreBtn: {
      type: Boolean,
      default: false,
    },
    isShowArrows: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onTapAllHandler() {
      this.$emit("showAll", this.title.toLowerCase());
    },
    onTapLeftHandler() {
      this.$emit("tapLeft");
    },
    onTapRightHandler() {
      this.$emit("tapRight");
    },
  },
  computed: {
    iconUrl() {
      if (this.icon === "") {
        return require("@/assets/tag_icon.png");
      } else {
        return this.$global.data.cdn_url + this.icon;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  height: 30px;
  border-radius: 10px;
  background: var(--casino-tag-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    margin-left: 6px;
    width: 20px;
    height: 20px;
  }

  .title {
    margin-right: auto;
    color: #ffffff;
    text-align: left;
    margin-left: 5px;
    font-size: 15px;
    font-weight: 500;
  }

  .buttons {
    display: flex;

    .all {
      border: 0;
      border-radius: 5px;
      height: 20px;
      font-size: 12px;
      font-weight: 500;
      color: #526382;
      margin-right: 6px;
      background: transparent;
    }
  }
}
</style>
