<template>
  <div class="container">
    <CategoryComp class="cate" :list="cateList" v-model:index="index"></CategoryComp>
    <div class="wrapper">
      <div class="balance-card">
        <div class="title-box">
          <div class="title">
            <img src="@/assets/wallet/balance_icon.png" alt="" />
            <div>Saldo Da Conta</div>
          </div>
          <div class="title" v-show="index === 0">
            <img src="@/assets/wallet/give_icon.png" alt="" />
            <div>Saldo de bônus</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="money-box">
          <div class="left">
            <div>R$</div>
            <div>{{ showCoins }}</div>
          </div>
          <div class="left" v-show="index === 0">
            <div>R$</div>
            <div>{{ parseFloat(wallet.coins_give || 0).toFixed(2) }}</div>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import CategoryComp from "@/components/CategoryComp.vue";
export default {
  components: { CategoryComp },
  data() {
    let prefix = "";
    if (location.href.includes("m-wallet2")) {
      prefix = "m-";
    }
    return {
      index: 0,
      cateList: [
        {
          title: this.$t("deposit"),
          path: prefix + "deposit-page",
        },
        {
          title: this.$t("withdrawal"),
          path: prefix + "withdrawal-page",
        },
      ],
      wallet: {},
    };
  },
  mounted() {
    this.$emit("update:title", this.$t("my_wallet"));
    this.getUserWallet();
  },
  computed: {
    showCoins() {
      if (this.index === 0) {
        if (this.wallet && this.wallet.coins) {
          return parseFloat(this.wallet.coins).toFixed(2);
        } else {
          return "0.00";
        }
      } else {
        if (this.wallet && this.wallet.withdrawable) {
          return parseFloat(this.wallet.withdrawable).toFixed(2);
        } else {
          return "0.00";
        }
      }
    },
  },
  methods: {
    getUserWallet() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.wallet = response.data.user_wallet;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .cate {
    margin: 15px;
  }

  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .balance-card {
      margin: 0 15px;
      background: var(--wallet-primary-bg-color);
      border-radius: 10px;
      display: flex;
      flex-direction: column;

      .title-box {
        margin: 0 15px;
        height: 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          & > img {
            width: 20px;
            height: 20px;
          }

          & > div {
            text-align: left;
            margin-left: 8px;
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .line {
        height: 0.5px;
        background: #245f32;
      }

      .money-box {
        height: 57px;
        margin: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          flex: 1;
          display: flex;
          height: 36px;

          div:nth-child(1) {
            margin-top: 10px;
            color: #ffd400;
            font-size: 15px;
            font-weight: 500;
          }

          div:nth-child(2) {
            margin-left: 7px;
            color: #ffd400;
            font-size: 25px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
