import { createApp } from "vue";
import App from "./App.vue";

// vuex
import store from "./store";

// 路由
import router from "@/router/index";

// 网络
import http from "@/utils/http";

// toast
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import { useToast } from "vue-toast-notification";
const toast = useToast();

// 全局css文件
import "./assets/css/global.css";

// 全局数据变量
import global from "@/utils/global";

// 工具类
import tools from "@/utils/tools";

// 国际化
import { createI18n } from "vue-i18n";
import cn from "./locales/cn.json";
import en from "./locales/en.json";
import bra from "./locales/bra.json";
const messages = {
  cn,
  en,
  bra,
};

const i18n = createI18n({
  // locale: "cn",
  locale: "bra",
  fallbackLocale: "bra",
  messages,
});

// element plus
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";

// vant
import { ConfigProvider } from "vant";
import { Locale } from "vant";
import ptBR from "vant/es/locale/lang/pt-BR";
Locale.use("pt-BR", ptBR);

// event bus
import eventBus from "@/utils/EventBus";

// loadding
import { load } from "@/utils/loading.js";

// hongbao
import { hbObj } from "@/utils/hongbao.js";

// Google tag
import * as VueGtag from "vue-gtag";

import vConsole from "vconsole";

// 判断条件来决定是否启动vConsole
if (process.env.NODE_ENV === "development") {
  new vConsole();
}

const app = createApp(App);

app.config.globalProperties.$http = http;
app.config.globalProperties.$global = global;
app.config.globalProperties.$tools = tools;
app.config.globalProperties.toast = toast;
app.config.globalProperties.$eventBus = eventBus;
app.config.globalProperties.$load = load;
app.config.globalProperties.$hongbao = hbObj;
app.config.globalProperties.$fbq = window.fbq;
app.config.globalProperties.$kwaiq = window.kwaiq;

app.use(
  VueGtag,
  {
    config: { id: "G-64KXBDXPEB" },
    appName: "BetEagle",
    pageTrackerScreenviewEnabled: true,
  },
  router
);

app.use(router);
app.use(ToastPlugin, {
  position: "top",
});
app.use(store);
app.use(i18n);
app.use(ElementPlus);
app.use(ConfigProvider);
app.mount("#app");
