<template>
  <div class="container">
    <div class="wrapper">
      <div class="card">
        <div class="level">
          <div class="value">
            <div>LV.{{ userInfo?.vip_small_level }}</div>
            <div>{{ userInfo?.vip_level_name }}</div>
          </div>
          <img v-show="vipIcon !== ''" :src="vipIcon" alt="" />
        </div>
        <div class="progress-box">
          <div class="progress-info">
            <div>0</div>
            <div>{{ vipSchedule }}%</div>
            <div>{{ userInfo?.next_vip_level_small_need_exp }}</div>
          </div>
          <div class="progress">
            <div class="current" :style="{ width: vipSchedule + '%' }"></div>
          </div>
          <div class="mark">
            <div>{{ userInfo?.vip_level_name + ":" + userInfo?.vip_small_level }}</div>
            <div>{{ userInfo?.next_vip_level_name + ":" + userInfo?.next_vip_level_small }}</div>
          </div>
        </div>
        <div class="freeze" v-if="userInfo?.vip_level_state === 0">
          <img src="@/assets/level/freeze_icon.webp" alt="" />
          <div>congelar</div>
        </div>
      </div>
      <div class="freeze-task" v-if="userInfo?.vip_level_state === 0">
        <div class="task-progress-bg">
          <div class="task-progress-current" :style="{ width: freezeCurrentValue + '%' }"></div>
          <div>depósito:{{ userInfo?.pay_amount }}/{{ userInfo?.vip_level_keep_need }}</div>
        </div>
        <div>
          Suas vantagens VIP estão congeladas devido a depósitos insuficientes no mês passado. Complete um depósito de
          R${{ userInfo?.vip_level_keep_need - userInfo?.pay_amount }} este mês para restaurar suas vantagens VIP.
        </div>
      </div>
      <div class="title-box">
        <div>privilégio</div>
        <img src="@/assets/level-info.png" alt="" @click="$refs.vipRuleDialog.showModal()" />
      </div>
      <div class="rule" v-if="showRule">
        <div v-for="(item, index) in ruleList" :key="index" class="item">
          <div class="info-box">
            <div>{{ item.title }}</div>
            <div>{{ item.des1 }}</div>
            <div>{{ item.des2 }}</div>
          </div>
          <div class="img-box">
            <img :src="item.img" alt="" />
          </div>
        </div>
      </div>
      <div class="list">
        <!-- 黄金俱乐部 -->
        <div class="item" v-if="goldenClubInfo !== null && !showRule">
          <div class="content-box">
            <div class="title">Clube de Ouro</div>
            <div class="des">Dinheiro Pendente para Receber</div>
            <div class="money">R$:{{ goldenWinPrize }}</div>
          </div>
          <div class="btn" @click="$router.push('/m-vip-club')">Ver</div>
          <div class="img-box">
            <img src="@/assets/level/icon3.webp" alt="" />
          </div>
        </div>
        <!-- 投注返利 -->
        <div class="item" v-if="rebate1 !== null && !showRule">
          <div class="content-box">
            <div class="title">Reembolso de apostas</div>
            <div class="des">Reembolso Pendente para Receber</div>
            <div class="money">R$:{{ parseFloat(rebate1?.rebate_mount).toFixed(2) }}</div>
          </div>
          <div class="btn" @click="$router.push('/m-vip-rebate')">Ver</div>
          <div class="img-box">
            <img src="@/assets/level/icon2.webp" alt="" />
          </div>
        </div>
        <!-- 会员抽奖 -->
        <div class="item" v-if="drawsNumber !== null && !showRule">
          <div class="content-box">
            <div class="title">Orteio Para Membros</div>
            <div class="draws-count">
              Sorteio diário: <span>{{ drawsNumber?.today_draws_numbers?.available_number }}</span>
            </div>
            <div class="draws-count">
              Sorteio de Promoção: <span>{{ drawsNumber?.today_promotion_numbers?.available_number }}</span>
            </div>
          </div>
          <div class="btn" @click="handleOfShowRaffle(1)">Ver</div>
          <div class="img-box">
            <img src="@/assets/level/icon1.webp" alt="" />
          </div>
        </div>
        <!-- 专属客服 -->
        <div class="cs" @click="$tools.openTelegram(cs)">
          <div class="cs-box">
            <div>{{ csData.title }}</div>
            <div>{{ csData.des1 }}</div>
            <div>{{ cs }}</div>
          </div>
          <div class="img-box">
            <img :src="csData.img" alt="" />
          </div>
        </div>
        <!-- 提款手续费 -->
        <!-- <div class="tax">
          <div class="tax-title">Minha Taxa de Saque</div>
          <div class="tax-value">
            Normal:<span>{{ currentTaxa }}</span>
          </div>
        </div> -->
      </div>
      <!-- 黄金俱乐部奖励 -->
      <!-- <div class="senior" v-if="goldenClubInfo !== null && !showRule">
        <div class="senior-collection">
          <div class="senior-item" v-if="goldenClubInfo?.this_week !== null">
            <div class="title">Bônus da semana</div>
            <div class="money">R$:{{ parseFloat(goldenClubInfo?.this_week?.prize).toFixed(2) }}</div>
            <div class="count-down">Contagem regressiva: {{ goldenCountDown }}</div>
          </div>
          <div class="senior-item" v-if="goldenClubInfo?.this_week !== null">
            <div class="title">Missão da Semana</div>
            <div class="senior-des">
              Apostas qualificadas da semana:R$
              {{ parseFloat(goldenClubInfo?.this_week?.user_effective_bet).toFixed(2) }}
            </div>
            <div class="senior-progress">
              <div class="senior-progress-current" :style="{ width: goldThisWeekTaskValue + '%' }"></div>
            </div>
            <div class="senior-progress-des">
              <div>{{ userInfo?.vip_level_name }}</div>
              <div>
                apostas válidas:{{ parseFloat(goldenClubInfo?.this_week?.vip_level_need_effective_bet).toFixed(2) }}
              </div>
            </div>
          </div>
          <div class="senior-item" v-if="goldenClubInfo?.last_week !== null">
            <div class="title">lista de vencedores</div>
            <div class="money">R$:{{ parseFloat(goldenClubInfo?.last_week?.prize).toFixed(2) }}</div>
            <div class="date-range">
              {{ monthAndDay(goldenClubInfo?.last_week?.start_date) }}~{{
                monthAndDay(goldenClubInfo?.last_week?.end_date)
              }}
            </div>
            <div
              class="senior-btn"
              :class="goldenClubInfo?.last_week?.have_drawn === 0 ? 'senior-btn-disable' : 'senior-btn-enable'"
              @click="handleOfShowWinningList"
            >
              {{ goldenClubInfo?.last_week?.have_drawn === 0 ? "Em processamento" : "verificar" }}
            </div>
          </div>
          <div class="senior-item" v-if="goldenClubInfo?.last_week !== null">
            <div class="title">meu bônus</div>
            <div class="money">R$:{{ goldenWinPrize }}</div>
            <div class="date-range">bônus ganho no ciclo anterior</div>
            <div
              class="senior-btn"
              :class="
                goldenClubInfo?.last_week?.have_drawn === 1 && goldenClubInfo?.last_week.have_claimed === 0
                  ? 'senior-btn-enable'
                  : 'senior-btn-disable'
              "
              @click="handleOfGoldenReward"
            >
              {{
                goldenClubInfo?.last_week?.have_drawn === 0
                  ? "Em processamento"
                  : goldenClubInfo?.last_week?.have_claimed === 0
                  ? "receber"
                  : "Recebido"
              }}
            </div>
          </div>
        </div>
      </div> -->
      <!-- 初级奖励 -->
      <!-- <div class="primary" v-if="!showRule">
        <div class="valid-bet" v-if="rebate1 !== null">
          apostas válidas hoje R$:{{ parseFloat(rebate1?.today_bet_amount).toFixed(2) }}
        </div>
        <div class="primary-collection">
          <div class="primary-item" v-if="rebate1 !== null">
            <div class="title">reembolso pendente</div>
            <div class="money">R$:{{ parseFloat(rebate1.result_amount).toFixed(2) }}</div>
            <div class="count-down">Contagem regressiva:{{ rebateCountDownTimeStr }}</div>
          </div>
          <div class="primary-item" v-if="rebate2 !== null">
            <div class="title">reembolso pendente</div>
            <div class="money">R$:{{ parseFloat(rebate2.result_amount).toFixed(2) }}</div>
            <div
              class="primary-btn"
              :class="rebate2.state === 2 ? 'primary-btn-enable' : 'primary-btn-disable'"
              @click="handleOfClaimRebate"
            >
              {{ rebate2.state === 2 ? "receber" : "Recebido" }}
            </div>
          </div>
          <div class="primary-item" v-if="drawsNumber !== null">
            <div class="title">Loteria de hoje</div>
            <div class="des">Quanto mais alto o nível VIP, mais sorteios diários, e maior o valor.</div>
            <div class="primary-btn" @click="handleOfShowRaffle(0)">
              Sorteio ({{ drawsNumber?.today_draws_numbers?.available_number }} vez)
            </div>
          </div>
          <div class="primary-item" v-if="drawsNumber !== null">
            <div class="title">Loteria de atualizar</div>
            <div class="des">
              1 sorteio gratuito por cada atualização. Quanto mais elevado for o nível VIP, maior será o valor do
              sorteio.
            </div>
            <div class="primary-btn" @click="handleOfShowRaffle(1)">
              Sorteio ({{ drawsNumber?.today_promotion_numbers?.available_number }} vez)
            </div>
          </div>
        </div>
      </div> -->
      <dialog ref="vipRuleDialog">
        <DialogContentComp
          :headerImgPath="require('@/assets/level/rule_dialog_icon.webp')"
          @closeEvent="$refs.vipRuleDialog.close()"
        >
          <VipRule :benefits="benefits" :goldCludInfo="goldBenefitsInfo" @close="isShowRule = false"></VipRule>
        </DialogContentComp>
      </dialog>
      <!-- <WinningList
        v-if="isShowWinningList"
        :style="{ 'margin-bottom': modalPopupMarginBottom }"
        @close="isShowWinningList = false"
        :dateRangeStr="
          monthAndDay(goldenClubInfo?.last_week?.start_date) + '~' + monthAndDay(goldenClubInfo?.last_week?.end_date)
        "
        :userList="goldenUserList"
      ></WinningList> -->
    </div>
    <dialog ref="rewardTips">
      <div class="reward-tips-box">
        <div>Prompt</div>
        <div>R$:{{ parseFloat(goldenClubInfo?.last_week?.win_prize).toFixed(2) }}</div>
        <div>A recompensa foi depositada na carteira</div>
        <div @click="$refs.rewardTips.close()">bom</div>
      </div>
    </dialog>
    <dialog ref="levelUpTips">
      <div class="up-tips-box">
        <div>Parabéns</div>
        <div>Atualize para {{ userInfo?.vip_level_name }} VIP</div>
        <img :src="vipIcon" alt="" />
        <div>{{ userInfo?.vip_up_info }}</div>
        <div @click="handleOfCloseLevelUpTips">bom</div>
      </div>
    </dialog>
  </div>
</template>

<script>
import VipRule from "@/pages/mine/vip/VipRule.vue";
import DialogContentComp from "@/components/DialogContentComp.vue";
// import WinningList from "@/pages/mine/vip/WinningList.vue";
export default {
  components: {
    VipRule,
    DialogContentComp,
    // WinningList
  },
  data() {
    let modalPopupMarginBottom = "0px";
    if (location.pathname.includes("m-") === false) {
      const tabBarHeight = getComputedStyle(document.documentElement).getPropertyValue("--tab-bar-height");
      modalPopupMarginBottom = tabBarHeight;
    }
    return {
      isShowRule: false,
      isShowWinningList: false,
      userInfo: null,
      vipIcon: "",
      goldenClubInfo: null,
      goldenCountDown: "",
      goldenUserList: null,
      drawsNumber: null,
      rebate1: null, // 待结算返利
      rebate2: null, // 待领取返利
      rebateTimer: null,
      rebateCountDownTimeStr: "00:00:00",
      benefits: [],
      goldBenefitsInfo: "",
      taxaDesStr: "",
      currentTaxa: "",
      cs: "",
      modalPopupMarginBottom,
      ruleList: [
        {
          title: "Orteio Para Membros",
          des1: "Bronze VIP desbloqueado",
          des2: "1 sorteio grátis por dia. Quanto maior o nível VIP, maior o valor.",
          img: require("@/assets/level/icon1.webp"),
        },
        {
          title: "Reembolso de Apostas",
          des1: "Silver VIP desbloqueado",
          des2: "As apostas válidas do primeiro dia recebem reembolso no segundo dia",
          img: require("@/assets/level/icon2.webp"),
        },
        {
          title: "Benefícios do clube",
          des1: "Gold VIP desbloqueado",
          des2: "Um mínimo de 10.000 ganhos por semana.",
          img: require("@/assets/level/icon3.webp"),
        },
        // {
        //   title: "taxa de saque",
        //   des1: "Gold VIP desbloqueado",
        //   des2: "Ouro 4%, Platina 3%, Diamante 2%, Coroa 1%",
        //   img: require("@/assets/level/icon4.webp"),
        // },
      ],
      csData: {
        title: "Atendimento ao Cliente Exclusivo",
        des1: "Seu suporte VIP exclusivo",
        des2: "https://t.me/BetEagle",
        img: require("@/assets/level/icon5.webp"),
      },
    };
  },
  unmounted() {
    clearInterval(this.rebateTimer);
  },
  mounted() {
    this.$emit("update:title", this.$t("vip"));
    this.fetchUserInfo();
    this.fetchGoldenClubInfo();
    this.fetchCount();
    this.fetchUserBetRebateInfo(1);
    this.fetchUserBetRebateInfo(2);
    this.$tools.newerActivityFinish({ view_member_finish: 1 });
  },
  computed: {
    goldThisWeekTaskValue() {
      if (this.goldenClubInfo?.this_week.vip_level_need_effective_bet === 0) {
        return 0;
      }

      const value =
        (this.goldenClubInfo.this_week.user_effective_bet /
          this.goldenClubInfo.this_week.vip_level_need_effective_bet) *
        100;
      if (value > 100) {
        return 100;
      }

      return value;
    },
    goldenWinPrize() {
      if (this.goldenClubInfo.last_week.have_drawn === 0) {
        return "0.00";
      } else {
        return parseFloat(this.goldenClubInfo.last_week.win_prize).toFixed(2);
      }
    },
    vipSchedule() {
      if (this.userInfo === null || this.userInfo?.next_vip_level_small_need_exp === 0) {
        return 0;
      }
      const ret =
        ((this.userInfo?.next_vip_level_small_need_exp -
          (this.userInfo?.next_vip_level_small_need_exp_sum - this.userInfo?.experience)) /
          this.userInfo?.next_vip_level_small_need_exp) *
        100;
      return parseInt(ret);
    },
    freezeCurrentValue() {
      if (this.userInfo?.vip_level_keep_need === null || this.userInfo?.vip_level_keep_need === 0) {
        return 0;
      }

      const ret = (this.userInfo?.pay_amount / this.userInfo?.vip_level_keep_need) * 100;
      return ret;
    },
    showRule() {
      return this.userInfo?.vip_level < 1 || this.userInfo?.vip_level_state === 0;
    },
  },
  methods: {
    async handleOfCloseLevelUpTips() {
      this.$refs.levelUpTips.close();
      const data = {
        action: "vip_up_status",
        data: {},
      };
      try {
        await this.$http.post("userVip", data);
        this.userInfo.vip_up_status = 1;
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfShowCs() {
      window.open(this.cs, "_self");
    },
    async fetchGoldClubBenefits() {
      const data = {
        action: "gold_club_benefits",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        if ("glod_club_benefits_info" in response.data) {
          this.goldBenefitsInfo = response.data.glod_club_benefits_info;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchBenefits() {
      const data = {
        action: "vip_benefits",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.benefits = response.data.vip_benefits;
        const arr = this.benefits.map((item) => {
          return item.vip_level_name + " " + item.pay_out_rate + "%";
        });
        this.taxaDesStr = arr.join(", ");
        const obj = this.benefits.find((item) => {
          return item.vip_level_name === this.userInfo.vip_level_name;
        });
        this.currentTaxa = obj.pay_out_rate + "%";
        this.cs = obj.vip_customer_service;
      } catch (error) {
        console.log("error", error);
      }
    },
    // async handleOfClaimRebate() {
    //   if (this.rebate2?.state === 2) {
    //     const data = {
    //       action: "receive_bet_rebate",
    //       data: {},
    //     };
    //     try {
    //       await this.$http.post("userVip", data);
    //       this.fetchUserBetRebateInfo(2);
    //     } catch (error) {
    //       console.log("error", error);
    //     }
    //   }
    // },
    async fetchUserBetRebateInfo() {
      const data = {
        action: "user_bet_rebate_info",
        data: {},
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.rebate1 = response.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    // startRebateCountDown() {
    //   if (this.rebate1 === null) {
    //     return;
    //   }

    //   const targetTime = new Date(this.rebate1.order_date);
    //   const targetTimestamp = targetTime.getTime();
    //   const currentTimestamp = Date.now();
    //   let diff = targetTimestamp - currentTimestamp;
    //   this.rebateTimer = setInterval(() => {
    //     if (diff <= 0) {
    //       clearInterval(this.rebateTimer);
    //       this.rebateCountDownTimeStr = "00:00:00";
    //       return;
    //     }
    //     const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    //       .toString()
    //       .padStart(2, "0");
    //     const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    //       .toString()
    //       .padStart(2, "0");
    //     const seconds = Math.floor((diff % (1000 * 60)) / 1000)
    //       .toString()
    //       .padStart(2, "0");
    //     this.rebateCountDownTimeStr = `${hours}:${minutes}:${seconds}`;
    //     diff -= 1000;
    //   }, 1000);
    // },
    async fetchCount() {
      const data = {
        action: "draws_numbers",
        data: {},
      };

      try {
        const response = await this.$http.post("userVip", data);
        this.drawsNumber = response.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    fetchGoldenClubInfo() {
      const data = {
        action: "golden_club_info",
        data: {},
      };

      this.$http
        .post("userVip", data)
        .then((response) => {
          this.goldenClubInfo = response.data;
          this.startGoldenCountDown();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    fetchGoldenUserList() {
      const data = {
        action: "golden_club_user_list",
        data: {
          golden_club_id: this.goldenClubInfo.last_week.golden_club_id,
        },
      };

      this.$http
        .post("userVip", data)
        .then((response) => {
          this.goldenUserList = response.data;
          this.isShowWinningList = true;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleOfGoldenReward() {
      if (this.goldenClubInfo.last_week.have_claimed === 0) {
        const data = {
          action: "golden_club_claim",
          data: {
            golden_club_id: this.goldenClubInfo.last_week.golden_club_id,
          },
        };

        this.$http
          .post("userVip", data)
          .then(() => {
            this.$refs.rewardTips.showModal();
            this.fetchGoldenClubInfo();
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },
    startGoldenCountDown() {
      const dateString = this.goldenClubInfo?.this_week?.end_date + " 23:59:59";
      const dateTimestamp = new Date(dateString).getTime();
      const currentTimestamp = Date.now();
      const difference = dateTimestamp - currentTimestamp;
      let seconds = Math.floor(difference / 1000);
      setInterval(() => {
        seconds--;
        const hours = Math.floor(seconds / 3600)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((seconds % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
        this.goldenCountDown = `${hours}:${minutes}:${remainingSeconds}`;
      }, 1000);
    },
    monthAndDay(dateStr) {
      if (dateStr === null || dateStr === undefined) {
        return "";
      }
      return dateStr.split("-")[1] + dateStr.split("-")[2];
    },
    handleOfShowRaffle(value = 0) {
      this.$router.push({ name: "m-raffle", query: { index: value } });
    },
    handleOfShowWinningList() {
      this.fetchGoldenUserList();
    },
    fetchUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };

      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.userInfo = response.data.data;
          // this.userInfo.vip_level_state = 0; //test 冻结
          this.vipIcon = this.$global.data.cdn_url + this.userInfo.vip_icon;
          this.fetchBenefits();
          this.currentTaxa = this.userInfo.pay_out_rate + "%";
          this.fetchGoldClubBenefits();
          if (this.userInfo.vip_up_status === 0) {
            this.$refs.levelUpTips.showModal();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  // padding: 22px 15px;

  .wrapper {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 22px 15px;
  }

  .card {
    position: relative;
    background: #33439e;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 20px;
    min-height: 140px;

    .level {
      display: flex;

      .value {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        div:nth-child(1) {
          color: #ffffff;
          font-size: 30px;
          font-weight: normal;
        }

        div:nth-child(2) {
          color: #bcbcbc;
          font-size: 18px;
          font-weight: 300;
        }
      }

      img {
        width: 65px;
        height: 65px;
      }
    }

    .progress-box {
      display: flex;
      flex-direction: column;

      .progress-info {
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .progress {
        background: #121212;
        border-radius: 5px;
        height: 10px;
        display: flex;
        align-items: center;

        .current {
          background: #11793b;
          border-radius: 5px;
          height: 7px;
        }
      }

      .mark {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        font-size: 10px;
        font-weight: 500;
      }
    }

    .freeze {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(18, 0, 60, 0.6);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
        height: auto;
      }

      & > div {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .freeze-task {
    border-radius: 10px;
    margin-top: 15px;
    background: #33439e;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 102px;
    min-height: 102px;
    padding: 0 25px;

    .task-progress-bg {
      position: relative;
      height: 20px;
      border-radius: 10px;
      background: #132144;
      display: flex;
      flex-direction: column;

      .task-progress-current {
        border-radius: 10px;
        background: #15ad51;
        height: 100%;
      }

      & > div {
        position: absolute;
        color: #ffffff;
        font-size: 9px;
        font-weight: 500;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    & > div {
      color: #ffffff;
      font-size: 9px;
      font-weight: 250;
      text-align: left;
    }
  }

  .title-box {
    margin: 24px 15px 0;
    display: flex;
    justify-content: space-between;

    & > div {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }

    & > img {
      width: 20px;
      height: 20px;
    }
  }

  .rule {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    & > .item {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(90deg, #0a1f32 0%, #293577 100%);
      border-radius: 10px;
      height: 82px;
      padding: 10px 17px 8px 14px;
      margin-bottom: 10px;

      & > .info-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;

        & > div:nth-child(1) {
          color: #ffd400;
          font-size: 16px;
          font-weight: bold;
        }

        & > div:nth-child(2) {
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }

        & > div:nth-child(3) {
          color: #96a7c6;
          font-size: 10px;
          font-weight: normal;
        }
      }

      & > .img-box {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 75px;
          height: auto;
        }
      }
    }
  }

  .list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    & > .item {
      background: linear-gradient(90deg, #0a1f32 0%, #293577 100%);
      border-radius: 10px;
      height: 144px;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 10px 10px 6px;
      margin-bottom: 10px;

      & > .content-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > .title {
          color: #ffd400;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        & > .des {
          color: #96a7c6;
          font-size: 12px;
          font-weight: 500;
        }

        & > .money {
          color: #ffd400;
          font-size: 18px;
          font-weight: 500;
        }

        & > .draws-count {
          color: #96a7c6;
          font-size: 12px;
          font-weight: bold;

          & > span {
            color: #ffffff;
          }
        }
      }

      & > .btn {
        margin-bottom: 0;
        background: #33439e;
        border-radius: 8px;
        height: 34px;
        line-height: 34px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > .img-box {
        position: absolute;
        right: 17px;
        top: 10px;

        img {
          width: 75px;
          height: auto;
        }
      }
    }

    & > .cs {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(90deg, #0a1f32 0%, #293577 100%);
      border-radius: 10px;
      height: 82px;
      padding: 10px 17px 8px 14px;
      margin-bottom: 10px;

      & > .cs-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;

        & > div:nth-child(1) {
          color: #ffd400;
          font-size: 16px;
          font-weight: bold;
        }

        & > div:nth-child(2) {
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }

        & > div:nth-child(3) {
          color: #96a7c6;
          font-size: 10px;
          font-weight: normal;
        }
      }

      & > .img-box {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 75px;
          height: auto;
        }
      }
    }

    .tax {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #0a1f32;
      height: 60px;
      min-height: 45px;
      border-radius: 10px;
      padding: 0 15px;

      .tax-title {
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
      }

      .tax-value {
        color: #96a7c6;
        font-size: 12px;
        font-weight: bold;

        & > span {
          color: #ffd400;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .senior {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .senior-collection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 10px;
      column-gap: 5px;

      .senior-item {
        background: var(--mine-card-bg-color);
        height: 140px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding: 0 10px;

        .title {
          color: #3e7bb0;
          font-size: 12px;
          font-weight: 500;
        }

        .money {
          color: var(--primary-text-color);
          font-size: 21px;
          font-weight: 500;
        }

        .count-down {
          color: #ffffff;
          font-size: 9px;
          font-weight: 300;
        }

        .senior-des {
          text-align: left;
          color: #ffffff;
          font-size: 10px;
          font-weight: 300;
        }

        .senior-progress {
          background: #141414;
          border-radius: 5px;
          height: 7px;
          width: 100%;

          .senior-progress-current {
            background: #11793b;
            border-radius: 5px;
            height: 7px;
            width: 10%;
          }
        }
        .senior-progress-des {
          width: 100%;
          display: flex;
          justify-content: space-between;
          color: #ffffff;
          font-size: 9px;
          font-weight: 300;
        }

        .date-range {
          color: #ffffff;
          font-size: 10px;
          font-weight: 300;
        }

        .senior-btn {
          width: 100%;
          background: #3e7bb0;
          height: 36px;
          border-radius: 6px;
          color: #070704;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .senior-btn-enable {
          background: var(--color-btn-bg);
          color: #070704;
        }

        .senior-btn-disable {
          background: var(--color-btn-disable-bg);
          color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .primary {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .valid-bet {
      margin-bottom: 10px;
      background: var(--mine-card-bg-color);
      border-radius: 10px;
      height: 40px;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .primary-collection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 10px;
      column-gap: 5px;

      .primary-item {
        background: var(--mine-card-bg-color);
        border-radius: 10px;
        height: 159px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding: 0 10px;

        .title {
          color: #3e7bb0;
          font-size: 12px;
          font-weight: 500;
        }

        .money {
          color: var(--primary-text-color);
          font-size: 18px;
          font-weight: 500;
        }

        .count-down {
          color: #ffffff;
          font-size: 9px;
          font-weight: 300;
        }

        .primary-btn {
          width: 100%;
          background: var(--color-btn-bg);
          height: 36px;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(7, 7, 4, 1);
        }

        .primary-btn-enable {
          background: var(--color-btn-bg);
          color: rgba(7, 7, 4, 1);
        }

        .primary-btn-disable {
          background: var(--color-btn-disable-bg);
          color: rgba(255, 255, 255, 0.2);
        }

        .des {
          text-align: left;
          color: #ffffff;
          font-size: 9px;
          font-weight: 300;
        }
      }
    }
  }

  .reward-tips-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 345px;
    height: 220px;
    background: var(--color-popup-bg);
    border-radius: 10px;

    div:nth-child(1) {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }

    div:nth-child(2) {
      color: #ffd400;
      font-size: 32px;
      font-weight: 500;
    }

    div:nth-child(3) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    div:nth-child(4) {
      background: #ffd400;
      width: 270px;
      height: 40px;
      border-radius: 8px;
      color: #070704;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .up-tips-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 345px;
    background: var(--color-popup-bg);
    border-radius: 10px;
    padding: 20px 0;

    div:nth-child(1) {
      color: #ffd400;
      font-size: 18px;
      font-weight: bold;
    }

    div:nth-child(2) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
      margin-top: 10px;
    }

    img {
      margin: 25px 0;
      width: 65px;
      height: 65px;
    }

    div:nth-child(4) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    div:nth-child(5) {
      margin-top: 10px;
      background: #ffd400;
      width: 270px;
      height: 40px;
      border-radius: 8px;
      color: #070704;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
