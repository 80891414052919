<template>
  <div class="tag-list">
    <div class="left">
      <div
        :class="tId === tag.id ? 'bg-highlight' : 'bg-normal'"
        v-for="tag in tagsList"
        :key="tag.id"
        @click="handleOfTag(tag)"
      >
        <img :src="$global.data.cdn_url + (tId === tag.id ? tag.icon_select : tag.tag_icon)" alt="" />
        <div :class="tId === tag.id ? 'txt-highlight' : 'txt-normal'">{{ tag.tag_name }}</div>
      </div>
    </div>
    <div class="right">
      <div class="collection">
        <div class="card" v-for="item in gameList" :key="item.id">
          <GameCard :game="item"></GameCard>
        </div>
      </div>
      <div class="footer">
        <LoadMoreComp
          :cur-count="curCount"
          :total-count="totalCount"
          @loadMoreEvent="fetchTagGameListTypeData"
        ></LoadMoreComp>
      </div>
    </div>
  </div>
</template>

<script>
import GameCard from "@/components/GameCard.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  components: { GameCard, LoadMoreComp },
  data() {
    return {
      tId: -1,
      title: "",
      tagsList: [],
      curCount: 0,
      totalCount: 0,
    };
  },
  computed: {
    gameList() {
      const item = this.tagsList.find((item) => {
        return item.id === this.tId;
      });
      if (item && "list" in item) {
        return item.list;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.tId = Number(this.$route.params.id);
    this.fetchTagsData();
  },
  methods: {
    handleOfTag(item) {
      if (this.tId !== item.id) {
        this.tId = item.id;
        const tag = this.tagsList.find((item) => {
          return item.id === this.tId;
        });
        if (tag === null || !("list" in tag)) {
          this.fetchTagGameListTypeData();
        } else {
          this.curCount = tag.list.length;
          this.totalCount = tag.totalCount;
          this.$emit("update:title", tag.tag_name);
          this.title = tag.tag_name;
        }
      }
    },
    fetchTagsData() {
      const data = {
        action: "game_lobby",
      };
      this.$http
        .post("gameCenter", data)
        .then((response) => {
          this.tagsList = response.data.game_tags_list.list;
          this.fetchTagGameListTypeData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    fetchTagGameListTypeData() {
      const item = this.tagsList.find((item) => item.id === this.tId);

      if (item === null) {
        return;
      }

      this.$emit("update:title", item.tag_name);
      this.title = item.tag_name;

      let page = 0;
      if ("page" in item) {
        page = item.page + 1;
      }

      const data = {
        action: "game_list",
        data: {
          page,
          game_tag: this.tId,
        },
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          if ("list" in item) {
            item.list.push(...response.data.game_list);
          } else {
            item.list = response.data.game_list;
          }
          item.page = page;
          item.totalCount = response.data.count;
          this.curCount = item.list.length;
          this.totalCount = response.data.count;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tag-list {
  margin: 0 15px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .left {
    overflow-x: auto;
    height: 40px;
    display: flex;

    .bg-normal {
      background: #0c2133;
      height: 40px;
      margin-right: 10px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin: auto 6px;
        width: 24px;
        height: 24px;
      }

      .txt-normal {
        margin-right: 12px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-wrap: nowrap;
      }
    }

    .bg-highlight {
      height: 40px;
      background: linear-gradient(287deg, #d6ae60 0%, #ebdd9c 100%);
      margin-right: 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;

      img {
        margin: auto 6px;
        width: 24px;
        height: 24px;
      }

      .txt-highlight {
        margin-right: 12px;
        color: #06131c;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-wrap: nowrap;
      }
    }
  }

  .right {
    overflow-y: auto;
    margin-top: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .collection {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 10px;
      column-gap: 10px;
      margin-bottom: 6px;

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 90px;
        height: auto;
      }
    }

    .footer {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
