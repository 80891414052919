<template>
  <div class="container">
    <div class="wallet">
      <div class="wallet-item">
        <div>Saldo de bônus</div>
        <div class="wallet-money">
          <img src="@/assets/user/coin_g.webp" alt="" />
          <div class="green">{{ parseFloat(userWallet?.coins_give || 0).toFixed(2) }}</div>
        </div>
      </div>
      <div
        class="btn"
        :class="userWallet?.coins_give >= bonus_task_config?.pay_out_single_min_bonus ? 'enable' : 'disable'"
        @click="handleOfCreateAwardWalletTask"
      >
        Sacar
      </div>
    </div>
    <div class="no-task-wrapper" v-if="user_bounty_task === null">
      <div class="title">Converter em dinheiro</div>
      <div class="input-box">
        <input type="text" v-model="bonus_amount" placeholder="Inserir" />
        <div class="info">
          Aposta válida:&nbsp;<span>{{ bonus_amount * bonus_task_config?.bonus_task_rate }}</span>
        </div>
      </div>
      <div class="other-info">
        <div class="recommend">Sugestão: insira <span>2</span></div>
        <div class="limit">
          Limite diário: <span>{{ daily_bonus }}</span
          ><span> / {{ bonus_task_config?.daily_max_bonus }}</span>
        </div>
      </div>
      <div class="btn" @click="handleOfCreatingTask1">Criar tarefa</div>
    </div>
    <div class="main" v-else>
      <div class="title">{{ user_bounty_task?.task_type === 1 ? "Converter em dinheiro" : "Tarefa de saque" }}</div>
      <div class="money" :class="user_bounty_task?.task_type === 1 ? 'yellow-money' : 'green-money'">
        {{ parseFloat(user_bounty_task?.bounty_amount || 0).toFixed(2) }}
      </div>
      <div class="des-box">
        <div v-if="user_bounty_task?.task_type === 1">
          Você ainda precisa completar
          <span class="green-money">{{
            parseFloat(
              user_bounty_task?.effective_bet_amount - user_bounty_task?.effective_bet_amount_done || 0
            ).toFixed(2)
          }}</span>
          apostas válidas para poder converter até
          <span class="yellow-money">{{ parseFloat(user_bounty_task?.bounty_amount || 0).toFixed(2) }}</span> em
          dinheiro.
        </div>
        <div v-else-if="user_bounty_task?.task_type === 2">
          Você ainda precisa completar
          <span class="yellow-money">{{
            parseFloat(
              user_bounty_task?.effective_bet_amount - user_bounty_task?.effective_bet_amount_done || 0
            ).toFixed(2)
          }}</span>
          em apostas válidas para poder retirar
          <span class="green-money">{{ parseFloat(user_bounty_task?.bounty_amount || 0).toFixed(2) }}</span>
        </div>
      </div>
      <div class="progress-box">
        <div class="progress-bg">
          <div
            class="progress-cur"
            :style="{
              width: `${progress}%`,
            }"
          ></div>
        </div>
        <div class="mark">
          <div>0.00</div>
          <div>{{ progress }}%</div>
          <div>{{ parseFloat(user_bounty_task?.effective_bet_amount || 0).toFixed(2) }}</div>
        </div>
      </div>
      <div class="btn" :class="user_bounty_task?.status === 3 ? 'highlight' : 'normal'" @click="handleOfButton">
        Retirar
      </div>
    </div>
    <div class="rule-box">
      <div>Sacar bônus:</div>
      <div>
        1. O saldo de bônus deve ser ≥ R${{ bonus_task_config?.pay_out_single_min_bonus }} para solicitar a retirada de
        bônus.<br />
        2. A retirada de bônus requer a conclusão da tarefa de apostas válidas.<br />
        3. Ao solicitar a retirada, todo o saldo de bônus será deduzido.<br />
        4. Após solicitar a retirada, não é possível criar novas tarefas de bônus.<br />
      </div>
    </div>
    <div class="rule-box">
      <div>Converter em dinheiro:</div>
      <div>
        1. O saldo de bônus deve ser > {{ bonus_task_config?.bonus_balance }} para criar uma tarefa.<br />
        2. A conversão em dinheiro requer a conclusão de uma tarefa de apostas válidas. <br />
        3. A criação da tarefa irá deduzir o valor correspondente do saldo de bônus. <br />
        4. O limite diário para criar tarefas de bônus é R${{ bonus_task_config?.bonus_bet_rate }}. <br />
        5. Após criar a tarefa, não é possível solicitar o saque do bônus.<br />
      </div>
    </div>
    <div class="bonus-rule-box" @click="$eventBus.emit('emitOfChangeTab', 'activity')">
      <img src="@/assets/award-task/icon.webp" alt="" />
      <div class="bonus-des-box">
        <div>Como obter o bônus?</div>
        <div>Participar de atividades promocionais pode render bônus.</div>
        <div>Obter bônus 》</div>
      </div>
    </div>

    <dialog ref="walletEmptyDialog">
      <div class="wallet-empty-wrapper">
        <div class="header">
          <div>Aviso</div>
          <img src="@/assets/award-task/recharge.webp" alt="" />
          <img src="@/assets/award-task/close.webp" alt="" @click="$refs.walletEmptyDialog.close()" />
        </div>
        <div class="des">{{ desForCreatingFail }}</div>
        <div class="btn" @click="handleOfDeposite">{{ btnTitleForCreatingFail }}</div>
      </div>
    </dialog>
    <!-- 转换钱包任务创建成功弹窗 -->
    <dialog ref="awardWalletTaskCreateDialog">
      <div class="award-wallet-wrapper">
        <div class="header">
          <div>Dica</div>
          <img src="@/assets/award-task/recharge.webp" alt="" />
          <img src="@/assets/award-task/close.webp" alt="" @click="$refs.awardWalletTaskCreateDialog.close()" />
        </div>
        <div class="des1">
          <div>Balanço de bônus</div>
          <div>{{ userWallet?.coins_give }}</div>
          <div>Tarefa de saque: Aposta válida</div>
          <div>{{ userWallet?.coins_give * bonus_task_config?.pay_out_bonus_task_rate }}</div>
        </div>
        <div class="des2">Não é possível criar a tarefa de conversão antes de concluir a tarefa</div>
        <div class="btn" @click="handleOfAwardWalletTaskCreateDialogOk">Confirmado</div>
      </div>
    </dialog>
    <dialog ref="successDialog">
      <div class="success-wrapper">
        <div class="header">
          <div>Parabéns</div>
          <img src="@/assets/award-task/coin.webp" alt="" />
          <img src="@/assets/award-task/close.webp" alt="" @click="handleOfCloseSuccessDialog" />
        </div>
        <div class="title">Sucesso na retirada</div>
        <div class="money">+{{ parseFloat(sucessMoney).toFixed(2) }}</div>
        <div class="des">Foi automaticamente depositado na sua carteira de dinheiro</div>
        <div class="btn" @click="handleOfCloseSuccessDialog">Entendido</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bonus_task_config: null,
      convert_bounty_task: 0,
      withdraw_bounty_task: 0,
      daily_bonus: 0,
      user_bounty_task: null,
      sucessMoney: 0,
      userWallet: null,
      desForCreatingFail: "",
      btnTitleForCreatingFail: "",
      bonus_amount: "",
    };
  },
  computed: {
    /**
     * 进度条
     */
    progress() {
      if (this.user_bounty_task === null) {
        return 0;
      }
      if (this.user_bounty_task?.effective_bet_amount === 0) {
        return 0;
      }
      return parseFloat(
        (parseFloat(this.user_bounty_task?.effective_bet_amount_done || 0) /
          parseFloat(this.user_bounty_task?.effective_bet_amount)) *
          100
      ).toFixed(2);
    },
  },
  mounted() {
    this.$emit("update:title", "Tarefa de Prêmio");
    this.getUserWallet();
    this.queryTask();
    this.$tools.newerActivityFinish({ view_bounty_finish: 1 });
  },
  methods: {
    handleOfAwardWalletTaskCreateDialogOk() {
      this.$refs.awardWalletTaskCreateDialog.close();
      this.createTask(2);
    },
    handleOfCreateAwardWalletTask() {
      if (this.userWallet.coins_give >= this.bonus_task_config.pay_out_single_min_bonus) {
        this.$refs.awardWalletTaskCreateDialog.showModal();
      } else {
        this.desForCreatingFail =
          "Retirada de bônus,É necessário que o saldo de bônus seja ≥ " +
          this.bonus_task_config.pay_out_single_min_bonus;
        this.btnTitleForCreatingFail = "Obter Bônus";
        this.$refs.walletEmptyDialog.showModal();
      }
    },
    /**
     * 创建奖金转现金任务
     */
    handleOfCreatingTask1() {
      if (this.convert_bounty_task === 0) {
        // 无奖金任务存在 且 不可转换、不可创建
        this.desForCreatingFail = "Criar tarefa de bônus Saldo de bônus > 0";
        this.btnTitleForCreatingFail = "Obter Bônus";
        this.$refs.walletEmptyDialog.showModal();
      } else if (this.convert_bounty_task === 1) {
        // 无奖金任务存在 且 可直接创建(钱包余额>0且奖金余额>0)
        this.createTask(1);
      } else if (this.convert_bounty_task === -1) {
        // 无奖金任务存在 且 需充值成功后自动创建(钱包余额<0且奖金余额>0)
        this.desForCreatingFail = "Criar tarefa de bônus Saldo em dinheiro > 0";
        this.btnTitleForCreatingFail = "Depósito";
        this.$refs.walletEmptyDialog.showModal();
      }
    },
    /**
     * 关闭成功弹窗
     */
    handleOfCloseSuccessDialog() {
      this.$refs.successDialog.close();
      this.getUserWallet();
      this.queryTask();
    },
    /**
     * 获取用户钱包
     */
    getUserWallet() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          this.userWallet = response.data.user_wallet;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    /**
     * 按钮响应事件
     */
    handleOfButton() {
      if (this.user_bounty_task.status === 3) {
        if (this.user_bounty_task.task_type === 1) {
          this.bounty2Coins();
        } else if (this.user_bounty_task.task_type === 2) {
          this.checkUserInfo();
        }
      }
    },
    checkUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          const data = response.data.data;
          this.userInfo = data;
          if (
            data.real_name === null ||
            data.customer_cert === null ||
            data.email === null ||
            data.phone_verify === 0
          ) {
            this.$router.push({
              path: "/m-verification-info1",
              query: { withdrawable: this.user_bounty_task.bounty_amount, amountType: 3 },
            });
          } else {
            this.$router.push({
              path: "/m-verification-info2",
              query: { withdrawable: this.user_bounty_task.bounty_amount, amountType: 3 },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    /**
     * 充值按钮响应事件
     */
    handleOfDeposite() {
      this.$refs.walletEmptyDialog.close();
      if (this.convert_bounty_task === -1) {
        this.$router.push({ name: "m-deposit-page" });
      } else if (this.convert_bounty_task === 0) {
        this.$eventBus.emit("emitOfChangeTab", "activity");
      }
    },
    /**
     * 奖金转换为现金
     */
    async bounty2Coins() {
      const data = {
        action: "user_bounty_to_coins",
        data: {},
      };
      try {
        const response = await this.$http.post("userWallet", data);
        this.sucessMoney = response.data.amount;
        this.$refs.successDialog.showModal();
      } catch (error) {
        console.log("error", error);
      }
    },
    /**
     * 创建奖金提现任务
     * @param task_type 1: 奖金转现金任务 2: 奖金提现任务
     */
    async createTask(task_type) {
      const data = {
        action: "create_user_bounty",
        data: {
          bonus_amount: this.bonus_amount,
          task_type,
        },
      };
      try {
        await this.$http.post("userWallet", data);
        this.queryTask();
        this.getUserWallet();
      } catch (error) {
        console.log("error", error);
        if (error.code === 30001) {
          this.desForCreatingFail = error.msg;
          this.btnTitleForCreatingFail = task_type === 1 ? "Obter Bônus" : "Entendido";
          this.$refs.walletEmptyDialog.showModal();
        }
      }
    },
    /**
     * 查询用户奖金任务
     */
    async queryTask() {
      const data = {
        action: "query_user_bounty_task",
        data: {},
      };
      try {
        const response = await this.$http.post("userWallet", data);
        this.convert_bounty_task = response.data.convert_bounty_task;
        this.withdraw_bounty_task = response.data.withdraw_bounty_task;
        this.daily_bonus = response.data.daily_bonus;
        this.user_bounty_task = response.data.user_bounty_task;
        this.bonus_task_config = response.data.bonus_task_config;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .yellow-money {
    color: #ffd400;
  }

  .green-money {
    color: #10b349;
  }

  .wallet {
    margin: 0px 15px;
    display: flex;
    align-items: center;
    background: var(--mine-card-bg-color);
    border-radius: 10px;

    .wallet-item {
      margin-left: 25px;
      height: 46px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 12px 9px;

      & > div:first-child {
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;
      }

      .wallet-money {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 500;

        & > img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        & > .yellow {
          color: #ffd400;
        }

        & > .blue {
          color: #4e68fd;
        }

        & > .green {
          color: #10b349;
        }
      }
    }

    & > .btn {
      margin-left: auto;
      margin-right: 16px;
      border-radius: 8px;
      width: 73.5px;
      height: 40px;
      line-height: 40px;

      font-size: 15px;
      font-weight: 500;
    }

    & > .enable {
      background: #ffd400;
      color: #000000;
    }

    & > .disable {
      background: #132f48;
      color: #526382;
    }
  }

  .no-task-wrapper {
    margin: 10px 15px 0;
    background: #0a1f32;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 12px 10px 15px;

    & > .title {
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
    }

    & > .input-box {
      margin-top: 20px;
      display: flex;
      align-items: center;
      background: #06131c;
      height: 40px;
      border-radius: 10px;
      padding: 0 10px;

      & > input {
        height: 100%;
        flex: 1;
        background: transparent;
        border: 0;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > .info {
        color: #96a7c6;
        font-size: 10px;
        font-weight: 500;

        & > span {
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    & > input::placeholder {
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
    }

    & > .other-info {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .recommend {
        color: #96a7c6;
        font-size: 9px;
        font-weight: 500;

        & > span {
          color: #ffffff;
        }
      }

      & > .limit {
        color: #96a7c6;
        font-size: 10px;
        font-weight: 500;

        & > span:first-child {
          color: #ffd400;
          font-size: 12px;
          font-weight: 500;
        }

        & > span:last-child {
          color: #96a7c6;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    & > .btn {
      margin-top: 6px;
      background: #ffd400;
      border-radius: 6px;
      height: 40px;
      line-height: 40px;
      color: #06131c;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .main {
    margin: 10px 15px 0;
    display: flex;
    flex-direction: column;
    background: #0a1f32;
    border-radius: 10px;
    padding: 12px;

    & > .title {
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money {
      margin-top: 10px;
      font-size: 32px;
      font-weight: bold;
    }

    & > .des-box {
      margin-top: 10px;

      & > div {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    & > .progress-box {
      margin-top: 15px;
      display: flex;
      flex-direction: column;

      & > .progress-bg {
        background: #06131c;
        height: 15px;
        border-radius: 10px;
        display: flex;

        & > .progress-cur {
          background: #299c3a;
          border-radius: 10px;
          height: 100%;
        }
      }

      & > .mark {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        color: #96a7c6;
        font-size: 12px;
        font-weight: 500;

        & > div:nth-child(2) {
          color: #ffffff;
        }
      }
    }

    & > .btn {
      margin-top: 15px;
      background: #132f48;
      height: 40px;
      line-height: 40px;
      border-radius: 6px;
      font-size: 15px;
      font-weight: 500;
      color: #526382;
    }

    & > .highlight {
      background: #ffd400;
      color: #06131c;
    }

    & > .normal {
      background: #132f48;
      color: #526382;
    }
  }

  & > .rule-box {
    margin: 10px 15px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    & > div:nth-child(1) {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }

    & > div:nth-child(2) {
      margin-top: 10px;
      color: #90acdd;
      font-size: 12px;
      font-weight: 300;

      & > span {
        color: #ffffff;
      }
    }
  }

  & > .bonus-rule-box {
    margin: 25px 15px 0;
    display: flex;
    background: linear-gradient(90deg, #0c2133 0%, #293577 100%);
    border-radius: 10px;
    min-height: 91.5px;

    & > img {
      width: 50px;
      height: 50px;
      margin-top: 20px;
      margin-left: 7px;
      margin-right: 14px;
    }

    & > .bonus-des-box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: left;

      & > div:nth-child(1) {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > div:nth-child(2) {
        color: #96a7c6;
        font-size: 9px;
        font-weight: 500;
      }

      & > div:nth-child(3) {
        color: #10b349;
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  .wallet-empty-wrapper {
    display: flex;
    flex-direction: column;
    height: 247px;
    background: #0a1f32;
    border-radius: 10px;

    & > .header {
      border-radius: 10px 10px 0px 0px;
      background: #132f48;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 23px;

      & > div:first-child {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
      }

      & > img:first-of-type {
        width: 70px;
        height: 48px;
      }

      & > img:last-of-type {
        width: 20px;
        height: 20px;
      }
    }

    & > .des {
      flex: 1;
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
      margin: auto 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > .btn {
      margin: 0 20px 20px;
      background: #33439e;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .award-wallet-wrapper {
    display: flex;
    flex-direction: column;
    // min-width: 345px;
    height: 247px;
    background: #0a1f32;
    border-radius: 10px;

    & > .header {
      border-radius: 10px 10px 0px 0px;
      background: #132f48;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 23px;

      & > div:first-child {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
      }

      & > img:first-of-type {
        width: 70px;
        height: 48px;
      }

      & > img:last-of-type {
        width: 20px;
        height: 20px;
      }
    }

    & > .des1 {
      margin-top: 23px;
      display: flex;
      flex-direction: column;
      color: #ffffff;
      font-size: 16px;
      font-weight: normal;

      & > div:nth-child(2) {
        color: #15ad51;
        font-weight: bold;
      }

      & > div:nth-child(4) {
        color: #ffd400;
        font-weight: bold;
      }
    }

    & > .des2 {
      margin: 5px 50px 0;
      width: 241.5px;
      align-self: center;
      color: #96a7c6;
      font-size: 10px;
      font-weight: normal;
    }

    & > .btn {
      margin: 5px 20px 20px;
      background: #ffd400;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #06131c;
    }
  }

  .success-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #0a1f32;
    height: 247px;

    & > .header {
      border-radius: 10px 10px 0px 0px;
      background: #132f48;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 23px;

      & > div:first-child {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
      }

      & > img:first-of-type {
        width: 70px;
        height: 48px;
      }

      & > img:last-of-type {
        width: 20px;
        height: 20px;
      }
    }

    & > .title {
      margin-top: 15px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money {
      margin-top: 15px;
      color: #ffd400;
      font-size: 21px;
      font-weight: bold;
    }

    & > .des {
      margin: 25px 80px 0;
      color: #526382;
      font-size: 11px;
      font-weight: 500;
    }

    & > .btn {
      margin: 5px 20px 20px;
      background: #33439e;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
