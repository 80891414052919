<template>
  <ModalPopupComp>
    <div class="modal-popup-content">
      <div class="invite-rules-title">Descrição das regras</div>
      <div class="invite-rules-des">
        <div>
          Cada membros que você convida, completar o primeiro depósito, você pode obter R${{ low }} a {{ high }}. Quando
          você cumpre as condições do V3 e acima, Contacte seu serviço exclusivo de clientes para atualizar nível.
        </div>
        <div>
          Após a atualização, você pode receber recompensas de comissão de seus membros de equipe de segundo e terceiro
          nível.
        </div>
      </div>
      <div class="table">
        <div class="header">
          <div class="header-item" v-for="(x, i) in headerDataList" :key="i">
            <div class="title">{{ x.title }}</div>
            <div class="subTitles">
              <div class="subtitle" v-for="(y, j) in x.list" :key="j">
                {{ y }}
              </div>
            </div>
          </div>
        </div>
        <div class="cell-box">
          <div class="cell" v-for="(item, index) in formData" :key="index">
            <div>VIP{{ item.agentLevel }}</div>
            <div>{{ item.firstPayLevel1 }}</div>
            <div>{{ item.add_bonus_first_pay_level1 }}</div>
            <div>{{ item.add_bonus_first_pay_level2 }}</div>
            <div>{{ item.add_bonus_first_pay_level3 }}</div>
          </div>
        </div>
      </div>
    </div>
  </ModalPopupComp>
</template>
<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: {
    ModalPopupComp,
  },
  props: {
    formData: {
      type: Array,
      default: new Array(),
    },
  },
  data() {
    return {
      headerDataList: [
        { title: "Nível", list: [] },
        { title: "Número de Depósito", list: [] },
        { title: "Comissões de Primeiro Depósitos", list: ["Primeiro", "Segundo", "Terceiro"] },
      ],
    };
  },
  computed: {
    low() {
      return this.formData[0].add_bonus_first_pay_level1;
    },
    high() {
      return this.formData[this.formData.length - 1].add_bonus_first_pay_level1;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-popup-content {
  display: flex;
  flex-direction: column;
  .invite-rules-title {
    margin-top: 22px;
    font-size: 20px;
    font-weight: 500;
    color: var(--color-popup-title);
  }
  .invite-rules-des {
    margin: 20px;
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
    text-align: left;
  }
  .table {
    margin: 0 20px 16px;
    display: flex;
    flex-direction: column;

    .header {
      border-radius: 4px 4px 0px 0px;
      background: var(--mine-card-bg-color);
      height: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .header-item:last-of-type {
        width: 60%;
      }
      .header-item {
        width: 20%;
        display: flex;
        flex-direction: column;

        .title {
          color: #ffffff;
          font-weight: 300;
          font-size: 11px;
        }

        .subTitles {
          display: flex;
          justify-content: space-around;
          .subtitle {
            color: #ffffff;
            font-weight: 300;
            font-size: 11px;
          }
        }
      }
    }

    .cell-box {
      border-radius: 0px 0px 4px 4px;
      padding: 10px 0;
      background: var(--mine-card-bg-color);
      .cell {
        height: 30px;
        display: flex;
        justify-content: space-around;
        div {
          flex: 1;
          font-size: 12px;
          font-weight: 300;
          color: #ffffff;
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
