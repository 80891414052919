<template>
  <div class="container">
    <div class="wrapper">
      <img src="../../../assets/level/level3.png" alt="" />
      <div class="name">Gold Member</div>
      <div class="des">
        You need to be at least a Gold Member to unlock this privilege.
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .wrapper {
    height: 400px;
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 132px;
      height: auto;
    }

    .name {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
    }

    .des {
      font-size: 12px;
      font-weight: normal;
      color: #ffffff;
      opacity: 0.8;
    }
  }
}
</style>
