<template>
  <div class="flow">
    <div class="wrapper">
      <mine-empty v-if="isShowEmpty"></mine-empty>
      <div class="table" v-if="isMobile === false && isShowEmpty === false">
        <div class="header">
          <div>{{ $t("date") }}</div>
          <div>{{ $t("type") }}</div>
          <div>{{ $t("sn") }}</div>
          <div>{{ $t("wallet") }}</div>
          <div>{{ $t("amount") }}</div>
          <div>{{ $t("balance") }}</div>
        </div>
        <div class="cell" v-for="(cell, index) in tableData" :key="index">
          <div>{{ cell.create_time }}</div>
          <div>{{ cell.bet_type_str }}</div>
          <div>{{ cell.bet_id }}</div>
          <div>{{ cell.currency_code }}</div>
          <div :class="cell.transfer_amount >= 0 ? 'value-positive' : 'value-negative'">
            R${{ parseFloat(cell.transfer_amount).toFixed(2) }}
          </div>
          <div class="balance">
            <div>R${{ parseFloat(cell.coins).toFixed(2) + " Real" }}</div>
            <div>R${{ parseFloat(cell.bonus).toFixed(2) + " Bonus" }}</div>
          </div>
        </div>
      </div>
      <el-pagination
        v-if="isMobile === false && isShowEmpty === false"
        class="pagination"
        style="justify-content: right"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="totalPage"
        @current-change="changePage"
      ></el-pagination>

      <div class="m-table" v-if="isMobile && isShowEmpty === false">
        <div class="m-cell" v-for="(cell, index) in tableData" :key="index">
          <div class="m-item">
            <div class="m-title">{{ $t("date") }}</div>
            <div class="m-value">{{ cell.create_time }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("type") }}</div>
            <div class="m-value">{{ cell.bet_type_str }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("sn") }}</div>
            <div class="m-value">{{ cell.bet_id }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("wallet") }}</div>
            <div class="m-value">{{ cell.currency_code }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("amount") }}</div>
            <div :class="cell.transfer_amount >= 0 ? 'm-value-positive' : 'm-value-negative'">
              R${{ parseFloat(cell.transfer_amount).toFixed(2) }}
            </div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("balance") }}</div>
            <div class="m-balance">
              <div>R${{ parseFloat(cell.coins).toFixed(2) + " Real" }}</div>
              <div>R${{ parseFloat(cell.bonus).toFixed(2) + " Bonus" }}</div>
            </div>
          </div>
        </div>
        <LoadMoreComp
          :curCount="tableData.length"
          :totalCount="totalPage"
          @loadMoreEvent="fatchBetHistory(page + 1)"
        ></LoadMoreComp>
      </div>
    </div>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  data() {
    return {
      tableData: [],
      page: 0,
      totalPage: 0,
      pageSize: 10,
      isShowEmpty: true,
      isMobile: true,
    };
  },
  components: {
    MineEmpty,
    LoadMoreComp,
  },
  mounted() {
    this.isMobile = this.$tools.isMobile();
    this.fatchBetHistory(this.page);
  },
  methods: {
    changePage(index) {
      this.fatchBetHistory(index - 1);
    },
    fatchBetHistory(page) {
      const data = {
        action: "game_bet_history",
        data: {
          page,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("gameCenter", data)
        .then((response) => {
          const list = response.data.bet_history;
          const vmList = list.map((item) => {
            if (item.bet_type === 1) {
              item.bet_type_str = this.$t("place_a_bet");
            } else {
              item.bet_type_str = this.$t("other");
            }

            return item;
          });
          if (this.isMobile) {
            this.tableData.push(...vmList);
          } else {
            this.tableData = vmList;
          }

          this.page = response.data.page;
          this.isShowEmpty = this.tableData.length === 0;
          this.totalPage = response.data.count;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-pagination.is-background .btn-prev) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-prev:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled).is-active) {
  background-color: #e9c97e;
  color: #070704;
}

:deep(.el-pagination.is-background .btn-next) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-next:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

.flow {
  display: flex;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    .table {
      margin-bottom: 20px;
      background: #1a1919;
      border-radius: 8px;
      .header {
        display: flex;
        height: 56px;
        border-radius: 8px;
        font-size: 12px;
        color: rgba($color: #ffffff, $alpha: 0.8);
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }
      .cell {
        margin: 0 10px 8px;
        display: flex;
        background: #1a1919;
        height: 40px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 40px;
        color: rgba($color: #ffffff, $alpha: 0.8);
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        border: 1px solid #2b2b2b;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .value-positive {
          flex: 1;
          font-size: 12px;
          color: #42f388;
          opacity: 0.8;
          text-align: left;
        }

        .value-negative {
          flex: 1;
          font-size: 12px;
          color: #d04835;
          opacity: 0.8;
          text-align: left;
        }

        .balance {
          flex: 1;
          display: flex;
          justify-content: center;

          div {
            flex: 1;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .m-table {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      padding-bottom: 20px;

      .m-cell {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        background: var(--mine-card-bg-color);
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 10px 16px 7px;

        .m-item:nth-child(3) {
          .m-value {
            font-size: 12px;
          }
        }

        .m-item {
          margin-bottom: 6px;
          display: flex;
          .m-title {
            width: 30%;
            font-size: 14px;
            font-weight: 300;
            color: #3e7bb0;
            text-align: left;
          }
          .m-value {
            flex: 1;
            font-size: 14px;
            font-weight: 300;
            color: #ffffff;
            text-align: left;
          }

          .m-value-positive {
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            color: #15ad51;
            text-align: left;
          }

          .m-value-negative {
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            color: #ff5000;
            text-align: left;
          }

          .m-balance {
            flex: 1;
            display: flex;
            flex-direction: column;

            div {
              font-size: 11px;
              font-weight: 300;
              color: #ffffff;
              opacity: 0.8;
              text-align: left;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper .pagination {
      display: none;
    }
  }
}
</style>
