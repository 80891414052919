<template>
  <div class="container">
    <div class="wrapper">
      <MineEmpty v-if="isShowEmpty"></MineEmpty>
      <div class="table" v-else>
        <div class="cell" v-for="(cell, index) in data_list" :key="index">
          <div class="row">
            <div class="title">{{ $t("date") }}</div>
            <div class="value">{{ cell.create_time }}</div>
          </div>
          <div class="row">
            <div class="title">{{ $t("type") }}</div>
            <div class="value">{{ typeStr(cell.type) }}</div>
          </div>
          <div class="row">
            <div class="title">{{ $t("amount") }}</div>
            <div class="value" :class="cell.type === 2 && cell.amount > 0 ? 'yellow' : 'green'">
              R${{ parseFloat(cell.amount).toFixed(2) }}
            </div>
          </div>
          <div class="row">
            <div class="title">{{ $t("status") }}</div>
            <div class="value">{{ statusStr(cell.status) }}</div>
          </div>
          <div class="row">
            <div class="title">{{ $t("remarks") }}</div>
            <div class="value">--</div>
          </div>
        </div>
        <LoadMoreComp
          :curCount="data_list.length"
          :totalCount="totalPage"
          @loadMoreEvent="fetchDataList(page + 1)"
        ></LoadMoreComp>
      </div>
    </div>
  </div>
</template>
<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  data() {
    return {
      isShowEmpty: true,
      data_list: [],
      pageSize: 10,
      page: 0,
    };
  },
  components: {
    MineEmpty,
    LoadMoreComp,
  },
  mounted() {
    this.fetchDataList(this.page);
  },
  methods: {
    statusStr(obj) {
      if (obj.type !== 2) {
        return "sucesso";
      } else if (obj.status === 1) {
        return "Transferir dinheiro";
      } else if (obj.status === -1) {
        return "Deduzir Prêmio ";
      }
    },
    typeStr(type) {
      switch (type) {
        case 1:
          return "Primeira recarga promocional";
        case 2:
          return "Missões de recompensa";
        case 3:
          return "Cupom de dinheiro de itens";
        case 4:
          return "Jogo gratuito de itens";
        case 5:
          return "Itens de bônus de itens";
        case 6:
          return "Cupom de depósito de itens";
        case 7:
          return "Cashback de apostas VIP";
        case 8:
          return "Clube VIP Gold";
        case 9:
          return "Atividades operacionais";
        case 10:
          return "Atividade de recarga com desconto diário";
        case 11:
          return "Registro de presente";
        case 12:
          return "Atividade de depósito de registro";
        case 13:
          return "Recompensa de recarga";
        case 14:
          return "Depósito manual";
        case 15:
          return "Atividade de desafio diário";
        case 16:
          return "Retirada do Cofrinho";
        case 17:
          return "Missões para novatos";
        case 18:
          return "Check-in diário";

        default:
          return "";
      }
    },
    async fetchDataList(page) {
      const data = {
        action: "user_gift_list",
        data: {
          page,
          page_size: this.pageSize,
        },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        this.data_list.push(...response.data.data_list);
        this.isShowEmpty = this.data_list.length === 0;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
      display: flex;
      flex-direction: column;

      .cell {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: #0a1f32;
        border-radius: 4px;
        height: 150px;
        padding: 10px 16px;
        margin-bottom: 10px;

        .row {
          display: flex;
          justify-content: space-between;
          text-align: left;
          line-height: 20px;

          .title {
            width: 150px;
            font-size: 14px;
            color: #90acdd;
            font-size: 11px;
            font-weight: 500;
          }

          .value {
            flex: 1;
            color: #ffffff;
            font-size: 11px;
            font-weight: 500;
          }

          .yellow {
            color: #ffd400;
          }

          .green {
            color: #15ad51;
          }
        }
      }
    }
  }
}
</style>
