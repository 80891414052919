<template>
  <div class="general">
    <div class="form">
      <div class="section">
        <div class="title-box">
          <div class="title">Rotina</div>
        </div>
        <div class="list">
          <div class="item">
            <img class="icon" src="@/assets/account/name_icon.webp" alt="" />
            <input
              type="text"
              placeholder="Insira seu nome verdadeiro"
              v-model="nickname"
              @blur="handleOfEndNickNameInput"
            />
          </div>
          <div class="item">
            <img class="icon" src="@/assets/account/gender_icon.webp" alt="" />
            <div class="gender">Gênero</div>
            <img
              class="gender-mark"
              :src="
                sex === 1
                  ? require('@/assets/account/gender_selected_icon.webp')
                  : require('@/assets/account/gender_empty_icon.webp')
              "
              alt=""
              @click="sex = 1"
            />
            <div class="gender-text" @click="sex = 1">Masculino</div>
            <img
              class="gender-mark"
              :src="
                sex === 2
                  ? require('@/assets/account/gender_selected_icon.webp')
                  : require('@/assets/account/gender_empty_icon.webp')
              "
              alt=""
              @click="sex = 2"
            />
            <div class="gender-text" @click="sex = 2">Feminino</div>
          </div>
          <div class="item">
            <img class="icon" src="@/assets/account/addr_icon.webp" alt="" />
            <input type="text" placeholder="Insira sua cidade" v-model="address" @blur="handleOfEndAddressInput" />
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title-box">
          <div class="title">E-mail</div>
          <div class="tag" :class="userData?.email_verify === 1 ? 'green-tag' : 'red-tag'">
            {{ userData?.email_verify === 1 ? "Verificado" : "Não verificado" }}
          </div>
        </div>
        <div class="list">
          <div class="item" @click="handleOfVerify(1)">
            <img class="icon" src="@/assets/account/email_icon.webp" alt="" />
            <div class="placeholder" v-if="email === '' || email === null">Insira seu endereço de e-mail</div>
            <div class="value" v-else>{{ email }}</div>
            <div class="btn" v-if="userData?.email_verify !== 1">Verificar</div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title-box">
          <div class="title">Telefone</div>
          <div class="tag" :class="userData?.phone_verify === 1 ? 'green-tag' : 'red-tag'">
            {{ userData?.phone_verify === 1 ? "Verificado" : "Não verificado" }}
          </div>
        </div>
        <div class="list">
          <div class="item" @click="handleOfVerify(2)">
            <img class="icon" src="@/assets/account/phone_icon.webp" alt="" />
            <div class="placeholder" v-if="phone === '' || phone === null">Insira seu número de telefone</div>
            <div class="value" v-else>{{ phone }}</div>
            <div class="btn" v-if="userData?.phone_verify !== 1">Verificar</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 验证手机or邮箱弹窗 -->
    <dialog ref="verifyDialog">
      <VerifyDialogForPhoneOrEmail
        :verifyType="verifyType"
        :phone="userData?.phone"
        :email="userData?.email"
        @closeEvent="handleOfVerifyDialogClose"
      ></VerifyDialogForPhoneOrEmail>
    </dialog>
  </div>
</template>

<script>
import VerifyDialogForPhoneOrEmail from "@/components/VerifyDialogForPhoneOrEmail.vue";
export default {
  components: {
    VerifyDialogForPhoneOrEmail,
  },
  data() {
    return {
      userData: null,
      nickname: "",
      address: "",
      sex: 0, // 1男 2女 0未知
      email: "",
      phone: "",
      verifyType: 2, // 1邮箱 2手机号
    };
  },
  watch: {
    sex(val) {
      this.updateUserInfo({ sex: val });
    },
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    handleOfVerifyDialogClose() {
      this.$refs.verifyDialog.close();
      this.userData = null;
      this.getUserInfo();
    },
    handleOfVerify(type) {
      if (type === 1 && this.userData.email_verify === 1) {
        return;
      }

      if (type === 2 && this.userData.phone_verify === 1) {
        return;
      }

      this.verifyType = type;
      this.$refs.verifyDialog.showModal();
    },
    handleOfEndNickNameInput() {
      this.updateUserInfo({ nickname: this.nickname });
    },
    handleOfEndAddressInput() {
      this.updateUserInfo({ address: this.address });
    },
    getUserInfo() {
      const data = {
        action: "my_account",
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          const data = response.data.data;
          this.userData = data;
          this.nickname = data.nickname;
          this.sex = data.sex;
          this.address = data.address;
          this.email = data.email;
          // 手机号大于11位，保留后11位
          this.phone = data.phone.length > 11 ? data.phone.substr(data.phone.length - 11, 11) : data.phone;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateUserInfo(params) {
      const data = {
        action: "update_user",
        data: params,
      };
      this.$http
        .post("userCenter", data)
        .then(() => {
          this.getUserInfo();
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.general {
  display: flex;
  flex-direction: column;
  .form {
    display: flex;
    flex-direction: column;

    .section {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .title-box {
        display: flex;
        align-items: center;

        .title {
          margin-left: 15px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          text-align: left;
        }

        .tag {
          margin-left: 5px;

          height: 20px;
          line-height: 20px;
          border-radius: 4px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          padding: 2px 5px;
        }

        .green-tag {
          background: #10b349;
        }

        .red-tag {
          background: #ff5000;
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .item {
          height: 40px;
          display: flex;
          align-items: center;
          background: #0a1f32;
          border-radius: 8px;
          margin-top: 10px;
          font-size: 12px;
          font-weight: 300;
          padding: 0 15px;

          .icon {
            width: 15px;
            height: auto;
          }

          & > input {
            margin: 0 11px;
            flex: 1;
            background: transparent;
            color: #ffffff;
            outline-style: none;
            border: 0;
          }

          .gender {
            margin-left: 11px;
            margin-right: auto;
            color: #96a7c6;
            font-size: 12px;
            font-weight: 500;
          }

          .gender-mark {
            width: 15px;
            height: auto;
          }

          .gender-text {
            margin-left: 5px;
            margin-right: 7.5px;
            color: #96a7c6;
            font-size: 10px;
            font-weight: 500;
          }

          & > .placeholder {
            margin: 0 11px;
            flex: 1;
            text-align: left;
            color: #96a7c6;
          }

          & > .value {
            margin: 0 11px;
            flex: 1;
            color: #ffffff;
            text-align: left;
          }

          & > .btn {
            background: #33439e;
            border-radius: 6px;
            width: 58px;
            height: 28px;
            line-height: 28px;
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
