<template>
  <div class="commission">
    <div class="top-box">
      <img v-if="icon !== ''" :src="$global.data.cdn_url + icon" alt="" />
      <div class="item">
        <div class="title">Nível de Agente</div>
        <div class="level">{{ commission.agent_level }}</div>
      </div>
      <div class="item">
        <div class="title">Comissão de Primeira Recarga</div>
        <div class="money">{{ commission.invite_bonus_level1 }}</div>
      </div>
    </div>
    <div class="collection">
      <div class="item1">
        <div class="title">Carteira de Comissão (Saldo)</div>
        <div class="money">
          <div>R$</div>
          <div>{{ parseFloat(commission.withdrawal_commission + commission.freeze_coins).toFixed(2) }}</div>
        </div>
        <div class="buttons">
          <div @click="withdrawalHandle">{{ $t("withdrawal") }}</div>
          <div @click="isShowTransferBalance = true">{{ $t("transfer_out") }}</div>
        </div>
      </div>

      <div class="item2">
        <img class="info" src="@/assets/info.png" alt="" @click="isShowInviteRules = true" />
        <div class="title">{{ $t("your_invitation_link") }}</div>
        <div class="linker">
          <div>{{ linker }}</div>
          <img src="@/assets/user/copy-invite.webp" alt="" @click="copyHandle" />
          <img class="copiado" v-show="isShowCopiado" src="@/assets/user/copiado.png" alt="" />
        </div>
      </div>
    </div>

    <div class="progress-title-box">
      <div>Convite de recompensa</div>
      <img src="@/assets/info.png" alt="" @click="isShowRules = true" />
    </div>

    <div class="invitation">
      <div class="table">
        <div class="cell" v-for="(item, index) in invite" :key="index">
          <div class="item">
            <div class="title">Número de Convites</div>
            <div class="value">{{ item.first_pay_number }}</div>
          </div>
          <div class="item">
            <div class="title">Quantia do Bônus</div>
            <div class="value">{{ parseFloat(item.add_bonus).toFixed(2) }}</div>
          </div>
          <div class="item">
            <div class="title"></div>
            <div :class="item.reach === 1 ? 'achieved-st' : 'not-achieved-st'">
              {{ item.reach === 0 ? "Não concluído" : "Distribuído" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="partner-title">Parceiro</div>
    <div class="partner-box">
      <div>Parceiro</div>
      <div>
        Se você tem muitos seguidores, oferecemos um plano de recomendação personalizado para você! Entre em contato
        conosco.
      </div>
      <div class="btn" @click="$tools.openTelegram($global.data.cooperate_link)">Entre em contato conosco</div>
    </div>

    <!-- 奖金提现弹窗 -->
    <ModalPopupComp
      v-show="isShowTransferBalance"
      class="to-balance"
      :style="{ 'margin-bottom': modalPopupMarginBottom }"
      :contextWidth="400"
      @close="isShowTransferBalance = false"
    >
      <div class="to-balance-title">Transferir ao Saldo Da Conta</div>
      <div class="to-balance-input">
        <input type="text" v-model="inputValue2Balance" placeholder="Valor" />
      </div>
      <button class="to-balance-modal" @click="toBalanceHandle">{{ $t("submit") }}</button>
    </ModalPopupComp>

    <!-- 提现身份信息弹窗 -->
    <DrawMoney
      v-if="isShowDrawMoney"
      :userInfo="userInfo"
      @close="isShowDrawMoney = false"
      @endSaveEvent="userInfoEndSaveEvent"
    ></DrawMoney>
    <!-- 提现信息弹窗 -->
    <DrawMoney2
      v-if="isShowDrawMoney2"
      :userInfo="userInfo"
      :withdrawable="commission.withdrawal_commission"
      :amountType="2"
      @close="isShowDrawMoney2 = false"
      @endDrawMoney2Event="endDrawMoney2Event"
    ></DrawMoney2>
    <!-- 邀请规则弹窗 -->
    <InviteRules
      v-if="isShowInviteRules"
      :style="{ 'margin-bottom': modalPopupMarginBottom }"
      :formData="inviteRulesData"
      :contextWidth="400"
      @close="isShowInviteRules = false"
    ></InviteRules>
    <!-- 邀请任务规则弹窗 -->
    <CommissionRules
      v-if="isShowRules"
      :style="{ 'margin-bottom': modalPopupMarginBottom }"
      :formData="inviteBonusData"
      @close="isShowRules = false"
    ></CommissionRules>
  </div>
</template>

<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
import DrawMoney from "../wallet/DrawMoney.vue";
import DrawMoney2 from "../wallet/DrawMoney2.vue";
import CommissionRules from "./CommissionRules.vue";
import clipboard from "clipboard";
import InviteRules from "./InviteRules.vue";

export default {
  components: { ModalPopupComp, DrawMoney, DrawMoney2, CommissionRules, InviteRules },
  data() {
    let urlDomain = location.origin + "/";
    if (
      urlDomain.indexOf("localhost") > -1 ||
      urlDomain.indexOf("127.0.0.1") > -1 ||
      urlDomain.indexOf("192.168") > -1
    ) {
      urlDomain = "https://developr.corp.betmace.com/";
    }
    const ucode = this.$store.getters.ucode;

    let modalPopupMarginBottom = "0px";
    if (location.pathname.includes("m-") === false) {
      const tabBarHeight = getComputedStyle(document.documentElement).getPropertyValue("--tab-bar-height");
      modalPopupMarginBottom = tabBarHeight;
    }

    return {
      linker: urlDomain + "?uc=" + ucode,
      isShowTransferBalance: false,
      isShowRules: false,
      commission: {
        withdrawal_commission: 0,
        freeze_coins: 0,
      },
      invite: [],
      inputValue2Balance: "",
      isShowDrawMoney: false,
      isShowDrawMoney2: false,
      userInfo: null,
      isShowCopiado: false,
      isShowInviteRules: false,
      inviteRulesData: [],
      inviteBonusData: [],
      modalPopupMarginBottom,
      icon: "",
    };
  },
  mounted() {
    this.fetchCommisionInfo();
  },
  methods: {
    fetchCommisionInfo() {
      const data = {
        action: "agent_commission_info",
        data: {},
      };
      this.$http
        .post("agentCenter", data)
        .then((response) => {
          this.commission = response.data.commission_data;
          this.invite = response.data.invite_progress;
          this.inviteRulesData = response.data.agent_level_and_service_list;
          this.inviteBonusData = response.data.agent_invite_pay_bonus_list;
          this.icon = response.data.icon;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    endDrawMoney2Event() {
      this.isShowDrawMoney2 = false;
      this.fetchCommisionInfo();
      this.$eventBus.emit("refreshHeaderCoins");
    },
    withdrawalHandle() {
      const data = {
        action: "my_account",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          const data = response.data.data;
          this.userInfo = data;
          if (
            data.firstname === null ||
            data.lastname === null ||
            data.birthday === null ||
            data.customer_cert === null ||
            data.email === null ||
            data.phone_verify === 0
          ) {
            if (this.$tools.isMobile()) {
              this.$router.push({
                path: "/m-verification-info1",
                query: { withdrawable: this.commission.withdrawal_commission, amountType: 2 },
              });
            } else {
              this.isShowDrawMoney = true;
            }
          } else {
            if (this.$tools.isMobile()) {
              this.$router.push({
                path: "/m-verification-info2",
                query: { withdrawable: this.commission.withdrawal_commission, amountType: 2 },
              });
            } else {
              this.isShowDrawMoney2 = true;
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    userInfoEndSaveEvent() {
      this.isShowDrawMoney = false;
      this.withdrawalHandle();
    },
    toBalanceHandle() {
      const data = {
        action: "agent_commission_transfer",
        data: {
          amount: parseInt(this.inputValue2Balance),
        },
      };
      this.$http
        .post("userWallet", data)
        .then(() => {
          this.isShowTransferBalance = false;
          this.fetchCommisionInfo();
          this.$eventBus.emit("refreshHeaderCoins");
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    copyHandle() {
      clipboard.copy(this.linker);
      this.isShowCopiado = true;
      setTimeout(() => {
        this.isShowCopiado = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.commission {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & > .top-box {
    background: #33439e;
    height: 70px;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    & > img {
      width: 50px;
      height: 50px;
    }

    & > .item {
      display: flex;
      flex-direction: column;
      text-align: left;

      & > .title {
        color: #96a7c6;
        font-size: 10px;
        font-weight: 500;
      }

      & > .level {
        margin-top: 10px;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }

      & > .money {
        margin-top: 10px;
        color: #ffd400;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .collection {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .item1 {
      padding: 15px 0;
      border-radius: 8px;
      background: var(--mine-card-bg-color);
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .title {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
      }

      & > .money {
        display: flex;
        align-items: center;

        & > div:first-of-type {
          font-size: 18px;
          font-weight: bold;
          line-height: 30px;
          color: #ffd400;
          margin-bottom: -8px;
        }

        & > div:last-of-type {
          font-size: 32px;
          font-weight: bold;
          line-height: 56px;
          color: #ffd400;
        }
      }

      & > .buttons {
        margin-top: 16px;
        display: flex;
        justify-content: center;

        & > div {
          margin-right: 20px;
          width: 140px;
          height: 40px;
          line-height: 40px;
          border-radius: 6px;
          background: #ffd400;
          color: #000000;
          font-size: 14px;
          font-weight: 800;
        }

        & > div:last-of-type {
          margin-right: 0;
        }
      }
    }

    .item2 {
      position: relative;
      margin-top: 15px;
      border-radius: 16px;
      background: var(--mine-card-bg-color);
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .info {
        position: absolute;
        top: 16px;
        right: 12px;
        width: 20px;
        height: 20px;
      }

      & > .title {
        align-self: flex-start;
        margin: 15px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
      }

      & > .linker {
        margin: 27px 8px 12px;
        border-radius: 6px;
        background: #33439e;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px 0 14px;

        & > div:first-of-type {
          margin: 5px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          text-align: left;
        }

        & > img {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }

        & > .copiado {
          position: absolute;
          top: 45px;
          right: 0px;
          width: 60px;
          height: auto;
        }
      }
    }
  }

  .progress-title-box {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;

    & > div {
      color: #ffffff;
      font-size: 14px;
      font-weight: 300;
    }

    & > img {
      margin-right: 14px;
      width: 20px;
      height: 20px;
    }
  }

  .invitation {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    .table {
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .cell {
        height: 45px;
        display: flex;
        border-radius: 8px;
        background: #0a1f32;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 10px 15px;

        & > .item {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;

          & > .title {
            color: #ffffff;
            font-size: 10px;
            font-weight: 500;
          }

          & > .value {
            color: #ecc603;
            font-size: 18px;
            font-weight: bold;
          }

          & > .achieved-st {
            color: #15ad51;
            font-size: 12px;
            font-weight: 500;
          }

          & > .not-achieved-st {
            color: #96a7c6;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }

  & > .partner-title {
    margin-top: 20px;
    text-align: left;
    color: #dadada;
    font-size: 12px;
    font-weight: 500;
  }

  & > .partner-box {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: #0a1f32;
    border-radius: 10px;
    padding: 15px;

    & > div:nth-child(1) {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
    }

    & > div:nth-child(2) {
      margin-top: 8px;
      color: #96a7c6;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
    }

    & > .btn {
      margin-top: 14px;
      background: #33439e;
      height: 40px;
      line-height: 40px;
      border-radius: 6px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }

  & > .title {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 500;
    color: #bcbcbc;
    text-align: left;
  }

  .to-balance {
    .to-balance-title {
      margin: 30px auto 0;
      font-size: 20px;
      font-weight: 500;
      color: var(--color-popup-title);
    }

    .to-balance-input {
      margin: 30px auto 0;
      background: var(--input-bg-color);
      border-radius: 8px;
      width: 320px;
      height: 46px;
      display: flex;

      input {
        flex: 1;
        margin: auto 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #ffffff;
        background: transparent;
        border: 0;
        outline-style: none;
      }

      input::placeholder {
        color: #96a7c6;
      }
    }

    .to-balance-modal {
      margin: 40px auto;
      width: 320px;
      height: 40px;
      border-radius: 8px;
      background: #ffd400;
      font-size: 14px;
      font-weight: 800;
      color: #0a1f32;
      border: none;
    }
  }
}
</style>
