<template>
  <ModalPopupComp>
    <div class="box">
      <div class="title">Descrição das regras</div>
      <div class="desc">
        À medida que aumenta o número de membros que completam a primeiro depósito, você pode obter Comissões Extras.
      </div>
      <div class="table">
        <div class="header">
          <div>Número de Depósito</div>
          <div>Comissões Extras</div>
        </div>
        <div class="cell-box">
          <div class="cell" v-for="(item, index) in formData" :key="index">
            <div>{{ item.first_pay_level1 }}</div>
            <div>{{ parseFloat(item.add_bonus).toFixed(2) }}</div>
          </div>
        </div>
      </div>
    </div>
  </ModalPopupComp>
</template>
<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: {
    ModalPopupComp,
  },
  props: {
    formData: {
      type: Array,
      default: new Array(),
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    color: var(--color-popup-title);
  }

  .desc {
    margin-top: 18px;
    margin-bottom: 0;
    font-size: 12px;
    color: #ffffff;
    opacity: 0.8;
  }

  .table {
    width: 100%;
    margin: 15px 0 0;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    opacity: 0.8;
    font-size: 12px;
    line-height: 28px;

    .header {
      border-radius: 4px 4px 0 0;
      background: var(--mine-card-bg-color);
      color: #ffffff;
      font-size: 12px;
      font-weight: 300;
      display: flex;
      justify-content: space-around;
    }

    .cell-box {
      border-radius: 0 0 4px 4px;
      background: var(--mine-card-bg-color);
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      .cell {
        display: flex;
        justify-content: space-around;
        color: #ffffff;
        font-weight: 300;
        font-size: 12px;
      }
    }
  }
}
</style>
