<template>
  <div class="award">
    <CategoryComp class="cate" :list="cateList" v-model:index="index" isJumpEvent="false"></CategoryComp>
    <div class="wrapper">
      <MineEmpty v-if="awardList.length === 0"></MineEmpty>
      <div class="list" v-else>
        <div class="cell" v-for="(award, i) in awardList" :key="i">
          <div class="content">
            <img :src="$global.data.cdn_url + award.prize_info.prize_image_url" alt="" />
            <div class="info">
              <div>{{ award.prize_info.prize_name }}</div>
              <div>{{ award.prize_info.describe }}</div>
              <div v-show="index === 0">Data limite:{{ award.deposit_expiration }}</div>
              <div v-show="index !== 0">Obtenir tempo:{{ award.create_time }}</div>
              <div v-show="index !== 0">Tempo de uso:{{ award.use_time }}</div>
              <div class="extend" v-if="index === 1">
                <div class="status" v-if="award.is_used === 2">Usado</div>
                <div class="using" v-else>Contagem regressiva：{{ buffCountDownTimeStr }}</div>
              </div>
            </div>
          </div>
          <div class="btn" v-show="index === 0" @click="handleOfUse(i)">Usar</div>
        </div>
        <LoadMoreComp :curCount="awardList.length" :totalCount="total" @loadMoreEvent="fetchAwardList"></LoadMoreComp>
      </div>
    </div>
    <dialog ref="prizeAmountTips">
      <div class="reward-tips-box">
        <div>Prompt</div>
        <div>R$:{{ parseFloat(prizeAmountTipsValue).toFixed(2) }}</div>
        <div>A recompensa foi depositada na carteira</div>
        <div class="button" @click="handleOfPrizeAmountTipsOk">OK</div>
      </div>
    </dialog>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import CategoryComp from "@/components/CategoryComp.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  components: { MineEmpty, CategoryComp, LoadMoreComp },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:title"],
  data() {
    return {
      index: -1,
      cateList: [
        {
          title: "Não usado",
        },
        {
          title: "Usado",
        },
        {
          title: "Expirou",
        },
      ],
      awardList: [],
      total: 0,
      pageSize: 10,
      page: 0,
      usePrizeInfo: {},
      buffTimer: null,
      buffCountDownTimeStr: "00:00:00",
      prizeAmountTipsValue: 0,
    };
  },
  beforeUnmount() {
    clearInterval(this.buffTimer);
  },
  mounted() {
    this.$emit("update:title", this.$t("my_prizes"));
    this.index = 0;
  },
  watch: {
    index() {
      this.awardList = [];
      this.page = 0;
      this.fetchAwardList();
    },
  },
  methods: {
    handleOfPrizeAmountTipsOk() {
      this.$refs.prizeAmountTips.close();
      this.nextOfUsedPrize();
    },
    nextOfUsedPrize() {
      const followPage = this.usePrizeInfo.prize_info.follow_page;
      switch (followPage) {
        case 0: // 不跳转
          this.page = 0;
          this.fetchAwardList();
          break;
        case 1: // 首页
          this.$eventBus.emit("emitOfChangeTab", "casino");
          break;
        case 2: // 充值页
          this.$router.push({ name: "m-deposit-page" });
          break;
        case 3: // 提款页面
          this.$router.push({ name: "m-withdrawal-page" });
          break;
        case 4: // 抽奖页面
          this.$router.push({ name: "m-raffle", query: { index: 0 } });
          break;
        default:
          break;
      }
    },
    async usePrize(award) {
      const data = {
        action: "use_raffle_prize",
        data: {
          raffle_prize_id: award.id,
        },
      };
      try {
        const response = await this.$http.post("userVip", data);
        this.usePrizeInfo = response.data.user_raffle_prize_info;
        if ("prize_amount" in response.data && response.data.prize_amount > 0) {
          this.prizeAmountTipsValue = response.data.prize_amount;
          this.$refs.prizeAmountTips.showModal();
        } else {
          this.nextOfUsedPrize();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfUse(i) {
      const award = this.awardList[i];
      this.usePrize(award);
    },
    async fetchAwardList() {
      const data = {
        action: "user_raffle_prize_list",
        data: {
          type: this.index, //0-未使用 1-已使用 2-已过期
          page: this.page,
          page_size: this.pageSize,
        },
      };

      try {
        const response = await this.$http.post("userVip", data);
        if (this.page === 0) {
          this.awardList = response.data.data_list;
        } else {
          this.awardList.push(...response.data.data_list);
        }

        if (this.index === 1) {
          // 使用中，找到最近的一个buff进行倒计时

          if (this.buffTimer) {
            clearInterval(this.buffTimer);
          }

          const obj = this.awardList.find((item) => {
            return item.state_duration_type === 1 && item.state_duration !== null;
          });

          if (obj) {
            const targetTime = new Date(obj.state_duration);
            const targetTimestamp = targetTime.getTime();
            const currentTimestamp = Date.now();
            let diff = targetTimestamp - currentTimestamp;
            this.bufferTimer = setInterval(() => {
              if (diff <= 0) {
                clearInterval(this.buffTimer);
                this.buffCountDownTimeStr = "00:00:00";
                return;
              }
              const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                .toString()
                .padStart(2, "0");
              const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
                .toString()
                .padStart(2, "0");
              const seconds = Math.floor((diff % (1000 * 60)) / 1000)
                .toString()
                .padStart(2, "0");
              this.buffCountDownTimeStr = `${hours}:${minutes}:${seconds}`;
              diff -= 1000;
            }, 1000);
          }
        }

        this.page = response.data.page + 1;
        this.total = response.data.count;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.award {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  .wrapper {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 40px;
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      text-align: left;
    }

    .list {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .cell {
        margin-bottom: 10px;
        padding: 10px 20px;
        background: var(--mine-card-bg-color);
        border-radius: 10px;
        // height: 117px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .content {
          display: flex;

          img {
            width: 60px;
            height: 40px;
          }

          .info {
            flex: 1;
            margin: 0 16px;
            display: flex;
            flex-direction: column;
            color: #3e7bb0;
            font-size: 9px;
            font-weight: 500;
            text-align: left;

            div:nth-child(1) {
              color: #ffffff;
              font-size: 12px;
              font-weight: 500;
            }

            .extend {
              .status {
                margin-top: 15px;
                background: #194164;
                height: 20px;
                border-radius: 5px;
                color: #3e7bb0;
                font-size: 9px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .using {
                margin-top: 15px;
                background: #15ad51;
                border-radius: 5px;
                height: 20px;
                font-size: 9px;
                font-weight: 500;
                color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        .btn {
          margin-top: 30px;
          background: #3e7bb0;
          border-radius: 8px;
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .reward-tips-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 345px;
    height: 220px;
    background: var(--color-popup-bg);
    border-radius: 10px;

    div:nth-child(1) {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }

    div:nth-child(2) {
      color: #ffd400;
      font-size: 32px;
      font-weight: 500;
    }

    div:nth-child(3) {
      color: #ffffff;
      font-size: 18px;
      font-weight: normal;
    }

    .button {
      background: var(--color-btn-bg);
      color: var(--color-btn-text);
      border-radius: 8px;
      width: 80%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
