<template>
  <div class="container">
    <div class="header">
      <div>R$</div>
      <div>&nbsp;{{ showAmount }}</div>
    </div>
    <div class="des1">
      Retirada disponível a partir de <span>R${{ cofrinho_active_info?.factor }}</span>
    </div>
    <img src="@/assets/piggy-bank/logo.webp" alt="" />
    <div class="ratio-box">
      <div>100%</div>
      <div>Reembolso</div>
    </div>
    <div class="des2">O valor de devolução é igual ao total dos depósitos</div>
    <div class="money-box">
      <div class="item">
        <div>Limite do cofrinho</div>
        <div>{{ parseFloat(cofrinho_active_info?.size).toFixed(2) }}</div>
      </div>
      <div class="item">
        <div>Limite já retirado</div>
        <div>{{ parseFloat(cofrinho_active_info?.extract_amount).toFixed(2) }}</div>
      </div>
    </div>
    <div class="btn" :class="cofrinho_active_info?.status !== 0 ? 'highlight' : ''" @click="handleOfButton">
      {{
        cofrinho_active_info?.status === -1
          ? "Depósito"
          : cofrinho_active_info?.status === 0
          ? "Aposte agora"
          : "Extrair"
      }}
    </div>

    <div class="logs-wrapper">
      <div>Registros de Reembolso de Apostas em Dinheiro Falhadas</div>
      <div>mostra apenas as últimas 10</div>
      <div class="over-box" v-if="isOver">
        <div>Esta edição da atividade foi encerrada</div>
        <div>Aguarde ansiosamente</div>
      </div>
      <div v-else class="running-box">
        <div class="empty-box" v-if="cofrinho_active_info?.deposit_log.length === 0">
          <img src="../../assets/dice.png" alt="" />
          <div>Vazio</div>
        </div>
        <div v-else class="log-box">
          <div class="log-item" v-for="(item, index) in cofrinho_active_info?.deposit_log" :key="index">
            <div>{{ $tools.formatIsoDate(item.time) }}</div>
            <div>R$-{{ parseFloat(item.lose_amount).toFixed(4) }}</div>
            <div>+R${{ parseFloat(item.amount).toFixed(4) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="logBtn" @click="hanldeOfShowRecords">Verificar histórico de retiradas</div>
    <!-- <div class="award-task-box" @click="handleOfAwardTask">
      <img src="@/assets/piggy-bank/icon.webp" alt="" />
      <div class="des-box">
        <div>Como utilizar o bônus?</div>
        <div>
          1. O bônus pode ser usado para apostar e experimentar diferentes jogos (não pode ser sacado)<br />
          2. Ao completar tarefas de bônus, ele pode ser convertido em dinheiro (pode ser sacado após apostas)<br />
        </div>
        <div>Converter agora 》</div>
      </div>
    </div> -->
    <div class="rules">
      <div class="title">Condições de participação:</div>
      <div>1，Todos os usuários</div>
      <div class="title">Explicação：</div>
      <div class="value">
        1. Se uma aposta em dinheiro falhar
        <span>{{ parseFloat(cofrinho_active_info?.ratio * 1000) }}‰</span> do valor apostado será depositado no cofrinho
        como bônus.<br />
        2. Ao acumular <span>R${{ cofrinho_active_info?.factor }}</span> no cofrinho, você pode retirar, sendo cada
        retirada um múltiplo de {{ cofrinho_active_info?.factor }}.<br />
        3. O valor máximo de devolução do cofrinho é igual ao total dos depósitos.<br />
        4. O bônus pode ser usado para apostas, mas não pode ser sacado.<br />
        5. O bônus pode ser convertido em dinheiro através de tarefas de bônus e pode ser sacado após apostas.<br />
        6. Se for detectada trapaça ou comportamento inadequado, o jogador pode perder a qualificação e as
        recompensas.<br />
      </div>
    </div>
    <dialog ref="successDialog">
      <div class="success-wrapper">
        <div class="header">
          <div>Parabéns</div>
          <img src="@/assets/award-task/coin.webp" alt="" />
          <img src="@/assets/award-task/close.webp" alt="" @click="handleOfCloseSuccessDialog" />
        </div>
        <div class="title">Sucesso na retirada</div>
        <div class="money">
          <div>R$</div>
          <div>&nbsp;{{ parseFloat(extract_amount).toFixed(2) }}&nbsp;</div>
        </div>
        <div class="des">Já foi automaticamente depositado na carteira de dinheiro</div>
        <div class="btn" @click="handleOfCloseSuccessDialog">Entendido</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      extract_amount: 0,
      cofrinho_active_info: null,
      isOver: false,
      showAmount: "0.00",
    };
  },
  computed: {
    progress() {
      if (this.cofrinho_active_info === null) {
        return 0;
      }

      if (this.cofrinho_active_info?.amount >= this.cofrinho_active_info?.size) {
        return 100;
      }

      return parseFloat((this.cofrinho_active_info?.amount / this.cofrinho_active_info?.size) * 100).toFixed(0);
    },
    minValue() {
      return parseFloat(
        this.cofrinho_active_info?.cycle > 1
          ? this.cofrinho_active_info?.cycle * this.cofrinho_active_info.size - this.cofrinho_active_info.size
          : 0
      ).toFixed(2);
    },
    maxValue() {
      return parseFloat(this.cofrinho_active_info?.size * this.cofrinho_active_info?.cycle).toFixed(4);
    },
  },
  mounted() {
    this.$emit("update:title", "Cofrinho");
    this.fetchInfo();
    this.$tools.newerActivityFinish({ view_cofrinho_finish: 1 });
  },
  methods: {
    handleOfAwardTask() {
      this.$router.push({ name: "award-task-page" });
    },
    hanldeOfShowRecords() {
      const isMobile = this.$tools.isMobile();
      if (isMobile) {
        this.$router.push({ name: "m-bonus" });
      } else {
        this.$router.push({ name: "bonus" });
      }
    },
    handleOfCloseSuccessDialog() {
      this.$refs.successDialog.close();
      this.fetchInfo();
    },
    async extractBounty() {
      const data = {
        action: "extract_cofrinho",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.extract_amount = response.data.extract_amount;
        this.$refs.successDialog.showModal();
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfButton() {
      const status = this.cofrinho_active_info.status;
      switch (status) {
        case -1:
          this.$router.push({ name: "m-deposit-page" });
          break;
        case 0:
          this.$router.push({ name: "casino" });
          break;
        case 1:
          this.extractBounty();
          break;
      }
    },
    async fetchInfo() {
      const data = {
        action: "cofrinho_active_info",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        this.cofrinho_active_info = response.data.cofrinho_active_info;
        this.isOver = this.cofrinho_active_info.extract_amount >= this.cofrinho_active_info.size;
        this.showAmount = parseFloat(
          this.cofrinho_active_info.amount - this.cofrinho_active_info.extract_amount
        ).toFixed(4);
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div:nth-child(1) {
      color: #96a7c6;
      font-size: 18px;
      font-weight: bold;
    }

    & > div:nth-child(2) {
      color: #ffd400;
      font-size: 32px;
      font-weight: bold;
    }
  }

  & > .des1 {
    color: #96a7c6;
    font-size: 9px;
    font-weight: 500;

    & > span {
      color: #ffd400;
    }
  }

  & > img {
    margin: 30px auto 0;
    width: 248.5px;
    height: 213px;
  }

  .ratio-box {
    margin-left: auto;
    margin-top: -102px;
    background: linear-gradient(180deg, #e8669c 0%, #c82f6f 100%);
    border-radius: 10px 0px 10px 0px;
    width: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3px 0;

    & > div:nth-child(1) {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }

    & > div:nth-child(2) {
      color: #ffd400;
      font-size: 10px;
      font-weight: bold;
    }
  }

  .des2 {
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
  }

  & > .money-box {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;

    & > .item {
      flex: 0 1 43%;
      display: flex;
      flex-direction: column;
      background: #0a1f32;
      border-radius: 10px;
      height: 40px;
      padding: 2px 10px 5px;

      & > div:nth-child(1) {
        text-align: left;
        color: #96a7c6;
        font-size: 10px;
        font-weight: 500;
        line-height: 21px;
      }

      & > div:nth-child(2) {
        text-align: right;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        line-height: 21px;
      }
    }
  }

  .btn {
    margin-top: 20px;
    background: #33439e;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
  }

  .highlight {
    background: #ffd400;
    color: #06131c;
  }

  .logs-wrapper {
    margin-top: 20px;
    background: #0a1f32;
    min-height: 237.5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 15px;

    & > div:nth-child(1) {
      color: #ffffff;
      font-size: 10px;
      font-weight: 500;
    }

    & > div:nth-child(2) {
      color: #90acdd;
      font-size: 9px;
      font-weight: normal;
      margin-bottom: 5px;
    }

    .over-box {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div {
        color: #96a7c6;
        font-size: 10px;
        font-weight: 500;
      }
    }

    .running-box {
      flex: 1;
      display: flex;
      width: 100%;

      .empty-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > img {
          width: 40px;
          height: 40px;
        }

        & > div {
          color: #526382;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .log-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      .log-item {
        width: 100%;
        height: 21px;
        display: flex;
        justify-content: space-between;

        & > div:nth-child(1) {
          color: #96a7c6;
          font-size: 10px;
          font-weight: 500;
        }

        & > div:nth-child(2) {
          color: #ff5000;
          font-size: 10px;
          font-weight: 500;
        }

        & > div:nth-child(3) {
          color: #ffd400;
          font-size: 10px;
          font-weight: bold;
        }
      }
    }
  }

  .logBtn {
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    background: #0a1f32;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
  }

  .award-task-box {
    margin-top: 10px;
    display: flex;
    background: linear-gradient(90deg, #0c2133 0%, #293577 100%);
    border-radius: 10px;
    min-height: 131.5px;

    & > img {
      width: 50px;
      height: 50px;
      margin-top: 30px;
      margin-left: 15px;
      margin-right: 15px;
    }

    & > .des-box {
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: left;

      & > div:nth-child(1) {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > div:nth-child(2) {
        color: #96a7c6;
        font-size: 9px;
        font-weight: 500;
      }

      & > div:nth-child(3) {
        color: #ffd400;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .rules {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #96a7c6;
    font-size: 10px;
    font-weight: 500;
    text-align: left;

    & > .title {
      color: #96a7c6;
      font-size: 12px;
      font-weight: bold;
    }

    & > .value {
      & > span {
        color: #ffd400;
      }
    }
  }
  .success-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #0a1f32;
    height: 247px;

    & > .header {
      border-radius: 10px 10px 0px 0px;
      background: #132f48;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 23px;

      & > div:first-child {
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
      }

      & > img:first-of-type {
        width: 70px;
        height: 48px;
      }

      & > img:last-of-type {
        width: 20px;
        height: 20px;
      }
    }

    & > .title {
      margin-top: 15px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }

    & > .money {
      margin-top: 15px;
      display: flex;
      justify-content: center;

      & > div:first-child {
        color: #96a7c6;
        font-size: 21px;
        font-weight: bold;
      }

      & > div:last-child {
        color: #ffd400;
        font-size: 21px;
        font-weight: bold;
      }
    }

    & > .des {
      margin: 25px 80px 0;
      color: #526382;
      font-size: 11px;
      font-weight: 500;
    }

    & > .btn {
      margin: 5px 20px 20px;
      background: #33439e;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
