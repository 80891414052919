<template>
  <div class="m-footer">
    <div class="list">
      <div class="row" v-for="(row, i) in list" :key="i" @click="handleOfRow(i)">
        <div class="row-content">
          <div class="row-name">{{ row.name }}</div>
          <img src="@/assets/footer/arrow.png" alt="" />
        </div>
        <div v-if="listIndex === i">
          <div class="item" v-for="(item, j) in row.list" :key="j" @click.stop="handleOfListItem(item)">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="des1">Junte-se à comunidade</div>
    <div class="icons">
      <div class="row">
        <img
          v-for="index in 3"
          :key="index"
          :src="require('@/assets/footer/icon' + index + '.png')"
          alt=""
          @click="handleOfOther(index)"
        />
      </div>
      <div class="row">
        <img
          v-for="index in 3"
          :key="index"
          :src="require('@/assets/footer/icon' + (index + 3) + '.png')"
          alt=""
          @click="handleOfOther(index + 3)"
        />
      </div>
    </div>

    <div class="logos">
      <img src="@/assets/footer/logo2.png" alt="" />
    </div>

    <div class="des2">
      Este site destina-se apenas para entretenimento. Verifique as leis e regulamentos em sua jurisdição, pois estas
      variam entre estados, províncias e países. É proibido utilizar este site para violar qualquer lei ou regulamento.
      Este site não tem afiliação, endosso ou associação com qualquer liga, associação ou equipe profissional ou
      universitária. Este site não é direcionado para menores de 18 anos. <br />
      1. Este produto destina-se apenas a utilizadores com mais de 18 anos e é apenas para fins de entretenimento.<br />
      2. Este jogo contém compras dentro da aplicação.
    </div>
    <!-- <div class="website" @click="handleOfWebsite">
      <img src="@/assets/eaglelogo.png" alt="" />
      <div>www.eagleintlgroup.com</div>
    </div> -->
    <div class="author">@ Direitos De Autor 2002-2024</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "Jogos Principais",
          list: [],
        },
        {
          name: "Promoções",
          list: ["Atividades Promocionais", "Clube de Membros"],
        },
        {
          name: "Sobre nós",
          list: ["EagleGroup"],
        },
        {
          name: "Ajuda",
          list: ["Política de Privacidade", "Termos de Serviço"],
        },
        {
          name: "Entre em contato",
          list: ["Parceria comercial", "Entre em contato"],
        },
      ],
      listIndex: -1,
      provider_list: [],
    };
  },
  mounted() {
    this.fetchProviderListData();
  },
  methods: {
    fetchProviderListData() {
      const data = {
        action: "provider_list",
        data: {},
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          this.provider_list = response.data.provider_list;
          const list = this.provider_list.map((item) => {
            return item.provider_name;
          });
          this.list[0].list = list;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleOfRow(i) {
      this.listIndex = this.listIndex === i ? -1 : i;
    },
    handleOfOther(index) {
      switch (index) {
        case 1:
          this.$tools.openTelegram();
          break;
        case 2:
          window.open("https://www.instagram.com/eagle1group1/", "_self");
          break;
        case 3:
          window.open("https://www.facebook.com/profile.php?id=61556713634248", "_self");
          break;
        case 4:
          // window.open("", "_self");
          break;
        case 5:
          window.open("https://twitter.com/Eagle1Group1", "_self");
          break;
        case 6:
          window.open("https://www.threads.net/@eagle1group1", "_self");
          break;

        default:
          break;
      }
    },
    handleOfWebsite() {
      window.open("https://www.eagleintlgroup.com", "_blank");
    },
    handleOfListItem(item) {
      const isLoggedIn = this.$store.getters.isLoggedIn;
      if (!isLoggedIn) {
        this.$eventBus.emit("isNeedLogin");
        return;
      }

      const provider = this.provider_list.find((i) => {
        return i.provider_name === item;
      });

      if (provider !== undefined) {
        this.$router.push({
          path: "/m-game-list/" + item,
          query: { id: provider.id, type: "provider" },
        });
        return;
      }

      if (item === "Atividades Promocionais") {
        // 促销活动
        this.$eventBus.emit("emitOfChangeTab", "activity");
      } else if (item === "Clube de Membros") {
        // 会员俱乐部
        this.$router.push({ name: "m-vip" });
      } else if (item === "EagleGroup") {
        // 官网
        this.handleOfWebsite();
      } else if (item === "Política de Privacidade") {
        // 隐私条款
        this.$router.push({ path: "/m-privacy-policy" });
      } else if (item === "Termos de Serviço") {
        // 服务条款
        this.$router.push({ path: "/m-service-terms" });
      } else if (item === "Parceria comercial" || item === "Entre em contato") {
        // 商务合作 || 联系我们
        this.$router.push({ name: "m-service" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.m-footer {
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 0 15px;

  .list {
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .row {
      display: flex;
      flex-direction: column;

      .row-content {
        margin: 0 15px 5px;
        background: #0a1f32;
        height: 34px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;

        .row-name {
          font-size: 11px;
          font-weight: 500;
          color: #ffffff;
        }

        & > img {
          width: 10px;
          height: 10px;
        }
      }

      .item {
        margin: 0 35px;
        text-align: left;
        height: 34px;
        line-height: 34px;
        font-size: 10px;
        font-weight: 500;
        color: #3e7bb0;
      }
    }
  }

  .des1 {
    margin: 30px auto 0;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
  }

  .icons {
    margin-top: 30px;
    width: 195px;
    height: 100px;
    display: flex;
    flex-direction: column;

    & > .row {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .logos {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    height: 25px;

    & > img:last-of-type {
      width: 25px;
      height: 25px;
    }
  }

  .des2 {
    margin: 20px 15px 0;
    font-size: 11px;
    font-weight: normal;
    color: #3e7bb0;
    text-align: left;
  }

  .website {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 70px;
      height: 60px;
    }

    div {
      margin-top: 10px;
      color: #e1c97e;
      font-size: 9px;
      font-weight: normal;
    }
  }

  .author {
    margin: 36px auto 45px;
    color: var(--footer-text-color);
    font-size: 9px;
    font-weight: normal;
  }
}
</style>
