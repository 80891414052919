<template>
  <div class="wrapper" @click="isShowPixTypeList = false">
    <div class="section">
      <!-- <div class="section-title">Método de Saque</div> -->
      <div class="item-bg">
        <div class="pix">PIX</div>
        <img class="pix-icon" src="@/assets/common/pix_icon.webp" alt="" />
      </div>
    </div>
    <div class="section">
      <div class="section-title">Informação de Saque</div>
      <!-- <div class="item-bg">
        <div v-if="userInfo.customer_cert_lock === 1">{{ userInfo.customer_cert }}</div>
        <input v-else v-model="userInfo.customer_cert" type="text" placeholder="CPF" />
        <img v-if="userInfo.customer_cert_lock === 1" src="@/assets/lock.png" alt="" />
      </div>
      <div class="item-bg">
        <div>{{ userInfo.email }}</div>
        <img src="@/assets/lock.png" alt="" />
      </div>
      <div class="item-bg">
        <div>{{ userInfo.phone }}</div>
        <img src="@/assets/lock.png" alt="" />
      </div> -->
      <div class="item-bg" @click.stop="isShowPixTypeList = true">
        <div :class="pixTypeIndex < 0 ? 'none' : 'item'">
          {{ pixTypeIndex === -1 ? "Tipo de Pix" : pixTypeList[pixTypeIndex] }}
        </div>
        <img src="@/assets/header/down_arrow.png" alt="" />
        <div class="pix-type" v-if="isShowPixTypeList === true">
          <div v-for="(type, index) in pixTypeList" :key="index" @click.stop="selPixTypeEvent(index)">
            {{ type }}
          </div>
        </div>
      </div>
      <div class="item-bg">
        <input type="text" v-model="pixId" placeholder="PIX ID (CPF)" :disabled="isLockForPixId" />
      </div>
    </div>
    <div class="section">
      <div class="section-title3" v-if="amountType === '3'">
        Missão de saque do bônus concluída：<span>{{ this.withdrawable }}</span>
      </div>
      <div class="section-title2" v-else>
        <div>Saldo retirável:</div>
        <div class="unit">&nbsp;R$&nbsp;</div>
        <div class="money">{{ withdrawable }}</div>
      </div>
      <div class="item-bg">
        <input
          :disabled="amountType === '3'"
          type="number"
          v-model="withdrawalbelValue"
          :placeholder="'Mínimo' + $global.data.payout_mini_amount + ', Máximo' + $global.data.payout_max_amount"
        />
      </div>
    </div>
    <div class="section" v-if="couponList?.length > 0">
      <div class="section-title">Um cupom de retirada（{{ couponList?.length }}）</div>
      <div class="coupons" v-if="couponList?.length > 0">
        <div class="coupon" v-for="(item, i) in couponList" :key="i" @click="handleOfCoupon(i)">
          <img
            :src="
              couponIndex === i ? require('@/assets/coupon_bg_highlight.png') : require('@/assets/coupon_bg_normal.png')
            "
            alt=""
          />
          <div>{{ item.prize_info.prize_name }}</div>
        </div>
      </div>
    </div>
    <div class="des" v-else>
      Valor mínimo de retirada de <span class="value">&nbsp;R$ {{ $global.data.payout_mini_amount }}</span
      >, sem taxa de retirada
    </div>

    <div class="btn" @click="btnClickHandle">{{ $t("withdrawal") }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: {
        lastname: "",
        firstname: "",
        birthday: "",
        email: "",
        phone: "",
      },
      withdrawalbelValue: "",
      isShowPixTypeList: false,
      pixTypeIndex: -1,
      pixTypeList: ["EMAIL", "PHONE", "CPF"],
      pixId: "",
      isLockForPixId: false,
      couponIndex: -1,
      couponList: [],
      amountType: 0,
    };
  },
  mounted() {
    this.$emit("update:title", "Saque");
    this.withdrawable = this.$route.query.withdrawable;
    this.amountType = this.$route.query.amountType;
    if (this.amountType === "3") {
      this.withdrawalbelValue = this.withdrawable;
    }
    this.fetchUserInfo();
    this.fetchCouponList();
  },
  methods: {
    handleOfCoupon(i) {
      this.couponIndex = i;
    },
    async fetchCouponList() {
      const data = {
        action: "user_pay_coupon_list",
        data: { type: 2 },
      };
      try {
        const response = await this.$http.post("userWallet", data);
        this.couponList = response.data.coupon_list;
      } catch (error) {
        console.log("error", error);
      }
    },
    fetchUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.userInfo = response.data.data;
          if (this.userInfo.phone !== null && this.userInfo.phone !== "") {
            this.userInfo.phone = this.userInfo.phone.substr(2, this.userInfo.phone.length - 2);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    selPixTypeEvent(index) {
      this.pixTypeIndex = index;
      this.isShowPixTypeList = false;
      // CPF && CPF未锁定
      if (index === 2) {
        this.pixId = this.userInfo.customer_cert;
        this.isLockForPixId = this.userInfo.customer_cert_lock === 1;
      } else {
        this.pixId = "";
        this.isLockForPixId = false;
      }
    },
    btnClickHandle() {
      if (this.pixTypeIndex === -1) {
        this.$toast.error("please select pix type");
        return;
      }

      // if (this.withdrawalbelValue < 50) {
      //   this.$toast.error("withdrawalbel value need > 50");
      //   return;
      // }

      this.postWithdrawal(this.withdrawalbelValue);
    },
    postWithdrawal(amount) {
      const data = {
        action: "user_withdrawal",
        data: {
          amount,
          amount_type: this.amountType,
          account_type: this.pixTypeList[this.pixTypeIndex],
          account_num: this.pixId,
          customer_cert: this.userInfo.customer_cert,
          pay_method: "hkpPay",
        },
      };

      if (this.couponIndex > -1) {
        data.data.activity_id = this.couponList[this.couponIndex].id;
      }

      this.$http
        .post("userWallet", data)
        .then(() => {
          this.$router.back();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  background: #06131c;

  .section {
    margin: 10px 15px 0;
    display: flex;
    flex-direction: column;
    .section-title {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      text-align: left;
      display: flex;
      align-items: center;
    }

    .section-title2 {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      text-align: left;
      display: flex;
      align-items: center;

      .unit {
        color: #ffd400;
        font-weight: bold;
      }
      .money {
        color: #ffd400;
        font-weight: bold;
      }
    }

    .section-title3 {
      margin-top: 10px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      text-align: left;

      & > span {
        color: #15ad51;
      }
    }

    .item-bg {
      margin-top: 12px;
      display: flex;
      border-radius: 8px;
      height: 46px;
      background: var(--input-bg-color);
      justify-content: space-between;
      align-items: center;
      padding: 0 12px 0 20px;
      position: relative;

      .pix {
        color: #73ffec;
        font-size: 14px;
        font-weight: 500;
      }

      .pix-icon {
        width: 49px;
        height: 20px;
      }

      img {
        width: 20px;
        height: 20px;
      }

      .item {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        opacity: 0.8;
      }

      .none {
        font-size: 14px;
        font-weight: 600;
        color: #96a7c6;
      }

      input {
        flex: 1;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }

      input::placeholder {
        color: #96a7c6;
      }

      div {
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
      }

      .pix-type {
        z-index: 1;
        left: 0;
        top: 45px;
        width: 100%;
        background: #202020;
        position: absolute;
        display: flex;
        flex-direction: column;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;

        div {
          height: 40px;
          line-height: 40px;
        }
      }
    }

    .coupons {
      margin-top: 10px;
      display: flex;
      overflow-x: scroll;

      .coupon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        img {
          width: 160px;
          height: 60px;
        }

        div {
          position: absolute;
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .des {
    text-align: left;
    margin: 20px 15px 0;
    font-size: 12px;
    font-weight: 300;
    color: #96a7c6;
    opacity: 0.8;

    .value {
      color: #ffd400;
      font-weight: 700;
      opacity: 0.8;
    }
  }

  .btn {
    margin: 10px 15px;
    height: 45px;
    font-size: 18px;
    font-weight: 800;
    color: #0a1f32;
    background: #ffd400;
    border-radius: 8px;
    line-height: 40px;
  }
}
</style>
