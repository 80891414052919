<template>
  <div class="history">
    <div class="wrapper">
      <mine-empty v-if="isShowEmpty"></mine-empty>
      <div class="m-table" v-if="isShowEmpty === false">
        <div class="cell" v-for="(item, index) in list" :key="index">
          <div class="content">
            <div class="line">
              <div class="left-item">{{ $t("date") }}</div>
              <div class="right-item">{{ item.create_time }}</div>
            </div>
            <div class="line">
              <div class="left-item">{{ $t("game") }}</div>
              <div class="right-item">{{ item.game_name_en }}</div>
            </div>
            <div class="line">
              <div class="left-item">{{ $t("bet_number") }}</div>
              <div class="right-item">{{ item.parent_bet_id }}</div>
            </div>
            <div class="line">
              <div class="left-item">{{ $t("status") }}</div>
              <div class="right-item">{{ getGameStatusStr(item.status) }}</div>
            </div>
            <div class="line">
              <div class="left-item">{{ $t("bet_amount") }}</div>
              <div class="right-item">R${{ parseFloat(item.bet_amount).toFixed(2) }}</div>
            </div>
            <div class="line">
              <div class="left-item">{{ $t("multiplier") }}</div>
              <div class="right-item">{{ item.multiple }}</div>
            </div>
            <div class="line">
              <div class="left-item">{{ $t("profit") }}</div>
              <div class="right-item" :class="item.win_amount >= 0 ? 'win' : 'lose'">
                R${{ parseFloat(item.win_amount).toFixed(2) }}
              </div>
            </div>
          </div>
          <div class="btn" @click="checkGame(item)">{{ $t("view_game") }}</div>
        </div>
        <LoadMoreComp
          :curCount="list.length"
          :totalCount="totalPage"
          @loadMoreEvent="fetchGameHistory(page + 1)"
        ></LoadMoreComp>
      </div>
    </div>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:title"],
  data() {
    return {
      list: [],
      page: 0,
      totalPage: 0,
      pageSize: 10,
      isShowEmpty: true,
      isMobile: true,
    };
  },
  components: {
    MineEmpty,
    LoadMoreComp,
  },
  mounted() {
    this.isMobile = this.$tools.isMobile();
    this.$emit("update:title", this.$t("game_history"));
    this.fetchGameHistory(0);
  },
  methods: {
    getGameStatusStr(status) {
      if (status < 0) {
        return "Bet";
      } else if (status > 0) {
        return "Win";
      } else {
        return "Igual";
      }
    },
    checkGame(game) {
      this.$router.push({
        path: "/game-details/" + game.game_id,
        query: {
          name: game.game_name_en,
          pName: game.provider_name,
          pId: game.game_provider,
          launcherUrl: game.poster_url,
        },
      });
    },
    changePage(index) {
      this.fetchGameHistory(index - 1);
    },
    fetchGameHistory(page) {
      const data = {
        action: "game_history",
        data: {
          page,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("gameCenter", data)
        .then((response) => {
          if (this.isMobile) {
            this.list.push(...response.data.list);
          } else {
            this.list = response.data.list;
          }

          this.page = response.data.page;
          this.isShowEmpty = this.list.length === 0;
          this.totalPage = response.data.count;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  display: flex;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .m-table {
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;

      .cell {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 8px;
        background: var(--mine-card-bg-color);

        .content {
          display: flex;
          flex-direction: column;
          padding: 10px 16px;

          .line {
            display: flex;
            .left-item {
              width: 30%;
              margin-bottom: 3px;
              font-size: 14px;
              font-weight: 500;
              color: #3e7bb0;
              text-align: left;
            }

            .right-item {
              margin-bottom: 3px;
              margin-left: 10px;
              font-size: 14px;
              font-weight: 500;
              color: #bcbcbc;
            }

            .win {
              color: #21d567;
              opacity: 0.8;
            }
            .lose {
              color: #d9796b;
              opacity: 0.8;
            }
          }
        }
        .btn {
          height: 40px;
          margin: 15px;
          line-height: 40px;
          background: var(--color-btn-bg);
          border-radius: 8px;
          font-size: 16px;
          font-weight: 800;
          color: var(--color-btn-text);
        }
      }
    }
  }
}
</style>
