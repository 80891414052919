<template>
  <div class="dialog-container">
    <div class="header">
      <div>Dica</div>
      <img class="icon" src="@/assets/account/verify_dialog_icon.webp" alt="" />
      <img class="close" src="@/assets/account/close.webp" alt="" @click="handleOfCloseDialog" />
    </div>
    <div class="title">{{ showTitle() }}</div>
    <div class="item-box" v-if="verifyType === 2">
      <img src="@/assets/account/phone_icon.webp" alt="" />
      <input type="number" placeholder="Digite o número do telefone" v-model="dialogPhone" :disabled="isLockInput" />
    </div>
    <div class="item-box" v-else>
      <img src="@/assets/account/email_icon.webp" alt="" />
      <input type="text" placeholder="Digite o endereço de e-mail" v-model="dialogEmail" :disabled="isLockInput" />
    </div>
    <div class="item-box">
      <img src="@/assets/account/verify_icon.webp" alt="" />
      <input type="number" placeholder="Digite o código de verificação" v-model="msgCode" />
      <div class="btn" @click="handleOfMsgCode">{{ timer !== null ? countDown + "s" : "Obter" }}</div>
    </div>
    <div class="btn" @click="handleOfVerifyOk">Confirmar</div>
  </div>
</template>
<script>
export default {
  props: {
    verifyType: {
      type: Number,
      default: 1, // 1邮箱 2手机号
    },
    phone: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    console.log("this.email", this.email);
    return {
      timer: null,
      captcha: "",
      countDown: 60,
      dialogEmail: this.email,
      dialogEmail2: "",
      dialogPhone: this.phone,
      dialogPhone2: "",
      msgCode: "",
      isLockInput: false,
    };
  },
  watch: {
    email: {
      handler(val) {
        console.log("watch email value", val);
        this.dialogEmail = val;
        if (this.verifyType === 1 && val !== "" && val !== null) {
          this.isLockInput = true;
        }
      },
      immediate: true,
    },
    phone: {
      handler(val) {
        console.log("watch phone value", val);
        this.dialogPhone = val.length > 11 ? val.substr(val.length - 11, 11) : val;
        if (this.verifyType === 2 && val !== "" && val !== null) {
          this.isLockInput = true;
        }
      },
      immediate: true,
    },
    verifyType(val) {
      if (val === 1 && this.email !== "" && this.email !== null) {
        this.isLockInput = true;
      } else if (val === 2 && this.phone !== "" && this.phone !== null) {
        this.isLockInput = true;
      } else {
        this.isLockInput = false;
      }
    },
  },
  methods: {
    showTitle() {
      if (this.title !== "") {
        return this.title;
      }
      return this.verifyType === 2 ? "Vincular telefone" : "Vincular e-mail";
    },
    async handleOfVerifyOk() {
      if (this.msgCode === "") {
        this.$toast.error(this.$t("enter_verification_code"));
        return;
      }

      const params = {
        type: this.verifyType,
        captcha: this.msgCode,
      };

      if (this.verifyType === 1) {
        if (this.dialogEmail !== this.dialogEmail2) {
          this.$toast.error(this.$t("email_verification_failed"));
          return;
        } else {
          params.email = this.dialogEmail;
        }
      } else if (this.verifyType === 2) {
        if (this.dialogPhone !== this.dialogPhone2) {
          this.$toast.error(this.$t("phone_verification_failed"));
          return;
        } else {
          params.phone = "55" + this.dialogPhone;
        }
      }

      const data = {
        action: "verify_user_info",
        data: params,
      };
      try {
        await this.$http.post("userCenter", data);
        this.$emit("endVerifyEvent");
        this.handleOfCloseDialog();
      } catch (error) {
        console.log("error", error);
      }
    },
    async handleOfMsgCode() {
      if (this.timer !== null) {
        this.$toast.error(this.$t("verification_code_sent"));
        return;
      }

      const params = {
        type: this.verifyType,
      };

      if (this.verifyType === 1) {
        if (this.$tools.isValidEmail(this.dialogEmail) === false) {
          this.$toast.error(this.$t("enter_email_address"));
          return;
        } else {
          params.email = this.dialogEmail;
        }
      } else {
        if (this.$tools.isNumeric(this.dialogPhone) === false) {
          this.$toast.error(this.$t("enter_phone_number"));
          return;
        } else {
          params.phone = "55" + this.dialogPhone;
        }
      }

      const data = {
        action: "sendCaptcha",
        data: params,
      };
      try {
        await this.$http.post("userCenter", data);
        if (this.verifyType === 1) {
          this.dialogEmail2 = this.dialogEmail;
        } else {
          this.dialogPhone2 = this.dialogPhone;
        }
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown <= 0) {
            this.countDown = 60;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    },
    handleOfCloseDialog() {
      this.$emit("closeEvent");
      this.dialogEmail = "";
      this.dialogEmail2 = "";
      this.dialogPhone = "";
      this.dialogPhone2 = "";
      this.msgCode = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  background: #0a1f32;
  border-radius: 10px;
  overflow: hidden;

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #132f48;
    height: 48px;

    & > div {
      margin-left: 23px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
    }

    & > .icon {
      width: 67px;
      height: 47px;
    }

    & > .close {
      margin-right: 13px;
      width: 20px;
      height: 20px;
    }
  }

  & > .title {
    margin: 30px 85px 0;
    color: #96a7c6;
    font-size: 12px;
    font-weight: 500;
  }

  & > .item-box {
    align-self: center;
    min-width: 282px;
    max-width: 282px;
    margin: 20px 16px 0;
    background: #06131c;
    border-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    & > img {
      width: 15px;
      height: 15px;
    }

    & > input {
      flex: 1;
      min-width: 0;
      margin: auto 10px;
    }

    & > .btn {
      border-radius: 6px;
      min-width: 58px;
      height: 28px;
      line-height: 28px;
    }
  }

  & > .btn {
    margin: 37px 16px 16px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
  }
}
</style>
