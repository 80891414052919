<template>
  <div class="container">
    <div class="tabbar">
      <div class="item" v-for="(item, i) in list" :key="i" @click="tabItemHandle(item)">
        <div :class="i !== 2 ? 'img_box' : index === i ? 'img_box2_h' : 'img_box2'">
          <img :src="index === i ? item.icon_h : item.icon" alt="" />
          <div :class="index === i ? 'txt_h' : 'txt'">{{ item.title }}</div>
        </div>
        <div v-if="tips[i] > 0" class="tip">{{ tips[i] }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      index: 0,
      tips: [0, 0, 0, 0, 0],
      list: this.$global.tabs,
    };
  },
  mounted() {
    this.$eventBus.on("emitOfChangeTab", this.changeTab);
    this.$eventBus.on("logoutSuccessHandle", () => {
      this.index = 0;
    });
    var obj = this.list.find((item) => {
      return item.path === window.location.pathname;
    });
    if (window.location.pathname.includes("/wallet2")) {
      this.index = 2;
    } else if (obj !== undefined) {
      this.index = obj.index;
    }
    if (this.$store.getters.isLoggedIn) {
      this.fetchListData();
    }
  },
  methods: {
    async fetchListData() {
      const data = {
        action: "promotion_activity_list",
        data: {},
      };
      try {
        const response = await this.$http.post("activity", data);
        const vip_activity = response.data.vip_activity;
        if (vip_activity.length > 0) {
          const info = vip_activity[0].info;
          let sum = 0;
          for (let i = 0; i < info.length; i++) {
            const item = info[i];
            for (let j = 0; j < item.value.length; j++) {
              sum += item.value[j].value;
            }
          }
          this.tips[1] = sum;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    changeTab(val) {
      const obj = this.list.find((item) => {
        return item.name === val;
      });
      if (obj) {
        this.tabItemHandle(obj);
      }
    },
    tabItemHandle(item) {
      const isLoggedIn = this.$store.getters.isLoggedIn;
      if (isLoggedIn) {
        this.index = item.index;
        this.$router.push({ name: item.name });
        if (this.index === 4) {
          // 我的埋点
          this.$tools.userTrackReport({ track_id: 23 });
        } else {
          // 其他埋点
          this.$tools.userTrackReport({ track_id: 15 + this.index + "" });
        }
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: var(--tab-bar-height);
  display: flex;
  flex-direction: column;
  align-items: center;

  .tabbar {
    background: var(--tab-bg-color);
    width: 760px;
    margin-top: 3.5px;
    display: flex;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      & > .tip {
        position: absolute;
        top: 0;
        left: 60%;
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        border: 0.5px solid #ff0000;
        border-radius: 50%;
        background: #ffffff;
        color: #ff0000;
        font-size: 10px;
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .img_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 53px;

        img {
          width: 17px;
          height: 17px;
        }

        .txt {
          margin-top: 5px;
          color: var(--tab-text-normal-color);
          font-size: 11px;
          font-weight: 300;
        }

        .txt_h {
          margin-top: 5px;
          color: var(--primary-text-color);
          font-size: 11px;
          font-weight: 500;
        }
      }
    }

    .item:nth-child(3) {
      .img_box2 {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #112e47;
        border-radius: 10px;
        width: 60px;
        height: 53px;

        img {
          width: 17px;
          height: 17px;
        }
      }

      .img_box2_h {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #ffd400;
        border-radius: 10px;
        width: 60px;
        height: 53px;

        img {
          width: 17px;
          height: 17px;
        }
      }

      .txt {
        margin-top: 5px;
        color: var(--tab-text-normal-color);
        font-size: 11px;
        font-weight: 500;
      }

      .txt_h {
        margin-top: 5px;
        color: #2c0c50;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 768px) {
    .tabbar {
      width: 100%;
    }
  }
}
</style>
