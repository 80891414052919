<template>
  <div class="category">
    <div class="wrapper">
      <div
        class="item"
        :class="index === i ? 'select' : 'normal'"
        v-for="(item, i) in list"
        :key="i"
        @click="onTapItem(i)"
      >
        {{ cateTitle(item) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    list: Array,
    isJumpEvent: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:index"],
  data() {
    return {};
  },
  mounted() {
    const i = this.list.findIndex((item) => {
      return window.location.pathname.indexOf(item.path) > -1;
    });
    if (i !== -1) {
      this.$emit("update:index", i);
    }
  },
  methods: {
    /**
     * 点击事件
     * @param {*} i
     */
    onTapItem(i) {
      this.$emit("update:index", i);
      this.$emit("changeEvent", i);
      if (this.isJumpEvent) {
        this.$router.replace({ name: this.list[i].path });
      }
    },
    cateTitle(item) {
      if ("catagory_name" in item) {
        return item.catagory_name;
      } else if ("title" in item) {
        return item.title;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  flex-direction: column;

  .wrapper {
    background: var(--cate-normal-bg);
    height: 45px;
    border-radius: 8px;
    display: flex;
    align-items: center;

    .item {
      flex: 1;
      height: 45px;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .select {
      color: var(--cate-text-selected-color);
      background: var(--cate-selected-bg);
    }

    .normal {
      color: var(--cate-text-normal-color);
      background: var(--cate-normal-bg);
    }
  }
}
</style>
