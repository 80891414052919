<template>
  <div class="app-wrapper">
    <router-view :registerGiveCoins="register_give_coins"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      globalTimer: null,
      countDown: 600,
      register_give_coins: 0,
      hbTimer: null,
    };
  },
  created() {
    this.fireHeartBeat();
    // this.getCountry();
    this.getGlobalData();
    this.$eventBus.on("fireGlobalTimer", () => {
      if (this.globalTimer === null) {
        this.globalTimer = setInterval(() => {
          this.countDown--;
          this.$eventBus.emit("globalCountDown", this.countDown);
          if (this.countDown === 0) {
            this.clearGlobalTimer();
          }
        }, 1000);
      }
    });
    this.$eventBus.on("clearGlobalTimer", () => {
      this.clearGlobalTimer();
    });
    this.$eventBus.on("logoutSuccessHandle", () => {
      this.clearGlobalTimer();
    });
    this.$eventBus.on("loginSuccessHandle", this.sendHeartBeat);
  },
  mounted() {
    // 在页面即将刷新时执行的操作
    window.addEventListener("beforeunload", this.beforeUnloadHandle);
    // 阻止双指缩放
    document.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  },
  beforeUnmount() {
    this.clearGlobalTimer();
    this.clearHbTimer();
  },
  methods: {
    clearHbTimer() {
      if (this.hbTimer) {
        clearInterval(this.hbTimer);
      }
    },
    fireHeartBeat() {
      this.hbTimer = setInterval(() => {
        const isLoggedIn = this.$store.getters.isLoggedIn;
        if (isLoggedIn) {
          this.sendHeartBeat();
        }
      }, 60000);
    },
    async sendHeartBeat() {
      const data = {
        action: "user_heartbeat_report",
        data: {},
      };
      this.$http.post("heartbeat", data);
    },
    clearGlobalTimer() {
      if (this.globalTimer) {
        clearInterval(this.globalTimer);
      }
      this.countDown = 600;
    },
    /**
     * 在页面即将刷新时执行的操作
     */
    beforeUnloadHandle() {
      console.log("Page is about to be refreshed!");
      this.$store.commit("clearTempCache");
      this.clearGlobalTimer();
      this.clearHbTimer();
    },
    /**
     * 获取全局数据
     */
    getGlobalData() {
      this.$http
        .post("load", {})
        .then((response) => {
          this.$store.commit("setChannel", response.data.resources.channel);
          this.register_give_coins = response.data.resources.register_give_coins | 0;
          this.$global.data = response.data.resources;
          this.$global.is_login = response.data.is_login;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getCountry() {
      const that = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          let lat = position.coords.latitude;
          let lon = position.coords.longitude;
          fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`
          )
            .then((response) => response.json())
            .then((data) => {
              that.$global.country = data.countryName;
            })
            .catch((error) => console.log(error));
        });
      }
    },
  },
};
</script>

<style>
#app {
  touch-action: manipulation; /* 防止双击放大而不禁止缩放 */
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-wrapper {
  background: #06131c;
  max-width: var(--max-width);
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  #app {
    width: 100vw;
    height: 100vh;
  }
}
</style>
