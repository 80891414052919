// 配置路由的地方
import { createRouter, createWebHistory } from "vue-router";

// 使用插件
import HomePage from "@/pages/HomePage.vue";
import CasinoPage from "@/pages/containers/CasinoPage.vue";
import GameList from "@/pages/containers/GameList.vue";
import GameDetails from "@/pages/containers/GameDetailsPage.vue";
import AccountPage from "@/pages/mine/AccountPage.vue";
import AwardPage from "@/pages/mine/AwardPage.vue";
import CustomerService from "@/pages/mine/CustomerService.vue";
import EmailPage from "@/pages/mine/EmailPage.vue";
import InvitePage from "@/pages/mine/InvitePage.vue";
import PlayHistory from "@/pages/mine/PlayHistory.vue";
import SettingPage from "@/pages/mine/SettingPage.vue";
import TransactionRecords from "@/pages/mine/TransactionRecords.vue";
import WalletPage from "@/pages/mine/WalletPage.vue";
import MessagePage from "@/pages/mine/MessagePage.vue";
import GeneralPage from "@/pages/mine/account/GeneralPage.vue";
import SecurePage from "@/pages/mine/account/SecurePage.vue";
import FreezePage from "@/pages/mine/account/FreezePage.vue";
import CommissionPage from "@/pages/mine/invite/CommissionPage.vue";
import TeamPage from "@/pages/mine/invite/TeamPage.vue";
import InviteLog from "@/pages/mine/invite/InviteLog.vue";
import TransactionFlow from "@/pages/mine/transaction/TransactionFlow.vue";
import DepositRecords from "@/pages/mine/transaction/DepositRecords.vue";
import WithdrawalsRecords from "@/pages/mine/transaction/WithdrawalsRecords.vue";
import SportsPage from "@/pages/containers/SportsPage.vue";
import MobileMine from "@/pages/mobile/MobileMine.vue";
import MobileUserHome from "@/pages/mobile/MobileUserHome.vue";
import MembershipPage from "@/pages/mine/MembershipPage.vue";
import VipPage from "@/pages/mine/membership/VipPage.vue";
import ImmunitiesPage from "@/pages/mine/membership/ImmunitiesPage.vue";
import ClubPage from "@/pages/mine/membership/ClubPage.vue";
import MobilePrivacyPolicy from "@/pages/mobile/MobilePrivacyPolicy.vue";
import MobileServiceTerms from "@/pages/mobile/MobileServiceTerms.vue";
import MobileDepositPage from "@/pages/mobile/MobileDepositPage.vue";
import MobilePlayingPage from "@/pages/mobile/MobilePlayingPage.vue";
import MobileVerificationInfo1 from "@/pages/mobile/MobileVerificationInfo1.vue";
import MobileVerificationInfo2 from "@/pages/mobile/MobileVerificationInfo2.vue";
import ActivityPage from "@/pages/containers/ActivityPage.vue";
import ActivityDetail from "@/pages/containers/ActivityDetail.vue";
import LauncherPage from "@/pages/containers/LauncherPage.vue";
import MobileTagList from "@/pages/mobile/MobileTagList.vue";
import MobileSearchDetail from "@/pages/mobile/MobileSearchDetail.vue";
import WalletPage2 from "@/pages/mine/WalletPage2.vue";
import WithdrawalPage from "@/pages/mine/wallet/WithdrawalPage.vue";
import MobileVipPage from "@/pages/mobile/MobileVipPage.vue";
import MessageDetail from "@/pages/mine/MessageDetail.vue";
import MobileRafflePage from "@/pages/mobile/MobileRafflePage.vue";
import DailyActivity from "@/pages/containers/DailyActivity.vue";
import FirstRechargeActivity from "@/pages/containers/FirstRechargeActivity.vue";
import FirstDepositPage from "@/pages/containers/FirstDepositPage.vue";
import ActivityCommonDetail from "@/pages/containers/ActivityCommonDetail.vue";
import RedemptionCode from "@/pages/mine/RedemptionCode.vue";
import MobilePayPage from "@/pages/mobile/MobilePayPage.vue";
import AwardTaskPage from "@/pages/mine/AwardTaskPage.vue";
import PiggyBankPage from "@/pages/mine/PiggyBankPage.vue";
import BonusRecords from "@/pages/mine/transaction/BonusRecords.vue";
import NewerTask from "@/pages/containers/NewerTask.vue";
import DailyCheckInPage from "@/pages/containers/DailyCheckInPage.vue";
import AvatarPage from "@/pages/mine/AvatarPage.vue";
import MobileVipRebatePage from "@/pages/mobile/MobileVipRebatePage.vue";
import MobileVipClubPage from "@/pages/mobile/MobileVipClubPage.vue";
import LoginPage from "@/pages/containers/LoginPage.vue";
import AppDownloadPage from "@/pages/containers/AppDownloadPage.vue";
import SetPassword from "@/pages/mine/SetPassword.vue";
import AddPixPage from "@/pages/mine/wallet/AddPixPage.vue";
import BetTaskPage from "@/pages/mine/wallet/BetTaskPage.vue";

// 配置路由
const router = createRouter({
  history: createWebHistory(),
  // 配置路由
  routes: [
    {
      // 重定向home
      path: "/",
      redirect: {
        name: "launcher",
      },
    },
    {
      path: "/launcher",
      name: "launcher",
      component: LauncherPage,
    },
    {
      path: "/m-user-home",
      name: "m-user-home",
      component: MobileUserHome,
      children: [
        {
          path: "/bet-task",
          name: "bet-task",
          component: BetTaskPage,
        },
        {
          path: "/add-pix",
          name: "add-pix",
          component: AddPixPage,
        },
        {
          path: "/set-password",
          name: "set-password",
          component: SetPassword,
        },
        {
          path: "/app-download-page",
          name: "app-download-page",
          component: AppDownloadPage,
        },
        {
          path: "/login-page",
          name: "login-page",
          component: LoginPage,
        },
        {
          path: "/avatar-page",
          name: "avatar-page",
          component: AvatarPage,
        },
        {
          path: "/daily-check-in",
          name: "daily-check-in",
          component: DailyCheckInPage,
        },
        {
          path: "/newer-task",
          name: "newer-task",
          component: NewerTask,
        },
        {
          path: "/piggy-bank-page",
          name: "piggy-bank-page",
          component: PiggyBankPage,
        },
        {
          path: "/award-task-page",
          name: "award-task-page",
          component: AwardTaskPage,
        },
        {
          path: "/m-pay-page",
          name: "m-pay-page",
          component: MobilePayPage,
        },
        {
          path: "/redemption-code",
          name: "redemption-code",
          component: RedemptionCode,
        },
        {
          path: "/activity-common-detail/:id",
          name: "activity-common-detail",
          component: ActivityCommonDetail,
        },
        {
          path: "/m-vip",
          name: "m-vip",
          component: MobileVipPage,
        },
        {
          path: "/m-vip-rebate",
          name: "m-vip-rebate",
          component: MobileVipRebatePage,
        },
        {
          path: "/m-vip-club",
          name: "m-vip-club",
          component: MobileVipClubPage,
        },
        {
          path: "/m-raffle",
          name: "m-raffle",
          component: MobileRafflePage,
        },
        {
          path: "/m-search-detail",
          name: "m-search-detail",
          component: MobileSearchDetail,
        },
        {
          path: "/m-tag-list/:id",
          name: "m-tag-list",
          component: MobileTagList,
        },
        {
          path: "/first-recharge-activity/:id",
          name: "first-recharge-activity",
          component: FirstRechargeActivity,
        },
        {
          path: "/first-deposit-page/:id",
          name: "first-deposit-page",
          component: FirstDepositPage,
        },
        {
          path: "/daily-activity/:id",
          name: "daily-activity",
          component: DailyActivity,
        },
        {
          path: "/activity-detail/:id",
          name: "activity-detail",
          component: ActivityDetail,
        },
        {
          path: "/m-verification-info1",
          name: "m-verification-info1",
          component: MobileVerificationInfo1,
        },
        {
          path: "/m-verification-info2",
          name: "m-verification-info2",
          component: MobileVerificationInfo2,
        },
        {
          path: "/m-privacy-policy",
          name: "m-privacy-policy",
          component: MobilePrivacyPolicy,
        },
        {
          path: "/m-service-terms",
          name: "m-service-terms",
          component: MobileServiceTerms,
        },
        {
          path: "/m-game-list/:info",
          name: "m-game-list",
          component: GameList,
        },
        {
          path: "/m-wallet",
          name: "m-wallet",
          component: WalletPage,
        },
        {
          path: "/m-deposit-page",
          name: "m-deposit-page",
          component: MobileDepositPage,
        },
        {
          path: "/m-withdrawal-page",
          name: "m-withdrawal-page",
          component: WithdrawalPage,
        },
        {
          path: "/m-wallet2",
          name: "m-wallet2",
          component: WalletPage2,
          // children: [
          //   {
          //     path: "/m-wallet2/m-deposit-page",
          //     name: "m-deposit-page",
          //     component: MobileDepositPage,
          //   },
          //   {
          //     path: "/m-wallet2/m-withdrawal-page",
          //     name: "m-withdrawal-page",
          //     component: WithdrawalPage,
          //   },
          // ],
        },
        {
          path: "/m-account",
          name: "m-account",
          component: AccountPage,
          children: [
            {
              path: "/m-account/m-general",
              name: "m-general",
              component: GeneralPage,
            },
            {
              path: "/m-account/m-secure",
              name: "m-secure",
              component: SecurePage,
            },
            {
              path: "/m-account/m-freeze",
              name: "m-freeze",
              component: FreezePage,
            },
          ],
        },
        {
          path: "/m-award",
          name: "m-award",
          component: AwardPage,
        },
        {
          path: "/m-invite",
          name: "m-invite",
          component: InvitePage,
          children: [
            {
              path: "/m-invite/m-commission",
              name: "m-commission",
              component: CommissionPage,
            },
            {
              path: "/m-invite/m-team",
              name: "m-team",
              component: TeamPage,
            },
            {
              path: "/m-invite/m-log",
              name: "m-log",
              component: InviteLog,
            },
          ],
        },
        {
          path: "/m-history",
          name: "m-history",
          component: PlayHistory,
        },
        {
          path: "/m-setting",
          name: "m-setting",
          component: SettingPage,
        },
        {
          path: "/m-transaction",
          name: "m-transaction",
          component: TransactionRecords,
          children: [
            {
              path: "/m-transactin/m-flow",
              name: "m-flow",
              component: TransactionFlow,
            },
            {
              path: "/m-transaction/m-deposit",
              name: "m-deposit",
              component: DepositRecords,
            },
            {
              path: "/m-transaction/m-withdrawals",
              name: "m-withdrawals",
              component: WithdrawalsRecords,
            },
            {
              path: "/m-transaction/m-bonus",
              name: "m-bonus",
              component: BonusRecords,
            },
          ],
        },
        {
          path: "/m-service",
          name: "m-service",
          component: CustomerService,
        },
        {
          path: "/m-message",
          name: "m-message",
          component: MessagePage,
        },
        {
          path: "/m-message-detail/:id",
          name: "m-message-detail",
          component: MessageDetail,
        },
      ],
    },
    {
      path: "/m-playing/:id",
      name: "mPlaying",
      component: MobilePlayingPage,
    },
    {
      path: "/m-game-details/:game",
      name: "m-game-details",
      component: GameDetails,
    },
    {
      path: "/",
      component: HomePage,
      children: [
        {
          path: "/casino",
          name: "casino",
          component: CasinoPage,
        },
        {
          path: "/activity",
          name: "activity",
          component: ActivityPage,
        },
        {
          path: "/membership",
          name: "membership",
          component: MembershipPage,
          children: [
            {
              path: "/membership/vip",
              name: "vip",
              component: VipPage,
            },
            {
              path: "/membership/immunities",
              name: "immunities",
              component: ImmunitiesPage,
            },
            {
              path: "/membership/club",
              name: "club",
              component: ClubPage,
            },
          ],
        },
        {
          path: "/m-mine",
          name: "mMine",
          component: MobileMine,
        },
        {
          path: "/sports",
          name: "sports",
          component: SportsPage,
        },
        {
          path: "/game-details/:game",
          name: "game-details",
          component: GameDetails,
        },
        {
          path: "/game-list/:info",
          name: "game-list",
          component: GameList,
        },
        {
          path: "/account",
          name: "account",
          component: AccountPage,
          children: [
            {
              path: "/account/general",
              name: "general",
              component: GeneralPage,
            },
            {
              path: "/account/secure",
              name: "secure",
              component: SecurePage,
            },
            {
              path: "/account/freeze",
              name: "freeze",
              component: FreezePage,
            },
          ],
        },
        {
          path: "/award",
          name: "award",
          component: AwardPage,
        },
        {
          path: "/service",
          name: "service",
          component: CustomerService,
        },
        {
          path: "/email",
          name: "email",
          component: EmailPage,
        },
        {
          path: "/invite",
          name: "invite",
          component: InvitePage,
          children: [
            {
              path: "/invite/commission",
              name: "commission",
              component: CommissionPage,
            },
            {
              path: "/invite/team",
              name: "team",
              component: TeamPage,
            },
            {
              path: "/invite/log",
              name: "log",
              component: InviteLog,
            },
          ],
        },
        {
          path: "/vip",
          name: "vip",
          component: MobileVipPage,
        },
        {
          path: "/history",
          name: "history",
          component: PlayHistory,
        },
        {
          path: "/setting",
          name: "setting",
          component: SettingPage,
        },
        {
          path: "/transaction",
          name: "transaction",
          component: TransactionRecords,
          children: [
            {
              path: "/transactin/flow",
              name: "flow",
              component: TransactionFlow,
            },
            {
              path: "/transaction/deposit",
              name: "deposit",
              component: DepositRecords,
            },
            {
              path: "/transaction/withdrawals",
              name: "withdrawals",
              component: WithdrawalsRecords,
            },
            {
              path: "/transaction/bonus",
              name: "bonus",
              component: BonusRecords,
            },
          ],
        },
        {
          path: "/wallet",
          name: "wallet",
          component: WalletPage,
        },
        // {
        //   path: "/wallet2",
        //   name: "wallet2",
        //   component: WalletPage2,
        //   children: [
        //     {
        //       path: "/wallet2/deposit-page",
        //       name: "deposit-page",
        //       component: MobileDepositPage,
        //     },
        //     {
        //       path: "/wallet2/withdrawal-page",
        //       name: "withdrawal-page",
        //       component: WithdrawalPage,
        //     },
        //   ],
        // },
        {
          path: "/message",
          name: "message",
          component: MessagePage,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
