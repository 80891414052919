<template>
  <div class="wrapper">
    <div class="bg">
      <div class="top">
        <div class="title">{{ title }}</div>
        <img class="close" src="@/assets/login/close_icon.png" alt="" @click="handleOfClose" />
      </div>
      <pre class="content" ref="scrollContainer">{{ content }}</pre>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed, ref } from "vue";
import global from "@/utils/global";

const props = defineProps(["aId"]);

const content = computed(() => {
  if (props.aId == "1") {
    const { privacyPolicy } = global;
    return privacyPolicy;
  } else {
    const { termsService } = global;
    return termsService;
  }
});

const title = computed(() => {
  if (props.aId == "1") {
    return "Privacy Policy";
  } else {
    return "Terms of Service";
  }
});

const emit = defineEmits([]);
const scrollContainer = ref(null);

function handleOfClose() {
  if (scrollContainer.value) {
    scrollContainer.value.scrollTop = 0;
  }
  emit("closeEvent");
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .bg {
    flex: 1;
    background: #33439e;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .top {
      background: url("@/assets/agreement/top_bg.webp");
      background-size: 100%;
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-between;

      .title {
        margin-top: 8px;
        margin-left: 34px;
        text-align: left;
        color: var(--login-title-color);
        font-size: 25px;
        font-weight: 500;
      }

      .close {
        margin-top: 16px;
        margin-right: 14px;
        width: 20px;
        height: 20px;
      }
    }

    .content {
      height: 600px;
      overflow-y: scroll;
      margin: 17px;
      text-align: left;
      color: #ffffff;
      font-size: 10px;
      font-weight: 300;
      white-space: pre-wrap;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
