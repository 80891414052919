<template>
  <div>
    <mine-empty></mine-empty>
  </div>
</template>

<script>
import MineEmpty from '@/components/MineEmpty.vue';
export default {
  components: { MineEmpty },
};
</script>

<style lang="scss" scoped></style>
