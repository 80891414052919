<template>
  <div class="activity-detail">
    <div class="card0" v-if="activityInfo.activity_type === 5">
      <img v-if="activityInfo.imageUrl" :src="activityInfo.imageUrl" alt="" />
      <div class="title1">atividades diárias</div>
      <div class="title2">{{ activityInfo.title }}</div>
      <div class="date">Período de validade: No dia do registro</div>
    </div>
    <div class="card1" v-else-if="activityInfo.activity_type === 2">
      <img v-if="activityInfo.imageUrl" :src="activityInfo.imageUrl" alt="" />
      <div class="date">{{ activityInfo.date }}</div>
      <div class="title">{{ activityInfo.title }}</div>
    </div>
    <div class="name" v-if="activityInfo.activity_type === 5">
      <img src="@/assets/marquee/gift.png" alt="" />
      <div>{{ activityInfo.title }}</div>
    </div>
    <div class="icons" v-else-if="activityInfo.activity_type === 2">
      <div v-for="n in 3" :key="n" class="icon">
        <img src="@/assets/marquee/gift.png" alt="" />
      </div>
    </div>
    <div class="money" v-if="activityInfo.activity_type === 5 && activityInfo.is_join === false">
      Não participou do evento
    </div>
    <div class="money" v-else>R$:{{ parseFloat(activityInfo.min_prize_value || 0).toFixed(2) }}</div>
    <div class="rule" v-html="activityInfo.activity_details"></div>
    <div
      v-if="activityInfo.activity_type === 5"
      class="btn"
      :class="isReceive ? 'join' : 'not-join'"
      @click="handleOfBtn"
    >
      {{ btnTitle }}
    </div>
    <div
      v-else-if="activityInfo.activity_type === 2"
      class="btn"
      :class="isJoin ? 'join' : 'not-join'"
      @click="handleOfBtn"
    >
      {{ btnTitle }}
    </div>
    <dialog ref="dialog">
      <div class="dialog-box">
        <div class="dialog-title">Parabéns</div>
        <div class="dialog-money">R$:{{ parseFloat(activityInfo.min_prize_value).toFixed(2) }}</div>
        <div class="dialog-des">A recompensa foi depositada na carteira</div>
        <div class="dialog-btn" @click="handleOfDialog">Bom</div>
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activityInfo: { min_prize_value: 0 },
      aId: "",
      isJoin: false,
      isReceive: false,
    };
  },
  mounted() {
    this.$emit("update:title", "Atividades operacionais");
    this.aId = this.$route.params.id;
    this.fetchDataDetail(this.aId);
  },
  methods: {
    handleOfDialog() {
      this.$refs.dialog.close();
    },
    handleOfBtn() {
      if (this.activityInfo.activity_type === 5) {
        if (this.activityInfo.is_join) {
          // 已参加
          if (this.activityInfo.is_receive === false) {
            // 未领取
            this.$eventBus.emit("showDeposit", "deposit");
          } else {
            // 已领取
            return;
          }
        } else {
          // 未参加
          this.$router.push({ name: "casino", query: { ext: "preHongbaoRain" } });
        }
      } else if (this.activityInfo.activity_type === 2 && !this.isJoin) {
        const data = {
          action: "claim_parize",
          data: { activity_id: this.aId },
        };
        this.$http
          .post("activity", data)
          .then(() => {
            this.isJoin = true;
            this.$refs.dialog.showModal();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    fetchDataDetail(id) {
      const data = {
        action: "join_activity",
        data: { activity_id: id },
      };
      this.$http
        .post("activity", data)
        .then((response) => {
          this.activityInfo = response.data.activity_info;
          if (this.activityInfo.prize_image) {
            this.activityInfo.imageUrl = this.$global.data.cdn_url + this.activityInfo.prize_image;
          } else {
            this.activityInfo.imageUrl = "";
          }
          this.activityInfo.date = this.$tools.formattedDate(this.activityInfo.factor_date);
          this.isJoin = this.activityInfo.is_join;
          this.isReceive = this.activityInfo.is_receive;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    formattedText() {
      if (this.activityInfo) {
        return this.activityInfo.activity_details.replace(/\n/g, "<br>");
      } else {
        return "";
      }
    },
    btnTitle() {
      if (this.activityInfo.activity_type === 5) {
        if (this.activityInfo.is_receive) {
          return "Recebeu";
        } else {
          if (this.activityInfo.is_join) {
            return "Vai recargar";
          } else {
            return "participou do evento";
          }
        }
      } else if (this.activityInfo.activity_type === 2) {
        return this.isJoin ? "Já coletado" : "obter";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-detail {
  display: flex;
  flex-direction: column;

  .card0 {
    margin: 15px 15px 0;
    background: linear-gradient(148deg, #c0440a 22%, #9000ff 220%);
    height: 120px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 10px;
    position: relative;

    img {
      position: absolute;
      width: 84px;
      height: 120px;
      top: 0;
      right: 0;
    }

    .title1 {
      color: #daeeff;
      font-size: 16px;
      font-weight: 500;
    }
    .title2 {
      color: var(--primary-text-color);
      font-size: 25px;
      font-weight: 500;
    }
    .date {
      color: #ffffff;
      font-size: 12px;
      font-weight: 300;
    }
  }

  .card1 {
    margin: 15px 15px 0;
    background: linear-gradient(148deg, #2a4499 22%, #d331f7 220%);
    height: 120px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    img {
      position: absolute;
      width: 165px;
      height: 120px;
      top: 0;
      right: 0;
    }

    .date {
      margin-top: 28px;
      margin-left: 15px;
      color: #daeeff;
      font-size: 23px;
      font-weight: 900;
    }

    .title {
      margin-top: 30px;
      margin-left: 15px;
      color: #6ebeff;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .name {
    margin: 30px 30px 15px;
    display: flex;

    img {
      width: 20px;
      height: 20px;
    }

    div {
      margin-left: 5px;
      color: #eccd81;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .icons {
    margin: 30px 30px 15px;
    display: flex;

    .icon {
      display: flex;
      margin-right: 10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .money {
    margin-left: 15px;
    margin-top: 15px;
    text-align: left;
    color: var(--primary-text-color);
    font-size: 32px;
    font-weight: 500;
  }

  .rule {
    margin-left: 15px;
    margin-top: 15px;
    color: white;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }

  .btn {
    margin: 50px 15px 0;
    height: 45px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
  }

  .join {
    background: var(--color-btn-bg);
    color: var(--color-btn-text);
  }

  .not-join {
    background: var(--color-btn-disable-bg);
    color: #3e7bb0;
  }
}
.dialog-box {
  height: 242px;
  border-radius: 10px;
  background: #107439;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .dialog-title {
    color: var(--primary-text-color);
    font-size: 18px;
    font-weight: bold;
  }

  .dialog-money {
    color: var(--primary-text-color);
    font-size: 32px;
    font-weight: 500;
  }

  .dialog-des {
    margin-left: 40px;
    margin-right: 40px;
    color: #ffffff;
    font-size: 18px;
    font-weight: normal;
  }

  .dialog-btn {
    background: var(--color-btn-bg);
    width: 270px;
    height: 40px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-btn-text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
