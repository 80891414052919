<template>
  <div class="wrapper">
    <swiper
      :slidesPerView="1"
      :spaceBetween="0"
      :loop="true"
      :loopedSlides="auto"
      :centeredSlides="true"
      :centeredSlidesBounds="true"
      :pagination="{
        clickable: true,
      }"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :speed="500"
      :navigation="false"
      :modules="modules"
      @slideChangeTransitionEnd="onSlideChange"
      class="mySwiper"
    >
      <swiper-slide class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
        <img :src="isMobile ? item.phone_picture : item.picture" alt="" @click="handleOfClick(item)" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import tools from "@/utils/tools";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    bannerList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isMobile: this.$tools.isMobile(),
    };
  },
  setup(props) {
    const spaceBetween = 10;
    const onProgress = (e) => {
      const [progress] = e.detail;
      console.log(progress);
    };

    const onSlideChange = (e) => {
      const banner = props.bannerList[e.realIndex];
      if (banner === undefined || banner === null) {
        return;
      }
      tools.userTrackReport({ track_id: 11, track_link_id: banner.id });
    };

    return {
      spaceBetween,
      onProgress,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  methods: {
    handleOfClick(item) {
      this.$tools.userTrackReport({ track_id: 12, track_link_id: item.id });
      if (item.url === "") {
        return;
      }

      if (this.$store.getters.isLoggedIn) {
        const tabs = this.$global.tabs;
        const obj = tabs.find((tab) => {
          return tab.path === item.url;
        });
        if (obj) {
          this.$eventBus.emit("emitOfChangeTab", obj.name);
        } else {
          this.$router.push(item.url);
        }
      } else {
        this.$eventBus.emit("isNeedLogin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  --swiper-pagination-color: white;
  --swiper-pagination-bottom: 0px;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;

  width: 100%;
  display: flex;
  flex-direction: column;
  .mySwiper {
    margin: 0 15px;
    display: flex;

    .swiper-slide {
      display: flex;
      flex-direction: column;

      img {
        height: 100%;
        border-radius: 16px;
      }
    }
  }
}
</style>
