<template>
  <div class="empty">
    <div class="wrapper">
      <img src="../assets/dice.png" alt="" />
      <div>Vazio</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  .wrapper {
    width: 100%;
    height: 350px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 80px;
      height: 80px;
    }
    div {
      margin-top: 20px;
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      opacity: 0.4;
    }
  }
}
</style>
