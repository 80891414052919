<template>
  <div class="setting">
    <div class="wrapper">
      <div class="title">{{ $t("settings") }}</div>
      <div class="item">
        <div>接受通知消息 接收有关存款和取款的电子邮件通知</div>
        <custom-switch
          :isOpen="isReceiveNoifications"
          @change-check="changeCheckBox1()"
        ></custom-switch>
      </div>
      <div class="item">
        <div>接受平台通知消息</div>
        <custom-switch
          :isOpen="isReceivePlatformNotifications"
          @change-check="changeCheckBox2()"
        ></custom-switch>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSwitch from "@/components/CustomSwitch.vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["update:title"],
  data() {
    return {
      isReceiveNoifications: true,
      isReceivePlatformNotifications: false,
    };
  },
  components: { CustomSwitch },
  mounted() {
    this.$emit("update:title", this.$t("settings"));
  },
  methods: {
    changeCheckBox1(isChecked) {
      this.isReceiveNoifications = isChecked;
    },
    changeCheckBox2(isChecked) {
      this.isReceivePlatformNotifications = isChecked;
    },
  },
};
</script>

<style lang="scss" scoped>
.setting {
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 140px;
  }

  .title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    text-align: left;
  }

  .item {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background: #202020;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    border-radius: 8px;
    font-size: 12px;
    color: rgba($color: #ffffff, $alpha: 0.8);
  }

  @media (max-width: 768px) {
    .wrapper {
      margin: 0 15px;
    }

    .wrapper .title {
      display: none;
    }
  }
}
</style>
