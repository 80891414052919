<template>
  <div class="wrapper">
    <div class="name">
      <input type="text" v-model="userInfo.real_name" placeholder="Nome" />
    </div>
    <div class="cpf">
      <input type="text" v-model="userInfo.customer_cert" placeholder="CPF" :readonly="userInfo.customer_cert_lock" />
    </div>
    <div class="email">
      <input type="text" v-model="userInfo.email" placeholder="Email" />
    </div>
    <div class="phone">
      <input type="text" v-model="userInfo.phone" placeholder="Celular" />
    </div>
    <div class="validate">
      <input type="text" v-model="code" placeholder="Validate" />
      <button @click="sendCodeHandle">{{ codeTitle }}</button>
    </div>
    <div class="button" @click="saveHandle">{{ $t("save") }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      userInfo: {
        real_name: "",
        customer_cert: "",
        email: "",
        phone: "",
      },
      code: "",
      codeTitle: this.$t("verification_code"),
      phone2: "",
      withdrawable: "",
      amountType: 1,
      timer: null,
      countDown: 60,
    };
  },
  mounted() {
    this.$emit("update:title", "Verificação de Segurança");
    this.withdrawable = this.$route.query.withdrawable;
    this.amountType = this.$route.query.amountType;
    this.fetchUserInfo();
  },
  beforeUnmount() {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    saveHandle() {
      const data = {
        action: "update_user_customer",
        data: {
          real_name: this.userInfo.real_name,
          customer_cert: this.userInfo.customer_cert,
          email: this.userInfo.email,
          phone: "55" + this.phone2,
          captcha: this.code,
        },
      };

      const hasEmpty = Object.keys(data.data).some((item) => {
        return data.data[item] === "";
      });

      if (hasEmpty) {
        return;
      }

      this.$http
        .post("userCenter", data)
        .then(() => {
          this.$router.replace({
            path: "/m-verification-info2",
            query: { withdrawable: this.withdrawable, amountType: this.amountType },
          });
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
          this.code = "";
        });
    },
    sendCodeHandle() {
      if (this.timer !== null) {
        return;
      }

      const value = this.$tools.isNumeric(this.userInfo.phone);
      if (value === false) {
        this.$toast.error(this.$t("enter_phone_number"));
        return;
      }

      const data = {
        action: "sendCaptcha",
        data: {
          type: 2,
          phone: "55" + this.userInfo.phone,
        },
      };

      this.$http
        .post("userCenter", data)
        .then(() => {
          this.fireTimer();
          this.phone2 = this.userInfo.phone;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fireTimer() {
      if (this.timer !== null) {
        return;
      }

      this.timer = setInterval(() => {
        this.codeTitle = this.countDown.toString().padStart(2, 0) + "s";
        this.countDown--;

        if (this.countDown < 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.countDown = 60;
          this.codeTitle = this.$t("verification_code");
        }
      }, 1000);
    },
    fetchUserInfo() {
      const data = {
        action: "my_account",
        data: {},
      };
      this.$http
        .post("userCenter", data)
        .then((response) => {
          this.userInfo = response.data.data;
          if (this.userInfo.phone !== null && this.userInfo.phone !== "") {
            this.userInfo.phone = this.userInfo.phone.substr(2, this.userInfo.phone.length - 2);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
$form-item-bg: var(--input-bg-color);

.wrapper {
  display: flex;
  flex-direction: column;
  background: #06131c;
}

input {
  background: transparent;
  margin: auto 20px;
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
}

input::placeholder {
  color: #7686a2;
}

.name {
  background: $form-item-bg;
  border-radius: 8px;
  margin: 10px 15px 0;
  height: 46px;
  display: flex;
}

.cpf {
  background: $form-item-bg;
  border-radius: 8px;
  margin: 10px 15px 0;
  height: 46px;
  display: flex;
}

.email {
  background: $form-item-bg;
  border-radius: 8px;
  margin: 10px 15px 0;
  height: 46px;
  display: flex;
}

.phone {
  background: $form-item-bg;
  border-radius: 8px;
  margin: 10px 15px 0;
  height: 46px;
  display: flex;
}

.validate {
  background: $form-item-bg;
  border-radius: 8px;
  margin: 10px 15px 0;
  height: 46px;
  display: flex;

  & > button {
    width: 100px;
    background: #33439e;
    border: none;
    border-radius: 6px;
    margin: 5px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 7px;
  }
}

.button {
  margin: 25px 15px;
  background: #ffd400;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 800;
  color: #0a1f32;
}
</style>
