<template>
  <label class="pw-label">
    <input type="text" v-model="value" @input="limitNumber" />
    <div class="show-input">
      <div class="item" v-for="n in 6" :key="n">
        {{ showValue(n) }}
      </div>
    </div>
  </label>
</template>
<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value(val) {
      // console.log("val", val);
      this.$emit("update:inputValue", val);
    },
  },
  methods: {
    limitNumber() {
      if (this.value.toString().length > 6) {
        this.value = this.value.toString().slice(0, 6);
      }
    },
    showValue(index) {
      const text = (this.value + "").slice(index - 1, index);
      if (text) {
        if (this.isVisible) {
          return text;
        } else {
          return "●";
        }
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pw-label {
  margin-top: 10px;
  width: 100%;
  height: 45px;
  position: relative;
  display: flex;

  & > input {
    opacity: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    caret-color: transparent; // 隐藏光标
  }

  .show-input {
    // opacity: 50%;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    transition: 0.4s;
    border-radius: 34px;
    display: flex;
    justify-content: space-between;

    & > .item {
      width: 45px;
      height: 45px;
      line-height: 45px;
      background: #0a1f32;
      font-size: 24px;
      text-align: center;
      border-radius: 4px;
      color: #ffffff;
    }
  }
}
</style>
