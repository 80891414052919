<template>
  <div class="deposit">
    <div class="wrapper">
      <mine-empty v-if="tableData.length === 0"></mine-empty>
      <div v-else-if="isMobile" class="m-table">
        <div class="m-cell" v-for="(cell, index) in tableData" :key="index">
          <div class="m-item">
            <div class="m-title">{{ $t("date") }}</div>
            <div class="m-value">{{ cell.pay_time }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("sn") }}</div>
            <div class="m-value">{{ cell.orderid }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("type") }}</div>
            <div class="m-value">PIX</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("amount") }}</div>
            <div :class="cell.amount >= 0 ? 'm-value-positive' : 'm-value-nagative'">
              R${{ parseFloat(cell.amount).toFixed(2) }}
            </div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("status") }}</div>
            <div class="m-value">{{ showStaus(index) }}</div>
          </div>
          <div class="m-item">
            <div class="m-title">{{ $t("remarks") }}</div>
            <div class="m-value">{{ cell.notes }}</div>
          </div>
        </div>
        <LoadMoreComp
          :curCount="tableData.length"
          :totalCount="total"
          @loadMoreEvent="getDeposit(page + 1)"
        ></LoadMoreComp>
      </div>
      <div v-else class="table">
        <div class="header">
          <div>{{ $t("date") }}</div>
          <div>{{ $t("sn") }}</div>
          <div>{{ $t("type") }}</div>
          <div>{{ $t("amount") }}</div>
          <div>{{ $t("status") }}</div>
          <div>{{ $t("remarks") }}</div>
        </div>
        <div class="cell" v-for="(item, index) in tableData" :key="index">
          <div>{{ item.pay_time }}</div>
          <div>{{ item.orderid }}</div>
          <div>PIX</div>
          <div :class="item.amount >= 0 ? 'value-positive' : 'value-nagative'">
            R${{ parseFloat(item.amount).toFixed(2) }}
          </div>
          <div>{{ showStaus(index) }}</div>
          <div>{{ item.notes }}</div>
        </div>
      </div>
      <el-pagination
        v-if="isMobile === false && tableData.length !== 0"
        style="justify-content: right"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
import LoadMoreComp from "@/components/LoadMoreComp.vue";
export default {
  components: { MineEmpty, LoadMoreComp },
  data() {
    return {
      tableData: [],
      isMobile: false,
      pageSize: 10,
      page: 0,
      total: 0,
      sid: "",
    };
  },
  mounted() {
    this.sid = this.$route.query?.sid || "";
    this.isMobile = this.$tools.isMobile();
    this.getDeposit(this.page);
  },
  methods: {
    changePage(index) {
      this.getDeposit(index - 1);
    },
    getDeposit(page) {
      const data = {
        action: "user_payorder_list",
        data: {
          page,
          page_size: this.pageSize,
        },
      };
      this.$http
        .post("userWallet", data)
        .then((response) => {
          if (this.isMobile) {
            this.tableData.push(...response.data.data_list);
          } else {
            this.tableData = response.data.data_list;
          }

          const index = this.tableData.findIndex((item) => {
            return item.orderid === this.sid && item.status === 2;
          });
          if (index !== -1) {
            console.log("index:", index);
            console.log("test sid:", this.sid);
            this.$fbq("track", "Purchase", { currency: "USD", value: 0 });
            this.$kwaiq.instance(this.$global.kwaiPixelId).track("purchase");
          }

          this.page = response.data.page;
          this.total = response.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showStaus(index) {
      const st = this.tableData[index].status;
      let ret = "";
      switch (st) {
        case 1:
          // 待支付
          ret = "Aguarde o pagamento";
          break;
        case 2:
          // 已支付
          ret = "Já pago";
          break;
        case 3:
          // 已补单
          ret = "Já suplementado";
          break;
        case 4:
          // 已取消
          ret = "Cancelado";
          break;
        default:
          // 未知
          ret = "Desconhecido";
          break;
      }

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-pagination.is-background .btn-prev) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-prev:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .el-pager li:not(.disabled).is-active) {
  background-color: #e9c97e;
  color: #070704;
}

:deep(.el-pagination.is-background .btn-next) {
  background-color: #1f1e1e;
  color: #fff;
}

:deep(.el-pagination.is-background .btn-next:disabled) {
  background-color: #1f1e1e;
  color: #fff;
}

.deposit {
  display: flex;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .m-table {
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      .m-cell {
        margin-bottom: 8px;
        box-sizing: border-box;
        background: var(--mine-card-bg-color);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 10px 16px 7px;

        .m-item:nth-child(2) {
          .m-value {
            font-size: 12px;
          }
        }

        .m-item {
          display: flex;
          margin-bottom: 6px;
          .m-title {
            width: 30%;
            font-size: 14px;
            font-weight: 250;
            color: #3e7bb0;
            text-align: left;
          }
          .m-value {
            flex: 1;
            font-size: 14px;
            font-weight: 250;
            color: #ffffff;
            opacity: 0.8;
            text-align: left;
          }

          .m-value-positive {
            // flex: 1;
            font-size: 14px;
            font-weight: 500;
            color: #42f388;
            opacity: 0.8;
            text-align: left;
          }

          .m-value-negative {
            // flex: 1;
            font-size: 14px;
            font-weight: 500;
            color: #d04835;
            opacity: 0.8;
            text-align: left;
          }
        }
      }
    }

    .table {
      background: #1a1919;
      border-radius: 8px;
      display: flex;
      flex-direction: column;

      .header {
        margin: 0 30px;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        height: 56px;
        justify-content: center;
        align-items: center;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }

      .cell {
        margin: 0 10px 8px;
        // padding: 0 20px;
        font-size: 12px;
        color: rgba($color: #ffffff, $alpha: 0.8);
        height: 40px;
        border-radius: 8px;
        background: #1f1e1e;
        box-sizing: border-box;
        border: 1px solid #2b2b2b;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
        }

        .value-positive {
          flex: 1;
          font-size: 12px;
          color: #42f388;
          opacity: 0.8;
          text-align: left;
        }

        .value-negative {
          flex: 1;
          font-size: 12px;
          color: #d04835;
          opacity: 0.8;
          text-align: left;
        }
      }
    }
  }
}
</style>
