<template>
  <ModalPopupComp :contextWidth="800">
    <div class="title">Terms of Service</div>
    <div class="content">
      <div>Terms of Service</div>
      <div>
        This end user agreement (the "Agreement") should be read by you (the "User" or "you") in its entirety prior to
        your use of BetEagle’s service or products. Please note that the Agreement constitutes a legally binding
        agreement between you and BetEagle (referred to herein as "BetEagle", "us" or "we") which owns and operates the
        Internet site found and games described at BetEagle (the "Service"). By clicking the "I Agree" button if and
        where provided and/or using the Service, you consent to the terms and conditions set forth in this Agreement.
      </div>
      <div>Grant of License</div>
      <div>
        1.1. Subject to the terms and conditions contained herein, BetEagle grants the User a non-exclusive, personal,
        non-transferable right to use the Service on your personal computer or other device that accesses the Internet
        in order to access the games ava
      </div>
      <div>
        1.2. The Service is not for use by (i) individuals under 18 years of age, (ii) individuals under the legal age
        of majority in their jurisdiction and (iii) individuals accessing the Service from jurisdictions from which it
        is illegal to do so. BetEagle is not able to verify the legality of the Service in each jurisdiction and it is
        the User’s responsibility to ensure that their use of the Service is lawful.
      </div>
      <div>
        1.3. BetEagle and its licensors are the sole holders of all rights in and to the Service and code, structure and
        organization, including copyright, trade secrets, intellectual property and other rights. You may not, within
        the limits prescribed by applicable laws: (a) copy, distribute, publish, reverse engineer, decompile,
        disassemble, modify, or translate the website; or (b) use the Service in a manner prohibited by applicable laws
        or regulations (each of the above is an "Unauthorized Use"). BetEagle reserves any and all rights implied or
        otherwise, which are not expressly granted to the User hereunder and retain all rights, title and interest in
        and to the Service. You agree that you will be solely liable for any damage, costs or expenses arising out of or
        in connection with the commission by you of any Unauthorized Use. You shall notify BetEagle immediately upon
        becoming aware of the commission by any person of any Unauthorized Use and shall provide BetEagle with
        reasonable assistance with any investigations it conducts in light of the information provided by you in this
        respect.
      </div>
      <div>
        1.4. The term "BetEagle", its domain names and any other trade marks, or service marks used by BetEagle as part
        of the Service (the "Trade Marks"), are solely owned by BetEagle In addition, all content on the website,
        including, but not limited to, the images, pictures, graphics, photographs, animations, videos, music, audio and
        text (the "Site Content") belongs to BetEagle and is protected by copyright and/or other intellectual property
        or other rights. You hereby acknowledge that by using the Service, you obtain no rights in the Site Content
        and/or the Trade Marks, or any part thereof. Under no circumstances may you use the Site Content and/or the
        Trade Marks without BetEagle’s prior written consent. Additionally, you agree not to do anything that will harm
        or potentially harm the rights, including the intellectual property rights of BetEagle
      </div>
      <div>No Warranties</div>
      <div>
        2.1. BetEagle disclaims any and all warranties, expressed or implied, in connection with the service which is
        provided to you "as is" and we provide you with no warranty or representation whatsoever regarding its quality,
        fitness for purpose, completeness or accuracy.
      </div>
      <div>
        2.2. Regardless of BetEagle’s efforts, BetEagle makes no warranty that the service will be uninterrupted, timely
        or error-free, or that defects will be corrected.
      </div>
      <div>
        Authority/Terms of Service You agree to the game rules described on the BetEagle website. BetEagle retains
        authority over the issuing, maintenance, and closing of the Service. The decision of BetEagle’s management,
        concerning any use of the Service, or dispute resolution, is final and shall not be open to review or appeal.
      </div>
      <div>Your Obligations as a Player</div>
      <div>4.1. You hereby declare and warrant that:</div>
      <div>
        4.1.1. You are over 18 years of age or such a higher minimum legal age of majority as stipulated if the
        jurisdiction of Your residence (e.g. Estonia – 21 years) and, under the laws applicable to You, legally allowed
        to participate in the Games offered on the Website.
      </div>
      <div>
        4.1.2. You participate in the Games strictly in your personal non-professional capacity for recreational and
        entertainment reasons only.
      </div>
      <div>4.1.3. You participate in the Games on your own behalf and not on behalf of any other person.</div>
      <div>
        4.1.4. All information that You provide to BetEagle during the term of validity of this agreement is true,
        complete, and correct, and that You shall immediately notify BetEagle of any change of such information.
      </div>
      <div>
        4.1.5. You are solely responsible for reporting and accounting for any taxes applicable to You under relevant
        laws for any winnings that You receive from BetEagle.
      </div>
      <div>
        4.1.6. You understand that by participating in the Games you take the risk of losing Virtual Funds deposited
        into Your Member Account.
      </div>
      <div>
        4.1.7. You shall not be involved in any fraudulent, collusive, fixing or other unlawful activity in relation to
        Your or third parties’ participation in any of the Games and shall not use any software- assisted methods or
        techniques or hardware devices for Your participation in any of the Games. BetEagle hereby reserves the right to
        invalidate any wager in the event of such behaviour.
      </div>
      <div>
        4.1.8. You understand that Virtual Funds as Bitcoin are not considered a legal currency or tender and as such on
        the Website they are treated as virtual funds with no intrinsic value.
      </div>
      <div>4.1.9. You understand that Bitcoin value can change dramatically depending on the market value.</div>
      <div>4.1.10. You are not allowed to use any payment methods that belong to a Third party or person.</div>
      <div>4.2. You are not allowed to transfer, sell and/or acquire, user accounts.</div>
      <div>
        4.3. Games played on Our site should be played in the same manner as games played in any other setting. This
        means that players should be courteous to each other and avoid rude or obscene comments.
      </div>
      <div>
        4.4. Some circumstances may arise where a wager is confirmed, or a payment is performed by us in error. In all
        these cases BetEagle reserves the right to cancel all the wagers accepted containing such an error.
      </div>
      <div>
        4.5. Should the user become aware of possible errors or incompleteness in the software, he/she agrees to refrain
        from taking advantage of them. Moreover, the user agrees to report any error or incompleteness immediately to
        BetEagle Should the user fail to fulfil the obligations stated in this clause, BetEagle has a right to full
        compensation for all costs related to the error or incompleteness, including any costs incurred in association
        with the respective error/incompleteness and the failed notification by the user.
      </div>
      <div>
        4.6. In the event a game is started but miscarried because of a failure of the system, BetEagle shall refund the
        amount wagered in the game to the User by crediting it to the User’s Account or, if the account no longer
        exists, by paying it to the User in an approved manner; and if the User has an accrued credit at the time the
        game miscarried, credit to the User’s Account the monetary value of the credit or, if the account no longer
        exists, pay it to the User in an approved manner.
      </div>
      <div>
        4.7. BetEagle reserves the right to reject or limit wagers. The user is not permitted to wager an amount
        exceeding his/her personal account. Wins are credited to the personal account of the user.
      </div>
      <div>
        4.8. BetEagle reserves the right to retain payments, if suspicion or evidence exists of manipulation of the
        casino system. Criminal charges will be brought against any user or any other person(s), who has/have
        manipulated the casino system or attempted to do so. BetEagle reserves the right to terminate and/or, change any
        games or events being offered on the Website.
      </div>
      <div>
        4.9. We reserve the right to require some verification in case of suspicious or fraudulent transactions.
      </div>
      <div>
        4.10. BetEagle reserves the right to declare a wager void partially or in full if BetEagle, at its own
        discretion, would deem it obvious that any of the following circumstances have occurred:
      </div>
      <div>
        4.10.1. You, or people associated with you may directly or indirectly influence the outcome of an event, to
        obtain an unlawful advantage,
      </div>
      <div>4.10.2. You and or people associated with you are directly or indirectly avoiding the rules of BetEagle</div>
      <div>4.10.3. The result of an event has been directly or indirectly affected by criminal activity.</div>
      <div>
        4.10.4. Wagers have been placed that would not have been accepted otherwise, but that were accepted during
        periods when the website have been affected by technical problems.
      </div>
      <div>
        4.10.5. Due to an error, such as a, misprint, technical error, force majeure or otherwise, wagers have been
        offered, placed and or accepted due to this error.
      </div>
      <div>
        4.10.6. If a player's deposit fee is too low and is flagged by blockchain or similar site as “not enough fee to
        relay” BetEagle reserve the right to confiscate the winnings if BetEagle at their own discretion deem the
        transaction and behavior of the player to be fraudulent in nature.
      </div>
      <div>
        4.11. You will inform BetEagle immediately if you enter into a self-exclusion agreement with any gambling
        provider.
      </div>
      <div>Prohibited Uses</div>
      <div>
        5.1. PERSONAL USE. The Service is intended solely for the User’s personal use. The User is only allowed to wager
        for his/her personal entertainment and may not create multiple accounts, including for the purpose of collusion
        and/or abuse of service.
      </div>
      <div>
        5.2. JURISDICTIONS. Persons located in or residents of Aruba, Bonaire, Curacao, Costa Rica, France, Netherlands,
        Saba, Statia, St Martin, China、USA (the ”Prohibited Jurisdictions”) are not permitted make use of the Service.
        For the avoidance of doubt, the foregoing restrictions on engaging in real-money play from Prohibited
        Jurisdictions applies equally to residents and citizens of other nations while located in a Prohibited
        Jurisdiction. Any attempt to circumvent the restrictions on play by any persons located in a Prohibited
        Jurisdiction or Restricted Jurisdiction, is a breach of this Agreement. An attempt at circumvention includes,
        but is not limited to, manipulating the information used by BetEagle to identify your location and providing
        BetEagle with false or misleading information regarding your location or place of residence.
      </div>
      <div>Know your Customer (“KYC”)</div>
      <div>
        BetEagle reserves the right, at any time, to ask for any KYC documentation it deems necessary to determine the
        identity and location of a User. BetEagle reserves the right to restrict service and payment until identity is
        sufficiently determined.
      </div>
      <div>Breach</div>
      <div>
        7.1. Without prejudice to any other rights, if a User breaches in whole or in part any provision contained
        herein, BetEagle reserves the right to take such action as it sees fit, including terminating this Agreement or
        any other agreement in place with the User and/or taking legal action against such User.
      </div>
      <div>
        7.2. You agree to fully indemnify, defend and hold harmless BetEagle and its shareholders, directors, agents and
        employees from and against all claims, demands, liabilities, damages, losses, costs and expenses, including
        legal fees and any other charges whatsoever, howsoever caused, that may arise as a result of: (i) your breach of
        this Agreement, in whole or in part; (ii) violation by you of any law or any third party rights; and (iii) use
        by you of the Service.
      </div>
      <div>Limitations and Liability</div>
      <div>
        8.1. Under no circumstances, including negligence, shall BetEagle be liable for any special, incidental, direct,
        indirect or consequential damages whatsoever (including, without limitation, damages for loss of business
        profits, business interruption, loss of business information, or any other pecuniary loss) arising out of the
        use (or misuse) of the Service even if BetEagle had prior knowledge of the possibility of such damages.
      </div>
      <div>
        8.2. Nothing in this Agreement shall exclude or limit BetEagle’s liability for death or personal injury
        resulting from its negligence.
      </div>
      <div>9.Disputes</div>
      <div>
        If a User wishes to make a complaint, please contact BetEagle’s customer service team at csBetEagle@gmail.com.
        Should any dispute not be resolved to your satisfaction you may pursue remedies in the governing law
        jurisdiction set forth below.
      </div>
      <div>10.Amendment</div>
      <div>
        BetEagle reserves the right to update or modify this Agreement or any part thereof at any time or otherwise
        change the Service without notice and you will be bound by such amended Agreement upon posting. Therefore, we
        encourage you to check the terms and conditions contained in the version of the Agreement in force at such time.
        Your continued use of the Service shall be deemed to attest to your agreement to any amendments to the
        Agreement.
      </div>
      <div>11.Governing Law</div>
      <div>
        The Agreement and any matters relating thereto shall be governed by, and construed in accordance with, the laws
        of Costa Rica. You irrevocably agree that, subject as provided below, the courts of Costa Rica shall have
        exclusive jurisdiction in relation to any claim, dispute or difference concerning the Agreement and any matter
        arising therefrom and irrevocably waive any right that it may have to object to an action being brought in those
        courts, or to claim that the action has been brought in an inconvenient forum, or that those courts do not have
        jurisdiction. Nothing in this clause shall limit the right of BetEagle to take proceedings against you in any
        other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions
        preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent
        permitted by the law of such other jurisdiction.
      </div>
      <div>
        If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall
        not affect the validity or enforceability in that jurisdiction of any other provision hereof or the validity or
        enforceability in other jurisdiction of that or any other provision hereof.
      </div>
      <div>12.Assignment</div>
      <div>
        BetEagle reserves the right to assign this agreement, in whole or in part, at any time without notice. The User
        may not assign any of his/her rights or obligations under this Agreement.
      </div>
      <div>13.ADVANTAGE PLAY</div>
      <div>
        Should the Casino become aware of any user who has accepted the bonus or a promotion with sole purpose of
        creating a positive expected value on bonus return by using known practices aimed at securing a cash out of said
        bonus or at any way try to take advantage of bonuses received by BetEagle, then BetEagle will enforce immediate
        confiscation of winnings and closure of the account with the right to withhold any further withdrawals. An
        example of advantage play would be delaying any game round in any game, including free spins features and bonus
        features, to a later time when you have no more wagering requirement and/or performing new deposit(s) while
        having free spins features or bonus features still available in a game. In the interests of fair gaming, equal,
        zero or low margin bets or hedge betting, shall all be considered irregular gaming for bonus play- through
        requirement purposes. Should the Casino deem that irregular game play has occurred, the Casino reserves the
        right to withhold any withdrawals and/or confiscate all winnings.
      </div>
      <div>User Agreement</div>
      <div>Definitions; BetEagle is referred to as 'we' or 'us'.</div>
      <div>The Player is referred to as "you" or 'the Player'.</div>
      <div>'The Website' means BetEagle through desktop, mobile or other platforms utilised by the Player.</div>
      <div>Definitions</div>
      <div>BetEagle is referred to as 'we' or 'us'</div>
      <div>The Player is referred to as "you" or 'the Player'</div>
      <div>'The Website' means BetEagle through desktop, mobile or other platforms utilised by the Player</div>
      <div>General</div>
      <div>1.1. This User Agreement applies to the usage of games accessible through BetEagle.</div>
      <div>
        1.2. This User Agreement comes into force as soon as you complete the registration process, which includes
        checking the box accepting this User Agreement and successfully creating an account. By using any part of the
        Website following account creation, you agree to this User Agreement.
      </div>
      <div>
        1.3. You must read this User Agreement carefully in their entirety before creating an account. If you do not
        agree with any provision of this User Agreement, you must not create an account or continue to use the Website.
      </div>
      <div>
        1.4. We are entitled to make amendments to this User Agreement at any time and without advanced notice. If we
        make such amendments, we may take appropriate steps to bring such changes to your attention (such as by e-mail
        or placing a notice on a prominent position on the Website, together with the amended User Agreement) but it
        shall be your sole responsibility to check for any amendments, updates and/or modifications. Your continued use
        of BetEagle services and Website after any such amendment to the User Agreement will be deemed as your
        acceptance and agreement to be bound by such amendments, updates and/or modifications.
      </div>
      <div>
        1.5. this User Agreement may be published in several languages for informational purposes and ease of access by
        players. The English version is the only legal basis of the relationship between you and us and in the case of
        any discrepancy with respect to a translation of any kind, the English version of this User Agreement shall
        prevail.
      </div>
      <div>Binding Declarations</div>
      <div>
        2.1. By agreeing to be bound by this User Agreement, you also agree to be bound by the BetEagle Rules and
        Privacy Policy that are hereby incorporated by reference into this User Agreement. In the event of any
        inconsistency, this User Agreement will prevail. You hereby represent and warrant that:
      </div>
      <div>
        2.1.1. You are over (a) 18 or (b) such other legal age or age of majority as determined by any laws which are
        applicable to you, whichever age is greater;
      </div>
      <div>
        2.1.2. You have full capacity to enter into a legally binding agreement with us and you are not restricted by
        any form of limited legal capacity;
      </div>
      <div>
        2.1.3. All information that you provide to us during the term of validity of this agreement is true, complete,
        correct, and that you shall immediately notify us of any change of such information;
      </div>
      <div>
        2.1.4. You are solely responsible for reporting and accounting for any taxes applicable to you under relevant
        laws for any winnings that you receive from us;
      </div>
      <div>
        2.1.5. You understand that by using our services you take the risk of losing money deposited into your Member
        Account and accept that you are fully and solely responsible for any such loss;
      </div>
      <div>2.1.6. You are permitted in the jurisdiction in which you are located to use online casino services;</div>
      <div>
        2.1.7. In relation to deposits and withdraws of funds into and from your Member Account, you shall only use
        Cryptocurrency that are valid and lawfully belong to you;
      </div>
      <div>
        2.1.8. You understand that the value of Cryptocurrency can change dramatically depending on the market value;
      </div>
      <div>
        2.1.9. The computer software, the computer graphics, the Websites and the user interface that we make available
        to you are owned by BetEagle or its associates and is protected by copyright laws. You may only use the software
        for your own personal, recreational uses in accordance with all rules, User Agreement we have established and in
        accordance with all applicable laws, rules and regulations;
      </div>
      <div>
        2.1.10. You understand that Cryptocurrency is not considered a legal currency or tender and as such on the
        Website they are treated as virtual funds with no intrinsic value.
      </div>
      <div>
        2.1.11. You affirm that you are not an officer, director, employee, consultant or agent of BetEagle or working
        for any company related to BetEagle, or a relative or spouse of any of the foregoing;
      </div>
      <div>
        2.1.12. You are not diagnosed or classified as a compulsive or problem gambler. We are not accountable if such
        problem gambling arises whilst using our services but will endeavor to inform of relevant assistance available.
        We reserve the right to implement cool off periods if we believe such actions will be of benefit.
      </div>
      <div>
        2.1.13. You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited
        techniques, including but not limited to fraudulent transaction detection, automated registration and signup,
        gameplay and screen capture techniques. These steps may include, but are not limited to, examination of Players
        device properties, detection of geo-location and IP masking, transactions and blockchain analysis;
      </div>
      <div>
        2.1.14. You accept our right to terminate and/or change any games or events being offered on the Website, and to
        refuse and/or limit bets.
      </div>
      <div>
        2.1.15. You accept that we have the right to ban/block multiple accounts and freely control the assets in such
        accounts.
      </div>
      <div>
        2.1.16. You are aware of possible errors or incompleteness in the software, you agree to refrain from taking
        advantage of them. Moreover, you agree to report any error or incompleteness immediately to BetEagle. Should you
        fail to fulfil the obligations stated in this clause, BetEagle has a right to full compensation for all costs
        related to the error or incompleteness, including any costs incurred in association with the respective
        error/incompleteness and the failed notification by the user.
      </div>
      <div>
        2.1.17. You are aware of that BetEagle has the right to carry out “KYC” (Know Your Customer) verification
        procedures. The access to your user account may be blocked or closed if we determine that you have supplied
        false or misleading information.
      </div>
      <div>
        2.2. We reserve the right to declare a wager void partially or in full if BetEagle, at its own discretion, would
        deem it obvious that any of the following circumstances have occurred:
      </div>
      <div>
        2.2.1. You, or people associated with you, may directly or indirectly influence the outcome of an event, to
        obtain an unlawful advantage.
      </div>
      <div>2.2.2. You and or people associated with you are directly or indirectly avoiding the rules of BetEagle.</div>
      <div>2.2.3. The result of an event has been directly or indirectly affected by criminal activity.</div>
      <div>
        2.2.4. Wagers have been placed that would not have been accepted otherwise, but that were accepted during
        periods when the website has been affected by technical problems.
      </div>
      <div>
        2.2.5. Due to an error, such as a mistake, vulnerabilities, technical error, force majeure or otherwise, wagers
        have been offered, placed and or accepted due to this error.
      </div>
      <div>
        2.2.6. If a player's deposit fee is too low and is flagged by blockchain or similar site as “not enough fee to
        relay” BetEagle reserves the right to confiscate the winnings if BetEagle at their own discretion deems the
        transaction and behavior of the player to be fraudulent in nature.
      </div>
      <div>Restricted Territories</div>
      <div>
        3.1. Blacklisted Territories: China, Netherlands, Dutch Caribbean Islands, Hungary, Australia, Ontario(Canada),
        Curacao, France, United States and/or any other restricted by law country or state. Note that it is strictly
        forbidden to play on BetEagle games in blacklisted countries mentioned above.your personal data for the purpose
        of executing their duties and providing you with the best possible assistance and service. You hereby consent to
        such disclosures.
      </div>
      <div>General Betting Rules</div>
      <div>4.1. A bet can only be placed by a registered account holder.</div>
      <div>4.2. A bet can only be placed over the internet.</div>
      <div>4.3. You can only place a bet if you have a sufficient balance in your account with BetEagle.</div>
      <div>
        4.4. The bet, once concluded, will be governed by the version of the User Agreement valid and available on the
        Website at the time of the bet being accepted.
      </div>
      <div>
        4.5. Any payout of a winning bet is credited to your account, consisting of the stake multiplied by the odds at
        which the bet was placed.
      </div>
      <div>
        4.6. BetEagle reserves the right to adjust a bet payout credited to a BetEagle account if it is determined by
        BetEagle in its sole discretion that such a payout has been credited due to an error.
      </div>
      <div>4.7. A bet, which has been placed and accepted, cannot be amended, withdrawn, or cancelled by you.</div>
      <div>4.8. The list of all the bets, their status and details are available to you on the Website.</div>
      <div>
        4.9. When you place a bet you acknowledge that you have read and understood in full all of this User Agreement
        regarding the bet as stated on the Website.
      </div>
      <div>
        4.10. BetEagle manages your account, and calculates the available funds, the pending funds, the betting funds as
        well as the amount of winnings. Unless proven otherwise, these amounts are considered as final and are deemed to
        be accurate.
      </div>
      <div>4.11. You are fully responsible for the bets placed.</div>
      <div>4.12. Winnings will be paid into your account after the final result is confirmed.</div>
      <div>Bonuses and Promotions</div>
      <div>
        5.1. BetEagle reserves the right to cancel any promotion, bonus or bonus program (including, but not limited to
        top-up rewards, invite friends to reward bonuses and loyalty programs) with immediate effect if we believe the
        bonus has been set up incorrectly or is being abused, and if the said bonus has been paid out, we reserve the
        right to decline any Withdraw request and to deduct such amount from your account. Whether or not a bonus is
        deemed to be set up incorrectly or abused shall be determined solely by BetEagle.
      </div>
      <div>
        5.2. If you use a Deposit Bonus, no Withdraw of your original deposit will be accepted before you have reached
        the requirements stipulated under the User Agreement of the Deposit Bonus.
      </div>
      <div>
        5.3. Where any term of the offer or promotion is breached or there is any evidence of a series of bets placed by
        a customer or group of customers, which due to a deposit bonus, enhanced payments, free bets, risk free bets or
        any other promotional offer results in guaranteed customer profits irrespective of the outcome, whether
        individually or as part of a group, BetEagle reserves the right to reclaim the bonus element of such offers and
        in their absolute discretion either settle bets at the correct odds, void the free bet bonus and risk free bets
        or void any bet funded by the deposit bonus. In addition, BetEagle reserves the right to levy an administration
        charge on the customer up to the value of the deposit bonus, free bet bonus, risk free bet or additional payment
        to cover administrative costs. We further reserve the right to ask any customer to provide sufficient
        documentation for us to be satisfied in our absolute discretion as to the customer's identity prior to us
        crediting any bonus, free bet, risk free bet or offer to their account.
      </div>
      <div>
        5.4. All BetEagle offers are intended for recreational players and BetEagle may in its sole discretion limit the
        eligibility of customers to participate in all or part of any promotion.
      </div>
      <div>
        5.5. BetEagle reserves the right to amend, cancel, reclaim or refuse any promotion at its own discretion.
      </div>
      <div>
        5.6. Bonuses can only be received once per person/account, family, household, address, e-mail address, IP
        addresses and environments where computers are shared (university, fraternity, school, public library,
        workplace, etc.). The Operator reserves the right to close your account and confiscate any existing funds if
        evidence of abuse/fraud is found.
      </div>
      <div>
        5.7. You acknowledge and understand that separate User Agreement exist with respect to promotions, bonuses and
        special offers, and are in addition to this User Agreement. This User Agreement is set forth in the respective
        content page on this website, or have been made available to you personally, as the case may be. In the event of
        a conflict between the provisions of such promotions, bonuses and special offers, and the provisions of this
        User Agreement, the provisions of such promotions, bonuses and special offers will prevail.
      </div>
      <div>
        5.8. We may insist that you bet a certain amount of your own deposit before you can bet with any free/bonus
        funds we credit to your account.
      </div>
      <div>
        5.9. You accept that certain promotions may be subject to Withdraw restrictions and/or requirements which need
        to be met before funds credited under the promotion can be withdrawn. Such terms shall be duly published and
        made available as part of the promotion. If you opt to make a Withdraw before the applicable wagering
        requirements are fulfilled, we will deduct the whole bonus amount as well as any winnings connected with the use
        of the bonus amounts before approving any Withdraw.
      </div>
      <div>Live Chat</div>
      <div>
        6.1. As part of your use of the Website we may provide you with a live chat facility, which is moderated by us
        and subject to controls. We reserve the right to review the chat and to keep a record of all statements made on
        the facility. Your use of the chat facility should be for recreational and socialising purposes.
      </div>
      <div>
        6.2. We have the right to remove the chat room functionality or immediately terminate your Member Account and
        refund your account balance if you:
      </div>
      <div>
        (a) make any statements that are sexually explicit or grossly offensive, including expressions of bigotry,
        racism, hatred or profanity;
      </div>
      <div>(b) make statements that are abusive, defamatory or harassing or insulting;</div>
      <div>(c) use the chat facility to advertise, promote or otherwise relate to any other online entities;</div>
      <div>
        (d) make statements about BetEagle, or any other Internet site(s) connected to the Website that are untrue
        and/or malicious and/or damaging to BetEagle;
      </div>
      <div>
        (e) user the chat facility to collude, engage in unlawful conduct or encourage conduct we deem seriously
        inappropriate. Any suspicious chats will be reported to the competent authority.
      </div>
      <div>
        6.3. Live Chat is used as a form of communication between us and you and should not be copied or shared with any
        forums or third parties.
      </div>
      <div>Limitation of Liability</div>
      <div>
        7.1. You enter the Website and participate in the Games at your own risk. The Websites and the Games are
        provided without any warranty whatsoever, whether expressed or implied.
      </div>
      <div>
        7.2. Without prejudice to the generality of the preceding provision, we, our directors, employees, partners,
        service providers.
      </div>
      <div>7.3. Do not warrant that the software, Games and the Websites are fit for their purpose.</div>
      <div>7.4. Do not warrant that the software, Games and the Websites are free from errors.</div>
      <div>7.5. Do not warrant that the software, Games and the Websites will be accessible without interruptions.</div>
      <div>
        7.6. Shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special,
        consequential, incidental or otherwise, arising in relation to your use of the Websites or your participation in
        the Games.
      </div>
      <div>
        7.7. You understand and acknowledge that, if there is a malfunction in a Game or its interoperability, any bets
        made during such a malfunction shall be void. Funds obtained from a malfunctioning Game shall be considered
        void, as well as any subsequent game rounds with said funds, regardless of what Games are played using such
        funds.
      </div>
      <div>
        7.8. You hereby agree to fully indemnify and hold harmless us, our directors, employees, partners, and service
        providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise in
        relation to your use of the Website or participation in the Games.
      </div>
      <div>
        7.9. To the extent permitted by law, our maximum liability arising out of or in connection with your use of the
        Websites, regardless of the cause of actions (whether in contract, tort, breach of warranty or otherwise), will
        not exceed €100.
      </div>
      <div>Breaches, Penalties and Termination</div>
      <div>
        8.1. If you breach any provision of this User Agreement or we have a reasonable ground to suspect that you have
        breached them, we reserve the right to not open, to suspend, or to close your Member Account, or withhold
        payment of your winnings and apply such funds to any damages due by you.
      </div>
      <div>
        8.2. You acknowledge that BetEagle shall be the final decision-maker of whether you have violated BetEagle’s
        rules, terms or conditions in a manner that results in your suspension or permanent barring from participation
        in our site.
      </div>
      <div>Self-exclusion</div>
      <div>
        9.1. By requesting a period of self-exclusion, you agree to follow the below terms and conditions, which will be
        in effect from the time that CS implements the chosen period of self-exclusion.
      </div>
      <div>
        9.2. You may self-exclude for periods of 1, 3, 6, 12 month/s or permanent. Self-exclusion requests are to be
        made via Live Support.
      </div>
      <div>
        9.3. Once you have self-excluded you will not be able to access your account or withdraw during this period.
      </div>
      <div>
        9.4. If you have excluded your account whilst you have pending bets on your account, bets placed will remain
        valid and settle according to official results.
      </div>
      <div>
        9.5. Once the period of self-exclusion has lapsed you may withdraw winnings from qualifying bets. BetEagle does
        not cancel or void any bets placed before a self-exclusion has been affected.
      </div>
      <div>
        9.6. Once you have self-excluded you will not be able to change or alter the period for a shorter length of time
        or have your self-exclusion cancelled until the period that you selected for self-exclusion has passed.
      </div>
      <div>9.7. Please contact our customer services team if you wish to extend your self-exclusion period.</div>
      <div>
        9.8. Once your self-exclusion period has elapsed, reinstatement of the account can be done by emailing the
        request to csBetEagle@gmail.com
      </div>
      <div>9.9. By self-excluding, you agree that:</div>
      <div>a) You will not create another account during this period.</div>
      <div>b) You will not deposit or attempt to deposit funds into a BetEagle account.</div>
      <div>c) You will not wager on this website during this period.</div>
      <div>
        d) This is a voluntary act initiated by yourself, and BetEagle. will not be liable for any losses you may incur
        during the period of self-exclusion in any form.
      </div>
      <div>Privacy Policy</div>
      <div>
        You hereby acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your
        personal data in order to allow you access and use of the Websites and in order to allow you to participate in
        the Games.
      </div>
      <div>
        We hereby acknowledge that in collecting your personal details as stated in the previous provision, we are bound
        by the Data Protection Act. We will protect your personal information and respect your privacy in accordance
        with best business practices and applicable laws.
      </div>
      <div>
        We will use your personal data to allow you to participate in the Games and to carry out operations relevant to
        your participation in the Games. We may also use your personal data to inform you of changes, new services and
        promotions that we think you may find interesting. If you do not wish to receive such direct marketing
        correspondences, you may opt out of the service.
      </div>
      <div>
        Your personal data will not be disclosed to third parties, unless it is required by law. As BetEagle business
        partners or suppliers or service providers may be responsible for certain parts of the overall functioning or
        operation of the Website, personal data may be disclosed to them. The employees of BetEagle have access to your
        personal data for the purpose of executing their duties and providing you with the best possible assistance and
        service. You hereby consent to such disclosures.
      </div>
      <div>
        We shall keep all information provided as personal data. You have the right to access personal data held by us
        about you. No data shall be destroyed unless required by law, or unless the information held is no longer
        required to be kept for the purpose of the relationship.
      </div>
      <div>
        In order to make your visit to the Websites more user-friendly, to keep track of visits to the Websites and to
        improve the service, we collect a small piece of information sent from your browser, called a cookie. You can,
        if you wish, turn off the collection of cookies. You must note, however, that turning off cookies may severely
        restrict or completely hinder your use of the Websites.
      </div>
      <div>Cookies Policy</div>
      <div>1.What are cookies?</div>
      <div>
        A cookie is a piece of information in the form of a very small text file that is placed on an internet user's
        computer. It is generated by a web page server (which is basically the computer that operates the website) and
        can be used by that server whenever the user visits the site. A cookie can be thought of as an internet user's
        identification card, which tells a website when the user has returned. Cookies can't harm your computer and we
        don't store any personally identifiable information about you on any of our cookies.
      </div>
      <div>2.Why do we use cookies on BetEagle?</div>
      <div>
        BetEagle uses two types of cookies: cookies set by us and cookies set by third parties (i.e. other websites or
        services). BetEagle cookies enable us to keep you signed in to your account throughout your visit and to tailor
        the information displayed on the site to your preferences.
      </div>
      <div>3.What cookies do we use on BetEagle?</div>
      <div>Below is a list of the main cookies set by BetEagle, and what each is used for:</div>
      <div>_fp - stores browser's fingerprint. Lifetime: forever.</div>
      <div>
        _t - stores timestamp when user firstly visited site in current browsing session. Needed for unique visits
        statistic. Lifetime: browsing session.
      </div>
      <div>
        _r - stores http referrer for current browsing session. Needed in order to external track traffic sources.
        Lifetime: browsing session.
      </div>
      <div>_c - stores identifier of affiliate campaign. Needed for affiliate statistic. Lifetime: forever.</div>
      <div>Cookies set by third parties for wildcard domain: *.BetEagle</div>
      <div>Google analytics: _ga, _gat, _gid</div>
      <div>Zendesk：__ zlcmid</div>
      <div>Cloudflare：__ cfuid</div>
      <div>
        Please keep in mind that some browsers (i.e. chrome on mac) keep background processes running even if no tabs
        opened due to this session cookies may left set between sessions.
      </div>
      <div>There are also cookies set by third party scripts to their domains.</div>
      <div>4.How can I manage my cookies on BetEagle?</div>
      <div>
        If you wish to stop accepting cookies, you can do so through the Privacy Settings option in your browser.
      </div>
      <div>5.Personal Data Protection Policy</div>
      <div>
        BetEagle’s mission is to keep your Data safe and for this matter we protect your data in various ways. We
        provide our customers with high security standards, such as encryption of data in motion over public networks,
        encryption of data in database, auditing standards, Distributed Denial of Service mitigations, and a Live Chat
        available on-site.
      </div>
      <div>6.Server Protection Policy</div>
      <div>All servers have full encryption;</div>
      <div>All backups have encryption;</div>
      <div>Firewalls, VPN Access;</div>
      <div>Access to servers allowed only over VPN;</div>
      <div>All http/s services work over Cloudflare;</div>
      <div>Connections to nodes over VPN;</div>
      <div>SSH port forwarding tunnels;</div>
      <div>Services allowed only over VPN;</div>
      <div>Server have firewall and allowed only SSH port;</div>
      <div>Alerts on critical services.</div>
      <div>Data Breach Notification</div>
      <div>
        When BetEagle will be made aware of personal data breaches we will notify relevant users in accordance with GDPR
        timeframes.
      </div>
      <div>7.Data International Transfer</div>
      <div>
        We only disclose personal data to third parties where it is necessary to provide the high-quality service or in
        order to respond lawful requests from authorities.
      </div>
      <div>We share the following data to third party systems:</div>
      <div>
        Zendesk Inc. – username and e-mail information is transferred if user sends a message to live-chat or sends an
        e-mail to support mailbox.
      </div>
      <div>
        Although we try to do our best, problems could occur now and then. Our team will do everything we could to solve
        your problems as soon as possible. To assist you quicker, You can join us by clicking the button above to join
        the telegram group.
      </div>
      <div>If an error occurs, please provide the following information:</div>
      <div>Username</div>
      <div>Date and time of the problem</div>
      <div>Game ID or table name, if any</div>
      <div>Screenshot of the error, if possible</div>
      <div>
        We really appreciate your help and the error report you provided because your information report could help us
        improve.
      </div>
      <div>Collecting and Using Your Personal Data</div>
      <div>Types of Data Collected</div>
      <div>Personal Data</div>
      <div>
        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can
        be used to contact or identify You. Personally identifiable information may include, but is not limited to:
      </div>
      <div>Email address</div>
      <div>First name and last name</div>
      <div>Usage Data</div>
      <div>Usage Data</div>
      <div>Usage Data is collected automatically when using the Service.</div>
      <div>
        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser
        type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent
        on those pages, unique device identifiers and other diagnostic data.
      </div>
      <div>
        When You access the Service by or through a mobile device, We may collect certain information automatically,
        including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address
        of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device
        identifiers and other diagnostic data.
      </div>
      <div>
        We may also collect information that Your browser sends whenever You visit our Service or when You access the
        Service by or through a mobile device.
      </div>
      <div>Information from Third-Party Social Media Services</div>
      <div>
        BetEagle allows You to create an account and log in to use the Service through the following Third-party Social
        Media Services:
      </div>
      <div>Google</div>
      <div>Facebook</div>
      <div>Telegram</div>
      <div>Metamask</div>
      <div>Web3.0</div>
      <div>
        If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may
        collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as
        Your name, Your email address, Your activities or Your contact list associated with that account.
      </div>
      <div>
        You may also have the option of sharing additional information with the BetEagle through Your Third-Party Social
        Media Service's account. If You choose to provide such information and Personal Data, during registration or
        otherwise, You are giving BetEagle permission to use, share, and store it in a manner consistent with this
        Privacy Policy.
      </div>
      <div>Delete Personal Data</div>
      <div>
        You can request to have your personal data deleted if BetEagle no longer have a legal reason to continue to
        process or store it. Please note that this right is not guaranteed - in the sense that BetEagle do not have the
        ability to comply with your request if it is subject to a legal obligation to store your data. You can request
        the deletion of your personal data by sending an email to csBetEagle@gmail.com.
      </div>
      <div>Registration and Login</div>
      <div>
        You must be at least 18 years old to register. If you want to add your email address, please make sure the email
        address you entered is correct so that later it can be used in KYC account verification.
      </div>
      <div>
        You can login at any time. For added security, we recommend you to add 2FA. To know more about Google
        authenticator.
      </div>
      <div>
        If you need to change your registered email, we are so sorry, but we are not able to update this information. If
        you insist on changing your username and/or registered email, we suggest you close the current account and
        register a new one.
      </div>
    </div>
  </ModalPopupComp>
</template>
<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: { ModalPopupComp },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 34px;
  font-weight: 250;
  color: var(--color-popup-title);
}

.content {
  margin: 20px;
  font-size: 20px;
  font-weight: 250;
  color: #ffffff;
  overflow: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}
</style>
