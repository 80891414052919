<template>
  <ModalPopupComp :contextWidth="560">
    <div class="box">
      <div class="title">Regras de bônus</div>
      <div class="rules">
        <div>As principais fontes de Dinheiro Bônus são os primeiros depósitos e VIP roleta.</div>
        <div>Dinheiro Bônus precisa ser convertido em dinheiro real antes de serem saque.</div>
        <div>A conversão para dinheiro real requer a conclusão da tarefa de dinheiro bónus de apostas válidas.</div>
        <div>
          Cada vez que você completa uma tarefa de dinheiro bónus, você pode converter um dinheiro de bónus parcial em
          dinheiro real.
        </div>
        <div>
          Se você permanecer dinheiro bónus após a conversão, uma nova tarefa bónus moeny será criada com o bónus moeny
          restante como um todo.
        </div>
        <div>
          Se você tem dinheiro de bónus mas não tem tarefa de dinheiro de bónus, completar um depósito de qualquer
          montante criará automaticamente uma nova tarefa de dinheiro de bónus.
        </div>
      </div>
    </div>
  </ModalPopupComp>
</template>

<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: { ModalPopupComp },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  margin: 30px 40px;
}
.title {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-popup-title);
}
.rules {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-size: 12px;
  line-height: 22px;
  color: #ffffff;
  opacity: 0.8;
  text-align: left;
}
</style>
