<template>
  <div class="home-container">
    <div class="home-wrapper">
      <MobileHeader class="header" :title="title" :isShowRightBtn="isShowHeaderRightBtn"></MobileHeader>
      <VanConfigProvider class="provider" theme="dark">
        <router-view
          class="main"
          v-model:title="title"
          v-model:isShowHeaderRightBtn="isShowHeaderRightBtn"
        ></router-view>
      </VanConfigProvider>
    </div>
  </div>
</template>
<script>
import MobileHeader from "@/components/MobileHeader.vue";
export default {
  data() {
    return {
      title: "",
      isShowHeaderRightBtn: false,
    };
  },
  components: {
    MobileHeader,
  },
};
</script>
<style lang="scss" scoped>
.home-container {
  max-width: var(--max-width);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      position: fixed;
      z-index: 100;
    }

    .provider {
      display: flex;
      flex-direction: column;
      max-width: var(--max-width);
      width: 100%;
      position: fixed;
      bottom: 0;
      top: 50px;

      .main {
        flex: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
