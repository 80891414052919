<template>
  <div class="container">
    <div class="wrapper">
      <div class="game-header">
        <div class="back-wrapper" @click="goBack">
          <img src="../../assets/back.png" alt="" />
        </div>
        <div class="header-title">
          <div>{{ gameName }}</div>
          <div>{{ providerName }}</div>
        </div>
        <img class="service" src="@/assets/user/service_icon.png" alt="" @click="handleOfCS" />
      </div>

      <div class="m-game-wrapper">
        <img v-show="isImageLoading === false" :src="launcherUrl" alt="" @load="onImageLoad" @error="onImageError" />
        <div v-show="isImageLoading === true" class="placeholder">
          <img src="@/assets/launch_default.webp" />
        </div>

        <div class="m-tool">
          <div class="real" @click="onTapRealHandel">Começar jogo</div>
        </div>
      </div>

      <div class="recommend">
        <CardHeader
          :title="$t('recommended')"
          :icon="recommendIcon"
          :totalCount="recommendTotalCount"
          isShowMoreBtn
          isShowArrows
          @tap-left="movePrev"
          @tap-right="moveNext"
          @show-all="showAllCallBack"
        ></CardHeader>
        <horizontal-card :current-index="recommendIndex" :game-list="recommendList"></horizontal-card>
      </div>
      <div class="providers">
        <CardHeader
          :title="$t('game_providers')"
          isShowArrows
          :icon="providerIcon"
          @tap-left="providerListPreEvent"
          @tap-right="providerListNextEvent"
        ></CardHeader>
        <div class="list-container">
          <div
            class="list"
            v-for="(supplier, index) in supplierList"
            :key="index"
            :style="{ transform: 'translateX(' + supplierIndex * -392 + 'px)' }"
          >
            <provider-card
              class="p-item"
              :title="supplier.provider_name"
              :log="supplier.provider_logo"
              @click="showSupplierGames(supplier)"
            ></provider-card>
          </div>
        </div>
      </div>
    </div>
    <WalletEmptyModal
      v-show="isShowEmptyWalletTips"
      @close="isShowEmptyWalletTips = false"
      @depositEvent="handelOfDepositEvent"
    ></WalletEmptyModal>
    <dialog ref="needFirstPayDialog">
      <div class="dialog-content">
        <div class="dialog-box">
          <div class="dialog-title">Dica</div>
          <div class="dialog-des">Por favor, conclua o primeiro depósito para desbloquear o bônus</div>
          <img class="dialog-icon" src="@/assets/user/drawing_icon.png" alt="" />
          <div class="dialog-btn" @click="handelOfDepositEvent">Depositar</div>
        </div>
        <img
          class="dialog-close"
          src="@/assets/login/close_icon.png"
          alt=""
          @click="$refs.needFirstPayDialog.close()"
        />
      </div>
    </dialog>
  </div>
</template>

<script>
import WalletEmptyModal from "@/components/WalletEmptyModal.vue";
import ProviderCard from "@/components/ProviderCard.vue";
import CardHeader from "@/components/CardHeader.vue";
import HorizontalCard from "@/components/HorizontalCard.vue";
export default {
  components: {
    ProviderCard,
    CardHeader,
    HorizontalCard,
    WalletEmptyModal,
  },
  data() {
    return {
      gameId: "",
      gameUrl: "",
      gameHtml: "",
      gameName: "",
      gameLauncher: "",
      providerName: "",
      providerId: "",
      supplierList: [],
      supplierIndex: 0,
      providerIcon: "",
      recommendList: [],
      recommendIndex: 0,
      recommendIcon: "",
      recommendTotalCount: 0,
      isImageLoading: false,
      isShowEmptyWalletTips: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.isImageLoading = true;
    // 在每次路由到相同页面时执行的操作
    this.gameId = to.params.game;
    this.gameName = to.query.name;
    this.providerName = to.query.pName;
    this.providerId = to.query.pId;
    this.gameLauncher = to.query.launcherUrl;
    this.fetchRecommendGameListData();
    this.fetchProviderListData();
    next();
  },
  mounted() {
    this.isImageLoading = true;
    this.gameId = this.$route.params.game;
    this.gameName = this.$route.query.name;
    this.providerName = this.$route.query.pName;
    this.providerId = this.$route.query.pId;
    this.gameLauncher = this.$route.query.launcherUrl;
    this.fetchRecommendGameListData();
    this.fetchProviderListData();
  },
  computed: {
    launcherUrl() {
      return this.$global.data.cdn_url + this.gameLauncher;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    isPG() {
      if (this.providerName === null || this.providerName === undefined) {
        return false;
      }

      const name = this.providerName.toLowerCase();
      if (name === "pg" || name === "pgs") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    handleOfCS() {
      this.$router.push({ name: "m-service" });
    },
    handelOfDepositEvent() {
      this.isShowEmptyWalletTips = false;
      this.$router.push({ name: "m-deposit-page" });
    },
    onImageLoad() {
      this.isImageLoading = false;
    },
    onImageError() {
      this.isImageLoading = false;
    },
    onTapRealHandel() {
      const data = {
        action: "query_wallet",
        data: {},
      };

      this.$http
        .post("userWallet", data)
        .then((response) => {
          const need_first_pay = response.data.need_first_pay;
          if (need_first_pay) {
            this.$refs.needFirstPayDialog.showModal();
            return;
          }
          const insufficient_balance = response.data.insufficient_balance;
          if (insufficient_balance) {
            // 余额不足
            this.isShowEmptyWalletTips = true;
          } else {
            this.$router.push({
              path: "/m-playing/" + this.gameId,
              query: { type: 1, pId: this.providerId },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    /**
     * setup数据
     */
    setupData() {
      const params = { game_id: this.gameId, game_type: 1, game_provider: this.providerId };
      this.$http
        .post("updateSession", { data: params })
        .then((response) => {
          // 请求成功后的处理
          const data = response.data;

          if ("game_html" in data && data.game_html !== "" && data.game_html !== null) {
            this.gameHtml = data.game_html;
            return;
          }

          // 如果有game_url，直接使用
          if ("game_url" in data && data.game_url !== "" && data.game_url !== null) {
            this.gameUrl = data.game_url;
            return;
          }

          // 没有需要自己拼接
          let pgparamStr = "";
          let pgparam = this.userInfo.pgparam;
          for (let key in pgparam) {
            if (key === "game_url") {
              continue;
            }
            if (pgparam[key] != null && Object.prototype.hasOwnProperty.call(pgparam, key)) {
              pgparamStr += "&" + key + "=" + pgparam[key];
            }
          }
          let url =
            "https://m.pgsoft-games.com/" +
            this.gameId +
            "/index.html?ops=" +
            data.ops +
            "&op=" +
            data.ocp +
            "&btt=1" +
            pgparamStr +
            "&__refer=" +
            pgparam.game_url +
            "&or=static.pgsoft-games.com&__hv=1f8e1d3b";
          this.gameUrl = url;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error("error: ", error);
        });
    },
    /**
     * 供应商列表
     */
    fetchProviderListData() {
      const data = {
        action: "provider_list",
        data: {},
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          this.supplierList = response.data.provider_list;
          this.providerIcon = response.data.provider_icon;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    /**
     * 推荐游戏列表
     */
    fetchRecommendGameListData() {
      const data = {
        action: "game_recommend",
      };

      this.$http
        .post("gameCenter", data)
        .then((response) => {
          // 请求成功后的处理
          this.recommendList = response.data.game_list;
          this.recommendIcon = response.data.recommend_icon;
          this.recommendTotalCount = response.data.count;
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },
    /**
     * 游戏供应商item点击事件
     * @param {*} providerInfo
     */
    showSupplierGames(providerInfo) {
      this.$router.push({
        path: "/m-game-list/" + providerInfo.provider_name,
        query: { id: providerInfo.id, type: "provider" },
      });
    },
    /**
     * see all按钮点击回调
     */
    showAllCallBack() {
      this.$router.push({
        path: "/m-game-list/recommend",
        query: { id: 500, type: "category" },
      });
    },
    /**
     * 右箭头事件
     */
    moveNext() {
      let gameIndex = this.recommendIndex;
      let gameCount = this.recommendList.length;

      if (gameIndex < gameCount - 1) {
        gameIndex++;
      } else {
        gameIndex = gameCount - 1;
      }
      this.recommendIndex = gameIndex;
    },
    /**
     * 左箭头事件
     */
    movePrev() {
      let gameIndex = this.recommendIndex;

      if (gameIndex > 0) {
        gameIndex--;
      } else {
        gameIndex = 0;
      }

      this.recommendIndex = gameIndex;
    },
    /**
     * 全屏显示
     */
    goFullScreen() {
      const iframe = this.$refs.myIframe;
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        // Firefox
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        // IE/Edge
        iframe.msRequestFullscreen();
      }
    },
    goBack() {
      this.$router.back();
    },
    providerListPreEvent() {
      if (this.supplierIndex > 0) {
        this.supplierIndex--;
      } else {
        this.supplierIndex = 0;
      }
    },
    providerListNextEvent() {
      if (this.supplierIndex < this.supplierList.length - 1) {
        this.supplierIndex++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #06131c;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .wrapper {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin: 0 15px;

    .game-header {
      margin: 31px 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .back-wrapper {
        display: flex;
        img {
          width: 32px;
          height: 32px;
        }

        div {
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
          text-align: left;
        }
      }
      .header-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div:first-of-type {
          font-size: 17px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          margin-bottom: 2px;
        }

        div:last-of-type {
          font-size: 12px;
          font-weight: normal;
          text-align: center;
          color: #ffffff;
          opacity: 0.6;
        }
      }

      .service {
        width: 22px;
        height: auto;
      }
    }

    .m-game-wrapper {
      display: flex;
      flex-direction: column;

      img {
        border-radius: 14px;
        width: 100%;
        height: auto;
      }
      .m-tool {
        margin-top: 14px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .real {
          flex: 1;
          height: 40px;
          line-height: 40px;
          border-radius: 10px;
          background: #ffd400;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          color: #121110;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .demo-enable {
          flex: 1;
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;
          border-radius: 10px;
          border: 1px solid var(--cate-normal-bg);
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          color: var(--cate-text-normal-color);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .demo-disable {
          flex: 1;
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;
          border-radius: 10px;
          border: 1px solid #797878;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          color: #565656;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .recommend {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }

    .providers {
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;

      .list-container {
        margin-bottom: 18px;
        margin-top: 10px;
        overflow-x: scroll;
        display: flex;

        .list {
          display: flex;
          justify-content: flex-start;
          transition: transform 0.3s ease-in-out;
          flex-shrink: 0;

          .p-item {
            margin-right: 16px;
            width: 125px;
            height: auto;
          }
        }
      }
    }
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dialog-box {
      display: flex;
      flex-direction: column;

      background: #0a1f32;
      border-radius: 10px;
      padding: 20px 15px;

      .dialog-title {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }

      .dialog-des {
        margin-top: 15px;
        color: #ffffff;
        font-size: 18px;
        font-weight: normal;
      }

      .dialog-icon {
        margin-top: 23px;
        align-self: center;
        width: 45px;
        height: 45px;
      }

      .dialog-btn {
        margin-top: 23px;
        background: #33439e;
        height: 40px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        line-height: 40px;
        border-radius: 8px;
      }
    }
    .dialog-close {
      margin-top: 44px;
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: 768px) {
}
</style>
