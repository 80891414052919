<template>
  <!-- 首充活动充值页 -->
  <div class="container">
    <div class="header">
      <img src="@/assets/activity/pix_icon_green.png" alt="" />
      <div class="des">
        <div>Segurança dos fundos garantida</div>
        <div>Se o seu depósito não for creditado, entre em contato com o suporte</div>
      </div>
    </div>
    <div class="title">Escolha valor de depósito</div>
    <div class="collection">
      <div
        v-for="(item, index) in payAmountList"
        :key="index"
        class="item"
        :class="index === selIndex ? 'highlight' : 'normal'"
        @click="selIndex = index"
      >
        <div class="amount">
          <div>R$</div>
          <div>{{ item.amount }}</div>
        </div>
        <div class="give">+{{ item.give_coins }}</div>
      </div>
    </div>
    <div class="btn" @click="recharge">Depositar</div>

    <dialog ref="newerDialog">
      <div class="newer-dialog">
        <div class="newer-dialog-content">
          <div>Dica</div>
          <div>
            Novos usuários não são capazes de participar nessa atividade no mesmo dia <br />
            Você pode participar na primeira atividade de carga e receber mais recompensas
          </div>
          <div @click="handleOfDialogRecharge">Recargar</div>
        </div>
        <img src="@/assets/login/close_icon.png" alt="" @click="handleOfCloseNewerDialog" />
      </div>
    </dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      selIndex: -1,
      payAmountList: [],
    };
  },
  mounted() {
    this.$emit("update:title", "Depositar");
    this.fetchReward();
  },
  methods: {
    handleOfDialogRecharge() {
      this.$refs.newerDialog.close();
      this.$eventBus.emit("emitOfChangeTab", "deposit-page");
    },
    handleOfCloseNewerDialog() {
      this.$refs.newerDialog.close();
      this.$router.back();
    },
    async recharge() {
      if (this.selIndex === -1) {
        this.$toast.error(this.$t("sel_recharge_opt"));
        return;
      }
      const item = this.payAmountList[this.selIndex];
      const data = {
        action: "collection",
        data: {
          scene: "login",
          amount: parseInt(item.amount) * 100,
          activity_type: 4, // 每日活动(优惠充值)
          activity_id: this.$route.params.id,
        },
      };
      try {
        const response = await this.$http.post("pay", data);
        const payUrl = response.data.order.payUrl;
        this.$router.push({ name: "m-pay-page", query: { payUrl: payUrl } });
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchReward() {
      const data = {
        action: "get_activity_amount_list",
        data: {
          daily_activity_id: this.$route.params.id,
        },
      };
      try {
        const response = await this.$http.post("activity", data);
        this.payAmountList = response.data.pay_amount_list;
      } catch (error) {
        console.log("error", error);
        if (error.code === 107) {
          this.$refs.newerDialog.showModal();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .header {
    margin: 4px 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #20732b;
    height: 45px;
    border-radius: 10px;
    padding: 0 10px;

    img {
      width: 49px;
      height: 20px;
    }

    .des {
      display: flex;
      flex-direction: column;
      text-align: right;

      & > div:first-child {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
      }

      & > div:last-child {
        color: #ffffff;
        font-size: 9px;
        font-weight: 500;
      }
    }
  }

  .title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    margin: 0 15px;
    text-align: left;
  }

  .collection {
    margin: 20px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    gap: 10px; /* 设置适当的间距 */

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #0a1f32;
      border-radius: 8px;
      height: 60px;

      .amount {
        display: flex;
        color: #ffffff;
        font-weight: 500;

        & > div:first-child {
          margin-top: 7px;
          margin-right: 5px;
          font-size: 12px;
        }

        & > div:last-child {
          font-size: 20px;
        }
      }

      .give {
        color: #ffd400;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .highlight {
      background: #20732b;
    }

    .normal {
      background: #0a1f32;
    }
  }

  .btn {
    margin: 0 15px;
    background: #33439e;
    height: 45px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .newer-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .newer-dialog-content {
      margin: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #194164;
      border-radius: 10px;
      padding: 20px;
      height: 200px;

      & > div:nth-child(1) {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
      }

      & > div:nth-child(2) {
        text-align: left;
        color: #ffffff;
        font-size: 12px;
        font-weight: normal;
      }

      & > div:nth-child(3) {
        background: #3e7bb0;
        height: 40px;
        border-radius: 8px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & > img {
      margin-top: 22px;
      width: 32px;
      height: 32px;
    }
  }
}
</style>
