<template>
  <div class="context" @mouseenter="handlerMouseEnter" @mouseleave="handlerMouseLeave" @click="onTapGameCard">
    <div class="card" :class="{ active: isMouseOver }">
      <img v-if="!isImageLoading" :src="imgUrl" alt="" @load="onImageLoad" @error="onImageError" />
      <div v-else class="placeholder">
        <img src="@/assets/game_default.webp" />
        <div class="animate"></div>
      </div>

      <div class="mark" v-show="isMouseOver">
        <div class="title">{{ game.game_name_en }}</div>
        <img class="play-img" src="../assets/play.png" alt="" />
        <div class="subTitle">{{ game.provider_info.provider_name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    game: null,
  },
  data() {
    return {
      isMouseOver: false,
      isImageLoading: false,
    };
  },
  mounted() {
    this.isImageLoading = true;
  },
  methods: {
    onImageLoad() {
      setTimeout(() => {
        this.isImageLoading = false;
      }, 500);
    },
    onImageError() {
      this.isImageLoading = false;
    },
    handlerMouseEnter() {
      this.isMouseOver = true;
    },
    handlerMouseLeave() {
      this.isMouseOver = false;
    },
    onTapGameCard() {
      this.$tools.userTrackReport({ track_id: "14", track_link_id: this.game.game_id });
      if (this.$store.getters.isLoggedIn) {
        const preStr = this.$tools.isMobile() ? "/m-" : "/";
        this.$router.push({
          path: preStr + "game-details/" + this.game.game_id,
          query: {
            name: this.game.game_name_en,
            pName: this.game.provider_info.provider_name,
            pId: this.game.game_provider,
            launcherUrl: this.game.poster_url,
          },
        });
      } else {
        if (window.location.href.indexOf("casino") > -1) {
          this.$eventBus.emit("isNeedLogin");
        } else {
          this.$router.push({
            name: "casino",
          });
          setTimeout(() => {
            this.$eventBus.emit("isNeedLogin");
          }, 100);
        }
      }
    },
  },
  computed: {
    imgUrl() {
      return this.$global.data.cdn_url + this.game.image_url;
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes lazy-loading {
  0% {
    background-position-x: 150%;
  }
  100% {
    background-position-x: -150%;
  }
}
.context {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .active {
    transform: translateY(-10px);
  }

  .card:active {
    transform: scale(0.97);
  }

  .card {
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    position: relative;
    display: flex;
    flex-direction: column;

    & > img {
      width: 100%;
      height: 100%;
    }

    .placeholder {
      width: 100%;
      height: 100%;
      background: url("../assets/game_default.webp");
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      & > img {
        width: 100%;
        height: 100%;
      }

      .animate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../assets/game_default2.png") transparent no-repeat;
        background-size: 200%;
        background-position-y: 40%;
        animation: lazy-loading 1.2s linear infinite;
      }
    }

    .mark {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(12, 13, 15, 0.92);
      display: flex;
      flex-direction: column;
      // border-radius: 15px;

      .title {
        margin: auto;
        height: 18px;
        font-size: 22px;
        font-weight: 600;
        color: white;
      }

      .play-img {
        margin: auto;
        width: 40px;
        height: auto;
      }

      .subTitle {
        margin: auto;
        height: 14px;
        color: white;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
</style>
