import { createStore } from "vuex";

/**
 * 本地保存登录的用户信息
 * @param {*} store 对象
 */
function persistState(store) {
  const localState = localStorage.getItem("bragiua-state");
  if (localState) {
    store.replaceState(JSON.parse(localState));
  }

  store.subscribe((mutation, state) => {
    localStorage.setItem("bragiua-state", JSON.stringify(state));
  });
}

const store = createStore({
  state() {
    return {
      userInfo: null,
      loginInfo: null,
      channel: "",
      activityInfo: null,
      registerInfo: null,
      lastLoginType: 0,
    };
  },
  temp() {
    return {
      cassinoData: null,
      gameListData: null,
    };
  },
  mutations: {
    setLastLoginType(state, lastLoginType) {
      state.lastLoginType = lastLoginType;
    },
    setRegisterInfo(state, registerInfo) {
      state.registerInfo = registerInfo;
    },
    clearActivityInfo(state) {
      state.activityInfo = null;
    },
    setActivityInfo(state, activityInfo) {
      state.activityInfo = activityInfo;
    },
    /**
     * 清除临时缓存
     * @param {*} temp
     */
    clearTempCache(temp) {
      temp.cassinoData = null;
      temp.gameListData = null;
    },
    clearGameListCache(temp) {
      temp.gameListData = null;
    },
    setGameListData(temp, gameListData) {
      temp.gameListData = gameListData;
    },
    setCassinoData(temp, cassinoData) {
      temp.cassinoData = cassinoData;
    },
    setChannel(state, channel) {
      state.channel = channel;
    },
    // 定义你的变更/修改状态的方法
    /**
     * 大厅init接口返回的info
     * @param {*} state
     * @param {object} userInfo
     */
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },

    /**
     * 保存登录的信息
     * @param {*} state
     * @param {object} loginInfo
     */
    setLoginInfo(state, loginInfo) {
      state.loginInfo = loginInfo;
    },

    /**
     * 清空保存的info
     * @param {*} state
     */
    clearInfo(state) {
      state.userInfo = null;
      state.loginInfo = null;
      state.channel = "";
      state.activityInfo = null;
    },
  },
  actions: {
    // 定义你的异步操作方法
  },
  getters: {
    isLoggedIn: (state) => !!state.userInfo,
    userInfo: (state) => state.userInfo,
    sessionid: (state) => state.loginInfo?.sessionid,
    ucode: (state) => state.loginInfo?.ucode,
    channel: (state) => state.channel,
    cassinoData: (temp) => temp.cassinoData,
    gameListData: (temp) => temp.gameListData,
    activityId: (state) => state.activityInfo?.activityId,
    isJoinActivity: (state) => state.activityInfo?.isJoinActivity,
    registerInfo: (state) => state.registerInfo,
    lastLoginType: (state) => state.lastLoginType,
  },
  modules: {
    // 如果有模块化的需求，可以在这里定义子模块
  },
  plugins: [persistState],
});

/**
 * 获取登录用户的业务编码ucode
 * @returns 返回ucode
 */
export function userCode() {
  if (store.getters.isLoggedIn) {
    return store.getters.ucode;
  } else {
    return "";
  }
}

export function sessionId() {
  if (store.getters.isLoggedIn) {
    return store.getters.sessionid;
  } else {
    return "";
  }
}

export function channel() {
  return store.getters.channel;
}

export default store;
