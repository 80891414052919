<template>
  <div class="popup-container">
    <div class="wrapper" :style="{ width: width + 'px' }">
      <img v-if="isShowDefaultCloseBtn" src="../assets/login/close.png" alt="" @click="handlerOfClose" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contextWidth: {
      type: Number,
      default: 564,
    },
    isShowDefaultCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      width: Math.min(window.innerWidth, this.contextWidth),
    };
  },
  methods: {
    handlerOfClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrapper {
    max-height: 100vh;
    position: relative;
    border-radius: 8px;
    background: var(--color-popup-bg);
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    img {
      position: absolute;
      right: 14px;
      top: 14px;
      cursor: pointer;
      opacity: 0.6;
      width: 14px;
      height: auto;
    }
  }
}
@media (max-width: 768px) {
  .popup-container {
    justify-content: flex-end;
  }
}
</style>
