<template>
  <ModalPopupComp :contextWidth="560">
    <div class="box" @click="isShowPixTypeList = false">
      <div class="title">Safety Certification</div>
      <div class="section">
        <div class="section-title">Withdrawal method</div>
        <div class="item-bg">
          <div class="item">PIX</div>
        </div>
      </div>
      <div class="section">
        <div class="section-title">Withdrawal Information</div>
        <div class="item-bg">
          <div v-if="cpfLock">{{ customerCert }}</div>
          <input v-else v-model="customerCert" type="text" placeholder="CPF" />
          <img v-if="cpfLock" src="../../../assets/lock.png" alt="" />
        </div>
        <div class="item-bg">
          <div>{{ email }}</div>
          <img src="../../../assets/lock.png" alt="" />
        </div>
        <div class="item-bg">
          <div>{{ phone }}</div>
          <img src="../../../assets/lock.png" alt="" />
        </div>
        <div class="item-bg" @click.stop="isShowPixTypeList = true">
          <div :class="pixTypeIndex === -1 ? 'item_placeholder' : 'item'">
            {{ pixTypeIndex === -1 ? "PIX-typology" : pixTypeList[pixTypeIndex] }}
          </div>
          <img src="../../../assets/header/down_arrow.png" alt="" />
          <div class="pix-type" v-if="isShowPixTypeList === true">
            <div v-for="(type, index) in pixTypeList" :key="index" @click.stop="selPixTypeEvent(index)">
              {{ type }}
            </div>
          </div>
        </div>
        <div class="item-bg">
          <input type="text" v-model="pixId" placeholder="PIX ID" :disabled="isLockForPixId" />
        </div>
      </div>
      <div class="section">
        <div class="section-title2">
          <div>Withdrawable amount:</div>
          <div class="unit">&nbsp;R$&nbsp;</div>
          <div class="money">{{ withdrawable }}</div>
        </div>
        <div class="item-bg">
          <input type="number" v-model="withdrawalbelValue" placeholder="Withdrawal amount" />
        </div>
      </div>
      <div class="des">
        <div>Minimum withdrawal amount</div>
        <div>&nbsp;R$50</div>
      </div>
      <div class="btn" @click="btnClickHandle">{{ $t("withdrawal") }}</div>
    </div>
  </ModalPopupComp>
</template>

<script>
import ModalPopupComp from "@/components/ModalPopupComp.vue";
export default {
  components: { ModalPopupComp },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
    withdrawable: {
      type: Number,
    },
    amountType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      withdrawalbelValue: 0,
      isShowPixTypeList: false,
      pixTypeIndex: -1,
      pixTypeList: ["EMAIL", "PHONE", "CPF", "CNPJ", "EVP"],
      pixId: "",
      customerCert: this.userInfo.customer_cert,
      isLockForPixId: false,
    };
  },
  computed: {
    cpfLock() {
      if (this.userInfo === null) {
        return false;
      }
      return this.userInfo.customer_cert_lock === 1;
    },
    email() {
      if (this.userInfo === null) {
        return "";
      }
      return this.userInfo.email;
    },
    phone() {
      if (this.userInfo === null) {
        return "";
      }
      return this.userInfo.phone;
    },
  },
  methods: {
    selPixTypeEvent(index) {
      this.pixTypeIndex = index;
      this.isShowPixTypeList = false;
      // CPF && CPF未锁定
      if (index === 2) {
        this.pixId = this.userInfo.customer_cert;
        this.isLockForPixId = this.userInfo.customer_cert_lock === 1;
      } else {
        this.pixId = "";
        this.isLockForPixId = false;
      }
    },
    btnClickHandle() {
      if (this.pixTypeIndex === -1) {
        this.$toast.error("please select pix type");
        return;
      }

      // if (this.withdrawalbelValue < 50) {
      //   this.$toast.error("withdrawalbel value need > 50");
      //   return;
      // }

      this.postWithdrawal(this.withdrawalbelValue);
    },
    postWithdrawal(amount) {
      const data = {
        action: "user_withdrawal",
        data: {
          amount,
          amount_type: this.amountType,
          account_type: this.pixTypeList[this.pixTypeIndex],
          account_num: this.pixId,
          customer_cert: this.customerCert,
          pay_method: "hkpPay",
        },
      };

      this.$http
        .post("userWallet", data)
        .then(() => {
          this.$emit("endDrawMoney2Event");
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--color-popup-title);
  margin: 30px auto 0;
}

.section {
  margin: 30px 40px 0;
  display: flex;
  flex-direction: column;
  .section-title {
    font-size: 12px;
    font-weight: 500;
    color: #e9c97e;
    text-align: left;
  }

  .section-title2 {
    font-size: 14px;
    font-weight: 500;
    color: #e9c97e;
    display: flex;
    align-items: center;

    .unit {
      color: #b6b6b6;
      font-size: 18px;
      font-weight: 700;
    }

    .money {
      color: #e9c97e;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .item-bg {
    margin-top: 12px;
    display: flex;
    border-radius: 8px;
    height: 40px;
    background: #202020;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 0 20px;
    position: relative;

    img {
      width: 20px;
      height: 20px;
    }
    .item {
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      opacity: 0.8;
    }

    .item_placeholder {
      font-size: 12px;
      font-weight: 600;
      color: #777777;
      opacity: 0.8;
    }

    input {
      flex: 1;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      opacity: 0.8;
    }

    div {
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
    }

    .pix-type {
      z-index: 1;
      left: 0;
      top: 45px;
      width: 100%;
      background: #202020;
      position: absolute;
      display: flex;
      flex-direction: column;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;

      div {
        height: 40px;
        line-height: 40px;
      }
    }
  }
}

.des {
  margin: 40px 40px 0;
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  opacity: 0.6;
  text-align: left;
  display: flex;

  div:last-child {
    color: #15ad51;
  }
}

.btn {
  margin: 12px 40px 40px;
  height: 40px;
  font-size: 16px;
  font-weight: 800;
  color: #070704;
  line-height: 40px;
}
</style>
