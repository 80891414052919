<template>
  <div class="email">
    <div class="wrapper">
      <div class="title">Message</div>
      <mine-empty></mine-empty>
      <div class="context">
        <div class="menu">
          <div class="cell" v-for="(item, index) in list" :key="index">
            <div class="box">
              <div class="summary">
                <span class="summary-title">{{ item.title }}</span>
                <span class="unread" v-if="!item.state">未读</span>
              </div>
              <div class="date">{{ item.date }}}</div>
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="d-wrapper">
            <div class="d-title">{{ list[index].title }}</div>
            <div class="d-date">{{ list[index].date }}</div>
            <div class="d-context" v-html="list[index].detail"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MineEmpty from "@/components/MineEmpty.vue";
export default {
  data() {
    let item = {
      date: "2023/00/00 00:00:00",
      title: "Reducing Carbon Emissions: EU Targets and Policies,Reducing Carbon Emissions: EU Targets and Policies",
      state: 0,
      detail:
        "The European Union has set ambitious targets to combat climate change as part of the European Green Deal. The recently adopted European Climate Law commits the EU to reducing net greenhouse gas emissions by at least 55% by 2030, a significant increase from the current 40% reduction target. Additionally, the Climate Law legally binds the EU to achieve climate neutrality by 2050. To realize these objectives, the EU has introduced the Fit for 55 in 2030 package, comprising a series of interconnected revised and new laws related to climate and energy. <br><br> To tackle carbon emissions from the industry, the EU has implemented the Emissions Trading System (ETS), the world's leading carbon market. Under this system, companies must obtain permits for each tonne of CO2 they emit and acquire these permits through auctions. The ETS covers about 40% of total EU greenhouse gas emissions, including around 10,000 power stations and manufacturing plants within the EU. To align with the emission reduction targets of the European Green Deal, the ETS underwent a significant update in April 2023, with emissions cuts set at 62% by 2030, based on 2005 levels.  <br><br><br><br><br><br> Ber Mace 团队<br> 我们提供24小时客户服务，您可通过BetEagle.cs@gmail.com邮件方式 或Telegram搜索@BetEagle轻松和我们优秀的客服团队联系并获得帮助。<br><br>祝您开心",
    };

    const list = Array.from({ length: 4 }).fill(item);

    return {
      list,
      index: 0,
    };
  },
  components: { MineEmpty },
};
</script>

<style lang="scss" scoped>
.email {
  display: flex;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 140px;
    .title {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      text-align: left;
    }

    .context {
      display: flex;
      .menu {
        padding-top: 10px;
        background: #1a1919;
        border-radius: 8px;
        width: 420px;

        display: flex;
        flex-direction: column;

        .cell {
          display: flex;

          .box {
            margin-left: 20px;
            margin-right: 20px;
            border-bottom: 1px rgba($color: #ffffff, $alpha: 0.08) solid;
            display: flex;
            flex-direction: column;
            .summary {
              margin-top: 13px;
              text-align: left;
              .summary-title {
                text-align: left;
                line-height: 22px;
                font-size: 12px;
                color: rgba($color: #ffffff, $alpha: 0.8);
              }

              .unread {
                padding: 2px 13px;
                background: #322422;
                width: 24px;
                height: 16px;
                border-radius: 4px;
                font-size: 12px;
                text-align: center;
                color: #d8776c;
              }
            }
            .date {
              margin-top: 4px;
              margin-bottom: 13px;
              text-align: left;
              font-size: 12px;
              color: rgba($color: #ffffff, $alpha: 0.4);
            }
          }
        }
      }
      .detail {
        flex: 1;
        margin-left: 20px;
        background: #1a1919;
        border-radius: 8px;
        display: flex;

        .d-wrapper {
          margin: 30px 40px;
          display: flex;
          flex-direction: column;

          .d-title {
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            text-align: left;
          }
          .d-date {
            margin-top: 6px;
            font-size: 12px;
            color: rgba($color: #ffffff, $alpha: 0.4);
            text-align: left;
          }

          .d-context {
            margin-top: 30px;
            flex: 1;
            font-size: 12px;
            color: rgba($color: #ffffff, $alpha: 0.8);
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
