<template>
  <div class="box">
    <label class="switch">
      <input type="checkbox" v-model="isChecked" @change="toggleSwitch" />
      <span class="slider"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      isChecked: this.isOpen,
    };
  },
  methods: {
    toggleSwitch() {
      // 处理开关状态的改变逻辑
      console.log("Switch is toggled");
      // 如果需要发送开关状态给父组件或执行其他操作，请在此处编写代码
      this.$emit("ChangeCheck", this.isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
}
.switch {
  margin: auto;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #121110;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;

  background: rgba($color: #ffffff, $alpha: 0.4);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  background: #e9c97e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e9c97e;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
</style>
