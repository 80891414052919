<template>
  <div class="dialog-content">
    <div class="wrapper">
      <div class="header">
        <div>Explicação</div>
        <img v-if="headerImgUrl !== ''" :src="headerImgPath" alt="" />
        <div class="close-box">
          <img src="@/assets/award-task/close.webp" alt="" @click="$emit('closeEvent')" />
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headerImgPath: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  display: flex;
  flex-direction: column;

  & > .wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #0a1f32;

    & > .header {
      border-radius: 10px 10px 0px 0px;
      background: #132f48;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;

      & > div:first-child {
        flex: 1;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        text-align: left;
      }

      & > img:first-of-type {
        width: 70px;
        height: 48px;
      }

      & > .close-box {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        & > img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
