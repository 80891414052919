<template>
  <div class="context" @mouseenter="handlerMouseEnter" @mouseleave="handlerMouseLeave">
    <div class="card" :class="{ active: isMouseOver }">
      <img class="bg" :src="logUrl" alt="" />
      <div class="mark" v-show="isMouseOver">
        <div class="title">{{ title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    log: String,
  },
  data() {
    return {
      isMouseOver: false,
      logUrl: this.$global.data.cdn_url + this.log,
    };
  },
  methods: {
    handlerMouseEnter() {
      this.isMouseOver = true;
    },
    handlerMouseLeave() {
      this.isMouseOver = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.context {
  display: flex;
  flex-direction: column;

  .card {
    position: relative;
    display: flex;
    flex-direction: column;

    .bg {
      width: 100%;
      height: 100%;
    }
    .mark {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(12, 13, 15, 0.92);
      display: flex;
      flex-direction: column;
      border-radius: 15px;

      .title {
        margin: auto;
        font-size: 22px;
        font-weight: 600;
        height: 18px;
        color: white;
      }
    }
  }
}
</style>
