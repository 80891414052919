import axios from "axios";
import store from "@/store/index";
import { userCode, sessionId, channel } from "../store/index";
import global from "./global.js";
import tools from "./tools.js";
import { load } from "@/utils/loading.js";
import eventBus from "@/utils/EventBus";
import router from "@/router/index";

import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
const $toast = useToast();
const toastConfig = {
  position: "top",
  duration: 3000,
};

let urlDomain = location.origin + "/";
if (urlDomain.indexOf("localhost") > -1 || urlDomain.indexOf("127.0.0.1") > -1 || urlDomain.indexOf("192.168") > -1) {
  urlDomain = "https://developr.corp.betmace.com/";
  // urlDomain = "https://test.BetEagle.com/";
  // urlDomain = "https://www.BetEagle.com/";
}

// 创建一个 axios 实例
const instance = axios.create({
  baseURL: urlDomain + "gateway?cmd=", // 设置接口的基础 URL
  // timeout: 5000, // 设置请求超时时间
  headers: {
    "Content-Type": "application/json", // 设置请求头
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // console.log("config:", config);

    // 客户端版本号
    const ver = "ver(" + global.version + ");";

    // (设备类型#运行平台)， 设备类型：ios / android， 运行平台：pc / mobile
    const appPlatform = tools.isMobile ? "mobile" : "pc";
    const device = "device(" + navigator.platform + "#" + appPlatform + ");";

    // (国家#语言)，Intl.Locale().region 和 Intl.Locale().language
    const system = "system(" + global.country + "#" + navigator.language + ");";

    let gameClient = ver + device + system;
    const channelStr = channel();
    if (channelStr !== "" && channelStr !== undefined && channelStr !== null) {
      gameClient += "channel(" + channelStr + ");";
    } else {
      // console.log("no channel:", config.url);
    }
    // console.log("gameClient:", gameClient);

    config.headers["game-client"] = gameClient;
    // config.headers["sing-token"] = "abcdef123456";
    // config.headers["user-agent"] = "";

    if (config.url === "updateSession") {
      config.baseURL = urlDomain + "game/checkVerify?cmd=";
    }

    if (config.url === "pay") {
      config.baseURL = urlDomain + "game/pay?cmd=";
    }

    if (!config.data.cmd) {
      // 添加cmd参数
      config.data.cmd = config.url;
    }

    if (config.url !== "login" || config.data.action === "logout") {
      // 添加ucode参数
      let ucode = userCode();
      config.url += "&ucode=" + ucode;
      config.data.ucode = ucode;

      // 添加sessionid参数
      let sId = sessionId();
      config.data.sessionid = sId;
    }

    if (
      !(location.href.includes("game-details") && config.data.action === "query_wallet") &&
      config.data.action !== "user_track_report" &&
      config.data.action !== "user_heartbeat_report" &&
      config.data.action !== "aggregate_data"
    ) {
      load.show("loading...");
    }
    return config;
  },
  (error) => {
    // 请求出错时的处理
    $toast.info(error, toastConfig);
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    setTimeout(() => {
      load.hide();
    }, 300);

    const params = JSON.parse(response.config.data);
    // if (params.cmd === "login" && response.data.rcode === null) {
    //   return Promise.resolve(response.data);
    // }

    if (params.cmd === "heartbeat") {
      // 心跳
      // return Promise.resolve(response.data);
      return;
    }

    if (response.data.rcode === 401) {
      // session id 过期
      store.commit("clearInfo");
      eventBus.emit("logoutSuccessHandle");
      router.push({ name: "casino" });
    }

    if (response.data.rcode === 107 && params.cmd === "userVip") {
      // 会员无参与资格返回rcode=107
      // 如果黄金俱乐部活动已关闭，返回错误信息，rcode=107
      // 如果用户当前等级无法参与黄金俱乐部，返回错误信息，rcode=107
      return Promise.reject({
        action: params.action,
        cmd: params.cmd,
        msg: response.data.errmsg,
        code: response.data.rcode,
      });
    }

    if (response.data.rcode === 30001) {
      // 接口报错业务弹窗
      return Promise.reject({
        action: params.action,
        cmd: params.cmd,
        msg: response.data.errmsg,
        code: response.data.rcode,
      });
    }

    if (response.data.rcode != 100) {
      // 业务错误
      showToast(response.data.errmsg);
      return Promise.reject({
        action: params.action,
        cmd: params.cmd,
        msg: response.data.errmsg,
        code: response.data.rcode,
      });
    }

    // session id是否有变化
    const sid = sessionId();
    if (response.data.sessionid !== null && response.data.sessionid !== "" && response.data.sessionid !== sid) {
      const uid = userCode();
      const loginInfo = {
        sessionid: response.data.sessionid,
        ucode: uid,
      };
      store.commit("setLoginInfo", loginInfo);
    }

    // 请求成功后的处理
    return Promise.resolve(response.data);
  },
  (error) => {
    // 请求失败后的处理
    load.hide();
    showToast(error);
    return Promise.reject(error);
  }
);

let lastTimestamp = 0;
let lastToastErrMsg = "";
function showToast(errMsg) {
  // 获取当前时间戳
  const timestamp = new Date().getTime();
  if (timestamp - lastTimestamp < 1000 && lastToastErrMsg === errMsg) {
    return;
  }

  $toast.info(errMsg, toastConfig);
  lastTimestamp = timestamp;
  lastToastErrMsg = errMsg;
}

export default instance;
